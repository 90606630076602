import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "redux/root-reducer";
import FormHeader from "toolkit/components/FormHeader";

import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";
import SpinningButton from "../../toolkit/components/SpinningButton";
import BearsightPasswordInput from "toolkit/components/BearsightPasswordInput";
import  actions  from "../redux/authentication-actions";
import { actions as systemActions } from "../../redux/system-actions";
import {
  notEmptyValidation,
  matchValidation
} from "toolkit/validations";
import { useStateReducer } from "toolkit/state-reducer";

const mapState = (state: RootState) => ({
  user: state.authentication.user,
  loading: state.authentication.changePassword.loading,
  success: state.authentication.changePassword.success,
  error: state.authentication.changePassword.error,
});

const mapDispatch = {
  changePassword: actions.changePassword,
  notify: systemActions.notify,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

interface ChangePasswordState {
  oldPassword?: string;
  newPassword?: string;
  confirmNewPassword?: string;
  submitted: boolean;
}

function ChangePassword({
  user,
  loading,
  success,
  error,
  changePassword,
  notify,
}: Props) {
  const initialState: ChangePasswordState = {
    submitted: false,
    oldPassword: undefined,
    newPassword: undefined,
    confirmNewPassword: undefined,
  };

  const [state, setState] = useStateReducer<ChangePasswordState>(
    initialState
  );

  const {
    oldPassword,
    newPassword,
    confirmNewPassword,
    submitted
  } = state;

  const formIsValid: boolean =
    !notEmptyValidation(oldPassword) &&
    !notEmptyValidation(newPassword) &&
    !notEmptyValidation(confirmNewPassword) &&
    !matchValidation(newPassword)(confirmNewPassword);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setState({ submitted: true });
    if (
      user &&
      oldPassword &&
      newPassword &&
      confirmNewPassword &&
      formIsValid
    ) {
      changePassword(user.username,oldPassword,newPassword);
    }
  };

  useEffect(() => {
    if (success) {
      notify("Password Change", "Password changed successfully");
      setState({submitted: false});
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      notify(
        "Password Change",
        typeof error === "string" ? error : "Failed to change password.",
        "danger"
      );
      setState({submitted: false});
    }
  }, [error]);

  return (
    <>
      <FormHeader name="Change Password" parentName="My Account" />
      <Container className="mt--6 d-flex justify-content-center" fluid>
        <Card className="col-lg-6">
          <CardHeader>
            <h3 className="mb-0">CHANGE PASSWORD</h3>
          </CardHeader>
          <CardBody>
            <Form
              onSubmit={handleSubmit}
              className="needs-validation"
              noValidate
            >
              <Row>
                <Col>
                  <BearsightPasswordInput
                    disabled={loading}
                    label="Old password"
                    onChange={(value) => setState({ oldPassword: value })}
                    validation={notEmptyValidation}
                    submitted={submitted}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BearsightPasswordInput
                    disabled={loading}
                    label="New password"
                    onChange={(value) => setState({ newPassword: value })}
                    validation={notEmptyValidation}
                    submitted={submitted}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BearsightPasswordInput
                    disabled={loading}
                    label="Confirm new password"
                    onChange={(value) => setState({ confirmNewPassword: value })}
                    validation={matchValidation(state.newPassword)}
                    error="Passwords don't match"
                    submitted={submitted}
                  />
                </Col>
              </Row>
              <Row>
                <SpinningButton
                icon="ni ni-lock-circle-open"
                color="default"
                type="submit"
                loading={loading}
                text="Change Password"
                />
            </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default connector(ChangePassword);
