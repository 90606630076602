import React, { useState, useEffect } from "react";
import classnames from "classnames";
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";

interface Props {
  label?: string;
  error?: string;
  onChange?: (value: string) => void;
  validation?: (value?: string) => boolean;
  submitted?: boolean;
  disabled?: boolean;
}

export default function BearsightPasswordInput({
  label,
  error,
  onChange,
  validation,
  submitted,
  disabled,
}: Props): JSX.Element {
  const [blured, setBlured] = useState(false);
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  const isSubmitted: boolean = !!submitted;
  const invalid =
    (blured || isSubmitted) && validation ? validation(value) : false;

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  return (
    <FormGroup>
      <label className="form-control-label">{label ? label : "Password"}</label>

      <InputGroup
        className={classnames("input-group-merge", {
          focused: focus,
        })}
      >
        <Input
          disabled={disabled}
          placeholder={label ? label : "Password"}
          onChange={(e: any) => setValue(e.target.value)}
          type={showPassword ? "text" : "password"}
          onFocus={(e) => setFocus(true)}
          onBlur={(e) => {
            setFocus(false);
            setBlured(true);
          }}
        />
        <InputGroupAddon addonType="append" onClick={toggleShowPassword}>
          <InputGroupText>
            <i className={showPassword ? "fas fa-eye" : "fas fa-eye-slash"} />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      {invalid && (
        <div className="text-danger">
          <small>{error ? error : "This value is required."}</small>
        </div>
      )}
    </FormGroup>
  );
}
