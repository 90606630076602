import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "redux/root-reducer";

import { Table, Row, Form, Modal, Badge, Spinner } from "reactstrap";
import { actions as clientActions } from "../redux/client-actions";
import { actions as systemActions } from "../../redux/system-actions";
import { useStateReducer } from "toolkit/state-reducer";
import IconButton from "toolkit/components/IconButton";
import BearsightTextInput from "toolkit/components/BearsightTextInput";
import EditBudget from "./EditBudget";
import { Client } from "./services/client";
import { selectClients } from "clients/redux/client-selector";
import BearsightPagination from "toolkit/components/BearsightPagination";
import { useDebounce } from "use-debounce";
import { getActive } from "toolkit/clock";

const mapState = (state: RootState) => ({
  loading: state.clients.loading,
  clients: selectClients(state.clients),
  currentPage: state.clients.clientsPage,
  totalPages: state.clients.clientsTotalPages,
  pageSize: state.clients.clientsPageSize,
});

const mapDispatch = {
  loadClients: clientActions.loadClients,
  notify: systemActions.notify,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

interface RecordingsState {
  filteringQuery: string;
  selectedClient?: Client;
  editOpened: boolean;
}

function ClientsTable({
  loading,
  clients,
  totalPages,
  currentPage,
  pageSize,
  loadClients,
  notify,
}: Props) {
  const initialState: RecordingsState = {
    filteringQuery: "",
    editOpened: false,
  };

  const [state, setState] = useStateReducer<RecordingsState>(initialState);

  const { filteringQuery, selectedClient, editOpened } = state;

  useEffect(() => {
    loadClients(currentPage, pageSize);
  }, []);

  const [debouncedSearchTerm] = useDebounce(filteringQuery, 500);

  useEffect(() => {
    loadClients(currentPage, pageSize, debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (selectedClient) {
      const updateSelectedClient = clients.find(
        (client) => client.id === selectedClient.id
      );
      if (updateSelectedClient) {
        setState({ selectedClient: updateSelectedClient });
      }
    }
  }, [JSON.stringify(clients)]);

  const toggleEdit = () => {
    setState({ editOpened: !editOpened });
  };

  const handleEdit = (client: Client) => {
    setState({ editOpened: true, selectedClient: client });
  };

  const handlePageSizeChanged = (pageSize: number) => {
    loadClients(currentPage, pageSize);
  };

  const handlePageChanged = (page: number) => {
    loadClients(page, pageSize);
  };

  if (loading && clients.length === 0) {
    return (
      <Row className="justify-content-center">
        <Spinner />
      </Row>
    );
  }

  return (
    <>
      <Form className="needs-validation mb-4" noValidate inline onSubmit={e => e.preventDefault()}>
        <BearsightTextInput
          label=""
          icon="fas fa-search"
          text={filteringQuery}
          onChange={(value) => setState({ filteringQuery: value })}
        />
      </Form>

      <Table className="align-items-center" responsive hover striped>
        <thead className="thead-light">
          <tr>
            {["Id", "Name", "Territory", "Budget", "Activation date", ""].map(
              (name) => (
                <th scope="col">{name}</th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => {
            return (
              <tr key={client.id}>
                <td>{client.id}</td>
                <th scope="row">
                  <span className="mb-0 text-sm">{client.name}</span>
                </th>
                <td>{getActive(client.territories)?.territory.name || "N/A"}</td>
                <td>
                  {client.activeBudget?.budget !== undefined ? (
                    <h2>
                      <Badge color="info" className="text-default">
                        {client.activeBudget?.budget.budget} $
                      </Badge>
                    </h2>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>
                  <span className="text-sm">
                    {client.activeBudget?.activationDate
                      ? new Date(
                          client.activeBudget?.activationDate
                        ).toLocaleDateString()
                      : "N/A"}
                  </span>
                </td>
                <td>
                  <IconButton
                    id={client.id}
                    color="default"
                    icon="fa fa-edit"
                    onClick={() => handleEdit(client)}
                    tooltip="Edit budget"
                    loading={loading}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <hr />
      <BearsightPagination
        currentPage={currentPage}
        totalPages={totalPages}
        pageSize={pageSize}
        onPageSizeChanged={handlePageSizeChanged}
        handleSearch={handlePageChanged}
      />

      <Modal
        className="modal-dialog-centered"
        isOpen={editOpened}
        size="xl"
        toggle={toggleEdit}
      >
        <EditBudget onClose={toggleEdit} client={selectedClient} from="BUDGET_MANAGEMENT" />
      </Modal>
    </>
  );
}

export default connector(ClientsTable);
