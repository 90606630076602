import React, { useEffect } from "react";

import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Form,
    Button,
} from "reactstrap";
import BearsightTextInput from "toolkit/components/BearsightTextInput";
import { useStateReducer } from "toolkit/state-reducer";
import SpinningButton from "toolkit/components/SpinningButton";
import { CreateClientPayload, Plan } from "clients/budgets/services/client";
import ModalCloseButton from "toolkit/components/ModalCloseButton";
import BearsightMultiselect from "toolkit/components/BearsightMultiselect";

interface Props {
    loading: boolean;
    availablePlans: Array<Plan>;
    onBack: () => void;
    onCreate: (client: CreateClientPayload) => void;
}

interface State {
    name?: string;
    selectedPlan?: Plan;
}

export default function CreateClient({
    loading,
    availablePlans,
    onBack,
    onCreate,
}: Props) {

    const [state, setState] = useStateReducer<State>({});
    const { name, selectedPlan } = state;

    const handleCreateClient = () => {
        console.log(selectedPlan);
        if (name) {
            onCreate({ 
                name: name, 
                planId:selectedPlan ? selectedPlan.id : undefined, 
            });
        }
    };

    useEffect(() => {
        if (!loading && name) {
            setState({ name: undefined });
            onBack();
        }
    }, [loading]);

    return (
        <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                    <ModalCloseButton onClose={onBack} />
                    <div className="text-muted text-center mt-2 mb-3">
                        <small>CREATE CLIENT</small>
                    </div>
                </CardHeader>
                <CardBody className="px-lg-5">
                    <Form role="form">

                        <BearsightTextInput
                            label="Name"
                            icon="fas fa-user"
                            text={name}
                            onChange={(value) => setState({ name: value })}
                        />
                        <BearsightMultiselect<Plan>
                            isMulti={false}
                            disabled={loading}
                            loading={loading}
                            options={availablePlans}
                            selected={selectedPlan ? [selectedPlan] : undefined}
                            label="Plan"
                            onChange={(value: Plan[]) =>
                                setState({ selectedPlan: value[0] })
                            }
                            displayValue="name"
                        />

                        <Row className="justify-content-end">
                            <SpinningButton
                                block={false}
                                icon="fa fa-save"
                                color="default"
                                type="submit"
                                loading={loading}
                                text="CREATE"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleCreateClient();
                                }}
                            />
                            <Button
                                color="link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onBack();
                                }}
                            >
                                <span className="text-default">CANCEL</span>
                            </Button>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </div>
    );
}
