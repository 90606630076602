import React, { CSSProperties } from "react";
import { FormGroup } from "reactstrap";
import makeAnimated from "react-select/animated";
import Select from "react-select";

interface Props<T> {
  defaultValue?: T[];
  options: T[];
  selected?: T[];
  displayValue?: keyof T;
  getDisplayValue?: (data: T) => string;
  label?: string;
  onChange: (data: T[]) => void;
  onClear?: () => void;
  submitted?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  styles?: CSSProperties;
  isNotForm?: boolean;
}

const animatedComponents = makeAnimated();

export default function BearsightMultiselect<T>({
  defaultValue,
  options,
  selected,
  displayValue,
  getDisplayValue,
  label,
  onChange,
  onClear,
  submitted,
  isMulti,
  isClearable,
  disabled,
  loading,
  className,
  styles,
  isNotForm,
}: Props<T>): JSX.Element {
  const select: JSX.Element = (
    <Select
      isDisabled={disabled}
      defaultValue={defaultValue}
      options={options}
      isClearable={isClearable === undefined ? true : isClearable}
      isSearchable
      isLoading={loading}
      isMulti={isMulti === undefined ? true : isMulti}
      components={animatedComponents}
      name={label}
      onChange={(value: any) => {
        if (Array.isArray(value)) {
          onChange(value);
        }
        if (value && !Array.isArray(value)) {
          onChange([value]);
        }
        if(value == null && onClear != null){
          onClear();
        }
      }}
      getOptionLabel={(data: any) =>
        getDisplayValue ? getDisplayValue(data) : (data[displayValue] as string)
      }
      getOptionValue={(data: any) => data["id"] as string}
      className={"basic-multi-select " + (className || "")}
      classNamePrefix="select"
    />
  );

  if (isNotForm) {
    return <div style={styles}> {select} </div>;
  }

  return (
    <FormGroup style={styles}>
      {label && <label className="form-control-label">{label}</label>}
      {select}
      {submitted && selected?.length === 0 && (
        <div className="text-danger">
          <small>{"This value is required."}</small>
        </div>
      )}
    </FormGroup>
  );
}
