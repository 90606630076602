import numbersService from "numbers/services/numbers-service";
import { TwilioNumber } from "numbers/services/twilio-number";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "redux/root-reducer";
import { Page } from "toolkit/pagination";


export enum NumbersActionType {
    LOADING = "NUMBERS_LOADING",
    FAILED = "NUMBERS_FAILED",
    CHANGE_TWILIO_NUMBERS = "NUMBERS_CHANGE_TWILIO_NUMBERS",
    CHANGE_TWILIO_NUMBERS_PAGE_SIZE = "NUMBERS_CHANGE_TWILIO_NUMBERS_PAGE_SIZE",
    UPDATE_TWILIO_NUMBER_SUCCESS = "NUMBERS_UPDATE_TWILIO_NUMBER_SUCCESS",
    UPDATE_TWILIO_NUMBER_FAILED = "NUMBERS_UPDATE_TWILIO_NUMBER_FAILED",
    

}


export interface LoadingAction {
    type: NumbersActionType.LOADING;
}

export interface FailedAction {
    type: NumbersActionType.FAILED,
    error: any;
}

export interface ChangeTwilioNumbersAction {
    type: NumbersActionType.CHANGE_TWILIO_NUMBERS;
    twilioNumbers: Page<TwilioNumber>;
}

export interface ChangeTwilioNumbersPageSizeAction {
    type: NumbersActionType.CHANGE_TWILIO_NUMBERS_PAGE_SIZE;
    pageSize: number;
}

export interface UpdateTwilioNumberSuccessAction {
    type: NumbersActionType.UPDATE_TWILIO_NUMBER_SUCCESS;
}

export interface UpdateTwilioNumberFailedAction {
    type: NumbersActionType.UPDATE_TWILIO_NUMBER_FAILED;
    error: any;
}

export type NumbersAction = 
    | LoadingAction
    | FailedAction
    | ChangeTwilioNumbersAction
    | ChangeTwilioNumbersPageSizeAction
    | UpdateTwilioNumberSuccessAction
    | UpdateTwilioNumberFailedAction

function loading(): LoadingAction{
    return {
        type: NumbersActionType.LOADING
    };
}

function failed(error:any): FailedAction {
    return {
        type: NumbersActionType.FAILED,
        error
    };
}

function updateTwilioNumberSuccess(): UpdateTwilioNumberSuccessAction{
    return {
        type: NumbersActionType.UPDATE_TWILIO_NUMBER_SUCCESS
    }
}

function updateTwilioNumberFailed(error: any): UpdateTwilioNumberFailedAction{
    return {
        type: NumbersActionType.UPDATE_TWILIO_NUMBER_FAILED,
        error: error
    }
}

function ChangeTwilioNumbers(twilioNumbers: Page<TwilioNumber>): ChangeTwilioNumbersAction {
    return {
        type: NumbersActionType.CHANGE_TWILIO_NUMBERS,
        twilioNumbers
    }
}

function changeTwilioNumbersPageSize(pageSize: number): ChangeTwilioNumbersPageSizeAction {
    return {
      type: NumbersActionType.CHANGE_TWILIO_NUMBERS_PAGE_SIZE,
      pageSize,
    };
  }

type NumbersThunkAction = ThunkAction<
  void,
  RootState,
  unknown,
  Action<NumbersActionType>
>;

const loadTwilioNumbers = (
    page: number,
    pageSize: number,
    phoneType?: string,
    query?: string,
    deleted?: boolean,
): NumbersThunkAction => async (dispatch, getState) => {
    dispatch(loading());
    try{
        const accessToken = getState().authentication?.tokens?.accessToken;
        if (accessToken) {
            const twilioNumbers = await numbersService.twilioNumbers(
                accessToken,
                page,
                pageSize,
                phoneType,
                query,
                deleted
            );
            dispatch(ChangeTwilioNumbers(twilioNumbers));
        }
    }catch(e){
        dispatch(failed(e));
    }
}

const updateTwilioNumber = (
    numberId: string,
    phoneType: string,
    deleted: boolean
): NumbersThunkAction => async (dispatch, getState) => {
    dispatch(loading());
    try{
        const accessToken = getState().authentication?.tokens?.accessToken;

        if(accessToken) {
            await numbersService.updateTwilioNumber(accessToken, numberId, phoneType, deleted);
            dispatch(updateTwilioNumberSuccess());
        }
    }catch(e) {
        dispatch(updateTwilioNumberFailed(e));
    }
}


export const actions = {
    loadTwilioNumbers,
    changeTwilioNumbersPageSize,
    updateTwilioNumber
}
