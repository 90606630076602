import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "redux/root-reducer";
import FormHeader from "toolkit/components/FormHeader";

import { Card, CardHeader, CardBody, Container } from "reactstrap";
import { actions as systemActions } from "../../redux/system-actions";
import { actions as recordingsActions } from "calls/redux/recordings-actions";
import RecordingsFilters from "./RecordingsFilters";
import RecordingsTable from "./RecordingsTable";
import RecordingsPagination from "./RecordingsPagination";

const mapState = (state: RootState) => ({
  error: state.recordings.error,
});

const mapDispatch = {
  initialize: recordingsActions.initialize,
  clearError: recordingsActions.clearError,
  notify: systemActions.notify,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

function RecordingsTagging({ error, initialize, clearError, notify }: Props) {
  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (error) {
      clearError();
      notify(
        "Recordings",
        typeof error === "string" ? error : "Operation failed!",
        "default"
      );
    }
  }, [error]);

  return (
    <>
      <FormHeader name="Recordings" parentName="Calls" />
      <Container className="mt--6 d-flex justify-content-center" fluid>
        <Card className="col-lg-12">
          <CardHeader>
            <h3 className="mb-0">RECORDINGS REVIEW</h3>
          </CardHeader>
          <CardBody>
            <RecordingsFilters />
            <hr />
            <RecordingsTable />
            <hr />
            <RecordingsPagination />
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default connector(RecordingsTagging);
