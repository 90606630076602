import React, { useEffect } from "react";
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import { useStateReducer } from "toolkit/state-reducer";
import { Moment } from "moment";

interface DateRangeState {
  startDate?: number;
  endDate?: number;
}

interface Props {
  startLabel: string;
  endLabel: string;
  defaultStartDate?: number;
  defaultEndDate?: number;
  className?: string;
  onStartDateChange: (date: number) => void;
  onEndDateChange: (date: number) => void;
}

export default function DateRange({
  startLabel,
  endLabel,
  defaultStartDate,
  defaultEndDate,
  className,
  onStartDateChange,
  onEndDateChange,
}: Props) {
  const [state, setState] = useStateReducer<DateRangeState>({
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  });

  useEffect(() => {
    setState({
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    });
  }, [defaultStartDate, defaultEndDate]);

  return (
    <>
      <FormGroup className={className}>
        <label className="form-control-label mr-3">{startLabel}</label>
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-calendar-grid-58" />
            </InputGroupText>
          </InputGroupAddon>
          <ReactDatetime
            value={state.startDate ? new Date(state.startDate) : undefined}
            inputProps={{
              placeholder: startLabel,
            }}
            timeFormat={false}
            renderDay={(props, currentDate, selectedDate) => {
              let classes = props.className;
              if (
                state.startDate &&
                state.endDate &&
                state.startDate === state.endDate
              ) {
              } else if (
                state.startDate &&
                state.endDate &&
                state.startDate === currentDate.valueOf()
              ) {
                classes += " start-date";
              } else if (
                state.startDate &&
                state.endDate &&
                new Date(state.startDate) < new Date(currentDate) &&
                new Date(state.endDate) > new Date(currentDate)
              ) {
                classes += " middle-date";
              } else if (
                state.endDate &&
                state.endDate === currentDate.valueOf()
              ) {
                classes += " end-date";
              }
              return (
                <td {...props} className={classes}>
                  {currentDate.date()}
                </td>
              );
            }}
            onChange={(e: Moment | string) => {
              if (typeof e !== "string") {
                const date = parseInt(e.valueOf().toString());
                setState({ startDate: date });
                onStartDateChange(date);
              }
            }}
          />
        </InputGroup>
      </FormGroup>

      <FormGroup className={className}>
        <label className="form-control-label mr-3">{endLabel}</label>
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-calendar-grid-58" />
            </InputGroupText>
          </InputGroupAddon>
          <ReactDatetime
            value={state.endDate ? new Date(state.endDate) : undefined}
            inputProps={{
              placeholder: endLabel,
            }}
            timeFormat={false}
            renderDay={(props, currentDate, selectedDate) => {
              let classes = props.className;
              if (
                state.startDate &&
                state.endDate &&
                state.startDate === state.endDate
              ) {
              } else if (
                state.startDate &&
                state.endDate &&
                state.startDate === currentDate.valueOf()
              ) {
                classes += " start-date";
              } else if (
                state.startDate &&
                state.endDate &&
                new Date(state.startDate) < new Date(currentDate) &&
                new Date(state.endDate) > new Date(currentDate)
              ) {
                classes += " middle-date";
              } else if (
                state.endDate &&
                state.endDate === currentDate.valueOf()
              ) {
                classes += " end-date";
              }
              return (
                <td {...props} className={classes}>
                  {currentDate.date()}
                </td>
              );
            }}
            onChange={(e: Moment | string) => {
              if (typeof e !== "string") {
                const date = parseInt(e.valueOf().toString());
                setState({ endDate: date });
                onEndDateChange(date);
              }
            }}
          />
        </InputGroup>
      </FormGroup>
    </>
  );
}
