import axios, { AxiosInstance } from "axios";
import { withAuthorization } from "../../toolkit/common-headers";
import { User, PortalUser } from "../redux/user";
import { KlipfolioUser } from "users/redux/klipfolio-user";
import { Page } from "toolkit/pagination";
import { ChangePasswordCredentials } from "authentication/services/credentials";

class UserService {
  private http: AxiosInstance;
  constructor(baseUrl: string) {
    this.http = axios.create({
      baseURL: baseUrl,
    });
  }

  async userInfo(accessToken: string): Promise<User> {
    const result = await this.http.get(
      "/users/me",
      withAuthorization(accessToken)
    );

    const data = result.data;
    return {
      id: data.id,
      username: data.username,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      admin: data.admin
    };
  }

  async users(accessToken: string): Promise<KlipfolioUser[]> {
    const result = await this.http.get(
      "/klipfolio/users",
      withAuthorization(accessToken)
    );

    const data = result.data;

    if (data === undefined) {
      return Promise.resolve([]);
    }

    return data.map((user: any) => {
      return {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        groups: user.groups,
        roles: user.roles,
      };
    });
  }

  async updateClient(
    accessToken: string,
    user: Partial<KlipfolioUser>
  ): Promise<void> {
    const { id, ...requestBody } = user;

    await this.http.put(
      `/klipfolio/users/${id}`,
      requestBody,
      withAuthorization(accessToken)
    );

    return Promise.resolve();
  }

  async deleteClient(accessToken: string, id: string): Promise<void> {
    await this.http.delete(
      `/klipfolio/users/${id}`,
      withAuthorization(accessToken)
    );

    return Promise.resolve();
  }

  async createUser(accessToken: string, user: PortalUser): Promise<void> {
    await this.http.post(`/users`, user, withAuthorization(accessToken));

    return Promise.resolve();
  }

  async portalUsers(
    accessToken: string,
    page: number,
    pageSize: number
    ): Promise<Page<User>> {
      const result = await this.http.get(
        "/users",
        withAuthorization(accessToken, {
          page,
          size: pageSize
        })
      );
      const data = result.data;
      if(data === undefined) {
        return Promise.resolve({
          page:0,
          totalPages:0,
          hasMorePages: false,
          data: [],
        });
      }
      const users: User[] = data.users.map((user: any) => {
        return {
          id: user.id,
          username: user.username,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          admin: user.admin
        }
      });
      return {
        page: data.page,
        totalPages: data.totalPages,
        hasMorePages: data.hasMorePages,
        data: users,
      };
    }

  async resetUserPassword(
    accessToken: string, 
    changePasswordCredentials: Partial<ChangePasswordCredentials>
    ) : Promise<void>{
      return new Promise((resolve, reject)=> {
        const { ...requestBody } = changePasswordCredentials;
        this.http.put(
          "/users/admin/action/change-password",
          requestBody,
          withAuthorization(accessToken),

        ).then(()=>{
          resolve();
        }).catch((error) => {
          if(error.response && error.response.data){
            let { message } = error.response.data;
            reject(message);
          }else{
            reject(error);
          }
        });
      });
  }

  async deleteProtalUser(accessToken: string, userId: string): Promise<void> {
    return new Promise((resolve, reject)=> {
      this.http.delete(
        `/users/${userId}`,
        withAuthorization(accessToken),

      ).then(()=>{
        resolve();
      }).catch((error) => {
        if(error.response && error.response.data){
          let { message } = error.response.data;
          reject(message);
        } else{
          reject(error);
        }
      });
    });
  }

  async grantAdmin(accessToken: string, username: string): Promise<void> {
    return new Promise((resolve, reject)=> {
      this.http.put(
        `/users/action/grant-admin-access`,
        { username },
        withAuthorization(accessToken),
      ).then(()=>{
        resolve();
      }).catch((error) => {
        if(error.response && error.response.data){
          let { message } = error.response.data;
          reject(message);
        } else{
          reject(error);
        }
      });
    });
  }

  async revokeAdmin(accessToken: string, username: string): Promise<void> {
    return new Promise((resolve, reject)=> {
      this.http.put(
        `/users/action/revoke-admin-access`,
        { username },
        withAuthorization(accessToken),
      ).then(()=>{
        resolve();
      }).catch((error) => {
        if(error.response && error.response.data){
          let { message } = error.response.data;
          reject(message);
        } else{
          reject(error);
        }
      });
    });
  }
}

const userService = new UserService(process.env.REACT_APP_USERS_API || "");

export default userService;
