import React from "react";
import { Redirect } from "react-router-dom";
import classnames from "classnames";

import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import LoginHeader from "./LoginHeader";
import { connect, ConnectedProps } from "react-redux";
import actions from "../redux/authentication-actions";
import { RootState } from "../../redux/root-reducer";
import SpinningButton from "../../toolkit/components/SpinningButton";
import logo from "../../assets/img/brand/argon-react.png";

const mapState = (state: RootState) => ({
  loading: state.authentication.loading,
  error: state.authentication.error,
  authenticated: state.authentication.authenticated,
});

const mapDispatch = {
  login: actions.login,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

function LoginPage({
  loading,
  error,
  authenticated,
  login,
}: Props): JSX.Element {
  const [focusedUsername, setFocusedEmail] = React.useState(false);
  const [focusedPassword, setFocusedPassword] = React.useState(false);
  const [username, setUsername] = React.useState<string | undefined>(undefined);
  const [password, setPassword] = React.useState<string | undefined>(undefined);

  if (authenticated) {
    return (
      <Redirect
        to={{
          pathname: "/admin",
        }}
      />
    );
  }

  const handleLogin = (e: React.SyntheticEvent) => {
    if (username !== undefined && password !== undefined) {
      e.preventDefault();
      login(username, password);
    }
  };

  return (
    <>
      <LoginHeader
        title="Welcome to ARM Portal!"
        lead="Find all your requests at one place."
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <img src={logo} alt="logo" />
                <div className="text-center text-muted mb-4">
                  <small>Sign in</small>
                </div>
                <Form role="form" onSubmit={handleLogin}>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedUsername,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Username"
                        onFocus={() => setFocusedEmail(true)}
                        onBlur={() => setFocusedEmail(false)}
                        onChange={(e: any) => setUsername(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onFocus={() => setFocusedPassword(true)}
                        onBlur={() => setFocusedPassword(false)}
                        onChange={(e: any) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="custom-control custom-control-alternative custom-checkbox custom-checkbox-default">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div>

                  <Col className="my-4">
                    <Row>
                      <SpinningButton
                        icon="fa fa-sign-in-alt"
                        color="default"
                        type="submit"
                        loading={loading}
                        text="Sign in"
                      />
                    </Row>
                    <Col>
                      <div className="mx-3 mt-1">
                        {error && (
                          <div className="text-center text-danger mb-4">
                            <small>Username or Password is Incorrect.</small>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Col>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="12">
                <a
                  className="text-light"
                  href="#bearsight"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Forgot password?</small>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default connector(LoginPage);
