import { RecordingState } from "./recordings-state";
import {
  RecordingsAction,
  RecordingsActionType,
  FailedAction,
  ChangeRecordingsAction,
  ChangeHistoryRecordingsAction,
  ChangeAvailableTagsAction,
  ChangeFilterDatesAction,
  ChangePageAction,
  ChangeTaggedAction,
  ChangePageSizeAction,
  ChangeTerritorryAction
} from "./recordings-actions";

const initialState: RecordingState = {
  loading: false,
  recordings: {
    page: 0,
    totalPages: 0,
    hasMorePages: false,
    recordings: [],
    historyRecordings: [],
    historyHasMore: false,
    historyTotalPages: 0,
    totalElements: 0
  },
  availableTags: {
    appliances: [],
    callResults: [],
    brands: [],
  },
  page: 0,
  tagged: false,
  recordingsPageSize: 5
};

export default function recordingReducer(
  state: RecordingState = initialState,
  action: RecordingsAction
): RecordingState {
  switch (action.type) {
    case RecordingsActionType.LOADING:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case RecordingsActionType.FAILED:
      return {
        ...state,
        loading: false,
        error: (action as FailedAction).error,
      };
    case RecordingsActionType.FINISH_LOADING:
      return {
        ...state,
        loading: false,
      };
    case RecordingsActionType.CLEAR_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case RecordingsActionType.CHANGE_RECORDINGS:
      return {
        ...state,
        error: undefined,
        loading: false,
        recordings: (action as ChangeRecordingsAction).recordings,
      };
    case RecordingsActionType.CHANGE_HISTORY_RECORDINGS:
      return {
        ...state,
        error: undefined,
        loading: false,
        recordings: {
          ...state.recordings,
          historyHasMore: (action as ChangeRecordingsAction).recordings.historyHasMore,
          historyRecordings: (action as ChangeRecordingsAction).recordings.historyRecordings,
          historyTotalPages: (action as ChangeRecordingsAction).recordings.historyTotalPages
        },
      };
    case RecordingsActionType.CHANGE_AVAILABLE_TAGS:
      return {
        ...state,
        error: undefined,
        loading: false,
        availableTags: (action as ChangeAvailableTagsAction).availableTags,
      };
    case RecordingsActionType.CHANGE_FILTER_DATES:
      const changeFilterDatesAction = action as ChangeFilterDatesAction;
      return {
        ...state,
        startDate: changeFilterDatesAction.startDate,
        endDate: changeFilterDatesAction.endDate,
      };
    case RecordingsActionType.CHANGE_PAGE:
      return {
        ...state,
        page: (action as ChangePageAction).page,
      };
    case RecordingsActionType.CHANGE_PAGE_SIZE:
      return {
        ...state,
        recordingsPageSize: (action as ChangePageSizeAction).pageSize,
      };
    case RecordingsActionType.CHANGE_TAGGED:
      return {
        ...state,
        tagged: (action as ChangeTaggedAction).tagged,
      };
    case RecordingsActionType.CHANGE_TERRITORY:
      return {
        ...state,
        territory: (action as ChangeTerritorryAction).territory
      }
    default:
      return state;
  }
}
