import React, { useEffect } from "react";

import { Row, Form, Card, CardBody, Spinner, Button, Table, FormGroup, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { useStateReducer } from "toolkit/state-reducer";
import { Client, BingAdsAccount, ClientBingAds } from "clients/budgets/services/client";
import BearsightCardHeader from "toolkit/components/BearsightCardHeader";
import DateRange from "calls/recordings/DateRange";
import SpinningButton from "toolkit/components/SpinningButton";
import { BingAdsAttributionMode } from "./bing-ads-attribution-mode";
import ReactDatetime from "react-datetime";
import { Moment } from "moment";
import IconButton from "toolkit/components/IconButton";
import { epochNow } from "toolkit/clock";
import BearsightMultiselect from "toolkit/components/BearsightMultiselect";

type Props = {
	from?: "CLIENT_MANAGEMENT" | "BING_ADS";
	client?: Client;
	loading: boolean;
	availableBingAdsAccounts?: BingAdsAccount[];
	onClose: () => void;
	onAttributeBingAdsAccount: (clientId: string, bingAdsAccountId: string, activationDate: number, deactivationDate?: number) => void;
	onUpdateBingAdsAccount: (clientId: string, bingAdsAccountId: string, activationDate: number, deactivationDate?: number) => void;
	loadClientBingAdsAccounts: (activationDate: number) => void;
};

interface State {
	activationDate?: number;
	deactivationDate?: number;
	selectedBingAdsAccount?: BingAdsAccount;
	attributedBingAdsAccounts?: ClientBingAds[];
	mode: BingAdsAttributionMode;
}

export default function BingAdsAttribution({ from, client, loading, availableBingAdsAccounts, onAttributeBingAdsAccount, onUpdateBingAdsAccount, loadClientBingAdsAccounts, onClose }: Props) {
	const initialState: State = {
		activationDate: epochNow(),
		mode: from === "CLIENT_MANAGEMENT" ? BingAdsAttributionMode.ATTRIBUTION : BingAdsAttributionMode.ATTRIBUTED
	};

	const [state, setState] = useStateReducer<State>(initialState);

	const { mode, attributedBingAdsAccounts, selectedBingAdsAccount, activationDate, deactivationDate } = state;

	useEffect(() => {
		if (activationDate !== undefined) {
			loadClientBingAdsAccounts(activationDate);
		}
	}, [activationDate]);

	const handleAttribute = () => {
		if (selectedBingAdsAccount && client && activationDate) {
			onAttributeBingAdsAccount(client.id, selectedBingAdsAccount.id, activationDate, deactivationDate);
		}
	};

	const handleClose = () => {
		setState({
			activationDate: undefined,
			deactivationDate: undefined
		});
		onClose();
	};

	const handleCancel = () => {
		if (from === "CLIENT_MANAGEMENT") {
			handleClose();
		} else {
			setState({
				mode: BingAdsAttributionMode.ATTRIBUTED
			});
		}
	};

	const goToAttributionMode = () => {
		setState({
			mode: BingAdsAttributionMode.ATTRIBUTION
		});
	};

	const handleEditBingAccount = (bingAdsAccount: ClientBingAds) => {
		if (client) {
			onUpdateBingAdsAccount(client.id, bingAdsAccount.BingAdsAccount.id, bingAdsAccount.activationDate, bingAdsAccount.deactivationDate);
		}
	};

	useEffect(() => {
		if (client && client.bingAdsAccounts) {
			setState({
				attributedBingAdsAccounts: [
					...client.bingAdsAccounts.map(p => {
						return { ...p };
					})
				],
				mode: from === "CLIENT_MANAGEMENT" ? BingAdsAttributionMode.ATTRIBUTION : BingAdsAttributionMode.ATTRIBUTED
			});
		}
	}, [client && client.bingAdsAccounts ? JSON.stringify(client.bingAdsAccounts) : undefined]);

	if (loading && !availableBingAdsAccounts) {
		return (
			<div className="modal-body p-0">
				<Card className="bg-secondary shadow border-0">
					<BearsightCardHeader onClose={handleClose} title="CLIENT" subTitle={`${client?.name} (${client?.id})`} />
					<CardBody className="px-lg-5">
						<div className="text-center text-muted mb-4">
							<small>BING ADS ACCOUNTS</small>
						</div>
						<Row className="justify-content-center">
							<Spinner />
						</Row>
					</CardBody>
				</Card>
			</div>
		);
	}

	if (mode === BingAdsAttributionMode.ATTRIBUTED) {
		return (
			<>
				<div className="modal-body p-0">
					<Card className="bg-secondary shadow border-0">
						<BearsightCardHeader onClose={handleClose} title="CLIENT" subTitle={`${client?.name} (${client?.id})`} />
						<CardBody className="px-lg-5">
							<div className="text-center text-muted mb-4">
								<small>BING ADS ACCOUNTS</small>
							</div>

							<Table size="sm" className="align-items-center" hover striped>
								<thead className="thead-light">
									<tr>
										{["Id", "Bing ads account Name", "Activation date", "Deactivation date", ""].map(name => (
											<th key={name} scope="col">
												{name}
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{attributedBingAdsAccounts &&
										attributedBingAdsAccounts.map((bingAdsAccount: ClientBingAds) => {
											return (
												<tr key={bingAdsAccount.associationId}>
													<td>{bingAdsAccount.BingAdsAccount.id}</td>
													<th scope="row">{bingAdsAccount.BingAdsAccount.name}</th>

													<td>
														<FormGroup style={{ marginBottom: 0.0 }}>
															<InputGroup className="input-group-alternative">
																<InputGroupAddon addonType="prepend">
																	<InputGroupText>
																		<i className="ni ni-calendar-grid-58" />
																	</InputGroupText>
																</InputGroupAddon>
																<ReactDatetime
																	defaultValue={new Date(bingAdsAccount.activationDate)}
																	inputProps={{
																		placeholder: "Activation date"
																	}}
																	timeFormat={false}
																	onChange={(e: Moment | string) => {
																		if (typeof e !== "string") {
																			const date = parseInt(e.valueOf().toString());
																			bingAdsAccount.activationDate = date;
																		}
																	}}
																/>
															</InputGroup>
														</FormGroup>
													</td>

													<td>
														<FormGroup style={{ marginBottom: 0.0 }}>
															<InputGroup className="input-group-alternative">
																<InputGroupAddon addonType="prepend">
																	<InputGroupText>
																		<i className="ni ni-calendar-grid-58" />
																	</InputGroupText>
																</InputGroupAddon>

																<ReactDatetime
																	defaultValue={bingAdsAccount.deactivationDate && bingAdsAccount.deactivationDate !== 0 ? new Date(bingAdsAccount.deactivationDate) : undefined}
																	inputProps={{
																		placeholder: "Deactivation date"
																	}}
																	timeFormat={false}
																	onChange={(e: Moment | string) => {
																		if (typeof e !== "string") {
																			const date = parseInt(e.valueOf().toString());
																			bingAdsAccount.deactivationDate = date;
																		} else {
																			bingAdsAccount.deactivationDate = undefined;
																		}
																	}}
																/>
															</InputGroup>
														</FormGroup>
													</td>

													<td>
														<IconButton id={bingAdsAccount.associationId} color="default" icon="fa fa-save" onClick={() => handleEditBingAccount(bingAdsAccount)} tooltip="Edit bing ads account" loading={loading} />
													</td>
												</tr>
											);
										})}
								</tbody>
							</Table>
							<Row className="justify-content-end">
								<SpinningButton
									block={false}
									icon="fa fa-plus"
									color="default"
									loading={loading}
									text="Allocate new bing ads account"
									onClick={e => {
										e.preventDefault();
										goToAttributionMode();
									}}
								/>
								<Button
									color="link"
									onClick={e => {
										e.preventDefault();
										onClose();
									}}
								>
									<span className="text-default">CANCEL</span>
								</Button>
							</Row>
						</CardBody>
					</Card>
				</div>
			</>
		);
	}

	let newAvailableBingAdsAccounts: BingAdsAccount[] = [];
	if (availableBingAdsAccounts) {
		newAvailableBingAdsAccounts = availableBingAdsAccounts?.map(aBAA => {
			const newBGAA: BingAdsAccount = { ...aBAA };
			newBGAA.name = `${newBGAA.name} (${newBGAA.id})`;
			return newBGAA;
		});
	}

	return (
		<>
			<div className="modal-body p-0">
				<Card className="bg-secondary shadow border-0">
					<BearsightCardHeader onClose={handleClose} title="CLIENT" subTitle={`${client?.name} (${client?.id})`} />
					<CardBody className="px-lg-5">
						<div className="text-center text-muted mb-4">
							<small>BING ADS ACCOUNTS</small>
						</div>
						<Form role="form">
							<>
								<DateRange
									defaultStartDate={epochNow()}
									startLabel="Activation date"
									endLabel="Deactivation date"
									onStartDateChange={date => setState({ activationDate: date })}
									onEndDateChange={date => setState({ deactivationDate: date })}
								/>

								{availableBingAdsAccounts && (
									<BearsightMultiselect<BingAdsAccount>
										isMulti={false}
										disabled={loading}
										loading={loading}
										options={newAvailableBingAdsAccounts}
										selected={selectedBingAdsAccount ? [selectedBingAdsAccount] : undefined}
										label="Bing ads account"
										onChange={(value: BingAdsAccount[]) => {
											setState({ selectedBingAdsAccount: value[0] });
										}}
										displayValue="name"
									/>
								)}

								<Row className="justify-content-end">
									<SpinningButton
										block={false}
										icon="fa fa-plus"
										color="default"
										type="submit"
										loading={loading}
										text="Add"
										onClick={e => {
											e.preventDefault();
											handleAttribute();
										}}
									/>
									<Button
										color="link"
										onClick={e => {
											e.preventDefault();
											handleCancel();
										}}
									>
										<span className="text-default">CANCEL</span>
									</Button>
								</Row>
							</>
						</Form>
					</CardBody>
				</Card>
			</div>
		</>
	);
}
