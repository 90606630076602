import React from "react";
import { CardHeader } from "reactstrap";
import ModalCloseButton from "./ModalCloseButton";

interface Props {
    onClose?: () => void;
    title?: string;
    subTitle?: string;
}

export default function BearsightCardHeader({ title, subTitle, onClose }: Props): JSX.Element {
    return (
        <CardHeader className="bg-transparent pb-5">
            <ModalCloseButton
                onClose={onClose}
            />
            <div className="text-muted text-center mt-2 mb-3">
                <small>{title}</small>
            </div>
            <p className="font-weight-bold text-center">{subTitle}</p>
        </CardHeader>
    );
}