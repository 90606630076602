import { RootState } from "redux/root-reducer";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { Client, Budget, PricingGridClient, PricingGridClients, ClientPhoneNumbers, GoogleAdsAccount, BingAdsAccount, Plan, CreateClientPayload, UpdateClientPayload, PhoneNumber, ClientBudget } from "clients/budgets/services/client";
import clientsService from "clients/budgets/services/clients-service";

import pricingGridService from "clients/pricing-grid/services/pricing-grid-service";
import { PricingGrid, PricingGridDetail } from "../pricing-grid/services/pricing-grid";
import { PricingGrid as PricingGridSummary } from "clients/budgets/services/client";
import territoriesService from "clients/territories/services/territories-service";
import plansService from "clients/plans/services/plans-service";
import { Territory, AssignTerritoryToClientPayload, ClientTerritory } from "clients/territories/services/territory";
import { Page } from "toolkit/pagination";
import phoneNumbersService from "clients/phonenumbers/services/phone-numbers-service";
import googleAdsService from "clients/google-ads/services/google-ads-service";
import bingAdsService from "clients/bing-ads/services/bing-ads-service";
import { epochNow, toEpoch } from "toolkit/clock";

export enum ClientActionType {
	LOADING = "CLIENT_LOADING",
	FINISH_LOADING = "CLIENT_FINISH_LOADING",
	FAILED = "CLIENT_FAILURE",
	CLEAR_ERROR = "CLIENT_CLEAR_ERROR",
	CHANGE_CLIENT = "CHANGE_CLIENT",
	CHANGE_CLIENTS = "CLIENT_CHANGE_RECORDINGS",
	CHANGE_PRICING_GRIDS = "CLIENT_CHANGE_PRICING_GRIDS",
	CHANGE_CLIENT_PAGE_SIZE = "CLIENT_CHANGE_CLIENT_PAGE_SIZE",
	CHANGE_CLIENT_PAGE = "CLIENT_CHANGE_CLIENT_PAGE",
	CHANGE_CLIENT_TOTAL_PAGES = "CLIENT_CHANGE_CLIENT_TOTAL_PAGES",
	CHANGE_CLIENTS_ASSIGNED_TO_GRID = "CLIENT_CHANGE_CLIENTS_ASSIGNED_TO_GRID",
	CHANGE_TERRITORIES = "CLIENT_CHANGE_TERRITORIES",
	CHANGE_TERRITORY_DETAILS = "CHANGE_TERRITORY_DETAILS",
	CHANGE_PLANS = "CLIENT_CHANGE_PLANS",
	CHANGE_CLIENT_PHONE_NUMBERS = "CLIENT_CHANGE_CLIENT_PHONE_NUMBERS",
	CLEAN_CLIENT_PHONE_NUMBERS = "CLIENT_CLEAN_CLIENT_PHONE_NUMBERS",
	CHANGE_AVAILABLE_PHONE_NUMBERS = "CHANGE_AVAILABLE_PHONE_NUMBERS",
	CHANGE_GOOGLE_ADS_ACCOUNTS = "CLIENT_CHANGE_GOOGLE_ADS_ACCOUNTS",
	CHANGE_BING_ADS_ACCOUNTS = "CLIENT_CHANGE_BING_ADS_ACCOUNTS",
	CHANGE_SEARCH_QUERY = "CLIENT_CHANGE_SEARCH_QUERY",
	CHANGE_PHONE_SEARCH_QUERY = "CLIENT_CHANGE_PHONE_SEARCH_QUERY",
	DELETE_PRICING_RANGE_DETAIL = "DELETE_PRICING_RANGE_DETAIL",
	UPDATE_PRICING_RANGE_DETAIL = "UPDATE_PRICING_RANGE_DETAIL",
	ADD_PRICING_RANGE_DETAIL = "ADD_PRICING_RANGE_DETAIL"
}

export interface LoadingAction {
	type: ClientActionType.LOADING;
}

export interface FinishLoadingAction {
	type: ClientActionType.FINISH_LOADING;
}

export interface ClearErrorAction {
	type: ClientActionType.CLEAR_ERROR;
}

export interface FailedAction {
	type: ClientActionType.FAILED;
	error: any;
}

export interface ChangeClientsAction {
	type: ClientActionType.CHANGE_CLIENTS;
	clients: Client[];
}

export interface ChangeClientAction {
	type: ClientActionType.CHANGE_CLIENT;
	client: Client;
}

export interface ChangePricingGridAction {
	type: ClientActionType.CHANGE_PRICING_GRIDS;
	pricingGrid: PricingGridSummary[];
}

export interface AddPricingRangeDetailAction {
	type: ClientActionType.ADD_PRICING_RANGE_DETAIL;
	pricingGridId: string;
	detail: PricingGridDetail;
}

export interface DeletePricingRangeDetailAction {
	type: ClientActionType.DELETE_PRICING_RANGE_DETAIL;
	pricingGridId: string;
	detailId: string;
}

export interface UpdatePricingRangeDetailAction {
	type: ClientActionType.UPDATE_PRICING_RANGE_DETAIL;
	pricingGridId: string;
	detail: PricingGridDetail;
}

export interface ChangeClientsPageSizeAction {
	type: ClientActionType.CHANGE_CLIENT_PAGE_SIZE;
	pageSize: number;
}

export interface ChangeClientsPageAction {
	type: ClientActionType.CHANGE_CLIENT_PAGE;
	page: number;
}

export interface ChangeClientsTotalPagesAction {
	type: ClientActionType.CHANGE_CLIENT_TOTAL_PAGES;
	totalPages: number;
}

export interface ChangeClientsAssignedToPricingGridAction {
	type: ClientActionType.CHANGE_CLIENTS_ASSIGNED_TO_GRID;
	pricingGridClients?: PricingGridClients;
}

export interface ChangeTerritoriesAction {
	type: ClientActionType.CHANGE_TERRITORIES;
	territories: Page<Territory>;
}

export interface ChangeTerritoryDetailsAction {
	type: ClientActionType.CHANGE_TERRITORY_DETAILS;
	territory?: Territory;
}

export interface ChangePlansAction {
	type: ClientActionType.CHANGE_PLANS;
	plans: Page<Plan>;
}

export interface ChangeClientPhoneNumbersAction {
	type: ClientActionType.CHANGE_CLIENT_PHONE_NUMBERS;
	clientPhoneNumbers: ClientPhoneNumbers;
}

export interface ChangeAvailablePhoneNumbersAction {
	type: ClientActionType.CHANGE_AVAILABLE_PHONE_NUMBERS;
	phoneNumbers: PhoneNumber[];
}

export interface CleanClientPhoneNumbersAction {
	type: ClientActionType.CLEAN_CLIENT_PHONE_NUMBERS;
}

export interface ChangeGoogleAdsAccountAction {
	type: ClientActionType.CHANGE_GOOGLE_ADS_ACCOUNTS;
	googleAdsAccounts: GoogleAdsAccount[];
}

export interface ChangeBingAdsAccountAction {
	type: ClientActionType.CHANGE_BING_ADS_ACCOUNTS;
	bingAdsAccounts: BingAdsAccount[];
}

export interface ChangeSearchQueryAction {
	type: ClientActionType.CHANGE_SEARCH_QUERY;
	query: string;
}

export interface ChangePhoneSearchQueryAction {
	type: ClientActionType.CHANGE_PHONE_SEARCH_QUERY;
	query: string;
}

export type ClientsAction =
	| FinishLoadingAction
	| LoadingAction
	| ClearErrorAction
	| FailedAction
	| ChangePricingGridAction
	| ChangeClientsPageSizeAction
	| ChangeClientsPageAction
	| ChangeClientsTotalPagesAction
	| ChangeClientsAssignedToPricingGridAction
	| ChangeTerritoriesAction
	| ChangeTerritoryDetailsAction
	| ChangePlansAction
	| ChangeClientPhoneNumbersAction
	| ChangeAvailablePhoneNumbersAction
	| CleanClientPhoneNumbersAction
	| ChangeGoogleAdsAccountAction
	| ChangeBingAdsAccountAction
	| ChangeSearchQueryAction
	| ChangePhoneSearchQueryAction
	| ChangeClientsAction
	| ChangeClientAction
	| DeletePricingRangeDetailAction
	| UpdatePricingRangeDetailAction
	| AddPricingRangeDetailAction;

function cleanClientPhoneNumbers(): CleanClientPhoneNumbersAction {
	return {
		type: ClientActionType.CLEAN_CLIENT_PHONE_NUMBERS
	};
}

function loading(): LoadingAction {
	return {
		type: ClientActionType.LOADING
	};
}

function changeClientPhoneNumbersAction(clientPhoneNumbers: ClientPhoneNumbers): ChangeClientPhoneNumbersAction {
	return {
		type: ClientActionType.CHANGE_CLIENT_PHONE_NUMBERS,
		clientPhoneNumbers
	};
}

function changeAvailablePhoneNumbersAction(phoneNumbers: PhoneNumber[]): ChangeAvailablePhoneNumbersAction {
	return {
		type: ClientActionType.CHANGE_AVAILABLE_PHONE_NUMBERS,
		phoneNumbers
	};
}

function changeClientsAssignedToPricingGrid(assigned: PricingGridClient[], unassigned: PricingGridClient[]): ChangeClientsAssignedToPricingGridAction {
	return {
		type: ClientActionType.CHANGE_CLIENTS_ASSIGNED_TO_GRID,
		pricingGridClients: { assigned, unassigned }
	};
}

function clearClientsForPricingGrid(): ChangeClientsAssignedToPricingGridAction {
	return {
		type: ClientActionType.CHANGE_CLIENTS_ASSIGNED_TO_GRID,
		pricingGridClients: undefined
	};
}

function finishLoading(): FinishLoadingAction {
	return {
		type: ClientActionType.FINISH_LOADING
	};
}

function failed(error: any): FailedAction {
	return {
		type: ClientActionType.FAILED,
		error
	};
}

function clearError(): ClearErrorAction {
	return {
		type: ClientActionType.CLEAR_ERROR
	};
}

function changeClients(clients: Client[]): ChangeClientsAction {
	return {
		type: ClientActionType.CHANGE_CLIENTS,
		clients
	};
}

function changeClient(client: Client): ChangeClientAction {
	return {
		type: ClientActionType.CHANGE_CLIENT,
		client
	};
}

function changeTerritories(territories: Page<Territory>): ChangeTerritoriesAction {
	return {
		type: ClientActionType.CHANGE_TERRITORIES,
		territories
	};
}

function changeTerritoryDetails(territory?: Territory): ChangeTerritoryDetailsAction {
	return {
		type: ClientActionType.CHANGE_TERRITORY_DETAILS,
		territory
	};
}

function changePlans(plans: Page<Plan>): ChangePlansAction {
	return {
		type: ClientActionType.CHANGE_PLANS,
		plans
	};
}

function changePricingGrid(pricingGrid: PricingGridSummary[]): ChangePricingGridAction {
	return {
		type: ClientActionType.CHANGE_PRICING_GRIDS,
		pricingGrid
	};
}

// function deletePricingRangeDetail(pricingGridId: string, detailId: string): DeletePricingRangeDetailAction {
//   return {
//     type: ClientActionType.DELETE_PRICING_RANGE_DETAIL,
//     pricingGridId,
//     detailId,
//   };
// }

// function updatePricingRangeDetail(pricingGridId: string, detail: PricingGridDetail): UpdatePricingRangeDetailAction {
//   return {
//     type: ClientActionType.UPDATE_PRICING_RANGE_DETAIL,
//     pricingGridId,
//     detail,
//   };
// }

// function addPricingRangeDetail(pricingGridId: string, detail: PricingGridDetail): AddPricingRangeDetailAction {
//   return {
//     type: ClientActionType.ADD_PRICING_RANGE_DETAIL,
//     pricingGridId,
//     detail,
//   };
// }

function changeClientsPageSize(pageSize: number): ChangeClientsPageSizeAction {
	return {
		type: ClientActionType.CHANGE_CLIENT_PAGE_SIZE,
		pageSize
	};
}

function changeClientsPage(page: number): ChangeClientsPageAction {
	return {
		type: ClientActionType.CHANGE_CLIENT_PAGE,
		page
	};
}

function changeClientsTotalPages(totalPages: number): ChangeClientsTotalPagesAction {
	return {
		type: ClientActionType.CHANGE_CLIENT_TOTAL_PAGES,
		totalPages
	};
}

function changeGoogleAdsAccounts(googleAdsAccounts: GoogleAdsAccount[]): ChangeGoogleAdsAccountAction {
	return {
		type: ClientActionType.CHANGE_GOOGLE_ADS_ACCOUNTS,
		googleAdsAccounts
	};
}

function changeBingAdsAccounts(bingAdsAccounts: BingAdsAccount[]): ChangeBingAdsAccountAction {
	return {
		type: ClientActionType.CHANGE_BING_ADS_ACCOUNTS,
		bingAdsAccounts
	};
}

function changeSearchQuery(query: string): ChangeSearchQueryAction {
	return {
		type: ClientActionType.CHANGE_SEARCH_QUERY,
		query
	};
}

function changePhoneSearchQuery(query: string): ChangePhoneSearchQueryAction {
	return {
		type: ClientActionType.CHANGE_PHONE_SEARCH_QUERY,
		query
	};
}

type ClientsThunkAction = ThunkAction<void, RootState, unknown, Action<ClientActionType>>;

const loadClient = (clientId: string): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());
	try {
		const accessToken = getState().authentication?.tokens?.accessToken;
		if (accessToken) {
			const client = await clientsService.client(accessToken, clientId);
			dispatch(changeClient(client));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const loadClients = (page: number, pageSize: number, query?: string, phone?: string, status?: string): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			const clients = await clientsService.clients(accessToken, page, pageSize, query, phone, status);

			dispatch(changeClientsPage(clients.page));
			dispatch(changeClientsTotalPages(clients.totalPages));
			dispatch(changeClientsPageSize(pageSize));
			dispatch(changeSearchQuery(query || ""));
			dispatch(changePhoneSearchQuery(phone || ""));

			dispatch(changeClients(clients.data));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const createClient = (client: CreateClientPayload): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await clientsService.create(accessToken, client);

			const clients = await clientsService.clients(accessToken, getState().clients.clientsPage, getState().clients.clientsPageSize);

			dispatch(changeClients(clients.data));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const updateClient = (client: UpdateClientPayload): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await clientsService.update(accessToken, client);

			const clients = await clientsService.clients(accessToken, getState().clients.clientsPage, getState().clients.clientsPageSize);

			dispatch(changeClients(clients.data));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const loadAvailableGoogleAdsAccounts = (activationDate: number): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			const googleAdsAccounts = await googleAdsService.available(accessToken, activationDate);

			dispatch(changeGoogleAdsAccounts(googleAdsAccounts));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const attributeGoogleAccount = (clientId: string, googleAccountId: string, activationDate: number, deactivationDate?: number): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await googleAdsService.allocate(accessToken, clientId, googleAccountId, activationDate, deactivationDate);

			const googleAdsAccounts = await googleAdsService.available(accessToken, activationDate);

			const clients = await clientsService.clients(accessToken, getState().clients.clientsPage, getState().clients.clientsPageSize);

			const client = await clientsService.client(accessToken, clientId);

			dispatch(changeClients(clients.data));
			dispatch(changeClient(client));
			dispatch(changeGoogleAdsAccounts(googleAdsAccounts));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const updateGoogleAdsAccounts = (clientId: string, googleAccountId: string, activationDate: number, deactivationDate?: number): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await googleAdsService.update(accessToken, clientId, googleAccountId, activationDate, deactivationDate);

			const googleAdsAccounts = await googleAdsService.available(accessToken, activationDate);

			const clients = await clientsService.clients(accessToken, getState().clients.clientsPage, getState().clients.clientsPageSize);
			const client = await clientsService.client(accessToken, clientId);

			dispatch(changeClients(clients.data));
			dispatch(changeGoogleAdsAccounts(googleAdsAccounts));
			dispatch(changeClient(client));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const deleteClientGoogleAdsAccount = (clientId: string, associationId: string): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());
	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await clientsService.deallocateGoogleAdsAccount(accessToken, clientId, associationId);
			const client = await clientsService.client(accessToken, clientId);
			dispatch(changeClient(client));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const loadAvailableBingAdsAccounts = (activationDate: number): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			const bingAdsAccounts = await bingAdsService.available(accessToken, activationDate);

			dispatch(changeBingAdsAccounts(bingAdsAccounts));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const attributeBingAccount = (clientId: string, bingAccountId: string, activationDate: number, deactivationDate?: number): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await bingAdsService.allocate(accessToken, clientId, bingAccountId, activationDate, deactivationDate);

			const bingAdsAccounts = await bingAdsService.available(accessToken, activationDate);

			const clients = await clientsService.clients(accessToken, getState().clients.clientsPage, getState().clients.clientsPageSize);

			const client = await clientsService.client(accessToken, clientId);

			dispatch(changeClients(clients.data));
			dispatch(changeClient(client));
			dispatch(changeBingAdsAccounts(bingAdsAccounts));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const updateBingAdsAccounts = (clientId: string, bingAccountId: string, activationDate: number, deactivationDate?: number): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await bingAdsService.update(accessToken, clientId, bingAccountId, activationDate, deactivationDate);

			const bingAdsAccounts = await bingAdsService.available(accessToken, activationDate);

			const clients = await clientsService.clients(accessToken, getState().clients.clientsPage, getState().clients.clientsPageSize);

			const client = await clientsService.client(accessToken, clientId);

			dispatch(changeClients(clients.data));
			dispatch(changeClient(client));
			dispatch(changeBingAdsAccounts(bingAdsAccounts));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const deleteClientBingAdsAccount = (clientId: string, associationId: string): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await clientsService.deallocateBingAdsAccount(accessToken, clientId, associationId);
			const client = await clientsService.client(accessToken, clientId);
			dispatch(changeClient(client));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const loadClientPhoneNumbers = (clientId: string, status: string, query: string, page: number, size: number): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			const phoneNumbers = await phoneNumbersService.clientNumbers(accessToken, clientId, status, query, page, size);

			dispatch(changeClientPhoneNumbersAction(phoneNumbers));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const loadAvailablePhoneNumbers = (activationDate: number): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			const phoneNumbers = await phoneNumbersService.availableNumbers(accessToken, activationDate);

			dispatch(changeAvailablePhoneNumbersAction(phoneNumbers));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const allocatePhoneNumber = (clientId: string, phoneNumberId: string[], activationDate: number, deactivationDate?: number): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			const phoneNumbers = await phoneNumbersService.allocate(accessToken, clientId, phoneNumberId, activationDate, deactivationDate);

			//dispatch(changeClientPhoneNumbersAction(phoneNumbers));

			const client = await clientsService.client(accessToken, clientId);
			dispatch(changeClient(client));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const updatePhoneNumber = (clientId: string, phoneNumberId: string, activationDate: number, deactivationDate?: number): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			const phoneNumbers = await phoneNumbersService.update(accessToken, clientId, phoneNumberId, activationDate, deactivationDate);

			//dispatch(changeClientPhoneNumbersAction(phoneNumbers));
			const client = await clientsService.client(accessToken, clientId);
			dispatch(changeClient(client));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const deleteClientPhoneNumber = (clientId: string, associationId: string): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());
	try {
		const accessToken = getState().authentication?.tokens?.accessToken;
		if (accessToken) {
			await clientsService.deallocatePhoneNumber(accessToken, clientId, associationId);
			const client = await clientsService.client(accessToken, clientId);
			dispatch(changeClient(client));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const initializeTerritories = (): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			const clients = await clientsService.clients(accessToken, 0, 2147483646);

			const territories = await territoriesService.territories(accessToken, 0, 5);

			dispatch(changeTerritories(territories));
			dispatch(changeClients(clients.data));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const loadTerritories = (page: number, pageSize: number, query?: string): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			const territories = await territoriesService.territories(accessToken, page, pageSize, query);

			dispatch(changeTerritories(territories));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const loadTerritoryDetails = (territoryId?: string): ClientsThunkAction => async (dispatch, getState) => {
	if (territoryId != null) {
		dispatch(loading());

		try {
			const accessToken = getState().authentication?.tokens?.accessToken;

			if (accessToken) {
				const territory = await territoriesService.territory(accessToken, territoryId);

				dispatch(changeTerritoryDetails(territory));
			}
		} catch (e) {
			dispatch(failed(e));
		}
	} else {
		dispatch(changeTerritoryDetails());
	}
};

const loadPlans = (page: number, pageSize: number, query?: string): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());
	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			const plans = await plansService.plans(accessToken, page, pageSize, query);
			dispatch(changePlans(plans));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const deleteTerritory = (territoryId: string): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await territoriesService.delete(accessToken, territoryId);

			const page = getState().clients.territories?.page || 0;
			const pageSize = getState().clients.territories?.pageSize || 5;
			const territories = await territoriesService.territories(accessToken, page, pageSize);

			dispatch(changeTerritories(territories));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const updateTerritory = (territory: Territory): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await territoriesService.update(accessToken, territory);

			const page = getState().clients.territories?.page || 0;
			const pageSize = getState().clients.territories?.pageSize || 5;
			const territories = await territoriesService.territories(accessToken, page, pageSize);

			dispatch(changeTerritories(territories));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const assignTerritoryToClient = (payload: AssignTerritoryToClientPayload): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await territoriesService.assign(accessToken, payload);

			const page = getState().clients.territories?.page || 0;
			const pageSize = getState().clients.territories?.pageSize || 5;
			const territories = await territoriesService.territories(accessToken, page, pageSize);

			const client = await clientsService.client(accessToken, payload.clientId);

			dispatch(changeTerritories(territories));
			dispatch(changeClient(client));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const updateClientTerritory = (territoryId: string, client: ClientTerritory): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await territoriesService.updateClientTerritory(accessToken, territoryId, client);

			const page = getState().clients.territories?.page || 0;
			const pageSize = getState().clients.territories?.pageSize || 5;
			const territories = await territoriesService.territories(accessToken, page, pageSize);
			const updatedClient = await clientsService.client(accessToken, client.clientId);

			dispatch(changeTerritories(territories));
			dispatch(changeClient(updatedClient));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const deleteClientTerritory = (clientId: string, associationId: string): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await clientsService.deallocateTerritory(accessToken, clientId, associationId);
			const client = await clientsService.client(accessToken, clientId);
			dispatch(changeClient(client));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const createTerritory = (territory: Territory): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await territoriesService.create(accessToken, territory);

			const page = getState().clients.territories?.page || 0;
			const pageSize = getState().clients.territories?.pageSize || 5;
			const territories = await territoriesService.territories(accessToken, page, pageSize);

			dispatch(changeTerritories(territories));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const loadClientsForPricingGrid = (gridId: string, query?: string): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			const clients = await clientsService.clientsForPricingGrid(accessToken, gridId, query);

			dispatch(changeClientsAssignedToPricingGrid(clients.assigned, clients.unassigned));
			dispatch(finishLoading());
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const loadPricingGrids = (): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			const grids = await pricingGridService.grids(accessToken);

			dispatch(changePricingGrid(grids));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const createPricingGrid = (pricingGrid: PricingGrid): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());
	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await pricingGridService.create(accessToken, pricingGrid);
			const grids = await pricingGridService.grids(accessToken);
			dispatch(changePricingGrid(grids));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const updatePricingGridDetails = (pricingGrid: PricingGrid, detail: PricingGridDetail): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await pricingGridService.updateDetail(accessToken, pricingGrid.id, detail);

			const grids = await pricingGridService.grids(accessToken);
			dispatch(changePricingGrid(grids));
			// dispatch(updatePricingRangeDetail(pricingGrid.id, detail));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const deletePricingGridDetails = (pricingGrid: PricingGrid, id: string): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await pricingGridService.deleteDetail(accessToken, pricingGrid.id, id);

			const grids = await pricingGridService.grids(accessToken);
			dispatch(changePricingGrid(grids));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const addPricingGridDetail = (pricingGrid: PricingGrid, detail: PricingGridDetail): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await pricingGridService.addDetail(accessToken, pricingGrid.id, detail);

			const grids = await pricingGridService.grids(accessToken);
			dispatch(changePricingGrid(grids));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const assignClientsToPricingGrid = (clientIds: string[], pricingGrid: PricingGrid, activationDate: number, deactivationDate?: number): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			for (var i = 0; i < clientIds.length; i++) {
				const clientId = clientIds[i];
				await clientsService.assign(accessToken, clientId, pricingGrid.id, activationDate, deactivationDate);
			}

			const clients = await clientsService.clientsForPricingGrid(accessToken, pricingGrid.id);

			dispatch(changeClientsAssignedToPricingGrid(clients.assigned, clients.unassigned));

			const grids = await pricingGridService.grids(accessToken);
			dispatch(changePricingGrid(grids));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const assignClientToPricingGrid = (clientId: string, pricingGrid: PricingGridSummary, activationDate: number, deactivationDate?: number): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await clientsService.assign(accessToken, clientId, pricingGrid.id, activationDate || epochNow(), deactivationDate);

			const client = await clientsService.client(accessToken, clientId);

			dispatch(changeClient(client));

			const grids = await pricingGridService.grids(accessToken);
			dispatch(changePricingGrid(grids));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const deleteClientPricingGrid = (clientId: string, associationId: string): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await clientsService.deallocatePricingGrid(accessToken, clientId, associationId);
			const client = await clientsService.client(accessToken, clientId);
			dispatch(changeClient(client));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const addNewBudget = (clientId: string, budget: number, activationDate: number, deactivationDate?: number): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await clientsService.addNewBudget(accessToken, clientId, budget, activationDate, deactivationDate);

			const page = getState().clients.clientsPage;
			const pageSize = getState().clients.clientsPageSize;
			const clients = await clientsService.clients(accessToken, page, pageSize);
			const client = await clientsService.client(accessToken, clientId);

			dispatch(changeClients(clients.data));
			dispatch(changeClient(client));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const updateBudget = (clientId: string, budget: ClientBudget): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await clientsService.updateBudget(accessToken, clientId, budget.budget.id, budget.budget.budget, budget.activationDate, budget.deactivationDate);

			const page = getState().clients.clientsPage;
			const pageSize = getState().clients.clientsPageSize;
			const clients = await clientsService.clients(accessToken, page, pageSize);
			const client = await clientsService.client(accessToken, clientId);

			dispatch(changeClients(clients.data));
			dispatch(changeClient(client));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

const deleteClientBudget = (clientId: string, associationId: string): ClientsThunkAction => async (dispatch, getState) => {
	dispatch(loading());

	try {
		const accessToken = getState().authentication?.tokens?.accessToken;

		if (accessToken) {
			await clientsService.deallocateBudget(accessToken, clientId, associationId);
			const client = await clientsService.client(accessToken, clientId);
			dispatch(changeClient(client));
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

export const actions = {
	clearClientsForPricingGrid,
	loadClientsForPricingGrid,
	createPricingGrid,
	addPricingGridDetail,
	updatePricingGridDetails,
	deletePricingGridDetails,
	updateBudget,
	addNewBudget,
	loadPricingGrids,
	changeClientsPageSize,
	assignClientsToPricingGrid,
	assignClientToPricingGrid,
	deleteTerritory,
	updateTerritory,
	createTerritory,
	loadTerritories,
	loadTerritoryDetails,
	loadPlans,
	assignTerritoryToClient,
	updateClientTerritory,
	initializeTerritories,
	createClient,
	updateClient,
	loadClient,
	loadClients,
	clearError,
	loadClientPhoneNumbers,
	allocatePhoneNumber,
	updatePhoneNumber,
	cleanClientPhoneNumbers,
	loadAvailableGoogleAdsAccounts,
	attributeGoogleAccount,
	updateGoogleAdsAccounts,
	loadAvailableBingAdsAccounts,
	attributeBingAccount,
	updateBingAdsAccounts,
	changeSearchQuery,
	changePhoneSearchQuery,
	loadAvailablePhoneNumbers,
	deleteClientPhoneNumber,
	deleteClientGoogleAdsAccount,
	deleteClientBingAdsAccount,
	deleteClientPricingGrid,
	deleteClientTerritory,
	deleteClientBudget
};
