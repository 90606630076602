import React, { useState, useEffect, CSSProperties } from "react";
import classnames from "classnames";
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import { InputType } from "reactstrap/lib/Input";

interface Props {
  label?: string;
  icon?: string;
  error?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  validation?: (value?: string) => boolean;
  submitted?: boolean;
  disabled?: boolean;
  text?: string;
  type?: InputType;
  style?: CSSProperties;
}

export default function BearsightTextInput({
  label,
  text,
  error,
  icon,
  onChange,
  onFocus,
  validation,
  submitted,
  disabled,
  type,
  style,
}: Props): JSX.Element {
  const [blured, setBlured] = useState(false);
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState<string>("");

  const isSubmitted: boolean = !!submitted;
  const invalid =
    (blured || isSubmitted) && validation ? validation(value) : false;

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  useEffect(() => {
    setValue(text || "");
  }, [text]);

  return (
    <>
      <FormGroup style={style}>
        {label && <label className="form-control-label">{label}</label>}
        <InputGroup
          className={classnames("input-group-merge", {
            focused: focus,
          })}
        >
          {icon && (
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className={icon} />
              </InputGroupText>
            </InputGroupAddon>
          )}
          <Input
            disabled={disabled}
            placeholder={label}
            value={value}
            onChange={(e: any) => setValue(e.target.value)}
            type={type ? type : "text"}
            onFocus={(e) => {
              setFocus(true);
              if (onFocus) {
                onFocus();
              }
            }}
            onBlur={(e) => {
              setFocus(false);
              setBlured(true);
            }}
          />
        </InputGroup>
        {invalid && (
          <div className="text-danger">
            <small>{error ? error : "This value is required."}</small>
          </div>
        )}
      </FormGroup>
    </>
  );
}
