import moment from "moment";

export function epochNow(): number {
	const now = new Date();
	now.setHours(0, 0, 0, 0);
	return now.getTime();
}

export function formatDate(date?: number): string | undefined {
	return date ? moment(new Date(date)).format("YYYY-MM-DD") : undefined;
}

export function toEpoch(date?: string): number | undefined {
	return date ? new Date(date).getTime() : undefined;
}

export function getActive(records: Array<any>): any {
	let sorted = records.sort((a, b) => {
		if (a.deactivationDate == null && b.deactivationDate == null) {
			return b.activationDate - a.activationDate;
		}
		if (a.deactivationDate == null) {
			return -1;
		}
		if (b.deactivationDate == null) {
			return 1;
		}
		return b.deactivationDate - a.deactivationDate;
	});
	let first = sorted[0];
	if (first == null || (first.deactivationDate != null && first.deactivationDate < new Date().getTime())) {
		return null;
	}
	return first;
}
