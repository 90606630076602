import axios, { AxiosInstance } from "axios";
import { Status, SyncStatus } from "sync/redux/sync-state";
import { formatDate } from "toolkit/clock";
import { withAuthorization } from "../../toolkit/common-headers";
import { WorkRequest, WorkRequestStatus } from "./work-request";

class WarehouseService {
	private http: AxiosInstance;
	constructor(baseUrl: string) {
		this.http = axios.create({
			baseURL: baseUrl
		});
	}

	async syncGoogleAdsAccounts(accessToken: string, withLabels: boolean): Promise<WorkRequestStatus> {
		return this.http
			.put(
				"/google-ads/accounts/action/harvest",
				undefined,
				withAuthorization(accessToken, {
					withLabels
				})
			)
			.then(response => {
				let workRequestId = response.headers["created-workrequest"];
				let workRequestStatus: WorkRequestStatus = { id: workRequestId, status: "CREATED" };
				return Promise.resolve(workRequestStatus);
			})
			.catch(error => {
				if (error.response.status === 409) {
					let workRequestId = error.response.headers["existing-workrequest"];
					let workRequestStatus: WorkRequestStatus = { id: workRequestId, status: "CONFLICT" };
					return Promise.resolve(workRequestStatus);
				} else {
					return Promise.reject(error);
				}
			});
	}

	async syncGoogleAdsPerformanceReports(accessToken: string, startDate?: number, endDate?: number): Promise<WorkRequestStatus> {
		return this.http
			.put(
				"/google-ads/reports/accounts-performance/action/harvest",
				undefined,
				withAuthorization(accessToken, {
					startDate: formatDate(startDate),
					endDate: formatDate(endDate)
				})
			)
			.then(response => {
				let workRequestId = response.headers["created-workrequest"];
				let workRequestStatus: WorkRequestStatus = { id: workRequestId, status: "CREATED" };
				return Promise.resolve(workRequestStatus);
			})
			.catch(error => {
				if (error.response.status === 409) {
					let workRequestId = error.response.headers["existing-workrequest"];
					let workRequestStatus: WorkRequestStatus = { id: workRequestId, status: "CONFLICT" };
					return Promise.resolve(workRequestStatus);
				} else {
					return Promise.reject(error);
				}
			});
	}

	async syncBingAdsAccounts(accessToken: string): Promise<WorkRequestStatus> {
		return this.http
			.put("/bing-ads/accounts/action/harvest", undefined, withAuthorization(accessToken))
			.then(response => {
				let workRequestId = response.headers["created-workrequest"];
				let workRequestStatus: WorkRequestStatus = { id: workRequestId, status: "CREATED" };
				return Promise.resolve(workRequestStatus);
			})
			.catch(error => {
				if (error.response.status === 409) {
					let workRequestId = error.response.headers["existing-workrequest"];
					let workRequestStatus: WorkRequestStatus = { id: workRequestId, status: "CONFLICT" };
					return Promise.resolve(workRequestStatus);
				} else {
					return Promise.reject(error);
				}
			});
	}

	async syncBingAdsPerformanceReports(accessToken: string, startDate?: number, endDate?: number): Promise<WorkRequestStatus> {
		return this.http
			.put(
				"/bing-ads/reports/accounts-performance/action/harvest",
				undefined,
				withAuthorization(accessToken, {
					startDate: formatDate(startDate),
					endDate: formatDate(endDate)
				})
			)
			.then(response => {
				let workRequestId = response.headers["created-workrequest"];
				let workRequestStatus: WorkRequestStatus = { id: workRequestId, status: "CREATED" };
				return Promise.resolve(workRequestStatus);
			})
			.catch(error => {
				if (error.response.status === 409) {
					let workRequestId = error.response.headers["existing-workrequest"];
					let workRequestStatus: WorkRequestStatus = { id: workRequestId, status: "CONFLICT" };
					return Promise.resolve(workRequestStatus);
				} else {
					return Promise.reject(error);
				}
			});
	}

	async getSyncStatus(accessToken: string, workRequestId: string): Promise<SyncStatus> {
		const result = await this.http.get(`/work-requests/${workRequestId}`, withAuthorization(accessToken));
		return Promise.resolve(this.toSyncStatus(result.data));
	}

	private toSyncStatus(workRequest: WorkRequest): SyncStatus {
		switch (workRequest.status) {
			case "Terminated":
				return { id: workRequest.id, status: Status.FINISHED };
			case "In progress":
				return { id: workRequest.id, status: Status.IN_PROGRESS };
			case "ABORTED":
				return { id: workRequest.id, status: Status.ABORTED };
			case "FAILED":
				return { id: workRequest.id, status: Status.FAILED };
			default:
				return { id: undefined, status: Status.NOT_INITIALIZED };
		}
	}
}

const warehouseService = new WarehouseService(process.env.REACT_APP_WAREHOUSE_API || "");

export default warehouseService;
