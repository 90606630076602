import moment from "moment";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Form, FormGroup, Row } from "reactstrap";

import { actions as recordingsAction } from "calls/redux/recordings-actions";
import { actions as clientActions } from "clients/redux/client-actions";
import { RootState } from "redux/root-reducer";
import { useStateReducer } from "toolkit/state-reducer";

import SpinningButton from "../../toolkit/components/SpinningButton";
import DateRange from "./DateRange";
import TaggingOption from "./TaggingOption";
import BearsightMultiselect from "toolkit/components/BearsightMultiselect";
import { Territory } from "clients/budgets/services/client";

const mapState = (state: RootState) => ({
	loading: state.recordings.loading,
	initialStartDate: state.recordings.startDate,
	initialEndDate: state.recordings.endDate,
	pageSize: state.recordings.recordingsPageSize,
  territory: state.recordings.territory,
	territories: state.clients.territories
});

const mapDispatch = {
	loadRecordings: recordingsAction.loadRecordings,
	loadTerritories: clientActions.loadTerritories
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

interface RecordingsState {
  selectedTerritory?: Territory,
	submitted: boolean;
	startDate?: number;
	endDate?: number;
	page: number;
	tagged: boolean;
}

function RecordingsFilters({ loading, initialStartDate, initialEndDate, loadRecordings, loadTerritories, territories, pageSize, territory }: Props) {
	const initialState: RecordingsState = {
		tagged: false,
		page: 0,
		submitted: false,
		startDate: initialStartDate,
		endDate: initialEndDate
	};

	const [state, setState] = useStateReducer<RecordingsState>(initialState);

	const { startDate, endDate, page, tagged, selectedTerritory } = state;

	const formIsValid: boolean = true;

	const handleSearch = (e: React.SyntheticEvent) => {
		e.preventDefault();
		setState({ submitted: true });
		if (startDate && endDate && formIsValid) {
			loadRecordings(startDate, endDate, page, tagged, pageSize, selectedTerritory);
		}
	};

	useEffect(() => {
		loadTerritories(0, 100000);
	}, []);

  useEffect(() => {
    setState({
      selectedTerritory: territory
    })
  }, [territory])

	useEffect(() => {
		setState({ startDate: initialStartDate, endDate: initialEndDate });
	}, [initialStartDate, initialEndDate]);
	const today = moment(moment().format("YYYY-MM-DD"));
	const startOfMonth = `${moment().year()}-${moment().format("MM")}-01`;
	const startOfThisMonth = moment(startOfMonth);

	return (
		<>
			<Form onSubmit={handleSearch} className="needs-validation" noValidate inline>
				<Row>
					<DateRange
						className="ml-4"
						defaultStartDate={startOfThisMonth.valueOf()}
						defaultEndDate={today.valueOf()}
						startLabel="Start date"
						endLabel="End date"
						onStartDateChange={date => setState({ startDate: date })}
						onEndDateChange={date => setState({ endDate: date })}
					/>

					<TaggingOption
						onChanged={(value: boolean) => {
							setState({ tagged: value });
						}}
					/>

					<span className="mr-2">&nbsp;</span>

					<BearsightMultiselect<Territory>
						className="w-100 ml-4"
						styles={{ flexFlow: "row", width: "360px" }}
						isMulti={false}
						disabled={loading}
						loading={loading}
						options={territories?.data || []}
            defaultValue={territory ? [territory] : undefined}
						selected={selectedTerritory ? [selectedTerritory] : undefined}
						label="Territory"
						onChange={(value: Territory[]) => {
							setState({ selectedTerritory: value[0]});
						}}
            onClear={() => {
              setState({selectedTerritory: undefined})
            }}
						displayValue="name"
					/>

					<FormGroup className="ml-4">
						<SpinningButton icon="fa fa-search" disabled={!formIsValid} color="default" loading={loading} text="SEARCH" type="submit" />
					</FormGroup>
				</Row>
			</Form>
		</>
	);
}

export default connector(RecordingsFilters);
