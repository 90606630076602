import { AuthenticationState } from "./authentication-state";
import { EMPTY } from "../../toolkit/consts";

export function profileName(state: AuthenticationState): string {
  const firstName = state.user?.firstName || EMPTY;
  const lastName = state.user?.lastName || EMPTY;

  return `${firstName} ${lastName}`;
}

export function profileSymbol(state: AuthenticationState): string {
  const lastName = state.user?.lastName;

  if (lastName && lastName.length > 0) {
    return lastName[0];
  }

  const firstName = state.user?.firstName;

  if (firstName && firstName.length > 0) {
    return firstName[0];
  }

  return EMPTY;
}
