import axios, { AxiosInstance } from "axios";
import { withAuthorization } from "toolkit/common-headers";
import { Client, ClientBingAds, ClientBudget, ClientGoogleAds, ClientPricingGrid, ClientTerritory, CreateClientPayload, PricingGridClients, UpdateClientPayload } from "./client";
import { Page } from "toolkit/pagination";
import moment from "moment";
import { formatDate, toEpoch } from "toolkit/clock";

class ClientsService {
	private http: AxiosInstance;
	constructor(baseUrl: string) {
		this.http = axios.create({
			baseURL: baseUrl
		});
	}

	async clients(accessToken: string, page: number, pageSize: number, query?: string, phone?: string, status?: string): Promise<Page<Client>> {
		const result = await this.http.get(
			"",
			withAuthorization(accessToken, {
				page: page,
				size: pageSize,
				q: query !== undefined && query.length > 0 ? query : undefined,
				p: phone !== undefined && phone.length > 0 ? phone : undefined,
				status
			})
		);

		const data = result.data;

		if (data === undefined) {
			return Promise.resolve({
				page: 0,
				totalPages: 0,
				hasMorePages: false,
				data: []
			});
		}

		const clients: Client[] = [];
		for (var i = 0; i < data.clients.length; i++) {
			const client = data.clients[i];
			clients.push({
				id: client.id,
				name: client.name,
				active: client.active,
				plan: client.plan,
				phoneNumbers:
					client.phoneNumbers?.map((p: any) => {
						return {
							id: p.id,
							number: p.phoneNumber,
							activationDate: toEpoch(p.activationDate),
							deactivationDate: toEpoch(p.deactivationDate)
						};
					}) || [],
				territories: client.territories?.map(
					(t: any): ClientTerritory => {
						return {
							territory: t.territory,
							associationId: t.associationId,
							activationDate: toEpoch(t.activationDate) || 0,
							deactivationDate: toEpoch(t.deactivationDate)
						};
					}
				),
				budgets: client.budgets?.map(
					(b: any): ClientBudget => {
						return {
							budget: b.budget,
							activationDate: toEpoch(b.activationDate) || 0,
							deactivationDate: toEpoch(b.deactivationDate),
							associationId: b.associationId
						};
					}
				),
				googleAdsAccounts:
					client.googleAds?.map(
						(g: any): ClientGoogleAds => {
							return {
								GoogleAdsAccount: g.GoogleAdsAccount,
								activationDate: toEpoch(g.activationDate) || 0,
								deactivationDate: toEpoch(g.deactivationDate),
								associationId: g.associationId
							};
						}
					) || [],
				bingAdsAccounts:
					client.bingAds?.map(
						(b: any): ClientBingAds => {
							return {
								BingAdsAccount: b.BingAdsAccount,
								activationDate: toEpoch(b.activationDate) || 0,
								deactivationDate: toEpoch(b.deactivationDate),
								associationId: b.associationId
							};
						}
					) || []
			});
		}

		return {
			page: data.page,
			totalPages: data.totalPages,
			hasMorePages: data.hasMorePages,
			data: clients
		};
	}

	async client(accessToken: string, clientId: string): Promise<Client> {
		const result = await this.http.get(`/${clientId}`, withAuthorization(accessToken));
		const client = result.data;
		return {
			id: client.id,
			name: client.name,
			active: client.active,
			plan: client.plan,
			territories: client.territories?.map(
				(t: any): ClientTerritory => {
					return {
						territory: t.territory,
						associationId: t.associationId,
						activationDate: toEpoch(t.activationDate) || 0,
						deactivationDate: toEpoch(t.deactivationDate)
					};
				}
			),
			budgets: client.budgets?.map(
				(b: any): ClientBudget => {
					return {
						budget: b.budget,
						activationDate: toEpoch(b.activationDate) || 0,
						deactivationDate: toEpoch(b.deactivationDate),
						associationId: b.associationId
					};
				}
			),
			googleAdsAccounts:
				client.googleAds?.map(
					(g: any): ClientGoogleAds => {
						return {
							GoogleAdsAccount: g.GoogleAdsAccount,
							activationDate: toEpoch(g.activationDate) || 0,
							deactivationDate: toEpoch(g.deactivationDate),
							associationId: g.associationId
						};
					}
				) || [],
			bingAdsAccounts:
				client.bingAds?.map(
					(b: any): ClientBingAds => {
						return {
							BingAdsAccount: b.BingAdsAccount,
							activationDate: toEpoch(b.activationDate) || 0,
							deactivationDate: toEpoch(b.deactivationDate),
							associationId: b.associationId
						};
					}
				) || [],
			pricingGrids: client.pricingGrids?.map(
				(p: any): ClientPricingGrid => {
					return {
						pricingGrid: p.pricingGrid,
						activationDate: toEpoch(p.activationDate) || 0,
						deactivationDate: toEpoch(p.deactivationDate),
						associationId: p.associationId
					};
				}
			)
		};
	}

	async clientsForPricingGrid(accessToken: string, gridId: string, query?: string): Promise<PricingGridClients> {
		const result = await this.http.get(
			`/grids/${gridId}`,
			withAuthorization(accessToken, {
				q: query !== undefined && query.length > 0 ? query : undefined
			})
		);

		const data = result.data;

		if (data === undefined) {
			return Promise.resolve({ assigned: [], unassigned: [] });
		}

		return {
			assigned: data.assigned.map((client: any) => {
				return {
					id: client.id,
					name: client.name,
					account: client.account
				};
			}),
			unassigned: data.unassigned.map((client: any) => {
				return {
					id: client.id,
					name: client.name,
					account: client.account
				};
			})
		};
	}

	private toEpoch(date: any): number {
		if (date) {
			return new Date(date).getTime();
		}
		return 0;
	}

	async addNewBudget(accessToken: string, clientId: string, budget: number, activationDate: number, deactivationDate?: number): Promise<void> {
		await this.http.put(
			`/${clientId}/budgets`,
			{
				budget,
				activationDate: formatDate(activationDate),
				deactivationDate: deactivationDate ? formatDate(deactivationDate) : undefined
			},
			withAuthorization(accessToken)
		);

		return Promise.resolve();
	}

	async updateBudget(accessToken: string, clientId: string, budgetId: string, budget: number, activationDate: number, deactivationDate?: number): Promise<void> {
		await this.http.put(
			`/${clientId}/budgets/${budgetId}`,
			{
				budget,
				activationDate: formatDate(activationDate),
				deactivationDate: deactivationDate ? formatDate(deactivationDate) : undefined
			},
			withAuthorization(accessToken)
		);

		return Promise.resolve();
	}

	async assign(accessToken: string, clientId: string, gridId: string, activationDate: number, deactivationDate?: number): Promise<void> {
		await this.http.put(
			`/${clientId}/actions/assignToGrid/${gridId}`,
			{
				activationDate: formatDate(activationDate),
				deactivationDate: deactivationDate ? formatDate(deactivationDate) : undefined
			},
			withAuthorization(accessToken)
		);

		return Promise.resolve();
	}

	async create(accessToken: string, client: CreateClientPayload): Promise<void> {
		await this.http.post(
			"",
			{
				name: client.name,
				planId: client.planId
			},
			withAuthorization(accessToken)
		);

		return Promise.resolve();
	}

	async update(accessToken: string, client: UpdateClientPayload): Promise<void> {
		await this.http.put(
			`/${client.id}`,
			{
				name: client.name,
				status: client.status,
				planId: client.planId
			},
			withAuthorization(accessToken)
		);

		return Promise.resolve();
	}

	async deallocateBudget(accessToken: string, clientId: string, associationId: string): Promise<void> {
		await this.http.put(`/action/deallocate-budget`, { clientId, associationId }, withAuthorization(accessToken));
		return Promise.resolve();
	}

	async deallocatePricingGrid(accessToken: string, clientId: string, associationId: string): Promise<void> {
		await this.http.put(`/action/deallocate-pricing-grid`, { clientId, associationId }, withAuthorization(accessToken));
		return Promise.resolve();
	}

	async deallocateBingAdsAccount(accessToken: string, clientId: string, associationId: string): Promise<void> {
		await this.http.put(`/action/deallocate-bing-ads-account`, { clientId, associationId }, withAuthorization(accessToken));
		return Promise.resolve();
	}

	async deallocateGoogleAdsAccount(accessToken: string, clientId: string, associationId: string): Promise<void> {
		await this.http.put(`/action/deallocate-google-ads-account`, { clientId, associationId }, withAuthorization(accessToken));
		return Promise.resolve();
	}

	async deallocatePhoneNumber(accessToken: string, clientId: string, associationId: string): Promise<void> {
		await this.http.put(`/action/deallocate-phone-number`, { clientId, associationId }, withAuthorization(accessToken));
		return Promise.resolve();
	}
	async deallocateTerritory(accessToken: string, clientId: string, associationId: string): Promise<void> {
		await this.http.put(`/action/deallocate-territory`, { clientId, associationId }, withAuthorization(accessToken));
		return Promise.resolve();
	}
}

const clientsService = new ClientsService(process.env.REACT_APP_CLIENTS_API || "");
export default clientsService;
