import React from "react";

import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  Row,
  Col,
} from "reactstrap";

interface PaginationProps {
  currentPage: number;
  pageSize?: number;
  totalPages: number;
  handleSearch: (page: number) => void;
  onPageSizeChanged?: (pageSize: number) => void;
}

const range = (start: number, end: number) => {
  return Array<number>(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx);
};

function activePages(page: number, totalPages: number): number[] {
  return range(0, totalPages).filter(
    (p) => p + 2 >= page && p - 2 <= page && p !== totalPages
  );
}

function pageSizes(): number[] {
  const pageSizeIncrement = 5;
  return range(pageSizeIncrement, 50).filter(
    (p) => p % pageSizeIncrement === 0
  );
}

export default function BearsightPagination({
  currentPage,
  totalPages,
  pageSize,
  handleSearch,
  onPageSizeChanged,
}: PaginationProps): JSX.Element {
  const pages = activePages(currentPage, totalPages);

  const handlePageSizeChanged = (pageSize: number) => {
    if (onPageSizeChanged) {
      onPageSizeChanged(pageSize);
    }
  };

  return (
    <>
      <Row>
        <Col sm={10} className="align-self-center">
          <nav aria-label="Page navigation">
            <Pagination
              className="pagination justify-content-center"
              listClassName="justify-content-center"
            >
              <PaginationItem className={currentPage === 0 ? "disabled" : ""}>
                <PaginationLink
                  href="#bearsight"
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    handleSearch(0);
                  }}
                >
                  <i className="fa fa-angle-left" />
                  <span className="sr-only">Previous</span>
                </PaginationLink>
              </PaginationItem>

              {pages.map((page, idx) => (
                <PaginationItem
                  className={page === currentPage ? "active" : ""}
                  key={`pg_item_${idx}`}
                >
                  <PaginationLink
                    href="#bearsight"
                    onClick={(e: React.SyntheticEvent) => {
                      e.preventDefault();
                      handleSearch(page);
                    }}
                  >
                    {page + 1}
                    {page === currentPage && (
                      <span className="sr-only">(current)</span>
                    )}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem
                className={currentPage >= totalPages - 1 ? "disabled" : ""}
              >
                <PaginationLink
                  href="#bearsight"
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    handleSearch(totalPages - 1);
                  }}
                >
                  <i className="fa fa-angle-right" />
                  <span className="sr-only">Next</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </nav>
        </Col>
        {pageSize && (
          <Col sm={2} className="align-self-end">
            <Input
              type="select"
              name="select"
              value={pageSize}
              onChange={(e: any) => {
                handlePageSizeChanged(e.target.value);
              }}
            >
              {pageSizes().map((ps) => (
                <option value={ps} key={`page_size_${ps}`}>{ps}</option>
              ))}
            </Input>
          </Col>
        )}
      </Row>
    </>
  );
}
