import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "redux/root-reducer";
import { actions as recordingsActions } from "calls/redux/recordings-actions";
import { useStateReducer } from "toolkit/state-reducer";
import IconButton from "toolkit/components/IconButton";

import { Table, Badge, Card, CardHeader, CardBody, Input, FormGroup } from "reactstrap";
import { selectHistoryRecordings } from "calls/redux/recordings-selector";
import { Recording } from "calls/redux/recordings";
import { RecordingTag } from "./services/recording";
import { actions as systemActions } from "../../redux/system-actions";
import RecordingsHistoryPagination from "./RecordingsHistoryPagination";

const mapState = (state: RootState) => ({
  userId: state.authentication.user?.id,
  loading: state.recordings.loading,
  recordings: selectHistoryRecordings(state.recordings),
  availableTags: state.recordings.availableTags,
});

const mapDispatch = {
  loadHistoryRecordings: recordingsActions.loadHistoryRecordings,
  updateTags: recordingsActions.updateTags,
  notify: systemActions.notify,
};

type RecordingsHistoryProps = {
  recording?: Recording;
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface HistoryRecordingsState {
  selectedCallResult: Map<string, RecordingTag>;
  selectedAppliance: Map<string, RecordingTag>;
  selectedBrand: Map<string, RecordingTag>;
  editedRecordingIds: Array<String>;
}

type Props = PropsFromRedux & RecordingsHistoryProps;

function RecordingsTable({
  recordings,
  recording,
  availableTags,
  loadHistoryRecordings,
  updateTags,
  notify
}: Props) {
  const initialState: HistoryRecordingsState = {
    selectedCallResult: new Map(),
    selectedAppliance: new Map(),
    selectedBrand: new Map(),
    editedRecordingIds: []
  };

  const [state, setState] = useStateReducer<HistoryRecordingsState>(initialState);

  const {
    selectedCallResult,
    selectedAppliance,
    selectedBrand,
    editedRecordingIds
  } = state;

  const handleSubmit = (recordingId: string) => {
    const appliance = selectedAppliance.get(recordingId);
    const brand = selectedBrand.get(recordingId);
    const callResult = selectedCallResult.get(recordingId);
    if (appliance && brand && callResult) {
      updateTags(recordingId, appliance.id, brand.id, callResult.id);
    }

    const errorMessage = (() => {
      var message = "";
      var hasError = false;
      if (!brand) {
        message += "brand, ";
        hasError = true;
      }
      if (!appliance) {
        message += "appliance, ";
        hasError = true;
      }
      if (!callResult) {
        message += "call result";
        hasError = true;
      }
      return { hasError, message: "(" + message + ")" };
    })();

    if (errorMessage.hasError) {
      notify(
        "Recordings tagging",
        "Please select " + errorMessage.message + " to proceed.",
        "danger"
      );
    }
  };

  return (
    <>
      <Card className="col-lg-12">
        <CardHeader>
          <div className="d-flex justify-content-between">
            <h3 className="mb-0">RECORDINGS HISTORY: {recording ? recording.from : ''}</h3>
            <h3>TOTAL COUNTS: {recording ? recording.totalCalls : 1}</h3>
          </div>
        </CardHeader>
        <CardBody>
          <Table className="align-items-center" responsive hover striped>
            <thead className="thead-light">
              <tr>
                {[
                  "Date",
                  "Call Info",
                  "Tag User",
                  "Recordings",
                  "Call result",
                  "Appliance",
                  "Brand",
                  ""
                ].map((name, idx) => (
                  <th scope="col" key={`recordings__header_${idx}`}>{name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {recordings.map((recording) => {
                const isSelected = editedRecordingIds.includes(recording.id);

                return (
                  <tr key={recording.id}>
                    <th scope="row">
                      <span className="mb-0 text-sm">{recording.date}</span>
                    </th>
                    <td className="d-flex flex-column">
                      {recording.formattedTo}
                      <Badge color="info" className="text-default">
                        {recording.duration}
                      </Badge>
                    </td>
                    <th scope="row">
                      <span className="mb-0 text-sm">{recording.assigneeName}</span>
                    </th>
                    <td>
                      <audio
                        controls
                        preload="auto"
                        crossOrigin="anonymous"
                      >
                        <source src={recording.recordingUrl.replace('.mp3', '.ogg')} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    </td>
                    <td>
                      <FormGroup style={{ marginBottom: 0.0 }}>
                        <Input
                          type="select"
                          name="callresult"
                          onChange={(e: any) => {
                            const selectedIds = [...e.target.selectedOptions].map(
                              (option: any) => option.value
                            );

                            const selectedId: number = parseInt(selectedIds[0]);
                            const newSelectedCallResult = new Map(selectedCallResult);
                            const newTag = availableTags.callResults.find(cr => cr.id === selectedId);
                            if (newTag) {
                              newSelectedCallResult.set(recording.id, newTag);
                              setState({
                                selectedCallResult: newSelectedCallResult,
                              });
                            }
                          }}
                          value={isSelected ? selectedCallResult.get(recording.id)?.id : recording.callResult?.id}
                          disabled={!isSelected}
                        >
                          {availableTags.callResults
                            .map((tag, idx) =>
                              <option
                                // selected={recording.callResult?.id === tag.id}
                                value={tag.id} key={`tag_${idx}`}>{tag.value}</option>
                            )
                          }
                        </Input>
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup style={{ marginBottom: 0.0 }}>
                        <Input
                          type="select"
                          name="appliance"
                          onChange={(e: any) => {
                            const selectedIds = [...e.target.selectedOptions].map(
                              (option: any) => option.value
                            );

                            const selectedId: number = parseInt(selectedIds[0]);
                            const newSelectedAppliance = new Map(selectedAppliance);
                            const newTag = availableTags.appliances.find(cr => cr.id === selectedId);
                            if (newTag) {
                              newSelectedAppliance.set(recording.id, newTag);
                              setState({
                                selectedAppliance: newSelectedAppliance,
                              });
                            }
                          }}
                          value={isSelected ? selectedAppliance.get(recording.id)?.id : recording.appliance?.id}
                          disabled={!isSelected}
                        >
                          {availableTags.appliances
                            .map((tag, idx) =>
                              <option
                                // selected={recording.appliance?.id === tag.id}
                                value={tag.id} key={`tag_${idx}`}>{tag.value}</option>
                            )
                          }
                        </Input>
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup style={{ marginBottom: 0.0 }}>
                        <Input
                          type="select"
                          name="brand"
                          onChange={(e: any) => {
                            const selectedIds = [...e.target.selectedOptions].map(
                              (option: any) => option.value
                            );

                            const selectedId: number = parseInt(selectedIds[0]);
                            const newSelectedBrand = new Map(selectedBrand);
                            const newTag = availableTags.brands.find(cr => cr.id === selectedId);
                            if (newTag) {
                              newSelectedBrand.set(recording.id, newTag);
                              setState({
                                selectedBrand: newSelectedBrand,
                              });
                            }
                          }}
                          value={isSelected ? selectedBrand.get(recording.id)?.id : recording.brand?.id}
                          disabled={!isSelected}
                        >
                          {availableTags.brands
                            .map((tag, idx) =>
                              <option
                                // selected={recording.brand?.id === tag.id}
                                value={tag.id} key={`tag_${idx}`}>{tag.value}</option>
                            )
                          }
                        </Input>
                      </FormGroup>
                    </td>
                    <td>
                      <IconButton
                        icon={`fa fa-${isSelected ? 'save' : 'edit'}`}
                        onClick={() => {
                          const newSelectedAppliance = new Map(selectedAppliance);
                          let newTag = availableTags.appliances.find(cr => cr.id === recording.appliance?.id);
                          if (newTag) {
                            newSelectedAppliance.set(recording.id, newTag);
                          }

                          const newSelectedBrand = new Map(selectedBrand);
                          newTag = availableTags.brands.find(cr => cr.id === recording.brand?.id);
                          if (newTag) {
                            newSelectedBrand.set(recording.id, newTag);
                          }

                          const newSelectedCallResult = new Map(selectedCallResult);
                          newTag = availableTags.callResults.find(cr => cr.id === recording.callResult?.id);
                          if (newTag) {
                            newSelectedCallResult.set(recording.id, newTag);
                          }

                          if (isSelected) {
                            handleSubmit(recording.id);
                          }

                          let newIds = [ ...editedRecordingIds ];
                          if (!newIds.includes(recording.id)) {
                            newIds.push(recording.id);
                          } else {
                            newIds = newIds.filter(id => id !== recording.id);
                          }

                          setState({
                            editedRecordingIds: newIds,
                            selectedAppliance: newSelectedAppliance,
                            selectedBrand: newSelectedBrand,
                            selectedCallResult: newSelectedCallResult
                          });
                        }}
                        tooltip={isSelected ? "Save Recording" : "Edit Recording"}
                        loading={false}
                      />
                      {isSelected &&
                        <IconButton
                          tableAction={true}
                          id={recording.id}
                          color="danger"
                          icon="fa fa-times"
                          onClick={() => {
                            const newIds = [...editedRecordingIds].filter(id => id !== recording.id);
                            setState({ editedRecordingIds: newIds });
                          }}
                          tooltip="Cancel"
                        />
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <hr />
          <RecordingsHistoryPagination from={recording?.from} />
        </CardBody>
      </Card>
    </>
  );
}

export default connector(RecordingsTable);
