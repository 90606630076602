import React from "react";
import { Button, ButtonProps } from "reactstrap";

interface Props extends ButtonProps {
  isRound?: boolean;
  text?: string;
  loading: boolean;
  type?: "submit" | "reset" | "button";
  disabled?: boolean;
  icon?: string;
  block?: boolean;
}

export default function SpinningButton({
  isRound,
  loading,
  color,
  text,
  type,
  disabled,
  icon,
  block,
  ...btnProps
}: Props): JSX.Element {
  return (
    <Button
      style={isRound ? {width: "40px", height: "40px", borderRadius: "50%", padding:"0"} : undefined}
      {...btnProps}
      block={block !== undefined ? block : true}
      color={color ? color : "info"}
      disabled={loading || !!disabled}
    >
      {loading ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      ) : (
        icon && (
          <span className="btn-inner--icon">
            <i className={icon} />
          </span>
        )
      )}
      {text && <span className="btn-inner--text"> {text}</span>}
    </Button>
  );
}
