import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "redux/root-reducer";
import FormHeader from "toolkit/components/FormHeader";
import ConfirmationPopup from "toolkit/components/ConfirmationPopup";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Spinner,
  Table,
  Modal,
  Form,
} from "reactstrap";
import { actions as systemActions } from "../../redux/system-actions";
import { actions as usersActions } from "users/redux/users-actions";
import IconButton from "toolkit/components/IconButton";
import BearsightPagination from "toolkit/components/BearsightPagination";
import { useStateReducer } from "toolkit/state-reducer";
import ResetPassword from "./ResetPassword";
import { User } from "users/redux/user";
import BearsightTextInput from "toolkit/components/BearsightTextInput";
const mapState = (state: RootState) => ({
  users: state.users.users,
  page: state.users.usersPage,
  pageSize: state.users.usersPageSize,
  totalPages: state.users.usersTotalPages,
  loading: state.users.loading,
  resetPassword: state.users.resetPassword
});

const mapDispatch = {
  loadUsers: usersActions.loadUsers,
  resetUserPassword: usersActions.resetUserPassword,
  notify: systemActions.notify,
  deleteUser: usersActions.deleteUser,
  grantAdmin: usersActions.grantAdmin,
  revokeAdmin: usersActions.revokeAdmin
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

interface State {
  changePasswordOpened: boolean;
  selectedUser?: User;
  filteringQuery: string;
  confirmationOpened: boolean;
}

function UsersTable({
  loading,
  users,
  page,
  pageSize,
  totalPages,
  loadUsers,
  deleteUser,
  grantAdmin,
  revokeAdmin,
  resetUserPassword,
  resetPassword,
  notify,
}: Props) {
  const initialState: State = {
    changePasswordOpened: false,
    filteringQuery: "",
    confirmationOpened: false
  };

  const [state, setState] = useStateReducer<State>(initialState);

  const {
    changePasswordOpened,
    selectedUser,
    filteringQuery,
    confirmationOpened
  } = state;

  useEffect(() => {
    loadUsers(0, 5);
  }, []);

  useEffect(() => {
    if(resetPassword.success){
        notify("Password Reset", "Password reset successfully");
    }
    if(resetPassword.error){
        notify(
            "Password Reset",
            typeof resetPassword.error === "string" ? resetPassword.error : "Failed to reset password.",
            "danger"
          );
    }
  },[resetPassword])

  
  const toggleChangePassword = () => {
    setState({ changePasswordOpened: !changePasswordOpened });
  }

  const handlePageSizeChanged = (pageSize: number) => {
    loadUsers(0, pageSize);
  };

  const handlePageChanged = (page: number) => {
    loadUsers(page, pageSize);
  };

  const handleSelectedUserChange = (user:User) => {
      setState({selectedUser: user});
  }

  const openDeleteConfirmation = (user: User) => {
    setState({
      confirmationOpened: true,
      selectedUser: user,
    });
  };

  const handleCancel = () => {
    setState({ confirmationOpened: false, selectedUser: undefined });
  };

  const handleDelete = () => {
    setState({ confirmationOpened: false, selectedUser: undefined });

    if (selectedUser) {
      deleteUser(selectedUser.id || '', page, pageSize);
    }
  };

  return (
    <>
      <FormHeader name="MANAGEMENT" parentName="Portal Users" />
      <Container className="mt--6 d-flex justify-content-center" fluid>
        <Card className="col-lg-8">
          <CardHeader>
            <h3 className="mb-0">PORTAL USERS</h3>
          </CardHeader>
          <CardBody>
            <Form className="needs-validation mb-4" noValidate inline onSubmit={e => e.preventDefault()}>
                <BearsightTextInput
                  label=""
                  icon="fas fa-search"
                  text={filteringQuery}
                  onChange={(value) => setState({ filteringQuery: value })}
                />
            </Form>
            {loading && (
              <Row className="justify-content-center">
                <Spinner />
            </Row>) }
            {!loading && users.length > 0 && (
                <>
                  <Table className="align-items-center" responsive hover striped>
                    <thead className="thead-light">
                      <tr>
                        <th className="text-left">Username</th>
                        <th>Email</th>
                        <th>Is Admin ?</th>
                        <th className="text-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users
                        .filter((u)=> u.username.includes(filteringQuery))
                        .map((user) => {
                        return (
                          <tr key={user.id}>
                            <th scope="row">
                              {user.username}
                            </th>
                            <td>
                                {user.email}
                            </td>
                            <td>
                              <IconButton
                                tableAction={true}
                                id={user.id}
                                color="default"
                                icon={user.admin ? "fa fa-toggle-on" : "fa fa-toggle-off"}
                                onClick={() => {
                                  if (!user.admin) {
                                    grantAdmin(user.username, page, pageSize);
                                  } else {
                                    revokeAdmin(user.username, page, pageSize);
                                  }
                                }}
                                tooltip={user.admin ? "Make non-admin" : "Make admin"}
                                loading={loading}
                                style={{ fontSize: '24px' }}
                              />
                                {/* {user.admin ? "Yes" :"No"} */}
                            </td>
                            <td className="text-right">
                              <IconButton
                                tableAction={true}
                                id={user.id}
                                color="default"
                                icon="fa fa-trash"
                                onClick={() => {
                                  openDeleteConfirmation(user);
                                }}
                                tooltip="Delete"
                                loading={loading}
                              />
                              <IconButton
                                tableAction={true}
                                id={user.id}
                                color="default"
                                icon="ni ni-lock-circle-open"
                                onClick={() => {
                                        handleSelectedUserChange(user);
                                        toggleChangePassword();
                                    }
                                } 
                                tooltip="Reset password"
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              )}

            <hr />
            <BearsightPagination
              currentPage={page}
              totalPages={totalPages}
              pageSize={pageSize}
              onPageSizeChanged={handlePageSizeChanged}
              handleSearch={handlePageChanged}
            />

          </CardBody>
        </Card>

        <ConfirmationPopup
          title={"Delete user"}
          message={"Are you sure you want to delete user?"}
          onConfirm={handleDelete}
          onCancel={handleCancel}
          opened={confirmationOpened}
        />

        <Modal
          className="modal-dialog-centered"
          isOpen={changePasswordOpened}
          size="xl"
          toggle={toggleChangePassword}
        >
           {selectedUser && (<ResetPassword user={selectedUser} loading={resetPassword.loading} onBack={toggleChangePassword} onResetPassword={resetUserPassword} />)}
        </Modal>
      </Container>
    </>
  );
}

export default connector(UsersTable);
