import { Recording } from "./recordings";
import { RecordingState } from "./recordings-state";

export function selectRecordings(state: RecordingState): Recording[] {
  return state.recordings.recordings.map((recording) => {
    return {
      id: recording.id,
      date: recording.date,
      from: recording.from,
      formattedFrom: recording.formattedFrom,
      formattedTo: recording.formattedTo,
      duration:
        Math.floor(recording.duration / 60) +
        " m:" +
        (recording.duration % 60) +
        " s",
      recordingUrl: recording.recordingUrl,
      callResult: state.availableTags.callResults.find(
        (tag) => tag.id === recording.callResultId
      ),
      appliance: state.availableTags.appliances.find(
        (tag) => tag.id === recording.applianceId
      ),
      brand: state.availableTags.brands.find(
        (tag) => tag.id === recording.brandId
      ),
      assigneeId: recording.assigneeId,
      assigneeName: recording.assigneeName,
      totalCalls: recording.totalCalls
    };
  });
}

export function selectHistoryRecordings(state: RecordingState): Recording[] {
  return state.recordings.historyRecordings.map((recording) => {
    return {
      id: recording.id,
      date: recording.date,
      from: recording.from,
      formattedFrom: recording.formattedFrom,
      formattedTo: recording.formattedTo,
      duration:
        Math.floor(recording.duration / 60) +
        " m:" +
        (recording.duration % 60) +
        " s",
      recordingUrl: recording.recordingUrl,
      callResult: state.availableTags.callResults.find(
        (tag) => tag.id === recording.callResultId
      ),
      appliance: state.availableTags.appliances.find(
        (tag) => tag.id === recording.applianceId
      ),
      brand: state.availableTags.brands.find(
        (tag) => tag.id === recording.brandId
      ),
      assigneeId: recording.assigneeId,
      assigneeName: recording.assigneeName,
      totalCalls: recording.totalCalls
    };
  });
}

export function selectTotalPages(state: RecordingState): number {
  return state.recordings.totalPages;
}

export function selectHistoryTotalPages(state: RecordingState): number {
  return state.recordings.historyTotalPages;
}
