import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "redux/root-reducer";

import { actions as recordingsAction } from "calls/redux/recordings-actions";
import { selectTotalPages } from "calls/redux/recordings-selector";
import BearsightPagination from "toolkit/components/BearsightPagination";

const mapState = (state: RootState) => ({
  startDate: state.recordings.startDate,
  endDate: state.recordings.endDate,
  currentPage: state.recordings.page,
  totalPages: selectTotalPages(state.recordings),
  tagged: state.recordings.tagged,
  pageSize: state.recordings.recordingsPageSize,
  territory: state.recordings.territory
});

const mapDispatch = {
  loadRecordings: recordingsAction.loadRecordings,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

function RecordingsPagination({
  startDate,
  endDate,
  currentPage,
  totalPages,
  tagged,
  pageSize,
  loadRecordings,
  territory,
}: Props): JSX.Element {
  const handleSearch = (page: number) => {
    if (startDate && endDate) {
      loadRecordings(startDate, endDate, page, tagged, pageSize, territory);
    }
  };

  const handlePageSizeChanged = (pageSize: number) => {
    if (startDate && endDate) {
      loadRecordings(startDate, endDate, currentPage, tagged, pageSize, territory);
    }
  };

  return (
    <BearsightPagination
      currentPage={currentPage}
      totalPages={totalPages}
      pageSize={pageSize}
      onPageSizeChanged={handlePageSizeChanged}
      handleSearch={handleSearch}
    />
  );
}

export default connector(RecordingsPagination);
