
export const numericValidation = (value?: string): boolean =>
  value !== undefined && new RegExp("^[0-9]*$").test(value);

export const notEmptyValidation = (value?: string) =>
  value === undefined || value.length === 0;

export const matchValidation = (value?: string) => (match?: string) =>
  value === undefined || match === undefined || value !== match;

export const emailValidation = (value?: string) =>
  value === undefined || !value.match(".*@.*\\..+");

export const composeValidation = (
  validations: ((value?: string) => boolean)[]
) => {
  return (value?: string) =>
    validations
      .map((validation) => validation(value))
      .reduce((prev, current) => prev && current);
};
