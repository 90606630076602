import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "redux/root-reducer";

import { Table, Row, Form, Modal, Spinner, Container, Card, CardHeader, CardBody, Col } from "reactstrap";
import { actions as clientActions } from "../redux/client-actions";
import { actions as systemActions } from "../../redux/system-actions";
import { useStateReducer } from "toolkit/state-reducer";
import IconButton from "toolkit/components/IconButton";
import BearsightTextInput from "toolkit/components/BearsightTextInput";
import { Client } from "clients/budgets/services/client";
import { selectClients } from "clients/redux/client-selector";
import BearsightPagination from "toolkit/components/BearsightPagination";
import { useDebounce } from "use-debounce";
import FormHeader from "toolkit/components/FormHeader";
import ClientNumberAllocation from "./ClientNumberAllocation";
import { epochNow, getActive } from "toolkit/clock";

const mapState = (state: RootState) => ({
	loading: state.clients.loading,
	clients: selectClients(state.clients),
	clientPhoneNumbers: state.clients.clientPhoneNumbers,
	availablePhoneNumbers: state.clients.availablePhoneNumbers,
	currentPage: state.clients.clientsPage,
	totalPages: state.clients.clientsTotalPages,
	pageSize: state.clients.clientsPageSize
});

const mapDispatch = {
	loadClients: clientActions.loadClients,
	loadClientPhoneNumbers: clientActions.loadClientPhoneNumbers,
	loadAvailablePhoneNumbers: clientActions.loadAvailablePhoneNumbers,
	allocatePhoneNumber: clientActions.allocatePhoneNumber,
	cleanClientPhoneNumbers: clientActions.cleanClientPhoneNumbers,
	updatePhoneNumber: clientActions.updatePhoneNumber,
	notify: systemActions.notify
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

interface State {
	filteringQuery: string;
	phoneQuery: string;
	selectedClient?: Client;
	editOpened: boolean;
}

function ClientsPhoneNumbersTable({
	loading,
	clients,
	totalPages,
	currentPage,
	pageSize,
	clientPhoneNumbers,
	availablePhoneNumbers,
	loadClients,
	loadClientPhoneNumbers,
	loadAvailablePhoneNumbers,
	allocatePhoneNumber,
	cleanClientPhoneNumbers,
	updatePhoneNumber,
	notify
}: Props) {
	const initialState: State = {
		filteringQuery: "",
		phoneQuery: "",
		editOpened: false
	};

	const [state, setState] = useStateReducer<State>(initialState);

	const { filteringQuery, phoneQuery, selectedClient, editOpened } = state;

	useEffect(() => {
		loadClients(currentPage, pageSize);
	}, []);

	const [debouncedSearchTerm] = useDebounce(filteringQuery, 500);

	useEffect(() => {
		loadClients(currentPage, pageSize, debouncedSearchTerm);
	}, [debouncedSearchTerm]);

	const [debouncedPhoneNumberSearchTerm] = useDebounce(phoneQuery, 500);

	useEffect(() => {
		loadClients(currentPage, pageSize, undefined, debouncedPhoneNumberSearchTerm);
	}, [debouncedPhoneNumberSearchTerm]);

	useEffect(() => {
		if (selectedClient) {
			const updateSelectedClient = clients.find(client => client.id === selectedClient.id);
			if (updateSelectedClient) {
				setState({ selectedClient: updateSelectedClient });
			}
		}
	}, [JSON.stringify(clients)]);

	useEffect(() => {
		if (clientPhoneNumbers) {
			loadClients(currentPage, pageSize, filteringQuery, phoneQuery);
		}
	}, [clientPhoneNumbers ? JSON.stringify(clientPhoneNumbers.phoneNumbers) : undefined]);

	const toggleEdit = () => {
		setState({ editOpened: !editOpened });
	};

	const handleClose = () => {
		cleanClientPhoneNumbers();
		setState({ editOpened: false });
	};

	const handleEdit = (client: Client) => {
		setState({ editOpened: true, selectedClient: client });
	};

	const handlePageSizeChanged = (pageSize: number) => {
		loadClients(currentPage, pageSize);
	};

	const handlePageChanged = (page: number) => {
		loadClients(page, pageSize);
	};

	if (loading && clients.length === 0) {
		return (
			<Row className="justify-content-center">
				<Spinner />
			</Row>
		);
	}

	return (
		<>
			<FormHeader name="PHONE NUMBERS" parentName="Clients" />
			<Container className="mt--6 d-flex justify-content-center" fluid>
				<Card className="col-lg-12">
					<CardHeader>
						<h3 className="mb-0">PHONE NUMBERS</h3>
					</CardHeader>
					<CardBody>
						<Form className="needs-validation mb-4" noValidate inline>
							<Row>
								<Col>
									<BearsightTextInput label="" icon="fas fa-search" text={filteringQuery} onChange={value => setState({ filteringQuery: value })} />
								</Col>
								<Col>
									<BearsightTextInput label="" icon="fas fa-phone" text={phoneQuery} onChange={value => setState({ phoneQuery: value })} />
								</Col>
							</Row>
						</Form>

						<Table className="align-items-center" responsive hover striped>
							<thead className="thead-light">
								<tr>
									{["Name", "Territory", "Total numbers", "Activation date", "Deactivation date", ""].map((name, idx) => (
										<th scope="col" key={`header_${idx}`}>
											{name}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{clients.map(client => {
									return (
										<tr key={client.id}>
											<th scope="row">
												<span className="mb-0 text-sm">{client.name}</span>
											</th>
											<td>{getActive(client.territories)?.territory.name  || "N/A"}</td>
											<td>{client.phoneNumbers ? <>{client.phoneNumbers.filter(p => p.deactivationDate === undefined || p.deactivationDate > epochNow()).length}</> : "N/A"}</td>
											<td>
												<span className="text-sm">{client.activePhoneNumber?.activationDate ? new Date(client.activePhoneNumber?.activationDate).toLocaleDateString() : "N/A"}</span>
											</td>
											<td>
												<span className="text-sm">{client.activePhoneNumber?.deactivationDate ? new Date(client.activePhoneNumber?.deactivationDate).toLocaleDateString() : "N/A"}</span>
											</td>
											<td>
												<IconButton id={client.id} color="default" icon="fa fa-edit" onClick={() => handleEdit(client)} tooltip="Edit phone number" loading={loading} />
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
						<hr />
						<Row className="d-flex justify-content-center align-items-center">
							<Col>
								<BearsightPagination currentPage={currentPage} totalPages={totalPages} pageSize={pageSize} onPageSizeChanged={handlePageSizeChanged} handleSearch={handlePageChanged} />
							</Col>
						</Row>

						<Modal className="modal-dialog-centered" isOpen={editOpened} size="xl" toggle={toggleEdit}>
							<ClientNumberAllocation
								onAllocatePhoneNumber={allocatePhoneNumber}
								loading={loading}
								client={selectedClient}
								loadClientPhoneNumbers={loadClientPhoneNumbers}
								clientPhoneNumbers={clientPhoneNumbers}
								onClose={handleClose}
								onUpdatePhoneNumber={updatePhoneNumber}
								availablePhoneNumbers={availablePhoneNumbers}
								loadAvailablePhoneNumbers={loadAvailablePhoneNumbers}
								from="PHONE_NUMBER_MANAGEMENT"
							/>
						</Modal>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default connector(ClientsPhoneNumbersTable);
