import { ClientState } from "./client-state";
import { ReduxClient } from "./clients";
import { PhoneNumber, Budget, GoogleAdsAccount, BingAdsAccount, ClientBudget, ClientGoogleAds, ClientBingAds, ClientPhoneNumber } from "clients/budgets/services/client";
import { epochNow } from "toolkit/clock";
import { PricingGrid as PricingGridSummary } from "clients/budgets/services/client";


export function selectClients(state: ClientState): ReduxClient[] {
  return state.clients.map((client) => {
    return {
      ...client,
      activeBudget: activeBudget(client.budgets),
      activePhoneNumber: activePhoneNumber(client.phoneNumbers),
      activeGoogleAdsAccount: activeGoogleAdsAccount(client.googleAdsAccounts),
      activeBingAdsAccount: activeBingAdsAccount(client.bingAdsAccounts),
    };
  });
}

function activeGoogleAdsAccount(googleAdsAccounts?: ClientGoogleAds[]): ClientGoogleAds | undefined {
  const googleAdsAccount = googleAdsAccounts?.sort((lhs, rhs) => {
    if ((lhs.deactivationDate ?? 0) > (rhs.deactivationDate ?? 0)) {
      return -1;
    }
    return +1;
  })[0];

  const alreadyDeactivated = googleAdsAccount
    && googleAdsAccount.deactivationDate
    && googleAdsAccount.deactivationDate < epochNow();
  if (alreadyDeactivated) {
    return undefined;
  }
  return googleAdsAccount;
}

function activeBingAdsAccount(bingAdsAccounts?: ClientBingAds[]): ClientBingAds | undefined {
  const bingAdsAccount = bingAdsAccounts?.sort((lhs, rhs) => {
    if ((lhs.deactivationDate ?? 0) > (rhs.deactivationDate ?? 0)) {
      return -1;
    }
    return +1;
  })[0];

  // const alreadyDeactivated = bingAdsAccount
  //   && bingAdsAccount.deactivationDate
  //   && bingAdsAccount.deactivationDate < epochNow();
  // if (alreadyDeactivated) {
  //   return undefined;
  // }
  return bingAdsAccount;
}

function activePhoneNumber(phoneNumbers?: ClientPhoneNumber[]): ClientPhoneNumber | undefined {
  const activePhoneNumber = phoneNumbers?.sort((lhs, rhs) => {
    if ((lhs.deactivationDate ?? 0) > (rhs.deactivationDate ?? 0)) {
      return -1;
    }
    return +1;
  })[0];

  const alreadyDeactivated = activePhoneNumber && (activePhoneNumber.deactivationDate ?? 0) < epochNow();
  if (alreadyDeactivated) {
    return undefined;
  }
  return activePhoneNumber;
}

function activeBudget(budgets: ClientBudget[]): ClientBudget {
  const activeBudget = budgets.sort((lhs, rhs) => {
    if (lhs.activationDate > rhs.activationDate) {
      return -1;
    }
    return +1;
  })[0];
  return activeBudget;
}

export function selectPricingGrids(state: ClientState): PricingGridSummary[] {
  return state.pricingGrid;
}
