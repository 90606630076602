import React from "react";
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from "reactstrap";
import Notifications from "./Notifications";

interface Props {
  name: string;
  parentName: string;
}

export default function FormHeader({ name, parentName }: Props): JSX.Element {
  return (
    <>
      <Notifications />
      <div className="header pb-6 bg-default">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 d-inline-block mb-0 text-secondary">{name}</h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links"
                >
                  <BreadcrumbItem>
                    <a href="#bearsight" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home text-secondary" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#bearsight" className="text-secondary" onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}
