import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "redux/root-reducer";

import { Card, CardHeader, CardBody, Table, Form, Row, Button, FormGroup, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { actions as clientsActions } from "clients/redux/client-actions";
import { actions as systemActions } from "../../redux/system-actions";
import { Client, Budget, ClientBudget } from "./services/client";
import BearsightTextInput from "toolkit/components/BearsightTextInput";
import { useStateReducer } from "toolkit/state-reducer";
import { notEmptyValidation, composeValidation, numericValidation } from "toolkit/validations";
import SpinningButton from "toolkit/components/SpinningButton";
import DateRange from "calls/recordings/DateRange";
import IconButton from "toolkit/components/IconButton";
import ReactDatetime from "react-datetime";
import { Moment } from "moment";
import { getActive } from "toolkit/clock";

const mapState = (state: RootState) => ({
	loading: state.clients.loading
});

const mapDispatch = {
	updateBudget: clientsActions.updateBudget,
	addNewBudget: clientsActions.addNewBudget,
	notify: systemActions.notify
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type EditBudgetProps = {
	from?: "CLIENT_MANAGEMENT" | "BUDGET_MANAGEMENT";
	client?: Client;
	onClose: () => void;
};

type Props = PropsFromRedux & EditBudgetProps;

interface State {
	submitted: boolean;
	budget: number;
	defaultActivationDate: number;
	activationDate: number;
	deactivationDate?: number;
	budgets: ClientBudget[];
	isEditMode: boolean;
}

function EditBudget({ from, loading, onClose, client, updateBudget, addNewBudget, notify }: Props) {
	const now = new Date();
	now.setHours(0, 0, 0, 0);
	const date = now.getTime();
	const [state, setState] = useStateReducer<State>({
		budget: 0,
		submitted: false,
		defaultActivationDate: date,
		activationDate: date,
		budgets: [],
		isEditMode: from === "CLIENT_MANAGEMENT"
	});

	const { submitted, isEditMode, budget, budgets, defaultActivationDate, activationDate, deactivationDate } = state;

	useEffect(() => {
		setState({
			budget: 0,
			budgets: client
				? [
						...client.budgets.map(b => {
							return { ...b };
						})
				  ]
				: [],
			isEditMode: from === "CLIENT_MANAGEMENT",
			submitted: false
		});
	}, [client?.id]);

	useEffect(() => {
		setState({
			budgets: client
				? [
						...client.budgets.map(b => {
							return { ...b };
						})
				  ]
				: []
		});
	}, [JSON.stringify(client?.budgets)]);

	const handleEditBudget = (budget: ClientBudget) => {
		setState({ submitted: true });
		if (client) {
			updateBudget(client.id, { ...budget });
		}
	};

	const handleCreateNewBudget = () => {
		setState({ submitted: true });
		if (client) {
			addNewBudget(client.id, budget, activationDate, deactivationDate);
		}
	};

	const goToEditModel = () => {
		setState({ isEditMode: true, submitted: false });
	};

	const cancelEditModel = () => {
		if (from === "CLIENT_MANAGEMENT") {
			setState({ submitted: false });
			onClose();
		} else {
			setState({ isEditMode: false, submitted: false });
		}
	};

	if (isEditMode) {
		return (
			<div className="modal-body p-0">
				<Card className="bg-secondary shadow border-0">
					<CardHeader className="bg-transparent pb-5">
						<button
							onClick={e => {
								e.preventDefault();
								onClose();
							}}
							type="button"
							className="close"
							aria-label="Close"
						>
							<span aria-hidden="true">×</span>
						</button>
						<div className="text-muted text-center mt-2 mb-3">
							<small>CLIENT</small>
						</div>
						<p className="font-weight-bold text-center">{`${client?.name} (${getActive(client?.territories || [])?.name || "N/A"}) (${client?.id})`}</p>
					</CardHeader>
					<CardBody className="px-lg-5">
						<div className="text-center text-muted mb-4">
							<small>BUDGET</small>
						</div>
						<Form role="form">
							<BearsightTextInput
								type="number"
								disabled={client === undefined}
								label="Budget"
								icon="fa fa-credit-card"
								text={budget.toString()}
								onChange={value => setState({ budget: +value })}
								validation={composeValidation([notEmptyValidation, numericValidation])}
								submitted={submitted}
							/>

							<DateRange
								defaultStartDate={defaultActivationDate}
								startLabel="Activation date"
								endLabel="Deactivation date"
								onStartDateChange={date => setState({ activationDate: date })}
								onEndDateChange={date => setState({ deactivationDate: date })}
							/>

							<Row className="justify-content-end">
								<SpinningButton
									block={false}
									icon="fa fa-plus"
									color="default"
									type="submit"
									loading={loading}
									text="Add"
									onClick={e => {
										e.preventDefault();
										handleCreateNewBudget();
									}}
								/>
								<Button
									color="link"
									onClick={e => {
										e.preventDefault();
										cancelEditModel();
									}}
								>
									<span className="text-default">CANCEL</span>
								</Button>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</div>
		);
	}

	return (
		<>
			<div className="modal-body p-0">
				<Card className="bg-secondary shadow border-0">
					<CardHeader className="bg-transparent pb-5">
						<button
							onClick={e => {
								e.preventDefault();
								onClose();
							}}
							type="button"
							className="close"
							aria-label="Close"
						>
							<span aria-hidden="true">×</span>
						</button>
						<div className="text-muted text-center mt-2 mb-3">
							<small>CLIENT</small>
						</div>
						<p className="font-weight-bold text-center">{`${client?.name} (${getActive(client?.territories || [])?.territory.name || "N/A"}) (${client?.id})`}</p>
					</CardHeader>
					<CardBody className="px-lg-5">
						<div className="text-center text-muted mb-4">
							<small>BUDGETS</small>
						</div>

						<Table className="align-items-center" hover striped>
							<thead className="thead-light">
								<tr>
									{["Budget", "Activation date", "Deactivation date", ""].map(name => (
										<th key={name} scope="col">
											{name}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{budgets
									.sort((lhs, rhs) => {
										return lhs.associationId.localeCompare(rhs.associationId);
									})
									.map(budget => {
										return (
											<tr key={budget.associationId}>
												<th scope="row">
													<BearsightTextInput
														type="number"
														text={budget.budget.budget.toString()}
														icon="fa fa-credit-card"
														onChange={value => {
															budget.budget.budget = +value;
														}}
													/>
												</th>

												<td>
													<FormGroup>
														<InputGroup className="input-group-alternative">
															<InputGroupAddon addonType="prepend">
																<InputGroupText>
																	<i className="ni ni-calendar-grid-58" />
																</InputGroupText>
															</InputGroupAddon>
															<ReactDatetime
																defaultValue={new Date(budget.activationDate)}
																inputProps={{
																	placeholder: "Activation date"
																}}
																timeFormat={false}
																onChange={(e: Moment | string) => {
																	if (typeof e !== "string") {
																		const date = parseInt(e.valueOf().toString());
																		budget.activationDate = date;
																	}
																}}
															/>
														</InputGroup>
													</FormGroup>
												</td>

												<td>
													<FormGroup>
														<InputGroup className="input-group-alternative">
															<InputGroupAddon addonType="prepend">
																<InputGroupText>
																	<i className="ni ni-calendar-grid-58" />
																</InputGroupText>
															</InputGroupAddon>

															<ReactDatetime
																defaultValue={budget.deactivationDate && budget.deactivationDate !== 0 ? new Date(budget.deactivationDate) : undefined}
																inputProps={{
																	placeholder: "Deactivation date"
																}}
																timeFormat={false}
																onChange={(e: Moment | string) => {
																	if (typeof e !== "string") {
																		const date = parseInt(e.valueOf().toString());
																		budget.deactivationDate = date;
																	} else {
																		budget.deactivationDate = 0;
																	}
																}}
															/>
														</InputGroup>
													</FormGroup>
												</td>

												<td>
													<IconButton id={budget.associationId} color="default" icon="fa fa-save" onClick={() => handleEditBudget(budget)} tooltip="Edit budget" loading={loading} />
												</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
						<Row className="justify-content-end">
							<SpinningButton
								block={false}
								icon="fa fa-plus"
								color="default"
								loading={loading}
								text="Add new budget"
								onClick={e => {
									e.preventDefault();
									goToEditModel();
								}}
							/>
							<Button
								color="link"
								onClick={e => {
									e.preventDefault();
									onClose();
								}}
							>
								<span className="text-default">CANCEL</span>
							</Button>
						</Row>
					</CardBody>
				</Card>
			</div>
		</>
	);
}

export default connector(EditBudget);
