import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  Media,
  DropdownMenu,
} from "reactstrap";
import { Link } from "react-router-dom";
import UserProfile from "./UserProfile";

interface Props {
  email?: string;
  logout: () => void;
}

export default function UserOptionsDropdown({
  email,
  logout,
}: Props): JSX.Element {
  return (
    <UncontrolledDropdown nav>
      <DropdownToggle className="nav-link pr-0" color="" tag="a">
        <Media className="align-items-center">
          <UserProfile />
        </Media>
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem className="noti-title" header tag="div">
          <h6 className="text-overflow m-0">{email}</h6>
        </DropdownItem>

        <DropdownItem divider />
        <DropdownItem tag={Link} to="/admin/change-password">
            <i className="ni ni-lock-circle-open" />
            <span>Change Password</span>
        </DropdownItem>
        <DropdownItem href="#bearsight" onClick={logout}>
          <i className="fa fa-sign-out-alt" />
          <span>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
