export enum SyncType {
	GOOGLE_ADS_ACCOUNTS = "GOOGLE_ADS_ACCOUNTS",
	GOOGLE_ADS_PERFORMANCE_REPORT = "GOOGLE_ADS_PERFORMANCE_REPORT",
	BING_ADS_ACCOUNTS = "BING_ADS_ACCOUNTS",
	BING_ADS_PERFORMANCE_REPORT = "BING_ADS_PERFORMANCE_REPORT"
}

export enum Status {
	NOT_INITIALIZED = "NOT_INITIALIZED",
	IN_PROGRESS = "IN_PROGRESS",
	FINISHED = "FINISHED",
	ABORTED = "ABORTED", 
	FAILED = "FAILED"
}

export interface SyncOptions {
	withLabels?: boolean;
	startDate?: number;
	endDate?: number;

}

export interface SyncStatus {
	id?: string;
	status: Status;
}

export interface SyncState {
	error?: any; 
	syncStatus: { 
        GOOGLE_ADS_ACCOUNTS: SyncStatus;
        GOOGLE_ADS_PERFORMANCE_REPORT: SyncStatus;
        BING_ADS_ACCOUNTS: SyncStatus;
        BING_ADS_PERFORMANCE_REPORT: SyncStatus;
     };
}
