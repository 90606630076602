import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "redux/root-reducer";
import { useStateReducer } from "toolkit/state-reducer";

import { actions as recordingsActions } from "calls/redux/recordings-actions";
import { selectTotalPages, selectHistoryTotalPages } from "calls/redux/recordings-selector";
import BearsightPagination from "toolkit/components/BearsightPagination";

const mapState = (state: RootState) => ({
  totalPages: selectHistoryTotalPages(state.recordings)
});

const mapDispatch = {
  loadHistoryRecordings: recordingsActions.loadHistoryRecordings,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type RecordingsHistoryProps = {
  from?: string;
};

interface RecordingsHistoryState {
  currentPage: number;
  pageSize: number;
}

type Props = PropsFromRedux & RecordingsHistoryProps;

function RecordingsHistoryPagination({
  totalPages,
  from,
  loadHistoryRecordings,
}: Props): JSX.Element {
  const initialState: RecordingsHistoryState = {
    pageSize: 5,
    currentPage: 0
  };

  const [state, setState] = useStateReducer<RecordingsHistoryState>(initialState);

  const {
    pageSize,
    currentPage
  } = state;

  const handleSearch = (page: number) => {
    if (from) {
      setState({ currentPage: page });
      loadHistoryRecordings(page, true, pageSize, from);
    }
  };

  const handlePageSizeChanged = (pageSize: number) => {
    console.log('history pageSize: ', pageSize);
    if (from) {
      setState({ pageSize });
      loadHistoryRecordings(currentPage, true, pageSize, from);
    }
  };

  return (
    <BearsightPagination
      currentPage={currentPage}
      totalPages={totalPages}
      pageSize={pageSize}
      onPageSizeChanged={handlePageSizeChanged}
      handleSearch={handleSearch}
    />
  );
}

export default connector(RecordingsHistoryPagination);
