import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "redux/root-reducer";
import FormHeader from "toolkit/components/FormHeader";

import { Card, CardHeader, CardBody, Container } from "reactstrap";
import { actions as systemActions } from "../../redux/system-actions";
import ClientsTable from "./ClientsTable";

const mapState = (state: RootState) => ({});

const mapDispatch = {
  notify: systemActions.notify,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

function ClientBudget({ notify }: Props) {
  return (
    <>
      <FormHeader name="BUDGETS" parentName="Clients" />
      <Container className="mt--6 d-flex justify-content-center" fluid>
        <Card className="col-lg-8">
          <CardHeader>
            <h3 className="mb-0">CLIENT BUDGET</h3>
          </CardHeader>
          <CardBody>
            <ClientsTable />
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default connector(ClientBudget);
