import React from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import IconButton from "toolkit/components/IconButton";
import { PricingGrid } from "./services/pricing-grid";
import BearsightTextInput from "toolkit/components/BearsightTextInput";
import { PricingGridClient } from "../budgets/services/client";
import { useStateReducer } from "toolkit/state-reducer";
import DateRange from "calls/recordings/DateRange";
import { epochNow } from "toolkit/clock";

type Props = {
  loading: boolean;
  pricingGrid?: PricingGrid;
  assignedClients?: PricingGridClient[];
  unAssignedClients?: PricingGridClient[];
  onBack: () => void;
  assignClientToPricingGrid: (
    clientIds: string[],
    pricingGrid: PricingGrid,
    activationDate: number,
    deactivationDate?: number
  ) => void;
};

interface State {
  selectedIdToAssign: string[];
  activationDate: number;
  deactivationDate?: number;
  filteringQuery?: string;
}

export default function PricingGridUserAssignement({
  loading,
  pricingGrid,
  assignedClients,
  unAssignedClients,
  onBack,
  assignClientToPricingGrid,
}: Props) {
  const date = epochNow();
  const [state, setState] = useStateReducer<State>({
    selectedIdToAssign: [],
    activationDate: date,
    deactivationDate: undefined,
  });

  const {
    selectedIdToAssign,
    filteringQuery,
    activationDate,
    deactivationDate,
  } = state;

  const handleAssignClient = () => {
    if (pricingGrid) {
      assignClientToPricingGrid(
        selectedIdToAssign,
        pricingGrid,
        activationDate,
        deactivationDate
      );
    }
  };

  if (
    loading &&
    (!assignedClients ||
      assignedClients.length === 0 ||
      !unAssignedClients ||
      unAssignedClients.length === 0)
  ) {
    return (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <button
              onClick={(e) => {
                e.preventDefault();
                onBack();
              }}
              type="button"
              className="close"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="text-muted text-center mt-2 mb-3">
              <small>PRICING GRID DETAILS</small>
            </div>
          </CardHeader>
          <CardBody className="px-lg-1">
            <Row className="justify-content-center">
              <Spinner />
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }

  const multiSelectSize = 8;

  return (
    <div className="modal-body p-0">
      <Card className="bg-secondary shadow border-0">
        <CardHeader className="bg-transparent pb-5">
          <button
            onClick={(e) => {
              e.preventDefault();
              onBack();
            }}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
          <div className="text-muted text-center mt-2 mb-3">
            <small>PRICING GRID DETAILS</small>
          </div>
        </CardHeader>
        <CardBody className="px-lg-1">
          <Form>
            <Col>
              <Row className="align-items-end ml-3">
                <BearsightTextInput
                  label="Search clients"
                  icon="fas fa-search"
                  text={filteringQuery}
                  onChange={(value) => setState({ filteringQuery: value })}
                />

                <DateRange
                  className="ml-3"
                  defaultStartDate={date}
                  startLabel="Activation date"
                  endLabel="Deactivation date"
                  onStartDateChange={(date) =>
                    setState({ activationDate: date })
                  }
                  onEndDateChange={(date) =>
                    setState({ deactivationDate: date })
                  }
                />
              </Row>

              <Row className="align-items-center">
                <Col>
                  <FormGroup>
                    <Label
                      size="sm"
                      className="ml-2 font-weight-bold"
                      for="unassigned-clients"
                    >
                      Unassigned clients
                    </Label>
                    <Input
                      type="select"
                      name="selectMulti"
                      id="unassigned-clients"
                      size={multiSelectSize}
                      onChange={(e: any) => {
                        const selectedIds = [...e.target.selectedOptions].map(
                          (option: any) => option.value
                        );

                        setState({
                          selectedIdToAssign: selectedIds,
                        });
                      }}
                      multiple
                    >
                      {unAssignedClients
                        ?.filter((client) => {
                          if (!filteringQuery) {
                            return true;
                          }

                          const filteringRegex = new RegExp(
                            filteringQuery.toUpperCase()
                          );
                          return (
                            filteringRegex.test(client.name.toUpperCase()) ||
                            filteringRegex.test(client.account.toUpperCase())
                          );
                        })
                        .map((client, idx) => (
                          <option value={client.id} key={`client_${idx}`}>{`${client.name} (${client.id})`}</option>
                        ))}
                    </Input>
                  </FormGroup>
                </Col>
                <IconButton
                  tableAction={true}
                  id={"assign"}
                  color="default"
                  icon="fa fa-arrow-right"
                  onClick={handleAssignClient}
                  tooltip="Assign"
                  loading={loading}
                />
                <Col>
                  <FormGroup>
                    <Label
                      size="sm"
                      className="ml-2 font-weight-bold"
                      for="assigned-clients"
                    >
                      Assigned clients
                    </Label>
                    <Input
                      type="select"
                      name="selectMulti"
                      id="assigned-clients"
                      size={multiSelectSize}
                      multiple
                    >
                      {assignedClients?.map((client) => (
                        <option value={client.id}>{`${client.name} (${client.id})`}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}
