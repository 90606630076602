import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "redux/root-reducer";
import FormHeader from "toolkit/components/FormHeader";

import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";
import SpinningButton from "../../toolkit/components/SpinningButton";
import BearsightTextInput from "toolkit/components/BearsightTextInput";
import { Group } from "users/redux/group";
import { Role } from "users/redux/role";
import BearsightMultiselect from "toolkit/components/BearsightMultiselect";
import { actions } from "users/redux/user-form-actions";
import { actions as systemActions } from "../../redux/system-actions";
import { notEmptyValidation, emailValidation } from "toolkit/validations";
import ConfirmationPopup from "toolkit/components/ConfirmationPopup";
import { selectEditUsers, EditableClient } from "users/redux/user-form-selectors";
import { useStateReducer } from "toolkit/state-reducer";

const mapState = (state: RootState) => ({
  loading: state.userForm.loading,
  roles: state.userForm.roles,
  groups: state.userForm.groups,
  users: selectEditUsers(state.userForm),
  succeed: state.userForm.succeed,
  error: state.userForm.error,
});

const mapDispatch = {
  clearSuccess: actions.clearSuccess,
  clearError: actions.clearError,
  updateUser: actions.updateClient,
  deleteUser: actions.deleteClient,
  notify: systemActions.notify,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

interface EditUserState {
  firstName?: string;
  lastName?: string;
  email?: string;
  selectedGroups: Group[];
  selectedRoles: Role[];
  submitted: boolean;
  confirmationOpened: boolean;
  confirmationTitle?: string;
  confirmationMessage?: string | JSX.Element;
  successMessage?: string;
  errorMessage?: string;
  operation?: () => void;

  selectedClient?: EditableClient;
}

function EditClient({
  loading,
  users,
  roles,
  groups,
  succeed,
  error,
  clearSuccess,
  clearError,
  notify,
  updateUser,
  deleteUser,
}: Props) {
  const initialState: EditUserState = {
    firstName: "",
    lastName: "",
    email: "",
    selectedGroups: [],
    selectedRoles: [],
    submitted: false,
    confirmationOpened: false,
    confirmationTitle: "",
    confirmationMessage: "",
    successMessage: "",
    errorMessage: "",
    operation: () => {},
    selectedClient: undefined,
  };

  const [state, setState] = useStateReducer<EditUserState>(initialState);

  const {
    firstName,
    lastName,
    email,
    selectedGroups,
    selectedRoles,
    submitted,
    confirmationOpened,
    confirmationTitle,
    confirmationMessage,
    selectedClient: selectedUser,
    successMessage,
    errorMessage,
    operation,
  } = state;

  const formIsValid: boolean =
    !notEmptyValidation(firstName) &&
    !notEmptyValidation(lastName) &&
    !emailValidation(email) &&
    selectedGroups.length > 0 &&
    selectedRoles.length > 0;

  const openEditConfirmation = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setState({
      confirmationOpened: true,
      confirmationTitle: "Edit client",
      confirmationMessage: (
        <span>
          You are about to update client
          <span className="font-weight-bold"> [{selectedUser?.email}]</span>.
          <div> You want to continue ?</div>
        </span>
      ),
      successMessage: "Client successfully updated.",
      errorMessage: "Failed to update client.",
      operation: handleEdit,
    });
  };

  const openDeleteConfirmation = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setState({
      confirmationOpened: true,
      confirmationTitle: "Delete client",
      confirmationMessage: (
        <span>
          You are about to delete client
          <span className="font-weight-bold"> [{selectedUser?.email}]</span>.
          <div> You want to continue ?</div>
        </span>
      ),
      successMessage: "Client successfully deleted.",
      errorMessage: "Failed to delete client.",
      operation: handleDelete,
    });
  };

  const handleCancel = () => {
    setState({ confirmationOpened: false });
  };

  const handleEdit = () => {
    setState({ submitted: true, confirmationOpened: false });

    if (selectedUser && email && firstName && lastName) {
      const command = {
        id: selectedUser.id,
        firstName: firstName,
        lastName: lastName,
        groups: selectedGroups,
        roles: selectedRoles,
      };

      updateUser(
        email !== selectedUser.email ? { ...command, email } : command
      );
    }
  };

  const handleDelete = () => {
    setState({ submitted: true, confirmationOpened: false });

    if (selectedUser) {
      deleteUser(selectedUser.id);
    }
  };

  useEffect(() => {
    if (succeed) {
      clearSuccess();
      setState(initialState);
      notify(
        confirmationTitle || "Edit/Delete",
        successMessage || "Operation succeeded!"
      );
    }
  }, [succeed]);

  useEffect(() => {
    if (error) {
      clearError();
      notify(
        confirmationTitle || "Edit/Delete",
        typeof error === "string" ? error : errorMessage || "Operation failed!",
        "danger"
      );
    }
  }, [error]);

  const handleUserChanged = (value: EditableClient[]) => {
    const newUser = value[0];
    setState({
      selectedClient: newUser,
      selectedGroups: newUser.groups,
      selectedRoles: newUser.roles,
    });
  };

  return (
    <>
      <FormHeader name="Edit client" parentName="Klipfolio clients" />
      <Container className="mt--6 d-flex justify-content-center" fluid>
        <Card className="col-lg-6">
          <CardHeader>
            <h3 className="mb-0">EDIT CLIENT</h3>
          </CardHeader>
          <CardBody>
            <Form>
              <Row>
                <Col>
                  <BearsightMultiselect<EditableClient>
                    disabled={loading}
                    loading={loading}
                    isClearable={false}
                    isMulti={false}
                    defaultValue={selectedUser ? undefined : []}
                    options={users}
                    label="Select user"
                    onChange={handleUserChanged}
                    getDisplayValue={(user: EditableClient) =>
                      `${user.lastName} ${user.firstName}`
                    }
                    submitted={submitted}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md="6">
                  <BearsightTextInput
                    disabled={selectedUser === undefined}
                    label="First name"
                    icon="fas fa-user"
                    text={selectedUser?.firstName}
                    onChange={(value) => setState({ firstName: value })}
                    validation={notEmptyValidation}
                    submitted={submitted}
                  />
                </Col>
                <Col md="6">
                  <BearsightTextInput
                    disabled={selectedUser === undefined}
                    label="Last name"
                    icon="fas fa-user"
                    text={selectedUser?.lastName}
                    onChange={(value) => setState({ lastName: value })}
                    validation={notEmptyValidation}
                    submitted={submitted}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BearsightTextInput
                    disabled={selectedUser === undefined}
                    label="Email"
                    icon="fas fa-envelope"
                    text={selectedUser?.email}
                    onChange={(value) => setState({ email: value })}
                    validation={emailValidation}
                    submitted={submitted}
                    error="Invalid email"
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <BearsightMultiselect<Group>
                    disabled={selectedUser === undefined}
                    defaultValue={selectedGroups}
                    options={selectedUser ? groups : []}
                    selected={selectedGroups}
                    label="Groups"
                    onChange={(value: Group[]) =>
                      setState({ selectedGroups: value })
                    }
                    displayValue="name"
                    submitted={submitted}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BearsightMultiselect<Role>
                    disabled={selectedUser === undefined}
                    defaultValue={selectedRoles}
                    options={selectedUser ? roles : []}
                    selected={selectedRoles}
                    label="Roles"
                    onChange={(value: Role[]) =>
                      setState({ selectedRoles: value })
                    }
                    displayValue="name"
                    submitted={submitted}
                  />
                </Col>
              </Row>
              <Row className="mt-6">
                <Col>
                  <SpinningButton
                    icon="fa fa-pencil-alt"
                    disabled={!formIsValid}
                    color="default"
                    loading={loading}
                    text="EDIT"
                    onClick={openEditConfirmation}
                  />
                </Col>
                <Col>
                  <SpinningButton
                    icon="fa fa-times"
                    disabled={!formIsValid}
                    color="danger"
                    loading={loading}
                    text="DELETE"
                    onClick={openDeleteConfirmation}
                  />
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
        <ConfirmationPopup
          title={confirmationTitle}
          message={confirmationMessage}
          onConfirm={operation}
          onCancel={handleCancel}
          opened={confirmationOpened}
        />
      </Container>
    </>
  );
}

export default connector(EditClient);
