import { UsersState } from "./users-state";
import { UsersAction, UsersActionType, UsersFailedAction, GetUsersAction, ChangeUsersPageSizeAction, ChangeUsersPageAction, ChangeUsersTotalPagesAction, ResetUserPasswordFail } from "./users-actions";

const inititalState: UsersState = {
    loading: false,
    users: [],
    usersPage: 0,
    usersPageSize: 5,
    usersTotalPages: 0,
    resetPassword:{
        loading:false,
        success: false
    }
};


export default function usersReducer(
    state: UsersState = inititalState,
    action: UsersAction
): UsersState {
    switch(action.type){
        case UsersActionType.USERS_LOADING: {
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        }
        case UsersActionType.USERS_FAILED: {
            return {
                ...state,
                loading: false,
                error: (action as UsersFailedAction).error,
            };
        }
        case UsersActionType.USERS_GET: {
            return {
                ...state,
                loading: false,
                error: undefined,
                users: (action as GetUsersAction).users
            };
        }
        case UsersActionType.CHANGE_USERS_PAGE_SIZE: {
            return {
                ...state,
                usersPageSize: (action as ChangeUsersPageSizeAction).pageSize,
            };
        }            
        case UsersActionType.CHANGE_USERS_PAGE: {
            return {
                ...state,
                usersPage: (action as ChangeUsersPageAction).page,
            };
        }
        case UsersActionType.CHANGE_USERS_TOTAL_PAGES: {
            return {
                ...state,
                usersTotalPages: (action as ChangeUsersTotalPagesAction).totalPages,
            };
        }
        case UsersActionType.RESET_USER_PASSWORD_LOADING: {
            return {
                ...state,
                resetPassword:{
                    loading: true,
                    success: false
                }
            }
        }
        case UsersActionType.RESET_USER_PASSWORD_SUCCESS: {
            return {
                ...state,
                resetPassword:{
                    loading: false,
                    success: true,
                    error: undefined
                }
            }
        }
        case UsersActionType.RESET_USER_PASSWORD_FAIL: {
            return {
                ...state,
                resetPassword:{
                    loading: false,
                    success: true,
                    error: (action as ResetUserPasswordFail).error
                }
            }
        }
        default: 
            return state;

    }
}