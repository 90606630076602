import { NumbersAction, NumbersActionType, FailedAction, ChangeTwilioNumbersAction, ChangeTwilioNumbersPageSizeAction, UpdateTwilioNumberFailedAction } from "./numbers-actions";
import { NumbersState } from "./numbers-state";


const initialState: NumbersState = {
    loading: false,
    twilioNumbers: [],
    twilioNumbersPage: 0,
    twilioNumbersPageSize: 5,
    twilioNumbersTotalPages: 0,
    updateTwilioNumberSuccess: false,
}

export default function NumbersReducer(
    state: NumbersState = initialState,
    action: NumbersAction
): NumbersState{
    
    switch(action.type){
        case NumbersActionType.LOADING:
            return {
                ...state,
                loading: true,
                updateTwilioNumberSuccess: false,
                error: undefined,
            };
        case NumbersActionType.FAILED: 
            return {
                ...state,
                loading: false,
                error: (action as FailedAction).error,
            };
        case NumbersActionType.CHANGE_TWILIO_NUMBERS:
            return {
                ...state,
                loading: false,
                updateTwilioNumberSuccess: false,
                error: undefined,
                twilioNumbers: (action as ChangeTwilioNumbersAction).twilioNumbers.data,
                twilioNumbersPage: (action as ChangeTwilioNumbersAction).twilioNumbers.page,
                twilioNumbersTotalPages: (action as ChangeTwilioNumbersAction).twilioNumbers.totalPages,
            }
        case NumbersActionType.CHANGE_TWILIO_NUMBERS_PAGE_SIZE: 
            return {
                ...state,
                twilioNumbersPageSize: (action as ChangeTwilioNumbersPageSizeAction).pageSize
            }
        case NumbersActionType.UPDATE_TWILIO_NUMBER_SUCCESS: 
            return {
                ...state,
                loading: false,
                updateTwilioNumberSuccess: true,
                error: undefined
            }
        case NumbersActionType.UPDATE_TWILIO_NUMBER_FAILED: 
            return {
                ...state,
                loading: false,
                updateTwilioNumberSuccess: false,
                error: (action as UpdateTwilioNumberFailedAction).error,
            }
        default:
            return state;
    }
}