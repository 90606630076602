import { User } from "./user";
import { Action } from "redux";
import { RootState } from "redux/root-reducer";
import { ThunkAction } from "redux-thunk";
import userService from "users/services/user-service";
import { ChangePasswordCredentials } from "authentication/services/credentials";


export enum UsersActionType {
    USERS_LOADING = "USERS_LOADING",
    USERS_GET = "GET_USERS",
    USERS_FAILED = "USERS_FAILED",
    CHANGE_USERS_PAGE_SIZE = "USERS_CHANGE_USERS_PAGE_SIZE",
    CHANGE_USERS_PAGE = "USERS_CHANGE_USERS_PAGE",
    CHANGE_USERS_TOTAL_PAGES = "USERS_CHANGE_USERS_TOTAL_PAGES",
    RESET_USER_PASSWORD_LOADING = "RESET USER PASSWORD LOADING",
    RESET_USER_PASSWORD_SUCCESS = "RESET USER PASSWORD SUCCESS",
    RESET_USER_PASSWORD_FAIL = "RESET USER PASSWORD FAIL", 
    
}

export interface UsersLoadingAction {
    type: UsersActionType.USERS_LOADING;
}

export interface GetUsersAction {
    type: UsersActionType.USERS_GET;
    users: User[];
}

export interface UsersFailedAction {
    type: UsersActionType.USERS_FAILED;
    error: any;
}

export interface ChangeUsersPageSizeAction {
    type: UsersActionType.CHANGE_USERS_PAGE_SIZE;
    pageSize: number;
}
  
export interface ChangeUsersPageAction {
    type: UsersActionType.CHANGE_USERS_PAGE;
    page: number;
}
  
export interface ChangeUsersTotalPagesAction {
    type: UsersActionType.CHANGE_USERS_TOTAL_PAGES;
    totalPages: number;
}

export interface RestUserPasswordLoading {
    type: UsersActionType.RESET_USER_PASSWORD_LOADING
}

export interface ResetUserPasswordSuccess {
    type: UsersActionType.RESET_USER_PASSWORD_SUCCESS
}

export interface ResetUserPasswordFail {
    type: UsersActionType.RESET_USER_PASSWORD_FAIL,
    error: any
}


export type UsersAction = 
                    | UsersLoadingAction 
                    | GetUsersAction 
                    | UsersFailedAction
                    | ChangeUsersPageAction
                    | ChangeUsersPageSizeAction
                    | ChangeUsersTotalPagesAction
                    | RestUserPasswordLoading
                    | ResetUserPasswordSuccess
                    | ResetUserPasswordFail;

function loading(): UsersLoadingAction {
    return {
        type: UsersActionType.USERS_LOADING,
    }
}

function failed(error:any):UsersFailedAction {
    return {
        type: UsersActionType.USERS_FAILED,
        error,
    }
}

function getUsers(users:User[]):GetUsersAction {
    return {
        type: UsersActionType.USERS_GET,
        users
    }
}

type UsersThunkAction = ThunkAction<
  void,
  RootState,
  unknown,
  Action<UsersActionType>
>;

const loadUsers = (
    page: number,
    pageSize: number,
  ): UsersThunkAction => async (dispatch, getState) => {
    dispatch(loading());
    try {
        const accessToken = getState().authentication?.tokens?.accessToken;

        if (accessToken) {
        const users = await userService.portalUsers(
            accessToken,
            page,
            pageSize,
        );

        dispatch(getUsers(users.data));
        dispatch(changeUsersPage(users.page))
        dispatch(changeUsersTotalPages(users.totalPages));
        dispatch(changeUsersPageSize(pageSize));
        
        }
    } catch (e) {
        dispatch(failed(e));
    }
};

const deleteUser = (userId: string, page: number, pageSize: number): UsersThunkAction => async (dispatch, getState) => {
    dispatch(loading());

    try {
        const accessToken = getState().authentication?.tokens?.accessToken;

        if (accessToken) {
        await userService.deleteProtalUser(
            accessToken,
            userId
        );

        const users = await userService.portalUsers(accessToken, page, pageSize);

        dispatch(getUsers(users.data));
        dispatch(changeUsersPage(users.page))
        dispatch(changeUsersTotalPages(users.totalPages));
        dispatch(changeUsersPageSize(pageSize));
        
        }
    } catch (e) {
        dispatch(failed(e));
    }
}

const grantAdmin = (username: string, page: number, pageSize: number): UsersThunkAction => async (dispatch, getState) => {
    dispatch(loading());

    try {
        const accessToken = getState().authentication?.tokens?.accessToken;

        if (accessToken) {
        await userService.grantAdmin(
            accessToken,
            username
        );

        const users = await userService.portalUsers(accessToken, page, pageSize);

        dispatch(getUsers(users.data));
        dispatch(changeUsersPage(users.page))
        dispatch(changeUsersTotalPages(users.totalPages));
        dispatch(changeUsersPageSize(pageSize));
        
        }
    } catch (e) {
        dispatch(failed(e));
    }
}

const revokeAdmin = (username: string, page: number, pageSize: number): UsersThunkAction => async (dispatch, getState) => {
    dispatch(loading());

    try {
        const accessToken = getState().authentication?.tokens?.accessToken;

        if (accessToken) {
        await userService.revokeAdmin(
            accessToken,
            username
        );

        const users = await userService.portalUsers(accessToken, page, pageSize);

        dispatch(getUsers(users.data));
        dispatch(changeUsersPage(users.page))
        dispatch(changeUsersTotalPages(users.totalPages));
        dispatch(changeUsersPageSize(pageSize));
        
        }
    } catch (e) {
        dispatch(failed(e));
    }
}

function changeUsersPageSize(pageSize: number): ChangeUsersPageSizeAction {
    return {
      type: UsersActionType.CHANGE_USERS_PAGE_SIZE,
      pageSize,
    };
  }
  
function changeUsersPage(page: number): ChangeUsersPageAction {
    return {
        type: UsersActionType.CHANGE_USERS_PAGE,
        page,
    };
}

function changeUsersTotalPages(
totalPages: number
): ChangeUsersTotalPagesAction {
    return {
        type: UsersActionType.CHANGE_USERS_TOTAL_PAGES,
        totalPages,
    };
}

function resetUserPasswordLoading(): RestUserPasswordLoading {
    return {
        type: UsersActionType.RESET_USER_PASSWORD_LOADING
    }
}

function resetUserPasswordSuccess(): ResetUserPasswordSuccess {
    return {
        type: UsersActionType.RESET_USER_PASSWORD_SUCCESS
    }
}

function resetUserPasswordFail(error:any): ResetUserPasswordFail {
    return {
        type: UsersActionType.RESET_USER_PASSWORD_FAIL,
        error
    }
}

const resetUserPassword = (
    changePasswordCredentials: Partial<ChangePasswordCredentials>
) : UsersThunkAction => async (dispatch, getState) => {
    dispatch(resetUserPasswordLoading());
    try {
        const accessToken = getState().authentication?.tokens?.accessToken;

        if (accessToken) {
            await userService.resetUserPassword(accessToken, changePasswordCredentials);
            dispatch(resetUserPasswordSuccess());
        }
    } catch(e) {
        dispatch(resetUserPasswordFail(e));
    }
    
}
  

export const actions = {
    loading,
    failed,
    loadUsers,
    resetUserPassword,
    deleteUser,
    grantAdmin,
    revokeAdmin
}