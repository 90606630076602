import React from "react";
import { Modal, Button } from "reactstrap";

interface Props {
  opened: boolean;
  title?: string;
  message?: string | JSX.Element;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export default function ConfirmationPopup({
  opened,
  title,
  message,
  onConfirm,
  onCancel,
}: Props) {
  return (
    <Modal className="modal-dialog-centered" isOpen={opened} toggle={onCancel}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {title}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onCancel}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">{message}</div>
      <div className="modal-footer">
        <Button
          color="secondary"
          data-dismiss="modal"
          type="button"
          onClick={onCancel}
        >
          Close
        </Button>
        <Button color="default" type="button" onClick={onConfirm}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
}
