import { ChangeBingAccountsStatusAction, ChangeBingPerformanceStatusAction, ChangeGoogleAccountsStatusAction, ChangeGooglePerformanceStatusAction, FailedAction, SyncAction, SyncActionType } from "./sync-actions";
import { Status, SyncState } from "./sync-state";

const initialState: SyncState = {
	syncStatus: {
		GOOGLE_ADS_ACCOUNTS: {
			status: Status.NOT_INITIALIZED
		},
		GOOGLE_ADS_PERFORMANCE_REPORT: {
			status: Status.NOT_INITIALIZED
		},
		BING_ADS_ACCOUNTS: {
			status: Status.NOT_INITIALIZED
		},
		BING_ADS_PERFORMANCE_REPORT: {
			status: Status.NOT_INITIALIZED
		}
	}
};

export function syncReducer(state: SyncState = initialState, action: SyncAction): SyncState {
	switch (action.type) {
		case SyncActionType.SYNC_FAILED:
			return {
				...state,
				error: (action as FailedAction).error
			};
		case SyncActionType.CLEAR_ERROR:
			return {
				...state,
				error: undefined
			};
		case SyncActionType.CHANGE_GOOGLE_ACCOUNTS_STATUS:
			return {
				...state,
				syncStatus: {
					...state.syncStatus,
					GOOGLE_ADS_ACCOUNTS: (action as ChangeGoogleAccountsStatusAction).status
				}
			};
		case SyncActionType.CHANGE_GOOGLE_PERFORMANCE_STATUS:
			return {
				...state,
				syncStatus: {
					...state.syncStatus,
					GOOGLE_ADS_PERFORMANCE_REPORT: (action as ChangeGooglePerformanceStatusAction).status
				}
			};
		case SyncActionType.CHANGE_BING_ACCOUNTS_STATUS:
			return {
				...state,
				syncStatus: {
					...state.syncStatus,
					BING_ADS_ACCOUNTS: (action as ChangeBingAccountsStatusAction).status
				}
			};
		case SyncActionType.CHANGE_BING_PERFORMANCE_STATUS:
			return {
				...state,
				syncStatus: {
					...state.syncStatus,
					BING_ADS_PERFORMANCE_REPORT: (action as ChangeBingPerformanceStatusAction).status
				}
			};
		default:
			return state;
	}
}
