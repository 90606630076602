import { UserFormState } from "./user-form-state";
import {
  UserFormAction,
  UserFormActionType,
  ChangeGroupsAction,
  ChangeRolesAction,
  UserFormFailedAction,
  UserFormChangeSuccessAction,
  ChangeUsersAction,
} from "./user-form-actions";

const initialState: UserFormState = {
  loading: false,
  succeed: false,
  groups: [],
  roles: [],
  users: [],
};

export default function userFormReducer(
  state: UserFormState = initialState,
  action: UserFormAction
): UserFormState {
  switch (action.type) {
    case UserFormActionType.LOADING:
      return {
        ...state,
        loading: true,
        succeed: false,
        error: undefined,
      };
    case UserFormActionType.FINISH_LOADING:
      return {
        ...state,
        loading: false,
      };
    case UserFormActionType.FAILED:
      return {
        ...state,
        loading: false,
        succeed: false,
        error: (action as UserFormFailedAction).error,
      };
    case UserFormActionType.SUCCESS:
      return {
        ...state,
        loading: false,
        succeed: true,
        error: undefined,
      };
    case UserFormActionType.CHANGE_GROUPS:
      return {
        ...state,
        groups: (action as ChangeGroupsAction).groups,
      };
    case UserFormActionType.CHANGE_ROLES:
      return {
        ...state,
        roles: (action as ChangeRolesAction).roles,
      };
    case UserFormActionType.CHANGE_USERS:
      return {
        ...state,
        users: (action as ChangeUsersAction).users,
      };
    case UserFormActionType.CHANGE_SUCCESS:
      return {
        ...state,
        succeed: (action as UserFormChangeSuccessAction).newValue,
      };
    case UserFormActionType.CLEAR_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case UserFormActionType.CLEAR_USER_FORM:
      return initialState;

    default:
      return state;
  }
}
