import React, { FunctionComponent } from "react";
import { Route, Redirect, RouteProps } from "react-router";
import { RootState } from "../../redux/root-reducer";
import { connect, ConnectedProps } from "react-redux";
import tokensService from "authentication/services/tokens-service";
import actions from "authentication/redux/authentication-actions";

const mapState = (state: RootState) => ({
  authenticated: state.authentication.authenticated,
});

const mapDispatch = {
  logout: actions.logout,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RouteProps;

const PrivateRoute: FunctionComponent<Props> = ({
  children,
  authenticated,
  logout,
  ...props
}) => {
  const isAuthenticated = tokensService.isAuthenticated();

  if (!isAuthenticated && authenticated) {
    logout();
  }

  return (
    <Route
      {...props}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { referrer: location },
            }}
          />
        )
      }
    />
  );
};

export default connector(PrivateRoute);
