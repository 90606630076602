import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "redux/root-reducer";
import FormHeader from "toolkit/components/FormHeader";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Spinner,
  Form,
  Table,
  Modal,
} from "reactstrap";
import { actions as systemActions } from "../../redux/system-actions";
import { actions as clientActions } from "clients/redux/client-actions";
import BearsightTextInput from "toolkit/components/BearsightTextInput";
import IconButton from "toolkit/components/IconButton";
import BearsightPagination from "toolkit/components/BearsightPagination";
import { useStateReducer } from "toolkit/state-reducer";
import { useDebounce } from "use-debounce/lib";
import ConfirmationPopup from "toolkit/components/ConfirmationPopup";
import { Territory } from "./services/territory";
import ClientTerritoryTable from "./ClientTerritoryTable";

const mapState = (state: RootState) => ({
  clients: state.clients.clients,
  loading: state.clients.loading,
  territories: state.clients.territories,
  selectedTerritoryDetails: state.clients.selectedTerritoryDetails,
});

const mapDispatch = {
  createTerritory: clientActions.createTerritory,
  updateTerritory: clientActions.updateTerritory,
  deleteTerritory: clientActions.deleteTerritory,
  loadTerritories: clientActions.loadTerritories,
  loadTerritoryDetails: clientActions.loadTerritoryDetails,
  assignTerritoryToClient: clientActions.assignTerritoryToClient,
  updateClientTerritory: clientActions.updateClientTerritory,
  initializeTerritories: clientActions.initializeTerritories,
  notify: systemActions.notify,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

interface State {
  filteringQuery: string;
  editOpened: boolean;
  modalOpened: boolean;
  confirmationOpened: boolean;
  selectedTerritory?: Territory;
  localTerretories: Territory[];
  editingTerritoryId: string;
  editingTerritoryName: string;
}

function TerritoriesTable({
  loading,
  clients,
  territories,
  selectedTerritoryDetails,
  deleteTerritory,
  updateTerritory,
  createTerritory,
  loadTerritories,
  loadTerritoryDetails,
  initializeTerritories,
  assignTerritoryToClient,
  updateClientTerritory,
  notify,
}: Props) {
  const initialState: State = {
    filteringQuery: "",
    editOpened: false,
    modalOpened: false,
    confirmationOpened: false,
    localTerretories: [],
    editingTerritoryId: '',
    editingTerritoryName: ''
  };

  const [state, setState] = useStateReducer<State>(initialState);

  const {
    filteringQuery,
    selectedTerritory,
    editOpened,
    modalOpened,
    confirmationOpened,
    localTerretories,
    editingTerritoryId,
    editingTerritoryName
  } = state;

  useEffect(() => {
    initializeTerritories();
  }, []);

  useEffect(() => {
    if (territories && territories.data) {
      const newTerritories = [
        ...territories.data.map((t) => {
          return { ...t };
        }),
      ];
      setState({
        localTerretories: newTerritories,
        selectedTerritory: selectedTerritory
          ? newTerritories.find((t) => t.id === selectedTerritory.id)
          : undefined,
      });
    }
  }, [JSON.stringify(territories)]);

  useEffect(() => {
    loadTerritoryDetails(selectedTerritory?.id);
  },[selectedTerritory])

  const toggleModalOpened = () => {
    setState({ modalOpened: !modalOpened });
  };

  const toggleEdit = () => {
    setState({ editOpened: !editOpened });
  };

  const closeModal = () => {
    setState({ modalOpened: false, selectedTerritory: undefined, });
  };

  const handlePageSizeChanged = (pageSize: number) => {
    if (territories) {
      loadTerritories(territories.page, pageSize);
    }
  };

  const handlePageChanged = (page: number) => {
    if (territories && territories.pageSize) {
      loadTerritories(page, territories.pageSize);
    }
  };

  const handleEdit = (territory: Territory, isEditing: boolean) => {
    if (isEditing) {
      if (territory.id.length === 0) {
        createTerritory(territory);
      } else {
        updateTerritory(territory);
      }
      setState({ editingTerritoryId: '', editingTerritoryName: '' });
    } else {
      setState({ editingTerritoryId: territory.id, editingTerritoryName: territory.name });
    }
  };

  const [debouncedSearchTerm] = useDebounce(filteringQuery, 500);

  useEffect(() => {
    if (territories && territories.pageSize) {
      loadTerritories(
        territories.page,
        territories.pageSize,
        debouncedSearchTerm
      );
    }
  }, [debouncedSearchTerm]);

  const handleCancel = () => {
    setState({ confirmationOpened: false, selectedTerritory: undefined });
  };

  const handleDelete = () => {
    setState({ confirmationOpened: false, selectedTerritory: undefined });

    if (selectedTerritory) {
      deleteTerritory(selectedTerritory.id);
    }
  };

  const openDeleteConfirmation = (territory: Territory) => {
    setState({
      confirmationOpened: true,
      selectedTerritory: territory,
    });
  };

  const handleEditClients = (territory: Territory) => {
    setState({
      modalOpened: true,
      selectedTerritory: territory,
    });
  };

  return (
    <>
      <FormHeader name="TERRITORIES" parentName="Geography" />
      <Container className="mt--6 d-flex justify-content-center" fluid>
        <Card className="col-lg-4">
          <CardHeader>
            <h3 className="mb-0">CLIENT TERRITORIES</h3>
          </CardHeader>
          <CardBody>
            <Form className="needs-validation mb-4" noValidate inline onSubmit={e => e.preventDefault()}>
              <BearsightTextInput
                label=""
                icon="fas fa-search"
                text={filteringQuery}
                onChange={(value) => setState({ filteringQuery: value })}
              />
            </Form>
            {loading && (!territories || clients.length === 0) ? (
              <Row className="justify-content-center">
                <Spinner />
              </Row>
            ) : (
                <>
                  <div className="d-flex flex-row-reverse">
                    <span className="mr-4">
                      <IconButton
                        icon="fa fa-plus"
                        onClick={() => {
                          setState({
                            localTerretories: [
                              ...localTerretories,
                              { id: "", name: "", clients: [] },
                            ],
                          });
                        }}
                        tooltip="Add new territory"
                        loading={loading}
                      />
                    </span>
                  </div>
                  <Table className="align-items-center" responsive hover striped>
                    <thead className="thead-light">
                      <tr>
                        {["Name", ""].map((name, idx) => (
                          <th scope="col" key={`header_${idx}`}>{name}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {localTerretories?.map((territory, idx) => {
                        const isEditing = territory.id === '' || editingTerritoryId === territory.id;
                        return (
                          <tr key={`territory_${idx}`}>
                            <th scope="row">
                              {isEditing ?
                              <BearsightTextInput
                                style={{ marginBottom: 0.0 }}
                                text={territory.name}
                                onChange={(value) => {
                                  territory.name = value;
                                }}
                              /> : territory.name}
                            </th>

                            <td className="text-right">
                              {isEditing &&
                                <IconButton
                                  tableAction={true}
                                  id={territory.id}
                                  color="danger"
                                  icon="fa fa-times"
                                  onClick={() => {
                                    territory.name = editingTerritoryName;
                                    setState({ editingTerritoryId: '', editingTerritoryName: '' });
                                  }}
                                  tooltip="Cancel"
                                  loading={loading}
                                />
                              }
                              <IconButton
                                tableAction={true}
                                id={territory.id}
                                color={
                                  isEditing
                                    ? "default"
                                    : "success"
                                }
                                icon={
                                  isEditing
                                    ? "fa fa-save"
                                    : "fa fa-edit"
                                }
                                onClick={() => handleEdit(territory, isEditing)}
                                tooltip={
                                  isEditing ? "Save" : "Edit"
                                }
                                loading={loading}
                              />
                              {territory.id.length > 0 && (
                                <>
                                  <IconButton
                                    tableAction={true}
                                    id={territory.id}
                                    color="danger"
                                    icon="fa fa-trash"
                                    onClick={() =>
                                      openDeleteConfirmation(territory)
                                    }
                                    tooltip="Delete"
                                    loading={loading}
                                  />

                                  <IconButton
                                    tableAction={true}
                                    id={territory.id}
                                    color="default"
                                    icon="fa fa-user-plus"
                                    onClick={() => handleEditClients(territory)}
                                    tooltip="Edit clients"
                                    loading={loading}
                                  />
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              )}

            <hr />
            <BearsightPagination
              currentPage={territories ? territories.page : 0}
              totalPages={territories ? territories.totalPages : 0}
              pageSize={territories ? territories.pageSize : 5}
              onPageSizeChanged={handlePageSizeChanged}
              handleSearch={handlePageChanged}
            />

            <Modal
              className="modal-dialog-centered"
              isOpen={editOpened}
              size="xl"
              toggle={toggleEdit}
            ></Modal>
          </CardBody>
        </Card>

        <ConfirmationPopup
          title={"Delete territory"}
          message={"Are you sure you want to delete territory ?"}
          onConfirm={handleDelete}
          onCancel={handleCancel}
          opened={confirmationOpened}
        />

        <Modal
          className="modal-dialog-centered"
          isOpen={modalOpened}
          size="xl"
          toggle={toggleModalOpened}
        >
          {selectedTerritoryDetails && (
            <ClientTerritoryTable
              allClients={clients}
              loading={loading}
              assignTerritoryToClient={assignTerritoryToClient}
              updateClientTerritory={updateClientTerritory}
              onBack={closeModal}
              territory={selectedTerritoryDetails}
            />
          )}
        </Modal>
      </Container>
    </>
  );
}

export default connector(TerritoriesTable);
