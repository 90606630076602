import { Tokens } from "./tokens";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../../redux/root-reducer";
import { Action } from "redux";
import { Credentials, ChangePasswordCredentials } from "../services/credentials";
import authenticationService from "../services/authentication-service";
import userService from "../../users/services/user-service";
import { User } from "../../users/redux/user";
import {
  UserFormActionType,
  clearUserForm,
  initialize as initializeUserForm,
} from "users/redux/user-form-actions";
import tokenStorage from "authentication/services/tokens-storage";

export enum AuthenticationActionType {
  LOGIN_LOADING = "LOGIN_LOADING",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILUR = "LOGIN_FAILUR",
  CHANGE_TOKENS = "CHANGE_TOKENS",
  CHANGE_USER = "CHANGE_USER",
  CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING",
  CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE",
  CLEAR_AUTHENTICATION = "CLEAR_AUTHENTICATION",
}

export interface ClearAuthenticationAction {
  type: AuthenticationActionType;
}

export interface LoginLoadingAction {
  type: AuthenticationActionType;
}

export interface LoginSuccessAction {
  type: AuthenticationActionType;
}

export interface ChangeTokensAction {
  type: AuthenticationActionType;
  tokens: Tokens;
}

export interface ChangeUserAction {
  type: AuthenticationActionType;
  user: User;
}

export interface LoginFailedAction {
  type: AuthenticationActionType;
  error: any;
}

export interface ChangePasswordLoadingAction {
  type: AuthenticationActionType;
}

export interface ChangePasswordSuccessAction {
  type: AuthenticationActionType;
}
export interface ChangePasswordFailedAction {
  type: AuthenticationActionType;
  error: any;
}

export type AuthenticationAction =
  | LoginLoadingAction
  | LoginSuccessAction
  | LoginFailedAction
  | ChangeTokensAction
  | ChangePasswordLoadingAction
  | ChangePasswordSuccessAction
  | ChangePasswordSuccessAction
  | ClearAuthenticationAction;

export function logingLoading(): LoginLoadingAction {
  return {
    type: AuthenticationActionType.LOGIN_LOADING,
  };
}

export function logingSuccess(): LoginSuccessAction {
  return {
    type: AuthenticationActionType.LOGIN_SUCCESS,
  };
}

export const changeTokens = (tokens: Tokens): LoginThunkAction => async (
  dispatch
) => {
  const changeTokensAction: ChangeTokensAction = {
    type: AuthenticationActionType.CHANGE_TOKENS,
    tokens: tokens,
  };

  tokenStorage.store(tokens);

  dispatch(changeTokensAction);
};

export function changeUser(user: User): ChangeUserAction {
  return {
    type: AuthenticationActionType.CHANGE_USER,
    user,
  };
}

export function logingFailure(error: any): LoginFailedAction {
  return {
    type: AuthenticationActionType.LOGIN_FAILUR,
    error: error,
  };
}

export function changePasswordLoading(): ChangePasswordLoadingAction{
  return {
    type: AuthenticationActionType.CHANGE_PASSWORD_LOADING
  };
}

export function changePasswordSuccess(): ChangePasswordSuccessAction{
  return {
    type: AuthenticationActionType.CHANGE_PASSWORD_SUCCESS
  };
}

export function changePasswordFailed(error: any): ChangePasswordFailedAction{
  return {
    type: AuthenticationActionType.CHANGE_PASSWORD_FAILURE,
    error: error
  };
}

export function clearAuthentication(): ClearAuthenticationAction {
  return {
    type: AuthenticationActionType.CLEAR_AUTHENTICATION,
  };
}

export const logout = (): InitializeThunkAction => async (dispatch) => {
  tokenStorage.clear();
  dispatch(clearAuthentication());
  dispatch(clearUserForm());
};

type LoginThunkAction = ThunkAction<
  void,
  RootState,
  unknown,
  Action<AuthenticationActionType>
>;
export const login = (
  username: string,
  password: string
): LoginThunkAction => async (dispatch) => {
  dispatch(logingLoading());

  const credentials: Credentials = { username, password };

  try {
    const tokens = await authenticationService.authenticate(credentials);

    dispatch(initialize(tokens));
  } catch (e) {
    dispatch(logingFailure(e));
  }
};

export const changePassword = (
  username: string,
  oldPassword: string,
  newPassword: string,
) : LoginThunkAction => async (dispatch) => {
  dispatch(changePasswordLoading());
  const changePasswordCredentials: ChangePasswordCredentials = {username, oldPassword, newPassword};
  authenticationService.changePassword(changePasswordCredentials)
    .then(() => {
      dispatch(changePasswordSuccess());
    }).catch((error) => {
      dispatch(changePasswordFailed(error));
    });
}

type InitializeThunkAction = ThunkAction<
  void,
  RootState,
  unknown,
  Action<AuthenticationActionType | UserFormActionType>
>;
export const initialize = (tokens: Tokens): InitializeThunkAction => async (
  dispatch
) => {
  dispatch(logingLoading());

  try {
    dispatch(changeTokens(tokens));
    dispatch(initializeUserForm());

    const user = await userService.userInfo(tokens.accessToken);
    dispatch(changeUser(user));

    dispatch(logingSuccess());
  } catch (e) {
    dispatch(logingFailure(e));
  }
};

const actions = {
  login: login,
  initialize: initialize,
  logingLoading: logingLoading,
  logingSuccess: logingSuccess,
  logingFailure: logingFailure,
  changeUser: changeUser,
  changeTokens: changeTokens,
  changePassword: changePassword,
  changePasswordLoading: changePasswordLoading,
  changePasswordSuccess: changePasswordSuccess,
  changePasswordFailed: changePasswordFailed,
  logout: logout,
};

export default actions;
