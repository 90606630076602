import { Group } from "./group";
import { Role } from "./role";
import { RootState } from "redux/root-reducer";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import klipfolioService from "users/services/klipfolio-service";
import {
  CreateClientCommand,
  UpdateClientCommand,
  CreateUserCommand,
} from "users/services/commands";
import { KlipfolioUser } from "./klipfolio-user";
import userService from "users/services/user-service";
import groupService from "users/services/group-service";
import roleService from "users/services/role-service";

export enum UserFormActionType {
  CHANGE_GROUPS = "USER_FORM_GROUPS",
  CHANGE_ROLES = "USER_FORM_ROLES",
  LOADING = "USER_FORM_LOADING",
  FINISH_LOADING = "USER_FORM_FINISHED_LOADING",
  FAILED = "USER_FORM_FAILURE",
  SUCCESS = "USER_FORM_SUCCESS",
  CHANGE_SUCCESS = "USER_FORM_CHANGE_SUCCESS",
  CLEAR_ERROR = "USER_FORM_CLEAR_ERROR",
  CLEAR_USER_FORM = "CLEAR_USER_FORM",
  CHANGE_USERS = "CHANGE_USERS",
}

export interface ChangeGroupsAction {
  type: UserFormActionType;
  groups: Group[];
}

export interface ChangeRolesAction {
  type: UserFormActionType;
  roles: Role[];
}

export interface UserFormLoadingAction {
  type: UserFormActionType;
}

export interface UserFormFinishLoadingAction {
  type: UserFormActionType;
}

export interface UserFormFailedAction {
  type: UserFormActionType;
  error: any;
}

export interface UserFormSuccessAction {
  type: UserFormActionType;
}

export interface UserFormClearErrorAction {
  type: UserFormActionType;
}

export interface UserFormChangeSuccessAction {
  type: UserFormActionType;
  newValue: boolean;
}

export interface ChangeUsersAction {
  type: UserFormActionType;
  users: KlipfolioUser[];
}

export interface ClearUserFormAction {
  type: UserFormActionType;
}

export type UserFormAction =
  | ChangeGroupsAction
  | ChangeRolesAction
  | UserFormFailedAction
  | UserFormSuccessAction
  | UserFormChangeSuccessAction
  | UserFormClearErrorAction
  | ClearUserFormAction
  | ChangeUsersAction
  | UserFormLoadingAction;

export function changeGroups(groups: Group[]): ChangeGroupsAction {
  return {
    type: UserFormActionType.CHANGE_GROUPS,
    groups,
  };
}

export function changeUsers(users: KlipfolioUser[]): ChangeUsersAction {
  return {
    type: UserFormActionType.CHANGE_USERS,
    users,
  };
}

export function changeRoles(roles: Role[]): ChangeRolesAction {
  return {
    type: UserFormActionType.CHANGE_ROLES,
    roles,
  };
}

export function loading(): UserFormLoadingAction {
  return {
    type: UserFormActionType.LOADING,
  };
}

export function finishLoading(): UserFormFinishLoadingAction {
  return {
    type: UserFormActionType.FINISH_LOADING,
  };
}

export function failed(error: any): UserFormFailedAction {
  return {
    type: UserFormActionType.FAILED,
    error: error,
  };
}

export function success(): UserFormSuccessAction {
  return {
    type: UserFormActionType.SUCCESS,
  };
}

export function clearError(): UserFormClearErrorAction {
  return {
    type: UserFormActionType.CLEAR_ERROR,
  };
}

export function changeSuccess(newValue: boolean): UserFormChangeSuccessAction {
  return {
    type: UserFormActionType.CHANGE_SUCCESS,
    newValue,
  };
}

export function clearSuccess(): UserFormChangeSuccessAction {
  return changeSuccess(false);
}

export function clearUserForm(): ClearUserFormAction {
  return {
    type: UserFormActionType.CLEAR_USER_FORM,
  };
}

type UserFormThunkAction = ThunkAction<
  void,
  RootState,
  unknown,
  Action<UserFormActionType>
>;
export const createClient = (
  createClientCommand: CreateClientCommand
): UserFormThunkAction => async (dispatch, getState) => {
  dispatch(loading());

  try {
    const accessToken = getState().authentication?.tokens?.accessToken;

    if (accessToken) {
      await klipfolioService.createUser(accessToken, createClientCommand);
      dispatch(success());
    }
  } catch (e) {
    dispatch(failed("Failed to create user"));
  }
};

export const createUser = (
  createUserCommand: CreateUserCommand
): UserFormThunkAction => async (dispatch, getState) => {
  dispatch(loading());

  try {
    const accessToken = getState().authentication?.tokens?.accessToken;

    if (accessToken) {
      await userService.createUser(accessToken, {
        ...createUserCommand,
      });
      dispatch(success());
    }
  } catch (e) {
    dispatch(failed(e.response?.data?.message || "Failed to create user"));
  }
};

export const updateClient = (
  updateUserCommand: UpdateClientCommand
): UserFormThunkAction => async (dispatch, getState) => {
  dispatch(loading());

  try {
    const accessToken = getState().authentication?.tokens?.accessToken;

    if (accessToken) {
      await userService.updateClient(accessToken, {
        id: updateUserCommand.id,
        firstName: updateUserCommand.firstName,
        lastName: updateUserCommand.lastName,
        email: updateUserCommand.email,
        groups: updateUserCommand.groups.map((group) => group.id),
        roles: updateUserCommand.roles.map((role) => role.id),
      });

      const users = await userService.users(accessToken);
      dispatch(changeUsers(users));

      dispatch(success());
    }
  } catch (e) {
    dispatch(failed(e.response?.data?.message || "Failed to update client"));
  }
};

export const deleteClient = (userId: string): UserFormThunkAction => async (
  dispatch,
  getState
) => {
  dispatch(loading());

  try {
    const accessToken = getState().authentication?.tokens?.accessToken;

    if (accessToken) {
      await userService.deleteClient(accessToken, userId);

      const users = await userService.users(accessToken);
      dispatch(changeUsers(users));

      dispatch(success());
    }
  } catch (e) {
    dispatch(failed(e.response?.data?.message || "Failed to update client"));
  }
};

export const initialize = (): UserFormThunkAction => async (
  dispatch,
  getState
) => {
  dispatch(loading());

  try {
    const accessToken = getState().authentication?.tokens?.accessToken;

    if (accessToken) {
      const groups = await groupService.groups(accessToken);
      const roles = await roleService.roles(accessToken);
      const users = await userService.users(accessToken);

      dispatch(changeGroups(groups));
      dispatch(changeRoles(roles));
      dispatch(changeUsers(users));
    }
  } finally {
    dispatch(finishLoading());
  }
};

export const actions = {
  createUser: createUser,
  createClient: createClient,
  clearError: clearError,
  clearSuccess: clearSuccess,
  clearUserForm: clearUserForm,
  changeUsers: changeUsers,
  updateClient: updateClient,
  deleteClient: deleteClient,
};
