import axios, { AxiosInstance } from "axios";
import { withAuthorization } from "../../toolkit/common-headers";
import { CreateClientCommand } from "./commands";
import { Role } from "users/redux/role";
import { Group } from "users/redux/group";

class KlipfolioService {
  private http: AxiosInstance;
  constructor(baseUrl: string) {
    this.http = axios.create({
      baseURL: baseUrl,
    });
  }

  async createUser(
    accessToken: string,
    createUserCommand: CreateClientCommand
  ): Promise<void> {
    const payload = {
      email: createUserCommand.email,
      firstName: createUserCommand.firstName,
      lastName: createUserCommand.lastName,
      password: createUserCommand.password,
      roles: createUserCommand.roles.map((role: Role) => role.id),
      groups: createUserCommand.groups.map((group: Group) => group.id),
    };

    await this.http.post("/bin", payload, withAuthorization(accessToken));

    return Promise.resolve();
  }

}

const klipfolioService = new KlipfolioService(
  process.env.REACT_APP_KLIPFOLIO_API || ""
);

export default klipfolioService;
