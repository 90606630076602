import axios, { AxiosInstance } from "axios";
import { withAuthorization } from "../../toolkit/common-headers";
import { Group } from "../redux/group";

class GroupService {
  private http: AxiosInstance;
  constructor(baseUrl: string) {
    this.http = axios.create({
      baseURL: baseUrl,
    });
  }

  async groups(accessToken: string): Promise<Group[]> {
    const result = await this.http.get(
      "/groups",
      withAuthorization(accessToken)
    );

    const data = result.data;

    if (data === undefined) {
      return Promise.resolve([]);
    }

    return data.map((group: any) => {
      return {
        id: group.id,
        description: group.description,
        name: group.name,
      };
    });
  }
}

const groupService = new GroupService(process.env.REACT_APP_GROUPS_API || "");

export default groupService;
