import React, { useEffect } from "react";

import { Table, Card, CardBody, CardHeader } from "reactstrap";
import IconButton from "toolkit/components/IconButton";
import { PricingGridDetail } from "./services/pricing-grid";
import BearsigntInput from "toolkit/components/BearsigntInput";
import ConfirmationPopup from "toolkit/components/ConfirmationPopup";

type Props = {
  loading: boolean;
  pricingGridDetails: PricingGridDetail[];
  onBack: () => void;
  onUpdateDetails: (details: PricingGridDetail) => void;
  onDeleteDetails: (id: string) => void;
  onAddDetails: (details: PricingGridDetail) => void;
};

const PricingRangeTableHeaders: Array<string> = [
  "Range from",
  "Range to",
  "Fee",
  ""
];

export default function PricingGridDetailsTable({
  loading,
  pricingGridDetails,
  onAddDetails,
  onUpdateDetails,
  onDeleteDetails,
  onBack,
}: Props) {
  const [allDetails, setAllDetails] = React.useState<PricingGridDetail[]>([]);
  const [editingIndex, setEditingIndex] = React.useState(-1);
  const [todoIndex, setTodoIndex] = React.useState(-1);
  const [originalDetail, setOriginalDetail] = React.useState<PricingGridDetail | null>(null);
  const [confirmationOpened, setConfirmationOpened] = React.useState(false);

  useEffect(() => {
    setAllDetails([
      ...pricingGridDetails.map((p) => {
        return { ...p };
      }),
    ]);
  }, [pricingGridDetails]);

  const handleEdit = (details: PricingGridDetail, index: number) => {
    if (editingIndex === index) {
      if (details.id !== '') {
        onUpdateDetails(details);
      } else {
        details.id = Date.now().toString();
        onAddDetails(details);
      }

      setOriginalDetail(null);
      setEditingIndex(-1);
    } else {
      if (editingIndex === -1) {
        setOriginalDetail({ ...details });
        setEditingIndex(index);  
      } else {
        setConfirmationOpened(true);
        setTodoIndex(index);
      }
    }
  };

  const handleDelete = (details: PricingGridDetail, index: number) => {
    if (details.id !== '') {
      onDeleteDetails(details.id);
    } else {
      setAllDetails(allDetails.filter((dt, idx) => idx !== index));
    }
  }

  const handleLeaveChange = () => {
    setConfirmationOpened(false);

    if (originalDetail) {
      const newAllDetails = [...allDetails];
      newAllDetails[editingIndex] = originalDetail;
      setAllDetails(newAllDetails);
    }
    setOriginalDetail({ ...allDetails[todoIndex] });
    setEditingIndex(todoIndex);
    setTodoIndex(-1);
  }

  const handleCancel = () => {
    setConfirmationOpened(false);
    setTodoIndex(-1);
  }

  const handleCancelEdit = () => {
    if (originalDetail) {
      const newAllDetails = [...allDetails];
      newAllDetails[editingIndex] = originalDetail;
      setAllDetails(newAllDetails);
    }
    setOriginalDetail(null);
    setEditingIndex(-1);
    setTodoIndex(-1);
  }

  const onAddRange = () => {
    setAllDetails([
      ...allDetails,
      { id: "", rangeFrom: 0, rangeTo: 0, fee: 0 },
    ]);
    setEditingIndex(allDetails.length);
  };

  return (
    <div className="modal-body p-0">
      <Card className="bg-secondary shadow border-0">
        <CardHeader className="bg-transparent pb-5">
          <button
            onClick={(e) => {
              e.preventDefault();
              onBack();
            }}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
          <div className="text-muted text-center mt-2 mb-3">
            <small>PRICING GRID DETAILS</small>
          </div>
          <div className="d-flex flex-row-reverse">
            <span className="mr-4">
              <IconButton
                icon="fa fa-plus"
                onClick={onAddRange}
                tooltip="Add new range"
                loading={loading}
              />
            </span>
          </div>
        </CardHeader>
        <CardBody className="px-lg-5">
          <Table
            size="sm"
            className="align-items-center"
            responsive
            hover
            striped
          >
            <thead className="thead-light">
              <tr>
                {PricingRangeTableHeaders.map((name, index) => (
                  <th scope="col" key={`PGD_Header_${index}`}>{name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {allDetails.map((details, index) => {
                return (
                  <tr key={details.id}>
                    <td>
                      <BearsigntInput
                        style={{ marginBottom: 0 }}
                        type="number"
                        text={details.rangeFrom.toString()}
                        onChange={(value) => {
                          const newAllDetails = [...allDetails];
                          newAllDetails[index].rangeFrom = +value;
                          setAllDetails(newAllDetails);
                        }}
                        disabled={editingIndex !== index}
                      />
                    </td>
                    <td>
                      <BearsigntInput
                        style={{ marginBottom: 0 }}
                        type="number"
                        text={details.rangeTo.toString()}
                        onChange={(value) => {
                          const newAllDetails = [...allDetails];
                          newAllDetails[index].rangeTo = +value;
                          setAllDetails(newAllDetails);
                        }}
                        disabled={editingIndex !== index}
                      />
                    </td>
                    <td>
                      <BearsigntInput
                        style={{ marginBottom: 0 }}
                        type="number"
                        text={details.fee.toString()}
                        onChange={(value) => {
                          const newAllDetails = [...allDetails];
                          newAllDetails[index].fee = +value;
                          setAllDetails(newAllDetails);
                        }}
                        disabled={editingIndex !== index}
                      />
                    </td>
                    <td style={{ width: '100px' }}>
                      <IconButton
                        id={details.id}
                        color={editingIndex === index ? "success" : "default"}
                        icon={`fa ${editingIndex === index ? 'fa-save' : 'fa-edit'}`}
                        onClick={() => handleEdit(details, index)}
                        tooltip={editingIndex === index ? "Save" : "Edit"}
                        loading={loading}
                      />
                      <IconButton
                        tableAction={true}
                        id={details.id}
                        color="danger"
                        icon="fa fa-trash"
                        onClick={() => handleDelete(details, index)}
                        tooltip="Delete"
                        loading={loading}
                      />
                      {editingIndex === index &&
                        <IconButton
                          tableAction={true}
                          id={details.id}
                          color="danger"
                          icon="fa fa-times"
                          onClick={() => handleCancelEdit()}
                          tooltip="Cancel"
                          loading={loading}
                        />
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <ConfirmationPopup
        title={"Leave changes"}
        message={"Are you sure you want to leave changes?"}
        onConfirm={handleLeaveChange}
        onCancel={handleCancel}
        opened={confirmationOpened}
      />
    </div>
  );
}
