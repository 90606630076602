import React, { useEffect } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Form,
  Table,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";
import IconButton from "toolkit/components/IconButton";
import { useStateReducer } from "toolkit/state-reducer";
import {
  Territory,
  ClientTerritory,
  AssignTerritoryToClientPayload,
} from "./services/territory";
import ReactDatetime from "react-datetime";
import { Moment } from "moment";
import DateRange from "calls/recordings/DateRange";
import SpinningButton from "toolkit/components/SpinningButton";
import { epochNow } from "toolkit/clock";
import { Client } from "clients/budgets/services/client";
import ClientsDropDown from "toolkit/components/ClientsDropDown";

interface Props {
  loading: boolean;
  territory: Territory;
  onBack: () => void;
  assignTerritoryToClient: (client: AssignTerritoryToClientPayload) => void;
  updateClientTerritory: (territoryId: string, client: ClientTerritory) => void;
  allClients: Client[];
}

interface State {
  selectedClient?: Client;
  clients: ClientTerritory[];
  newClient?: AssignTerritoryToClientPayload;
}

export default function ClientTerritoryTable({
  loading,
  territory,
  allClients,
  onBack,
  assignTerritoryToClient,
  updateClientTerritory,
}: Props) {
  const initialState: State = {
    clients: [],
  };

  const [state, setState] = useStateReducer<State>(initialState);

  useEffect(() => {
    setState({
      clients: [
        ...territory.clients.map((c) => {
          return { ...c };
        }),
      ],
    });
  }, [JSON.stringify(territory)]);

  const { newClient, selectedClient, clients } = state;

  const handleEditClient = (client: ClientTerritory) => {
    updateClientTerritory(territory.id, client);
  };

  const handleCreateClient = () => {
    if (
      newClient &&
      selectedClient &&
      newClient.territoryId.length > 0 &&
      newClient.activationDate > 0
    ) {
      const client = { ...newClient, clientId: selectedClient.id };
      assignTerritoryToClient(client);
    }
  };

  if (newClient) {
    return (
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <button
              onClick={(e) => {
                e.preventDefault();
                onBack();
              }}
              type="button"
              className="close"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="text-muted text-center mt-2 mb-3">
              <small>ASSIGN CLIENT TO TERRITORY</small>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5">
            <Form role="form">
              <ClientsDropDown
                clients={allClients}
                loading={loading}
                onClientSelected={(value: Client) =>
                  setState({ selectedClient: value })
                }
              />
              
              <DateRange
                defaultStartDate={newClient.activationDate}
                startLabel="Activation date"
                endLabel="Deactivation date"
                onStartDateChange={(date) =>
                  setState({
                    newClient: { ...newClient, activationDate: date },
                  })
                }
                onEndDateChange={(date) =>
                  setState({
                    newClient: { ...newClient, deactivationDate: date },
                  })
                }
              />

              <Row className="justify-content-end">
                <SpinningButton
                  block={false}
                  icon="fa fa-plus"
                  color="default"
                  type="submit"
                  loading={loading}
                  text="Add"
                  onClick={(e) => {
                    e.preventDefault();
                    handleCreateClient();
                  }}
                />
                <Button
                  color="link"
                  onClick={(e) => {
                    e.preventDefault();
                    setState({ newClient: undefined });
                  }}
                >
                  <span className="text-default">CANCEL</span>
                </Button>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    );
  }

  return (
    <div className="modal-body p-0">
      <Card className="bg-secondary shadow border-0">
        <CardHeader className="bg-transparent pb-5">
          <button
            onClick={(e) => {
              e.preventDefault();
              onBack();
            }}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
          <div className="text-muted text-center mt-2 mb-3">
            <small>CLIENT TERRITORIES ASSIGNEMENT</small>
          </div>
        </CardHeader>
        <CardBody className="px-lg-5">
          <>
            <div className="d-flex flex-row-reverse">
              <span className="mr-4">
                <IconButton
                  icon="fa fa-plus"
                  onClick={() => {
                    setState({
                      newClient: {
                        clientId: "",
                        territoryId: territory.id,
                        activationDate: epochNow(),
                        deactivationDate: 0,
                      },
                    });
                  }}
                  tooltip="Add new client"
                  disabled={newClient !== undefined}
                  loading={loading}
                />
              </span>
            </div>
            <Table className="align-items-center" size="sm" hover striped>
              <thead className="thead-light">
                <tr>
                  {[
                    "Id",
                    "Name",
                    "Activation date",
                    "Deactivation date",
                    "",
                  ].map((name) => (
                    <th scope="col">{name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {clients?.map((client) => {
                  return (
                    <tr key={client.clientId}>
                      <td>{client.clientId}</td>
                      <th scope="row">{client.name}</th>
                      <td>
                        <FormGroup style={{ marginBottom: 0.0 }}>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <ReactDatetime
                              defaultValue={new Date(client.activationDate)}
                              inputProps={{
                                placeholder: "Activation date",
                              }}
                              timeFormat={false}
                              onChange={(e: Moment | string) => {
                                if (typeof e !== "string") {
                                  const date = parseInt(e.valueOf().toString());
                                  client.activationDate = date;
                                }
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                      </td>

                      <td>
                        <FormGroup style={{ marginBottom: 0.0 }}>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                              </InputGroupText>
                            </InputGroupAddon>

                            <ReactDatetime
                              defaultValue={
                                client.deactivationDate &&
                                  client.deactivationDate !== 0
                                  ? new Date(client.deactivationDate)
                                  : undefined
                              }
                              inputProps={{
                                placeholder: "Deactivation date",
                              }}
                              timeFormat={false}
                              onChange={(e: Moment | string) => {
                                if (typeof e !== "string") {
                                  const date = parseInt(e.valueOf().toString());
                                  client.deactivationDate = date;
                                }
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                      </td>

                      <td className="text-right">
                        <IconButton
                          tableAction={true}
                          id={client.clientId}
                          color="default"
                          icon="fa fa-save"
                          onClick={() => handleEditClient(client)}
                          tooltip="Edit client"
                          loading={loading}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        </CardBody>
      </Card>
    </div>
  );
}
