import { Group } from "./group";
import { Role } from "./role";
import { UserFormState } from "./user-form-state";

export interface EditableClient {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  groups: Group[];
  roles: Role[];
}

export function selectEditUsers(userForm: UserFormState): EditableClient[] {
  return userForm.users.map((user) => {
    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      groups: userForm.groups.filter((group) => user.groups.includes(group.id)),
      roles: userForm.roles.filter((role) => user.roles.includes(role.id)),
    };
  });
}
