import React from "react";
import { FormGroup, Input } from "reactstrap";

interface Props {
  onChanged: (value: boolean) => void;
}

export default function TaggingOption({ onChanged }: Props) {

  return (
    <>
      <FormGroup>
        <div style={{paddingBottom: "4px"}} className="custom-control custom-control-alternative custom-checkbox custom-checkbox-default ml-4 mb-4">
          <Input
            className="custom-control-input"
            id=" customCheckLogin"
            type="checkbox"
            onChange={(e: any) => {
              onChanged(e.target.checked);
            }}
          />
          <label className="custom-control-label" htmlFor=" customCheckLogin">
            <span className="text-muted">
              Tagged
            </span>
          </label>
        </div>
      </FormGroup>
    </>
  );
}
