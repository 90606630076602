import { ClientState } from "./client-state";
import {
	ClientsAction,
	ClientActionType,
	FailedAction,
	ChangeClientsAction,
	ChangePricingGridAction,
	ChangeClientsPageSizeAction,
	ChangeClientsPageAction,
	ChangeClientsTotalPagesAction,
	ChangeClientsAssignedToPricingGridAction,
	ChangeTerritoriesAction,
	ChangeClientPhoneNumbersAction,
	ChangeGoogleAdsAccountAction,
	ChangeBingAdsAccountAction,
	ChangeSearchQueryAction,
	ChangePhoneSearchQueryAction,
	DeletePricingRangeDetailAction,
	UpdatePricingRangeDetailAction,
	AddPricingRangeDetailAction,
	ChangePlansAction,
	ChangeClientAction,
	ChangeAvailablePhoneNumbersAction,
	ChangeTerritoryDetailsAction
} from "./client-actions";

import { PricingGridDetail } from "../pricing-grid/services/pricing-grid";
import { PricingGrid as PricingGridSummary } from "clients/budgets/services/client";


const initialState: ClientState = {
	loading: false,
	clients: [],
	pricingGrid: [],
	clientsPageSize: 5,
	clientsPage: 0,
	clientsTotalPages: 0
};

export default function clientReducer(state: ClientState = initialState, action: ClientsAction): ClientState {
	switch (action.type) {
		case ClientActionType.LOADING:
			return {
				...state,
				loading: true,
				error: undefined
			};
		case ClientActionType.FAILED:
			return {
				...state,
				loading: false,
				error: (action as FailedAction).error
			};
		case ClientActionType.FINISH_LOADING:
			return {
				...state,
				loading: false
			};
		case ClientActionType.CLEAR_ERROR:
			return {
				...state,
				error: undefined
			};
		case ClientActionType.CHANGE_CLIENTS:
			return {
				...state,
				error: undefined,
				loading: false,
				clients: (action as ChangeClientsAction).clients
			};
		case ClientActionType.CHANGE_CLIENT:
			return {
				...state,
				error: undefined,
				loading: false,
				client: (action as ChangeClientAction).client
			};
		case ClientActionType.CHANGE_PRICING_GRIDS:
			return {
				...state,
				error: undefined,
				loading: false,
				pricingGrid: (action as ChangePricingGridAction).pricingGrid
			};
		case ClientActionType.DELETE_PRICING_RANGE_DETAIL: {
			const newPricingGrid: PricingGridSummary[] = [...state.pricingGrid];
			const pricingGridIndex: number = newPricingGrid.findIndex(pg => pg.id === (action as DeletePricingRangeDetailAction).pricingGridId);
			let newPricingRanges: PricingGridDetail[] = [...newPricingGrid[pricingGridIndex].details || []];
			newPricingRanges = newPricingRanges.filter(pr => pr.id !== (action as DeletePricingRangeDetailAction).detailId);
			newPricingGrid[pricingGridIndex].details = newPricingRanges;

			return {
				...state,
				error: undefined,
				loading: false,
				pricingGrid: newPricingGrid
			};
		}
		case ClientActionType.UPDATE_PRICING_RANGE_DETAIL: {
			const newPricingGrid: PricingGridSummary[] = [...state.pricingGrid];
			const pricingGridIndex: number = newPricingGrid.findIndex(pg => pg.id === (action as UpdatePricingRangeDetailAction).pricingGridId);
			let newPricingRanges: PricingGridDetail[] = [...newPricingGrid[pricingGridIndex].details || []];
			newPricingRanges = newPricingRanges.map(pr => (pr.id !== (action as UpdatePricingRangeDetailAction).detail.id ? pr : (action as UpdatePricingRangeDetailAction).detail));
			newPricingGrid[pricingGridIndex].details = newPricingRanges;

			return {
				...state,
				error: undefined,
				loading: false,
				pricingGrid: newPricingGrid
			};
		}
		case ClientActionType.ADD_PRICING_RANGE_DETAIL: {
			const newPricingGrid: PricingGridSummary[] = [...state.pricingGrid];
			const pricingGridIndex: number = newPricingGrid.findIndex(pg => pg.id === (action as AddPricingRangeDetailAction).pricingGridId);
			const newPricingRanges: PricingGridDetail[] = [...newPricingGrid[pricingGridIndex].details || []];
			newPricingRanges.push(action.detail);
			newPricingGrid[pricingGridIndex].details = newPricingRanges;

			return {
				...state,
				error: undefined,
				loading: false,
				pricingGrid: newPricingGrid
			};
		}
		case ClientActionType.CHANGE_CLIENT_PAGE_SIZE:
			return {
				...state,
				clientsPageSize: (action as ChangeClientsPageSizeAction).pageSize
			};
		case ClientActionType.CHANGE_CLIENT_PAGE:
			return {
				...state,
				clientsPage: (action as ChangeClientsPageAction).page
			};
		case ClientActionType.CHANGE_CLIENT_TOTAL_PAGES:
			return {
				...state,
				clientsTotalPages: (action as ChangeClientsTotalPagesAction).totalPages
			};
		case ClientActionType.CHANGE_CLIENTS_ASSIGNED_TO_GRID:
			const changeClientsAssignedToPricingGridAction = action as ChangeClientsAssignedToPricingGridAction;
			return {
				...state,
				selectedPricingGridClients: changeClientsAssignedToPricingGridAction.pricingGridClients
			};
		case ClientActionType.CHANGE_TERRITORIES:
			return {
				...state,
				error: undefined,
				loading: false,
				territories: (action as ChangeTerritoriesAction).territories
			};
		case ClientActionType.CHANGE_TERRITORY_DETAILS: 
			return {
				...state,
				error: undefined,
				loading: false,
				selectedTerritoryDetails: (action as ChangeTerritoryDetailsAction).territory
			}
		case ClientActionType.CHANGE_PLANS:
			return {
				...state,
				error: undefined,
				loading: false,
				plans: (action as ChangePlansAction).plans
			};
		case ClientActionType.CHANGE_CLIENT_PHONE_NUMBERS:
			return {
				...state,
				error: undefined,
				loading: false,
				clientPhoneNumbers: (action as ChangeClientPhoneNumbersAction).clientPhoneNumbers
			};

		case ClientActionType.CHANGE_AVAILABLE_PHONE_NUMBERS:
			return {
				...state,
				error: undefined,
				loading: false,
				availablePhoneNumbers: (action as ChangeAvailablePhoneNumbersAction).phoneNumbers
			};
		case ClientActionType.CHANGE_GOOGLE_ADS_ACCOUNTS:
			return {
				...state,
				error: undefined,
				loading: false,
				googleAdsAccounts: (action as ChangeGoogleAdsAccountAction).googleAdsAccounts
			};
		case ClientActionType.CHANGE_BING_ADS_ACCOUNTS:
			return {
				...state,
				error: undefined,
				loading: false,
				bingAdsAccounts: (action as ChangeBingAdsAccountAction).bingAdsAccounts
			};
		case ClientActionType.CHANGE_SEARCH_QUERY:
			return {
				...state,
				filteringQuery: (action as ChangeSearchQueryAction).query
			};
		case ClientActionType.CHANGE_PHONE_SEARCH_QUERY:
			return {
				...state,
				phoneQuery: (action as ChangePhoneSearchQueryAction).query
			};
		default:
			return state;
	}
}
