import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "redux/root-reducer";
import FormHeader from "toolkit/components/FormHeader";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Spinner,
  Form,
  Table,
  Input,
  FormGroup,
  Col,
  CustomInput
} from "reactstrap";
import BearsightTextInput from "toolkit/components/BearsightTextInput";
import BearsightPagination from "toolkit/components/BearsightPagination";
import { useStateReducer } from "toolkit/state-reducer";
import { actions as numbersActions } from "numbers/redux/numbers-actions";
import { actions as systemActions } from "../../redux/system-actions";
import { useDebounce } from "use-debounce/lib";
import IconButton from "toolkit/components/IconButton";
import { TwilioNumber } from "numbers/services/twilio-number";
import ConfirmationPopup from "toolkit/components/ConfirmationPopup";

const mapState = (state: RootState) => ({
  twilioNumbers: state.numbers.twilioNumbers,
  loading: state.numbers.loading,
  error: state.numbers.error,
  updateTwilioNumberSuccess: state.numbers.updateTwilioNumberSuccess,
  page: state.numbers.twilioNumbersPage,
  pageSize: state.numbers.twilioNumbersPageSize,
  totalPages: state.numbers.twilioNumbersTotalPages
});

const mapDispatch = {
  loadTwilioNumbers: numbersActions.loadTwilioNumbers,
  updateTwilioNumber: numbersActions.updateTwilioNumber,
  changeTwilioNumbersPageSize: numbersActions.changeTwilioNumbersPageSize,
  notify: systemActions.notify
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

interface State {
  filteringPhoneType?: NumberType;
  filteringQuery: string;
  filteringDeleted?: boolean;
  updatedTwilioNumbers: Map<string, TwilioNumber>;
  updatedNumberId?: string;
  confirmationOpened: boolean;
}

enum NumberType {
  Adwords = "Adwords",
  Bing = "Bing",
  Unallocated = "Unallocated",
}

const NumberTypes = Object.keys(NumberType);


function TwilioNumbersTable({
  loading,
  error,
  updateTwilioNumberSuccess,
  twilioNumbers,
  page,
  pageSize,
  totalPages,
  loadTwilioNumbers,
  updateTwilioNumber,
  changeTwilioNumbersPageSize,
  notify
}: Props) {
  const initialState: State = {
    filteringPhoneType: undefined,
    filteringQuery: "",
    updatedTwilioNumbers: new Map(),
    confirmationOpened: false,
  };

  const [state, setState] = useStateReducer<State>(initialState);

  const {
    filteringPhoneType,
    filteringQuery,
    filteringDeleted,
    updatedTwilioNumbers,
    updatedNumberId,
  } = state;

  useEffect(()=>{
    loadTwilioNumbers(0,5,filteringPhoneType, undefined, filteringDeleted);
  },[]);

  useEffect(()=>{
    loadTwilioNumbers(page, pageSize, filteringPhoneType, filteringQuery, filteringDeleted);
  },[filteringPhoneType, filteringDeleted]);

  const [debouncedSearchTerm] = useDebounce(filteringQuery, 500);

  useEffect(() => {
    loadTwilioNumbers(page, pageSize, filteringPhoneType, debouncedSearchTerm, filteringDeleted);
  }, [debouncedSearchTerm]);

  useEffect(()=>{
    if(!loading && !updateTwilioNumberSuccess && error){
      notify(
        "Update Twilio Number",
        "Failed to update twilio number, please try later!",
        "danger"
      );
    }else if(!loading && updateTwilioNumberSuccess && !error){
      notify(
        "Update Twilio Number",
        "Twilio number updated successfully",
        "success"
      );
      if(updatedNumberId){
        let newUpdatedTwilioNumbers = new Map(updatedTwilioNumbers);
        newUpdatedTwilioNumbers.delete(updatedNumberId);
        setState({
          updatedTwilioNumbers: newUpdatedTwilioNumbers,        })
      }
      loadTwilioNumbers(page, pageSize, filteringPhoneType, filteringQuery, filteringDeleted);
      
    }
  },[updateTwilioNumberSuccess])

  const handlePageSizeChanged = (pageSize: number) => {
    changeTwilioNumbersPageSize(pageSize);
    loadTwilioNumbers(page, pageSize, filteringPhoneType, filteringQuery, filteringDeleted);
  };

  const handlePageChanged = (page: number) => {
   loadTwilioNumbers(page, pageSize, filteringPhoneType, filteringQuery, filteringDeleted);
  };

  const handleSubmit = (twilioNumber: TwilioNumber) => {
    const updatedTwilioNumber = updatedTwilioNumbers.get(twilioNumber.id);
    if(updatedTwilioNumber){
      setState({
        updatedNumberId: twilioNumber.id
      });
      updateTwilioNumber(twilioNumber.id, updatedTwilioNumber.phoneType,updatedTwilioNumber.isDeleted);
    }
  }

  return (
    <>
      <FormHeader name="TWILIO NUMBERS" parentName="Numbers" />
      <Container className="mt--6 d-flex justify-content-center" fluid>
        <Card className="col-lg-8">
          <CardHeader>
            <h3 className="mb-0">TWILIO NUMBERS</h3>
          </CardHeader>
          <CardBody>
            <Form className="needs-validation mb-4" noValidate inline onSubmit={e => e.preventDefault()}>
              <BearsightTextInput
                label=""
                icon="fas fa-search"
                text={filteringQuery}
                onChange={(value) => setState({ filteringQuery: value })}
              />

              <FormGroup className="ml-4">
                  <label className="form-control-label mr-4">Number Type: </label>
                  <Input
                      type="select"
                      name="phoneType"
                      id="phoneType"
                      onChange={(e: any) => {
                          const selectedTypes = [...e.target.selectedOptions].map(
                              (option: any) => option.value
                          );

                          setState({
                              filteringPhoneType: selectedTypes[0],
                          });
                      }}

                  >
                      <option value="" >All</option>
                      {NumberTypes.map(s => <option defaultValue={filteringPhoneType} value={s}>{s}</option>)}
                  </Input>
              </FormGroup>
              <FormGroup className="ml-4">
                  <label className="form-control-label mr-4">Show: </label>
                  <Input
                      type="select"
                      name="deleted"
                      id="deleted"
                      onChange={(e: any) => {
                          const selectedOptions = [...e.target.selectedOptions].map(
                              (option: any) => option.value
                          );
                          let deleted = selectedOptions[0].length > 0 ? selectedOptions[0] : undefined;
                          setState({
                              filteringDeleted: deleted,
                          });
                      }}

                  >
                      <option value="" >All</option>
                      <option value="true" >Only Deleted</option>
                      <option value="false" >Only Non Deleted</option>
                      
                  </Input>
              </FormGroup>
              
            </Form>
            {loading && (
              <Row className="justify-content-center">
                <Spinner />
              </Row>
            )}
            {twilioNumbers && twilioNumbers.length > 0 && (
                  <Table className="align-items-center" responsive hover striped>
                    <thead className="thead-light">
                      <tr>
                        {["Number", "Friendly Name", "Type", "Is Deleted ?","Actions"].map((name, idx) => (
                          <th scope="col" key={`header_${idx}`}>{name}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {twilioNumbers.map((twilioNumber) => {
                        return (
                          <tr>
                            <td>
                              {twilioNumber.phoneNumber}
                            </td>

                            <td>
                              {twilioNumber.friendlyName}
                            </td>

                            <td>
                              <Input
                                type="select"
                                name="phoneType"
                                onChange={(e: any) => {
                                  const selectedTypes = [...e.target.selectedOptions].map(
                                    (option: any) => option.value
                                  );
                                  let newUpdatedTwilioNumbers = new Map(updatedTwilioNumbers);
                                  let updatedTwilioNumber = newUpdatedTwilioNumbers.get(twilioNumber.id) || {...twilioNumber};
                                  updatedTwilioNumber.phoneType = selectedTypes[0];
                                  newUpdatedTwilioNumbers.set(twilioNumber.id, updatedTwilioNumber);
                                  setState({
                                    updatedTwilioNumbers: newUpdatedTwilioNumbers
                                  })
                                    
                                }}
                                value={updatedTwilioNumbers.get(twilioNumber.id)?.phoneType || twilioNumber.phoneType}
                              >
                                {NumberTypes.map(nt => <option defaultValue={twilioNumber.phoneType} value={nt}>{nt}</option>)}
                              </Input>
                            </td>

                            <td className="text-center">
                              <CustomInput 
                              id={`isDeleted_${twilioNumber.id}`}
                              onChange={(e:any) => {
                                  let newUpdatedTwilioNumbers = new Map(updatedTwilioNumbers);
                                  let updatedTwilioNumber = newUpdatedTwilioNumbers.get(twilioNumber.id) || {...twilioNumber};
                                  updatedTwilioNumber.isDeleted = e.target.checked;
                                  newUpdatedTwilioNumbers.set(twilioNumber.id, updatedTwilioNumber);
                                  setState({
                                    updatedTwilioNumbers: newUpdatedTwilioNumbers
                                  })
                              }} 
                              type="switch" 
                              disabled={false}
                              checked={updatedTwilioNumbers.get(twilioNumber.id) ? updatedTwilioNumbers.get(twilioNumber.id)?.isDeleted : twilioNumber.isDeleted} />
                            </td>

                            <td className="text-left">
                              <Row>
                                <Col xs="4">
                                  <IconButton
                                    color="success"
                                    icon="fa fa-check"
                                    onClick={() => handleSubmit(twilioNumber) }
                                    tooltip="Submit"
                                    disabled={!updatedTwilioNumbers.get(twilioNumber.id)}
                                  />
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
              )}

            <hr />
            <BearsightPagination
              currentPage={page}
              totalPages={totalPages}
              pageSize={pageSize}
              onPageSizeChanged={handlePageSizeChanged}
              handleSearch={handlePageChanged}
            />

            
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default connector(TwilioNumbersTable);
