import axios, { AxiosInstance } from "axios";
import { withAuthorization } from "toolkit/common-headers";
import { Page } from "toolkit/pagination";
import moment from "moment";
import { Territory, AssignTerritoryToClientPayload, ClientTerritory } from "./territory";

class TerritoriesService {
	private http: AxiosInstance;
	constructor(baseUrl: string) {
		this.http = axios.create({
			baseURL: baseUrl
		});
	}

	async assign(accessToken: string, payload: AssignTerritoryToClientPayload): Promise<void> {
		await this.http.post(
			`/${payload.territoryId}/clients/${payload.clientId}`,
			{
				activationDate: this.formatDate(payload.activationDate),
				deactivationDate: payload.deactivationDate ? this.formatDate(payload.deactivationDate) : undefined
			},
			withAuthorization(accessToken)
		);

		return Promise.resolve();
	}

	async updateClientTerritory(accessToken: string, territoryId: string, client: ClientTerritory): Promise<void> {
		await this.http.put(
			`/${territoryId}/clients/${client.clientId}`,
			{
				activationDate: this.formatDate(client.activationDate),
				deactivationDate: client.deactivationDate ? this.formatDate(client.deactivationDate) : undefined
			},
			withAuthorization(accessToken)
		);

		return Promise.resolve();
	}

	async create(accessToken: string, territory: Territory): Promise<void> {
		await this.http.post(
			"",
			{
				name: territory.name
			},
			withAuthorization(accessToken)
		);

		return Promise.resolve();
	}

	async update(accessToken: string, territory: Territory): Promise<void> {
		await this.http.put(
			`/${territory.id}`,
			{
				name: territory.name
			},
			withAuthorization(accessToken)
		);

		return Promise.resolve();
	}

	async delete(accessToken: string, territoryId: string): Promise<void> {
		await this.http.delete(`/${territoryId}`, withAuthorization(accessToken));

		return Promise.resolve();
	}

	async territories(accessToken: string, page: number, pageSize: number, query?: string): Promise<Page<Territory>> {
		const result = await this.http.get(
			"",
			withAuthorization(accessToken, {
				page: page,
				size: pageSize,
				q: query !== undefined && query.length > 0 ? query : undefined
			})
		);

		const data = result.data;

		if (data === undefined) {
			return Promise.resolve({
				page: 0,
				totalPages: 0,
				hasMorePages: false,
				data: []
			});
		}

		return {
			page: data.page,
			totalPages: data.totalPages,
			hasMorePages: data.hasMorePages,
			pageSize,
			data: data.territories.map((territory: any) => {
				return {
					id: territory.id,
					name: territory.name
				};
			})
		};
	}

	async territory(accessToken: string, territoryId: string): Promise<Territory> {
		const result = await this.http.get(
			`/${territoryId}`,
			withAuthorization(accessToken)
		);
		const data = result.data;
		return {
			id: data.id,
			name: data.name,
			clients: data.clients.map((client: any) => {
				return {
					clientId: client.clientId,
					name: client.name,
					account: client.account,
					activationDate: this.toEpoch(client.activationDate),
					deactivationDate: this.toEpoch(client.deactivationDate)
				};
			})
		};
	}

	private toEpoch(date: any): number {
		if (date) {
			return new Date(date).getTime();
		}
		return 0;
	}

	private formatDate(date: number): string {
		return moment(new Date(date)).format("YYYY-MM-DD");
	}
}

const territoriesService = new TerritoriesService(process.env.REACT_APP_TERRITORIES_API || "");
export default territoriesService;
