import React, { useEffect } from "react";

import { Row, Form, Card, CardBody, Spinner, Button } from "reactstrap";
import { useStateReducer } from "toolkit/state-reducer";
import { Client, ClientPricingGrid, PricingGrid, } from "clients/budgets/services/client";
import BearsightCardHeader from "toolkit/components/BearsightCardHeader";
import DateRange from "calls/recordings/DateRange";
import SpinningButton from "toolkit/components/SpinningButton";
import { epochNow } from "toolkit/clock";
import BearsightMultiselect from "toolkit/components/BearsightMultiselect";

type Props = {
    client?: Client;
    loading: boolean;
    pricingGrids?: PricingGrid[];
    onClose: () => void;
    onAttributePricingGrid: (clientId: string, pricingGrid: PricingGrid, activationDate: number, deactivationDate?: number) => void;
    loadPricingGrids: () => void;
};

interface State {
    activationDate?: number;
    deactivationDate?: number;
    selectedPricingGrid?: PricingGrid;
}

export default function PricingGridAttribution({
    client,
    loading,
    pricingGrids,
    onAttributePricingGrid,
    loadPricingGrids,
    onClose,
}: Props
) {
    const initialState: State = {
        activationDate: epochNow()
    };

    const [state, setState] = useStateReducer<State>(initialState);

    const { selectedPricingGrid, activationDate, deactivationDate } = state;

    useEffect(() => {
        loadPricingGrids();
    }, []);

    const handleAttribute = () => {
        if (selectedPricingGrid && client && activationDate) {
            onAttributePricingGrid(client.id, selectedPricingGrid, activationDate, deactivationDate);
        }
    };

    const handleClose = () => {
        setState({
            activationDate: undefined,
            deactivationDate: undefined,
        });
        onClose();
    };

    return (
        <>
            <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                    <BearsightCardHeader
                        onClose={handleClose}
                        title="CLIENT"
                        subTitle={`${client?.name} (${client?.id})`}
                    />
                    <CardBody className="px-lg-5">
                        <div className="text-center text-muted mb-4">
                            <small>PRICING GRIDS</small>
                        </div>
                        { loading && (
                            <Row className="justify-content-center">
                            <Spinner />
                        </Row>
                        )}
                        <Form role="form">

                            <>
                                <DateRange
                                    defaultStartDate={epochNow()}
                                    startLabel="Activation date"
                                    endLabel="Deactivation date"
                                    onStartDateChange={(date) => setState({ activationDate: date })}
                                    onEndDateChange={(date) => setState({ deactivationDate: date })}
                                />

                                {pricingGrids &&
                                    <BearsightMultiselect<PricingGrid>
                                        isMulti={false}
                                        disabled={loading}
                                        loading={loading}
                                        options={pricingGrids}
                                        selected={selectedPricingGrid ? [selectedPricingGrid] : undefined}
                                        label="Pricing Grids"
                                        onChange={(value: PricingGrid[]) => {
                                            setState({ selectedPricingGrid: value[0] });
                                        }}
                                        displayValue="description"
                                    />
                                }

                                <Row className="justify-content-end">
                                    <SpinningButton
                                        block={false}
                                        icon="fa fa-plus"
                                        color="default"
                                        type="submit"
                                        loading={loading}
                                        text="Add"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleAttribute();
                                        }}
                                    />
                                    <Button
                                        color="link"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleClose();
                                        }}
                                    >
                                        <span className="text-default">CANCEL</span>
                                    </Button>
                                </Row>
                            </>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </>
    );
}
