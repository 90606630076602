import React, { useEffect } from "react";

import { Row, Form, Card, CardBody, Spinner, Button } from "reactstrap";
import { useStateReducer } from "toolkit/state-reducer";
import { Client, } from "clients/budgets/services/client";
import BearsightCardHeader from "toolkit/components/BearsightCardHeader";
import DateRange from "calls/recordings/DateRange";
import SpinningButton from "toolkit/components/SpinningButton";
import { epochNow } from "toolkit/clock";
import BearsightMultiselect from "toolkit/components/BearsightMultiselect";
import { AssignTerritoryToClientPayload, Territory } from "./services/territory";

type Props = {
    client?: Client;
    loading: boolean;
    territories?: Territory[];
    onClose: () => void;
    onAttributeTerritory: (payload: AssignTerritoryToClientPayload) => void;
    loadTerritories: (page: number, pageSize: number) => void;
};

interface State {
    activationDate?: number;
    deactivationDate?: number;
    selectedTerritory?: Territory;
}

export default function TerritoriesAttribution({
    client,
    loading,
    territories,
    onAttributeTerritory,
    loadTerritories,
    onClose,
}: Props
) {
    const initialState: State = {
        activationDate: epochNow()
    };

    const [state, setState] = useStateReducer<State>(initialState);

    const { selectedTerritory, activationDate, deactivationDate } = state;

    useEffect(() => {
        loadTerritories(0, 100000);
    }, []);

    const handleAttribute = () => {
        if (selectedTerritory && client && activationDate) {
            let payload : AssignTerritoryToClientPayload = {
                clientId: client.id,
                territoryId: selectedTerritory.id,
                activationDate: activationDate,
                deactivationDate: deactivationDate || 0
            } 
            onAttributeTerritory(payload);
        }
    };

    const handleClose = () => {
        setState({
            activationDate: undefined,
            deactivationDate: undefined,
        });
        onClose();
    };

    return (
        <>
            <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                    <BearsightCardHeader
                        onClose={handleClose}
                        title="CLIENT"
                        subTitle={`${client?.name} (${client?.id})`}
                    />
                    <CardBody className="px-lg-5">
                        <div className="text-center text-muted mb-4">
                            <small>TERRITORIES</small>
                        </div>
                        { loading && (
                            <Row className="justify-content-center">
                            <Spinner />
                        </Row>
                        )}
                        <Form role="form">

                            <>
                                <DateRange
                                    defaultStartDate={epochNow()}
                                    startLabel="Activation date"
                                    endLabel="Deactivation date"
                                    onStartDateChange={(date) => setState({ activationDate: date })}
                                    onEndDateChange={(date) => setState({ deactivationDate: date })}
                                />

                                {territories &&
                                    <BearsightMultiselect<Territory>
                                        isMulti={false}
                                        disabled={loading}
                                        loading={loading}
                                        options={territories}
                                        selected={selectedTerritory ? [selectedTerritory] : undefined}
                                        label="Territories"
                                        onChange={(value: Territory[]) => {
                                            setState({ selectedTerritory: value[0] });
                                        }}
                                        displayValue="name"
                                    />
                                }

                                <Row className="justify-content-end">
                                    <SpinningButton
                                        block={false}
                                        icon="fa fa-plus"
                                        color="default"
                                        type="submit"
                                        loading={loading}
                                        text="Add"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleAttribute();
                                        }}
                                    />
                                    <Button
                                        color="link"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleClose();
                                        }}
                                    >
                                        <span className="text-default">CANCEL</span>
                                    </Button>
                                </Row>
                            </>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </>
    );
}
