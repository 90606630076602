import moment from 'moment';
import { Recordings, AvailableTags, Recording } from "calls/recordings/services/recording";
import { RootState } from "redux/root-reducer";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import recordingsService from "calls/recordings/services/recordings-service";
import { Territory } from 'clients/budgets/services/client';

export enum RecordingsActionType {
  LOADING = "RECORDINGS_LOADING",
  FINISH_LOADING = "RECORDINGS_FINISH_LOADING",
  FAILED = "RECORDINGS_FAILURE",
  CLEAR_ERROR = "RECORDINGS_CLEAR_ERROR",
  CHANGE_RECORDINGS = "RECORDINGS_CHANGE_RECORDINGS",
  CHANGE_HISTORY_RECORDINGS = "RECORDINGS_CHANGE_HISTORY_RECORDINGS",
  CHANGE_AVAILABLE_TAGS = "RECORDINGS_CHANGE_AVAILABLE_TAGS",
  CHANGE_FILTER_DATES = "RECORDINGS_CHANGE_FILTER_DATES",
  CHANGE_PAGE = "RECORDINGS_CHANGE_PAGE",
  CHANGE_PAGE_SIZE = "RECORDINGS_CHANGE_PAGE_SIZE",
  CHANGE_TERRITORY = "CHANGE_TERRITORY",
  CHANGE_TAGGED = "RECORDINGS_CHANGE_TAGGED",
}

export interface LoadingAction {
  type: RecordingsActionType;
}

export interface FinishLoadingAction {
  type: RecordingsActionType;
}

export interface ClearErrorAction {
  type: RecordingsActionType;
}

export interface FailedAction {
  type: RecordingsActionType;
  error: any;
}

export interface ChangeRecordingsAction {
  type: RecordingsActionType;
  recordings: Recordings;
}

export interface ChangeHistoryRecordingsAction {
  type: RecordingsActionType;
  recordings: Recordings;
}

export interface IntializeRecordingsAction {
  type: RecordingsActionType;
}

export interface ChangeAvailableTagsAction {
  type: RecordingsActionType;
  availableTags: AvailableTags;
}

export interface ChangeFilterDatesAction {
  type: RecordingsActionType;
  startDate: number;
  endDate: number;
}

export interface ChangePageAction {
  type: RecordingsActionType;
  page: number;
}

export interface ChangePageSizeAction {
  type: RecordingsActionType;
  pageSize: number;
}

export interface ChangeTerritorryAction {
  type: RecordingsActionType;
  territory?: Territory;
}

export interface ChangeTaggedAction {
  type: RecordingsActionType;
  tagged: boolean;
}

export type RecordingsAction =
  | LoadingAction
  | ClearErrorAction
  | FailedAction
  | ChangeRecordingsAction
  | ChangeHistoryRecordingsAction
  | IntializeRecordingsAction
  | ChangeFilterDatesAction
  | ChangePageAction
  | ChangePageSizeAction
  | ChangeTerritorryAction
  | ChangeTaggedAction
  | ChangeAvailableTagsAction;

function changePage(page: number): ChangePageAction {
  return {
    type: RecordingsActionType.CHANGE_PAGE,
    page,
  };
}

function changePageSize(pageSize: number): ChangePageSizeAction {
  return {
    type: RecordingsActionType.CHANGE_PAGE_SIZE,
    pageSize,
  };
}

function changeTerritory(territory?: Territory): ChangeTerritorryAction {
  return {
    type: RecordingsActionType.CHANGE_TERRITORY,
    territory
  }
}

function changeTagged(tagged: boolean): ChangeTaggedAction {
  return {
    type: RecordingsActionType.CHANGE_TAGGED,
    tagged,
  };
}

function changeFilterDates(
  startDate: number,
  endDate: number
): ChangeFilterDatesAction {
  return {
    type: RecordingsActionType.CHANGE_FILTER_DATES,
    startDate,
    endDate,
  };
}

function loading(): LoadingAction {
  return {
    type: RecordingsActionType.LOADING,
  };
}

function finishLoading(): FinishLoadingAction {
  return {
    type: RecordingsActionType.FINISH_LOADING,
  };
}

function failed(error: any): FailedAction {
  return {
    type: RecordingsActionType.FAILED,
    error,
  };
}

function clearError(): ClearErrorAction {
  return {
    type: RecordingsActionType.CLEAR_ERROR,
  };
}

function changeRecordings(recordings: Recordings): ChangeRecordingsAction {
  return {
    type: RecordingsActionType.CHANGE_RECORDINGS,
    recordings,
  };
}

function changeHistoryRecordings(recordings: Recordings): ChangeHistoryRecordingsAction {
  return {
    type: RecordingsActionType.CHANGE_HISTORY_RECORDINGS,
    recordings
  };
}

function changeAvailableTags(
  availableTags: AvailableTags
): ChangeAvailableTagsAction {
  return {
    type: RecordingsActionType.CHANGE_AVAILABLE_TAGS,
    availableTags,
  };
}

type RecordingsThunkAction = ThunkAction<
  void,
  RootState,
  unknown,
  Action<RecordingsActionType>
>;

const loadRecordings = (
  startDate: number,
  endDate: number,
  page: number,
  tagged: boolean,
  pageSize: number,
  territory?: Territory
): RecordingsThunkAction => async (dispatch, getState) => {
  dispatch(loading());

  try {
    const accessToken = getState().authentication?.tokens?.accessToken;

    if (accessToken) {
      dispatch(changeFilterDates(startDate, endDate));
      dispatch(changePage(page));
      dispatch(changeTagged(tagged));
      dispatch(changePageSize(pageSize));
      dispatch(changeTerritory(territory))

      const recordings = await recordingsService.recordings(
        accessToken,
        startDate,
        endDate,
        page,
        tagged,
        pageSize,
        territory?.id
      );

      dispatch(changeRecordings(recordings));
    }
  } catch (e) {
    dispatch(failed(e));
  }
};

const loadHistoryRecordings = (
  page: number,
  tagged: boolean,
  pageSize: number,
  from: string
): RecordingsThunkAction => async (dispatch, getState) => {
  dispatch(loading());

  try {
    const accessToken = getState().authentication?.tokens?.accessToken;

    if (accessToken) {
      const recordings = await recordingsService.historyRecordings(
        accessToken,
        page,
        pageSize,
        from
      );

      dispatch(changeHistoryRecordings(recordings));
    }
  } catch (e) {
    dispatch(failed(e));
  }
};

const initialize = (): RecordingsThunkAction => async (dispatch, getState) => {
  dispatch(loading());

  try {
    const accessToken = getState().authentication?.tokens?.accessToken;

    if (accessToken) {
      const availableTags = await recordingsService.availableTags(accessToken);

      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const date = now.getTime();
      dispatch(changeFilterDates(date, date));
      dispatch(changePage(0));
      dispatch(changeTagged(false));

      const today = moment(moment().format('YYYY-MM-DD'));
      const startOfMonth = `${moment().year()}-${moment().format('MM')}-01`;
      const startOfThisMonth = moment(startOfMonth);

      const recordings = await recordingsService.recordings(
        accessToken,
        startOfThisMonth.valueOf(),
        today.valueOf(),
        0,
        false,
        getState().recordings.recordingsPageSize
      );

      dispatch(changeAvailableTags(availableTags));
      dispatch(changeRecordings(recordings));
    }
  } catch (e) {
    dispatch(failed(e));
  }
};

const reloadRecordings = (): RecordingsThunkAction => async (
  dispatch,
  getState
) => {
  dispatch(loading());

  try {
    const accessToken = getState().authentication?.tokens?.accessToken;

    if (accessToken) {
      const recordingsState = getState().recordings;
      const startDate = recordingsState.startDate;
      const endDate = recordingsState.endDate;
      const page = recordingsState.page;
      const pageSize = recordingsState.recordingsPageSize;
      const tagged = recordingsState.tagged;
      const territory = recordingsState.territory;

      if (startDate && endDate) {
        dispatch(loadRecordings(startDate, endDate, page, tagged, pageSize, territory));
      } else {
        dispatch(finishLoading());
      }
    }
  } catch (e) {
    dispatch(failed(e));
  }
};

const assign = (recordingId: string): RecordingsThunkAction => async (
  dispatch,
  getState
) => {
  dispatch(loading());

  try {
    const accessToken = getState().authentication?.tokens?.accessToken;

    if (accessToken) {
      await recordingsService.assign(accessToken, recordingId);

      dispatch(reloadRecordings());
    }
  } catch (e) {
    const message = e.response?.data?.message;
    if (e.response?.status === 409) {
      dispatch(failed("Recordings already assigned!"));
      dispatch(reloadRecordings());
    } else {
      dispatch(failed(message || "Failed to assign recordings!"));
    }
  }
};

const bulkAssign = (recordingIds: string[]): RecordingsThunkAction => async (
  dispatch,
  getState
) => {
  dispatch(loading());

  try {
    const accessToken = getState().authentication?.tokens?.accessToken;

    if (accessToken) {
      for (var i = 0; i < recordingIds.length; i++) {
        await recordingsService.assign(accessToken, recordingIds[i]);
      }

      dispatch(reloadRecordings());
    }
  } catch (e) {
    const message = e.response?.data?.message;
    if (e.response?.status === 409) {
      dispatch(failed("Recordings already assigned!"));
      dispatch(reloadRecordings());
    } else {
      dispatch(failed(message || "Failed to assign recordings!"));
    }
  }
};

const unassign = (recordingId: string): RecordingsThunkAction => async (
  dispatch,
  getState
) => {
  dispatch(loading());

  try {
    const accessToken = getState().authentication?.tokens?.accessToken;

    if (accessToken) {
      await recordingsService.unassign(accessToken, recordingId);

      dispatch(reloadRecordings());
    }
  } catch (e) {
    const message = e.response?.data?.message;
    if (e.response?.status === 409) {
      dispatch(failed("Recordings already assigned!"));
      dispatch(reloadRecordings());
    } else {
      dispatch(failed(message || "Failed to assign recordings!"));
    }
  }
};

const updateTags = (
  recordingId: string,
  applianceId: number,
  brandId: number,
  callResultId: number
): RecordingsThunkAction => async (dispatch, getState) => {
  dispatch(loading());

  try {
    const accessToken = getState().authentication?.tokens?.accessToken;

    if (accessToken) {
      await recordingsService.updateTags(accessToken, recordingId, {
        applianceId,
        brandId,
        callResultId,
      });

      const startDate = getState().recordings.startDate;
      const endDate = getState().recordings.endDate;
      const pageSize = getState().recordings.recordingsPageSize;
      const tagged = getState().recordings.tagged;
      const territory = getState().recordings.territory;

      if (startDate && endDate) {
        dispatch(loadRecordings(startDate, endDate, 0, tagged, pageSize, territory));
      } else {
        dispatch(finishLoading());
      }
    }
  } catch (e) {
    dispatch(failed(e));
  }
};

export const actions = {
  updateTags: updateTags,
  assign: assign,
  bulkAssign: bulkAssign,
  unassign: unassign,
  initialize: initialize,
  loadRecordings: loadRecordings,
  clearError: clearError,
  changePage: changePage,
  changeTagged: changeTagged,
  loadHistoryRecordings: loadHistoryRecordings
};
