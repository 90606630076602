import React from "react";
import { PhoneNumber } from "clients/budgets/services/client";
import BearsightMultiselect from "./BearsightMultiselect";
import { useStateReducer } from "toolkit/state-reducer";

interface Props {
    phoneNumbers: PhoneNumber[];
    loading: boolean;
    onPhoneNumberSelected: (phoneNumbers: PhoneNumber[]) => void;
}

interface State {
    selectedPhoneNumbers: PhoneNumber[];
}

export default function PhoneNumbersDropDown({ phoneNumbers, loading, onPhoneNumberSelected }: Props): JSX.Element {
    const [state, setState] = useStateReducer<State>({ selectedPhoneNumbers: [] });

    const { selectedPhoneNumbers } = state;

    return (
        <BearsightMultiselect<PhoneNumber>
            isMulti={true}
            disabled={loading}
            loading={loading}
            options={phoneNumbers}
            selected={selectedPhoneNumbers}
            label="Phone numbers"
            onChange={(value: PhoneNumber[]) => {
                setState({ selectedPhoneNumbers: value });
                onPhoneNumberSelected(value);
            }
            }
            displayValue="phoneNumber"
        />

    );
}