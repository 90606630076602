import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Badge, Col, FormGroup, Input, Modal, Row, Spinner, Table } from "reactstrap";

import { Recording as RecordingView } from "calls/redux/recordings";
import { actions as recordingsActions } from "calls/redux/recordings-actions";
import { selectRecordings } from "calls/redux/recordings-selector";
import { RootState } from "redux/root-reducer";
import IconButton from "toolkit/components/IconButton";
import { useStateReducer } from "toolkit/state-reducer";

import { actions as systemActions } from "../../redux/system-actions";
import RecordingHistoryTable from './RecordingHistoryTable';
import { RecordingTag } from "./services/recording";

const mapState = (state: RootState) => ({
  userId: state.authentication.user?.id,
  loading: state.recordings.loading,
  recordings: selectRecordings(state.recordings),
  availableTags: state.recordings.availableTags,
  initialStartDate: state.recordings.startDate,
  initialEndDate: state.recordings.endDate,
  pageSize: state.recordings.recordingsPageSize,
  tagged: state.recordings.tagged,
  totalElements: state.recordings.recordings.totalElements
});

const mapDispatch = {
  assign: recordingsActions.assign,
  bulkAssign: recordingsActions.bulkAssign,
  unassign: recordingsActions.unassign,
  updateTags: recordingsActions.updateTags,
  loadHistoryRecordings: recordingsActions.loadHistoryRecordings,
  notify: systemActions.notify,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

interface RecordingsState {
  submitted: boolean;
  confirmationOpened: boolean;
  isSelection: boolean;
  selectedCallResult: Map<string, RecordingTag>;
  selectedAppliance: Map<string, RecordingTag>;
  selectedBrand: Map<string, RecordingTag>;
  selectedRecordings: Map<string, boolean>;
  selectedRecording: RecordingView | undefined;
  isModalOpen: boolean
}

function RecordingsTable({
  userId,
  loading,
  recordings,
  availableTags,
  initialStartDate,
  initialEndDate,
  pageSize,
  totalElements,
  tagged,
  assign,
  bulkAssign,
  unassign,
  updateTags,
  notify,
  loadHistoryRecordings
}: Props) {
  const initialState: RecordingsState = {
    submitted: false,
    confirmationOpened: false,
    selectedCallResult: new Map(),
    selectedAppliance: new Map(),
    selectedBrand: new Map(),
    selectedRecordings: new Map(),
    isSelection: true,
    isModalOpen: false,
    selectedRecording: undefined
  };

  const [state, setState] = useStateReducer<RecordingsState>(initialState);

  const {
    selectedCallResult,
    selectedAppliance,
    selectedBrand,
    selectedRecordings,
    isSelection,
    isModalOpen,
    selectedRecording
  } = state;

  const handleAssignement = (e: any, recording: RecordingView) => {
    if (e) {
      e.preventDefault();
    }
    if (!loading && !recording.assigneeId) {
      assign(recording.id);
    }
  };

  const handleUnassignement = (recording: RecordingView) => {
    if (!loading && recording.assigneeId) {
      unassign(recording.id);
    }
  };

  const handleSubmit = (recordingId: string) => {
    const submitRecording = recordings.find(rd => rd.id === recordingId);
    const appliance = selectedAppliance.get(recordingId) || submitRecording?.appliance;
    const brand = selectedBrand.get(recordingId) || submitRecording?.brand;
    const callResult = selectedCallResult.get(recordingId) || submitRecording?.callResult;
    if (!loading && appliance && brand && callResult && callResult.id !== 3) {
      updateTags(recordingId, appliance.id, brand.id, callResult.id);
    }

    const errorMessage = (() => {
      var message = "";
      var hasError = false;
      if (!brand) {
        message += "brand, ";
        hasError = true;
      }
      if (!appliance) {
        message += "appliance, ";
        hasError = true;
      }
      if (!callResult || callResult.id === 3) {
        message += "call result";
        hasError = true;
      }
      return { hasError, message: "(" + message + ")" };
    })();

    if (errorMessage.hasError) {
      notify(
        "Recordings tagging",
        "Please select " + errorMessage.message + " to proceed.",
        "danger"
      );
    }
  };


  const isAssignedToUser = (assigneeId?: string): boolean => {
    return (
      assigneeId !== undefined && userId !== undefined && assigneeId === userId
    );
  };

  const handleBulkAssignement = () => {
    if (!loading) {
      const ids: string[] = [];
      selectedRecordings.forEach((value: boolean, key: string) => {
        if (value) {
          ids.push(key);
        }
      });
      bulkAssign(ids);
    }
  };

  const handleSelectAll = () => {
    const newSelectedRecordings = new Map(selectedRecordings);

    recordings.forEach((value) => {
      newSelectedRecordings.set(value.id, isSelection);
    });

    setState({
      selectedRecordings: newSelectedRecordings,
      isSelection: !isSelection,
    });
  };

  const toggleClose = () => {
    setState({ isModalOpen: !isModalOpen, selectedRecording: isModalOpen ? undefined : selectedRecording });
  };

  return (
    <>
    {loading &&
              <Row className="justify-content-center">
                <Spinner />
              </Row>
    }
      <div className="d-flex justify-content-between">
        <span className="mb-0 text-sm bold form-control-label">Total Count: {totalElements}</span>
        <div>
          <span className="mr-4">
            <IconButton
              icon="fa fa-user-plus"
              onClick={() => {
                handleBulkAssignement();
              }}
              tooltip="Assign selected"
              disabled={loading}
            />
          </span>
          <span className="mr-4">
            <IconButton
              icon={isSelection ? "fa fa-check-square" : "fa fa-minus-square"}
              onClick={() => {
                handleSelectAll();
              }}
              tooltip={isSelection ? "Select all" : "Deselect all"}
              disabled={loading}
            />
          </span>
        </div>
      </div>
      <Table className="align-items-center" responsive hover striped>
        <thead className="thead-light">
          <tr>
            {(tagged ? [
              "Date",
              "Call Info",
              "Tag User",
              "Recordings",
              "Call result",
              "Appliance",
              "Brand",
              "",
            ] :
            [
              "Date",
              "Call Info",
              "Recordings",
              "Call result",
              "Appliance",
              "Brand",
              "          ",
            ]).map((name, idx) => (
              <th scope="col" key={`recordings__header_${idx}`}>{name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {recordings.map((recording) => {
            return (
              <tr
                key={recording.id}
                className={
                  isAssignedToUser(recording.assigneeId) ? "table-light" : ""
                }
              >
                <th scope="row">
                  <Row>
                    <Input
                      type="checkbox"
                      checked={selectedRecordings.get(recording.id)}
                      onChange={(e: any) => {
                        const newSelectedRecordings = new Map(
                          selectedRecordings
                        );
                        newSelectedRecordings.set(
                          recording.id,
                          e.target.checked
                        );
                        setState({
                          selectedRecordings: newSelectedRecordings,
                        });
                      }}
                    />
                    <span className="mb-0 text-sm">{recording.date}</span>
                  </Row>
                </th>
                <td className="d-flex flex-column">
                  {recording.formattedFrom}
                  <Badge color="info" className="text-default">
                    {recording.duration}
                  </Badge>
                </td>
                {tagged && 
                  <td>
                    {recording.assigneeName}
                  </td>
                }

                <td>
                  {/* <audio
                    src={recording.recordingUrl}
                    controls
                    preload="auto"
                    crossOrigin="anonymous"
                    onPlay={(e) => {
                      handleAssignement(e, recording);
                    }}
                  /> */}
                  <audio
                    controls
                    preload="auto"
                    crossOrigin="anonymous"
                    onPlay={(e) => {
                      handleAssignement(e, recording);
                    }}
                  >
                    <source src={recording.recordingUrl.replace('.mp3', '.ogg')} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </td>
                <td>
                  <FormGroup style={{ marginBottom: 0.0 }}>
                    <Input
                      type="select"
                      name="callresult"
                      onChange={(e: any) => {
                        const selectedIds = [...e.target.selectedOptions].map(
                          (option: any) => option.value
                        );

                        const selectedId: number = parseInt(selectedIds[0]);
                        const newSelectedCallResult = new Map(selectedCallResult);
                        const newTag = availableTags.callResults.find(cr => cr.id === selectedId);
                        if (newTag) {
                          newSelectedCallResult.set(recording.id, newTag);
                          setState({
                            selectedCallResult: newSelectedCallResult,
                          });
                        }
                      }}
                      value={selectedCallResult.get(recording.id)?.id || recording.callResult?.id}
                    >
                      {availableTags.callResults
                        .map((tag, idx) =>
                          <option
                            // selected={recording.callResult?.id === tag.id}
                            value={tag.id} key={`tag_${idx}`}>{tag.value}</option>
                        )
                      }
                    </Input>
                  </FormGroup>
                </td>
                <td>
                  <FormGroup style={{ marginBottom: 0.0 }}>
                    <Input
                      type="select"
                      name="appliance"
                      onChange={(e: any) => {
                        const selectedIds = [...e.target.selectedOptions].map(
                          (option: any) => option.value
                        );

                        const selectedId: number = parseInt(selectedIds[0]);
                        const newSelectedAppliance = new Map(selectedAppliance);
                        const newTag = availableTags.appliances.find(cr => cr.id === selectedId);
                        if (newTag) {
                          newSelectedAppliance.set(recording.id, newTag);
                          setState({
                            selectedAppliance: newSelectedAppliance,
                          });
                        }
                      }}
                      value={selectedAppliance.get(recording.id)?.id || recording.appliance?.id}
                    >
                      {availableTags.appliances
                        .map((tag, idx) =>
                          <option
                            // selected={recording.appliance?.id === tag.id}
                            value={tag.id} key={`tag_${idx}`}>{tag.value}</option>
                        )
                      }
                    </Input>
                  </FormGroup>
                </td>
                <td>
                  <FormGroup style={{ marginBottom: 0.0 }}>
                    <Input
                      type="select"
                      name="brand"
                      onChange={(e: any) => {
                        const selectedIds = [...e.target.selectedOptions].map(
                          (option: any) => option.value
                        );

                        const selectedId: number = parseInt(selectedIds[0]);
                        const newSelectedBrand = new Map(selectedBrand);
                        const newTag = availableTags.brands.find(cr => cr.id === selectedId);
                        if (newTag) {
                          newSelectedBrand.set(recording.id, newTag);
                          setState({
                            selectedBrand: newSelectedBrand,
                          });
                        }
                      }}
                      value={selectedBrand.get(recording.id)?.id || recording.brand?.id}
                    >
                      {availableTags.brands
                        .map((tag, idx) =>
                          <option
                            // selected={recording.brand?.id === tag.id}
                            value={tag.id} key={`tag_${idx}`}>{tag.value}</option>
                        )
                      }
                    </Input>
                  </FormGroup>
                </td>
                <td>
                  <Row>
                    <Col xs="4">
                      <IconButton
                        id={recording.id}
                        color="default"
                        icon="fa fa-history"
                        onClick={() => {
                          setState({ isModalOpen: true, selectedRecording: recording });

                          if (initialStartDate && initialEndDate) {
                            loadHistoryRecordings(0, false, 5, recording.from);
                          }
                        }}
                        tooltip="View history"
                        disabled={loading || !recording.totalCalls || recording.totalCalls < 2}
                      />
                      {recording.totalCalls > 1 &&
                        <Badge className="position-absolute" style={{ top: '-8px', left: '24px' }} color="default" pill>{recording.totalCalls}</Badge>
                      }
                    </Col>
                    <Col xs="4">
                      <IconButton
                        id={recording.id}
                        color="success"
                        icon="fa fa-check"
                        onClick={() => handleSubmit(recording.id)}
                        tooltip="Submit"
                        disabled={loading || recording.assigneeId == null}
                      />
                    </Col>
                    <Col xs="4">
                      <IconButton
                        id={recording.id}
                        color={
                          isAssignedToUser(recording.assigneeId)
                            ? "danger"
                            : "default"
                        }
                        icon={
                          isAssignedToUser(recording.assigneeId)
                            ? "fa fa-user-minus"
                            : "fa fa-user-plus"
                        }
                        onClick={() => {
                          if (isAssignedToUser(recording.assigneeId)) {
                            handleUnassignement(recording);
                          } else {
                            handleAssignement(undefined, recording);
                          }
                        }}
                        tooltip={
                          isAssignedToUser(recording.assigneeId)
                            ? "Unassign"
                            : "Assign"
                        }
                        disabled={loading}
                      />
                    </Col>
                  </Row>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Modal
        className="modal-dialog-centered recordings-history-modal"
        style={{ maxWidth: 'calc(100% - 64px)' }}
        isOpen={isModalOpen}
        size="xl"
        toggle={toggleClose}
      >
        <RecordingHistoryTable recording={selectedRecording} />
      </Modal>
    </>
  );
}

export default connector(RecordingsTable);
