import React from "react";

import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";

import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  NavbarBrand,
} from "reactstrap";

import { Route, View } from "../../routes/routes";

function isViewActive(views: View[]) {
  return (
    views.find((view) => window.location.href.indexOf(view.path) !== -1) !==
    undefined
  );
}

interface IRouteLinkProps {
  route: Route;
  closeSidenav: () => void;
}

function RouteNavItem({ route, closeSidenav }: IRouteLinkProps): JSX.Element {
  const [opened, setOpened] = React.useState(false);
  const toggleOpened = () => setOpened(!opened);

  return (
    <NavItem>
      <NavLink
        href="#"
        data-toggle="collapse"
        aria-expanded={opened}
        className={classnames({
          active: isViewActive(route.views),
        })}
        onClick={(e) => {
          e.preventDefault();
          toggleOpened();
        }}
      >
        {route.icon ? (
          <>
            <i className={route.icon} />
            <span className="nav-link-text">{route.name}</span>
          </>
        ) : route.miniName ? (
          <>
            <span className="sidenav-mini-icon"> {route.miniName} </span>
            <span className="sidenav-normal"> {route.name} </span>)
          </>
        ) : null}
      </NavLink>
      <Collapse isOpen={opened}>
        <Nav className="nav-sm flex-column">
          {createViewsLinks(route.views, closeSidenav)}
        </Nav>
      </Collapse>
    </NavItem>
  );
}

function createLinks(routes: Route[], closeSidenav: () => void): JSX.Element[] {
  return routes.map((route, key) => {
    return (
      <RouteNavItem
        key={key}
        route={route}
        closeSidenav={closeSidenav}
      ></RouteNavItem>
    );
  });
}

function createViewsLinks(
  views: View[],
  closeSidenav: () => void
): JSX.Element[] {
  const activeRoute = (routeName: string) => {
    return window.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  return views.map((view, key) => {
    return (
      <NavItem
        className={activeRoute(view.layout + view.path) + " sidenav-normal"}
        key={key}
      >
        <NavLink
          to={view.layout + view.path}
          activeClassName=""
          onClick={(e) => closeSidenav()}
          tag={NavLinkRRD}
        >
          <span className="sidenav-normal"> {view.name} </span>
        </NavLink>
      </NavItem>
    );
  });
}

function onMouseEnterSidenav() {
  if (!document.body.classList.contains("g-sidenav-pinned")) {
    document.body.classList.add("g-sidenav-show");
  }
}
function onMouseLeaveSidenav() {
  if (!document.body.classList.contains("g-sidenav-pinned")) {
    document.body.classList.remove("g-sidenav-show");
  }
}

interface ISideBarProps {
  routes: Route[];
  logo: any;

  sidenavOpen: boolean;
  toggleSidenav: () => void;
}

export default function SideBar({
  routes,
  logo,
  sidenavOpen,
  toggleSidenav,
}: ISideBarProps): JSX.Element {
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };

  const scrollBarInner = (
    <div className="scrollbar-inner">
      <div className="sidenav-header d-flex align-items-center">
        {logo ? (
          <NavbarBrand {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        <div className="ml-auto">
          <div
            className={classnames("sidenav-toggler d-none d-xl-block", {
              active: !sidenavOpen,
            })}
            onClick={toggleSidenav}
          >
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-inner">
        <Collapse navbar isOpen={true}>
          <Nav navbar>{createLinks(routes, closeSidenav)}</Nav>
          <hr className="my-3" />
        </Collapse>
      </div>
    </div>
  );

  return (
    <Navbar
      className="sidenav navbar-vertical navbar-expand-xs navbar-light bg-white fixed-left"
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      {navigator.platform.indexOf("Win") > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
}
