import axios, { AxiosInstance } from "axios";
import { withAuthorization } from "toolkit/common-headers";
import { formatDate } from "toolkit/clock";
import { BingAdsAccount } from "clients/budgets/services/client";

class BingAdsService {
    private http: AxiosInstance;
    constructor(baseUrl: string) {
        this.http = axios.create({
            baseURL: baseUrl,
        });
    }

    async available(
        accessToken: string,
        activationDate: number,
    ): Promise<BingAdsAccount[]> {
        const result = await this.http.get(
            "",
            withAuthorization(accessToken, {
                activationDate: formatDate(activationDate),
            })
        );

        const data = result.data;

        if (data === undefined) {
            return Promise.resolve([]);
        }

        return data.map((b: any) => {
            return {
                id: b.id,
                name: b.name
            };
        });
    }

    async allocate(
        accessToken: string,
        clientId: string,
        bingAdsAccountId: string,
        activationDate: number,
        deactivationDate?: number,
    ): Promise<void> {
        await this.http.post(
            "/clients",
            {
                id: bingAdsAccountId,
                clientId,
                activationDate: formatDate(activationDate),
                deactivationDate: formatDate(deactivationDate),
            },
            withAuthorization(accessToken)
        );

        return Promise.resolve();
    }

    async update(
        accessToken: string,
        clientId: string,
        bingAdsAccountId: string,
        activationDate: number,
        deactivationDate?: number,
    ): Promise<void> {
        await this.http.put(
            `${bingAdsAccountId}/clients/${clientId}`,
            {
                activationDate: formatDate(activationDate),
                deactivationDate: formatDate(deactivationDate),
            },
            withAuthorization(accessToken)
        );

        return Promise.resolve();
    }


}

const bingAdsService = new BingAdsService(
    process.env.REACT_APP_BING_ADS_API || ""
);
export default bingAdsService;
