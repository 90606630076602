import axios, { AxiosInstance } from "axios";
import { Recordings, AvailableTags, Tags } from "./recording";
import { withAuthorization } from "toolkit/common-headers";
import moment from "moment";

class RecordingsService {
  private http: AxiosInstance;
  constructor(baseUrl: string) {
    this.http = axios.create({
      baseURL: baseUrl,
    });
  }

  async availableTags(accessToken: string): Promise<AvailableTags> {
    const result = await this.http.get(
      "/tags",
      withAuthorization(accessToken)
    );

    const data = result.data;

    if (data === undefined) {
      return Promise.resolve({
        appliances: [],
        brands: [],
        callResults: [],
      });
    }

    return {
      appliances: data.appliances,
      brands: data.brands,
      callResults: data.callResults,
    };
  }

  async recordings(
    accessToken: string,
    startDate: number,
    endDate: number,
    page: number,
    tagged: boolean,
    pageSize: number,
    territoryId?: string
  ): Promise<Recordings> {
    const result = await this.http.get(
      "",
      withAuthorization(accessToken, {
        startDate: this.formatDate(new Date(startDate)),
        endDate: this.formatDate(new Date(endDate)),
        page: page,
        state: tagged ? "tagged" : "untagged",
        size: pageSize,
        territoryId: territoryId ? territoryId : undefined
      })
    );

    const data = result.data;

    if (data === undefined) {
      return Promise.resolve({
        page: 0,
        totalPages: 0,
        hasMorePages: false,
        recordings: [],
        historyRecordings: [],
        historyHasMore: false,
        historyTotalPages: 0,
        totalElements: 0
      });
    }

    return {
      page: data.page,
      totalPages: data.totalPages,
      hasMorePages: data.hasMorePages,
      totalElements: data.totalElements,
      recordings: data.recordings.map((recording: any) => {
        return {
          id: recording.id,
          date: recording.date,
          from: recording.from,
          duration: recording.duration,
          recordingUrl: recording.recordingUrl,
          callResultId: recording.callResultId,
          applianceId: recording.applianceId,
          brandId: recording.brandId,
          assigneeId: recording.assigneeId,
          assigneeName: recording.assigneeName,
          formattedFrom: recording.formattedFrom,
          formattedTo: recording.formattedTo,
          totalCalls: recording.totalCalls
        };
      }),
      historyRecordings: [],
      historyHasMore: false,
      historyTotalPages: 0
    };
  }

  async historyRecordings(
    accessToken: string,
    page: number,
    pageSize: number,
    from: string
  ): Promise<Recordings> {
    const historyResult = await this.http.get(
      "",
      withAuthorization(accessToken, {
        page: page,
        size: pageSize,
        fromNumber: from.replace('+', ''),
      })
    );

    const historyData = historyResult.data;

    if (historyData === undefined) {
      return Promise.resolve({
        page: 0,
        totalPages: 0,
        hasMorePages: false,
        recordings: [],
        historyRecordings: [],
        historyHasMore: false,
        historyTotalPages: 0,
        totalElements: 0
      });
    }

    return {
      page: historyData.page,
      totalPages: historyData.totalPages,
      totalElements: historyData.totalElements,
      hasMorePages: historyData.hasMorePages,
      recordings: [],
      historyRecordings: historyData.recordings.map((recording: any) => {
        return {
          id: recording.id,
          date: recording.date,
          from: recording.from,
          duration: recording.duration,
          recordingUrl: recording.recordingUrl,
          callResultId: recording.callResultId,
          applianceId: recording.applianceId,
          brandId: recording.brandId,
          assigneeId: recording.assigneeId,
          assigneeName: recording.assigneeName,
          formattedFrom: recording.formattedFrom,
          formattedTo: recording.formattedTo,
          totalCalls: recording.totalCalls
        };
      }),
      historyHasMore: historyData.hasMorePages,
      historyTotalPages: historyData.totalPages
    };
  }

  private formatDate(date: Date): string {
    return moment(date).format('YYYY-MM-DD');
  }

  async assign(accessToken: string, recordingId: string): Promise<void> {
    await this.http.post(
      "",
      {
        recordingId,
      },
      withAuthorization(accessToken)
    );

    return Promise.resolve();
  }

  async unassign(accessToken: string, recordingId: string): Promise<void> {
    await this.http.delete(
      `/${recordingId}`,
      withAuthorization(accessToken)
    );

    return Promise.resolve();
  }

  async updateTags(
    accessToken: string,
    recordingId: string,
    tags: Tags
  ): Promise<void> {
    await this.http.patch(
      `/${recordingId}`,
      tags,
      withAuthorization(accessToken)
    );

    return Promise.resolve();
  }
}

const recordingsService = new RecordingsService(
  process.env.REACT_APP_RECORDINGS_API || ""
);
export default recordingsService;
