import axios, { AxiosInstance } from "axios";
import { withAuthorization } from "../../toolkit/common-headers";
import { Role } from "../redux/role";

class RoleService {
  private http: AxiosInstance;
  constructor(baseUrl: string) {
    this.http = axios.create({
      baseURL: baseUrl,
    });
  }

  async roles(accessToken: string): Promise<Role[]> {
    const result = await this.http.get(
      "/roles",
      withAuthorization(accessToken)
    );

    const data = result.data;

    if (data === undefined) {
      return Promise.resolve([]);
    }

    return data.map((group: any) => {
      return {
        id: group.id,
        description: group.description,
        name: group.name,
      };
    });
  }
}

const roleService = new RoleService(process.env.REACT_APP_ROLES_API || "");

export default roleService;
