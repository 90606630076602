import React from "react";
import "./App.scss";
import HomePage from "./home/HomePage";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PrivateRoute from "./authentication/components/PrivateRoute";
import LoginPage from "./authentication/view/Login";
import RootComponent from "RootComponent";

function App() {
  return (
    <RootComponent>
      <Router>
        <Switch>
          <PrivateRoute path="/admin">
            <HomePage />
          </PrivateRoute>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Redirect from="/" to="/admin" />
        </Switch>
      </Router>
    </RootComponent>
  );
}

export default App;
