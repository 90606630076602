import axios, { AxiosInstance } from "axios";
import { withAuthorization } from "toolkit/common-headers";
import { formatDate } from "toolkit/clock";
import { GoogleAdsAccount } from "clients/budgets/services/client";

class GoogleAdsService {
	private http: AxiosInstance;
	constructor(baseUrl: string) {
		this.http = axios.create({
			baseURL: baseUrl
		});
	}

	async available(accessToken: string, activationDate: number): Promise<GoogleAdsAccount[]> {
		const result = await this.http.get(
			"",
			withAuthorization(accessToken, {
				activationDate: formatDate(activationDate)
			})
		);

		const data = result.data;

		if (data === undefined) {
			return Promise.resolve([]);
		}

		return data.map((g: any) => {
			return {
				customerId: g.customerId,
				name: g.name
			};
		});
	}

	async allocate(accessToken: string, clientId: string, googleAdsAccountId: string, activationDate: number, deactivationDate?: number): Promise<void> {
		await this.http.post(
			"/clients",
			{
				customerId: googleAdsAccountId,
				clientId,
				activationDate: formatDate(activationDate),
				deactivationDate: formatDate(deactivationDate)
			},
			withAuthorization(accessToken)
		);

		return Promise.resolve();
	}

	async update(accessToken: string, clientId: string, googleAdsAccountId: string, activationDate: number, deactivationDate?: number): Promise<void> {
		await this.http.put(
			`${googleAdsAccountId}/clients/${clientId}`,
			{
				activationDate: formatDate(activationDate),
				deactivationDate: formatDate(deactivationDate)
			},
			withAuthorization(accessToken)
		);

		return Promise.resolve();
	}
}

const googleAdsService = new GoogleAdsService(process.env.REACT_APP_GOOGLE_ADS_API || "");
export default googleAdsService;
