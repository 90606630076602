import { Tokens } from "authentication/redux/tokens";

class TokenStorage {
  static TOKENS_KEY = "tokens";
  find(): Tokens | undefined {
    const tokensJson = localStorage.getItem(TokenStorage.TOKENS_KEY);
    if (tokensJson !== null) {
      const tokens = JSON.parse(tokensJson);
      return tokens;
    }
    return undefined;
  }

  store(tokens: Tokens) {
    localStorage.setItem(TokenStorage.TOKENS_KEY, JSON.stringify(tokens));
  }

  clear() {
    localStorage.removeItem(TokenStorage.TOKENS_KEY);
  }
}

const tokenStorage = new TokenStorage();
export default tokenStorage;
