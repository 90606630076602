import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Badge, Card, CardBody, CardHeader, Col, Container, Input, Row } from "reactstrap";
import { RootState } from "redux/root-reducer";
import { Status, SyncType } from "sync/redux/sync-state";

import FormHeader from "toolkit/components/FormHeader";
import IconButton from "toolkit/components/IconButton";
import { useStateReducer } from "toolkit/state-reducer";
import ReactDatetime from "react-datetime";

import { actions as SyncActions } from "../redux/sync-actions";
import { actions as SystemActions } from "redux/system-actions";

import googleAdsIcon from "assets/img/icons/common/google-ads-icon.png";
import bingAdsIcon from "assets/img/icons/common/bing-ads-icon.png";

import { Moment } from "moment";

function StatusBadge(props: any): JSX.Element {
	switch (props.status) {
		case Status.NOT_INITIALIZED:
			return <></>;
		case Status.IN_PROGRESS:
			return <Badge color="warning">In Progress</Badge>;
		case Status.FINISHED:
			return <Badge color="success">Finished</Badge>;
		case Status.ABORTED:
			return <Badge color="danger">Aborted</Badge>;
		case Status.FAILED:
			return <Badge color="danger">Failed</Badge>;
		default:
			return <></>;
	}
}

const mapState = (state: RootState) => ({
	syncStatus: state.sync.syncStatus,
	error: state.sync.error
});

const mapDispatch = {
	startSync: SyncActions.startSync,
	refreshSyncStatus: SyncActions.refreshSyncStatus,
	notify: SystemActions.notify
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

interface State {
	refreshIntervals: any;
	googleAdsWithLabels: boolean;
	googleAdsPerfStartDate?: number;
	googleAdsPerfEndDate?: number;
	bingAdsPerfStartDate?: number;
	bingAdsPerfEndDate?: number;
}

function ControlPanel({ syncStatus, error, startSync, refreshSyncStatus, notify }: Props) {
	const initialState: State = {
		googleAdsWithLabels: false,
		refreshIntervals: {}
	};

	const [state, setState] = useStateReducer<State>(initialState);

	const { refreshIntervals, googleAdsWithLabels, googleAdsPerfStartDate, googleAdsPerfEndDate, bingAdsPerfStartDate, bingAdsPerfEndDate } = state;

	useEffect(() => {
		let newRefreshIntervals: any = {};
		for (let key in syncStatus) {
			let status = syncStatus[key as SyncType].status;
			if (status !== Status.IN_PROGRESS && refreshIntervals[key]) {
				clearInterval(refreshIntervals[key]);
				newRefreshIntervals[key] = undefined;
			} else if (status === Status.IN_PROGRESS && !refreshIntervals[key]) {
				let interval = setInterval(() => {
					refreshSyncStatus(key as SyncType);
				}, 5000);
				newRefreshIntervals[key] = interval;
			}
		}
		setState({
			refreshIntervals: newRefreshIntervals
		});
		return () => {
			for (let key in refreshIntervals) {
				clearInterval(refreshIntervals[key]);
			}
		};
	}, [syncStatus]);

	useEffect(() => {
		if (error) {
			notify("Sync Failed", typeof error === "string" ? error : "Failed to sync data.", "danger");
		}
	}, [error]);

	const handleSync = (type: SyncType) => {
		switch (type) {
			case SyncType.GOOGLE_ADS_ACCOUNTS:
				startSync(type, { withLabels: googleAdsWithLabels });
				break;
			case SyncType.BING_ADS_PERFORMANCE_REPORT:
				startSync(type, { startDate: googleAdsPerfStartDate, endDate: googleAdsPerfEndDate });
				break;
			case SyncType.GOOGLE_ADS_PERFORMANCE_REPORT:
				startSync(type, { startDate: bingAdsPerfStartDate, endDate: bingAdsPerfEndDate });
				break;
			default:
				startSync(type, {});
		}
	};

	return (
		<>
			<FormHeader name="Sync Ad Accounts" parentName="Data Sync" />
			<Container className="mt-6 d-flex flex-column justify-content-center" fluid>
				<Card className="col-lg-10">
					<CardHeader className="d-flex">
						<h3 className="mb-0 mr-2">Google Ads</h3>
						<img src={googleAdsIcon} alt="google ads icon" height="32" />
					</CardHeader>
					<CardBody>
						<Row>
							<Col md="4">Accounts</Col>
							<Col md="4">
								<Input
									type="checkbox"
									checked={googleAdsWithLabels}
									onChange={(e: any) => {
										setState({
											googleAdsWithLabels: e.target.checked
										});
									}}
								/>
								<span className="mb-0 text-sm">With Labels</span>
							</Col>
							<Col md="2">
								<StatusBadge status={syncStatus[SyncType.GOOGLE_ADS_ACCOUNTS].status} />
							</Col>
							<Col md="2">
								<IconButton color="default" icon="fa fa-sync" onClick={() => handleSync(SyncType.GOOGLE_ADS_ACCOUNTS)} tooltip="Start Sync" loading={syncStatus[SyncType.GOOGLE_ADS_ACCOUNTS].status === Status.IN_PROGRESS} />
							</Col>
						</Row>

						<hr />
						<Row>
							<Col md="4">Performance Report</Col>
							<Col md="4 d-flex align-items-center">
								<span className="mr-2 text-sm">From: </span>
								<ReactDatetime
									className="w-50 mr-2"
									inputProps={{
										placeholder: "Start Date"
									}}
									timeFormat={false}
									onChange={(e: Moment | string) => {
										if (typeof e !== "string") {
											const date = parseInt(e.valueOf().toString());
											setState({
												googleAdsPerfStartDate: date
											});
										} else {
											setState({
												googleAdsPerfStartDate: undefined
											});
										}
									}}
								/>
								<span className="mr-2 text-sm">To: </span>
								<ReactDatetime
									className="w-50"
									inputProps={{
										placeholder: "End Date"
									}}
									timeFormat={false}
									onChange={(e: Moment | string) => {
										if (typeof e !== "string") {
											const date = parseInt(e.valueOf().toString());
											setState({
												googleAdsPerfEndDate: date
											});
										} else {
											setState({
												googleAdsPerfEndDate: undefined
											});
										}
									}}
								/>
							</Col>
							<Col md="2">
								<StatusBadge status={syncStatus[SyncType.GOOGLE_ADS_PERFORMANCE_REPORT].status} />
							</Col>
							<Col md="2">
								<IconButton
									color="default"
									icon="fa fa-sync"
									onClick={() => handleSync(SyncType.GOOGLE_ADS_PERFORMANCE_REPORT)}
									tooltip="Start Sync"
									loading={syncStatus[SyncType.GOOGLE_ADS_PERFORMANCE_REPORT].status === Status.IN_PROGRESS}
								/>
							</Col>
						</Row>
					</CardBody>
				</Card>
				<Card className="col-lg-10">
					<CardHeader className="d-flex">
						<h3 className="mb-0 mr-2">Bing Ads</h3>
						<img src={bingAdsIcon} alt="bing ads icon" height="32" />
					</CardHeader>
					<CardBody>
						<Row>
							<Col md="4">Accounts</Col>
							<Col md="4"></Col>
							<Col md="2">
								<StatusBadge status={syncStatus[SyncType.BING_ADS_ACCOUNTS].status} />
							</Col>
							<Col md="2">
								<IconButton color="default" icon="fa fa-sync" onClick={() => handleSync(SyncType.BING_ADS_ACCOUNTS)} tooltip="Start Sync" loading={syncStatus[SyncType.BING_ADS_ACCOUNTS].status === Status.IN_PROGRESS} />
							</Col>
						</Row>

						<hr />

						<Row>
							<Col md="4">Performance Report</Col>
							<Col md="4 d-flex align-items-center">
								<span className="mr-2 text-sm">From:</span>
								<ReactDatetime
									className="w-50 mr-2"
									inputProps={{
										placeholder: "Start Date"
									}}
									timeFormat={false}
									onChange={(e: Moment | string) => {
										if (typeof e !== "string") {
											const date = parseInt(e.valueOf().toString());
											setState({
												bingAdsPerfStartDate: date
											});
										} else {
											setState({
												bingAdsPerfStartDate: undefined
											});
										}
									}}
								/>
								<span className="mr-2 text-sm">To: </span>
								<ReactDatetime
									className="w-50"
									inputProps={{
										placeholder: "End Date"
									}}
									timeFormat={false}
									onChange={(e: Moment | string) => {
										if (typeof e !== "string") {
											const date = parseInt(e.valueOf().toString());
											setState({
												bingAdsPerfEndDate: date
											});
										} else {
											setState({
												bingAdsPerfEndDate: undefined
											});
										}
									}}
								/>
							</Col>
							<Col md="2">
								<StatusBadge status={syncStatus[SyncType.BING_ADS_PERFORMANCE_REPORT].status} />
							</Col>
							<Col md="2">
								<IconButton
									color="default"
									icon="fa fa-sync"
									onClick={() => handleSync(SyncType.BING_ADS_PERFORMANCE_REPORT)}
									tooltip="Start Sync"
									loading={syncStatus[SyncType.BING_ADS_PERFORMANCE_REPORT].status === Status.IN_PROGRESS}
								/>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default connector(ControlPanel);
