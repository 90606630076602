import React from "react";

import { Table, Spinner, Row } from "reactstrap";
import IconButton from "toolkit/components/IconButton";
import { PricingGrid } from "./services/pricing-grid";
import { PricingGrid as PricingGridSummary } from "clients/budgets/services/client";


type Props = {
  loading: boolean;
  pricingGrid: PricingGridSummary[];
  onSelectPricingGrid?: (pricingGrid: PricingGridSummary ) => void;
  onAssignClients?: (pricingGrid: PricingGridSummary) => void;
};

export default function PricingGridTable({
  loading,
  pricingGrid,
  onSelectPricingGrid,
  onAssignClients,
}: Props) {
  const goToDetails = (pricingGrid: PricingGridSummary) => {
    if (onSelectPricingGrid) {
      onSelectPricingGrid(pricingGrid);
    }
  };

  const goToClientsAssignement = (pricingGrid: PricingGridSummary) => {
    if (onAssignClients) {
      onAssignClients(pricingGrid);
    }
  };

  if (loading && pricingGrid.length === 0) {
    return (
      <Row className="justify-content-center">
        <Spinner />
      </Row>
    );
  }

  return (
    <>
      <Table className="align-items-center" responsive hover striped>
        <thead className="thead-light">
          <tr>
            {["Created at", "Updated at", "Description", ""].map((name) => (
              <th scope="col" key={name}>{name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {pricingGrid.map((pricingGrid) => {
            return (
              <tr key={pricingGrid.id}>
                <td>{new Date(pricingGrid.dateCreated).toLocaleString()}</td>
                <td>{new Date(pricingGrid.dateUpdated).toLocaleString()}</td>
                <td>{pricingGrid.description}</td>
                <td className="table-actions">
                  <IconButton
                    tableAction={true}
                    id={pricingGrid.id}
                    color="default"
                    icon="fa fa-edit"
                    onClick={() => goToDetails(pricingGrid)}
                    tooltip="Details"
                  />
                  <IconButton
                    tableAction={true}
                    id={pricingGrid.id}
                    color="default"
                    icon="fa fa-user-edit"
                    onClick={() => goToClientsAssignement(pricingGrid)}
                    tooltip="Assign clients"
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
