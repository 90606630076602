import {
  AuthenticationAction,
  AuthenticationActionType,
  LoginFailedAction,
  ChangeTokensAction,
  ChangeUserAction,
  ChangePasswordFailedAction,
} from "./authentication-actions";
import { AuthenticationState } from "./authentication-state";

const initialState: AuthenticationState = {
  loading: false,
  authenticated: false,
  changePassword: {
    loading: false,
    success: false,
  }
};

export default function authenticationReducer(
  state: AuthenticationState = initialState,
  action: AuthenticationAction
): AuthenticationState {
  switch (action.type) {
    case AuthenticationActionType.LOGIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case AuthenticationActionType.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        authenticated: true,
      };
    case AuthenticationActionType.CHANGE_TOKENS:
      return {
        ...state,
        tokens: (action as ChangeTokensAction).tokens,
      };
    case AuthenticationActionType.CHANGE_USER:
      return {
        ...state,
        user: (action as ChangeUserAction).user,
      };
    case AuthenticationActionType.LOGIN_FAILUR:
      return {
        ...state,
        loading: false,
        error: (action as LoginFailedAction).error,
      };
    case AuthenticationActionType.CHANGE_PASSWORD_LOADING:
      return {
        ...state,
        changePassword:{
          loading: true,
          success: false
        }
      }
    case AuthenticationActionType.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          loading: false,
          success: true,
          error: undefined,
        }
      }
    case AuthenticationActionType.CHANGE_PASSWORD_FAILURE: 
      return {
        ...state,
        changePassword: {
          loading: false,
          success: false,
          error: (action as ChangePasswordFailedAction).error,
        }
      }
    case AuthenticationActionType.CLEAR_AUTHENTICATION:
      return initialState;
    default:
      return state;
  }
}
