import React, { useEffect } from "react";
import { Client } from "clients/budgets/services/client";
import BearsightMultiselect from "./BearsightMultiselect";
import { useStateReducer } from "toolkit/state-reducer";
import { getActive } from "toolkit/clock";

interface Props {
    clients: Client[];
    loading: boolean;
    onClientSelected: (client: Client) => void;
}

interface State {
    selectedClient?: Client;
}


export default function ClientsDropDown({ clients, loading, onClientSelected }: Props): JSX.Element {
    const [state, setState] = useStateReducer<State>({});

    const { selectedClient } = state;

    useEffect(() => {
        if (selectedClient) {
            onClientSelected(selectedClient);
        }
    }, [selectedClient?.id]);

    const newClients = clients.map(ct => {
        const newClient = { ...ct };
        newClient.name = `${newClient.name} (${getActive(newClient.territories)?.territory.name  || 'Not Assigned'}) (${newClient.id})`
        return newClient;
    });

    return (
        <BearsightMultiselect<Client>
            isMulti={false}
            disabled={loading}
            loading={loading}
            options={newClients}
            selected={selectedClient ? [selectedClient] : undefined}
            label="Clients"
            onChange={(value: Client[]) =>
                setState({ selectedClient: value[0] })
            }
            displayValue="name"
        />

    );
}