import ClientRegistration from "users/registration/ClientRegistration";
import EditClient from "users/edit/EditClient";
import UserRegistration from "users/registration/UserRegistration";
import RecordingsTagging from "calls/recordings/RecordingsTagging";
import ClientBudget from "clients/budgets/ClientBudget";
import PricingGridView from "clients/pricing-grid/PricingGridView";
import TerritoriesTable from "clients/territories/TerritoriesTable";
import ClientsPhoneNumbersTable from "clients/phonenumbers/ClientsPhoneNumbersTable";
import GoogleAds from "clients/google-ads/GoogleAds";
import BingAds from "clients/bing-ads/BingAds";
import ClientsTable from "clients/clients/ClientsTable";
import UsersTable from "users/users/UsersTable";
import TwilioNumbersTable from "numbers/twilio-numbers/TwilioNumbersTable";
import ControlPanel from "sync/ad-accounts/ControlPanel";

export interface View {
  path: string;
  name: string;
  component: any;
  layout: string;
}

export interface Route {
  collapse: boolean;
  name: string;
  miniName?: string;
  icon: string;
  state: string;
  views: View[];
}

const routes: Route[] = [
  {
    collapse: true,
    name: "Klipfolio clients",
    icon: "icon-klipfolio text-default",
    state: "formsCollapse",
    views: [
      {
        path: "/clients/new",
        name: "Add new client",
        component: ClientRegistration,
        layout: "/admin",
      },
      {
        path: "/clients/edit",
        name: "Edit client",
        component: EditClient,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Portal users",
    icon: "fa fa-users text-default",
    state: "formsCollapse",
    views: [
      {
        path: "/users/new",
        name: "Add new user",
        component: UserRegistration,
        layout: "/admin",
      },
      {
        path: "/users",
        name: "Users management",
        component: UsersTable,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Clients",
    icon: "fa fa-user text-default",
    state: "formsCollapse",
    views: [
      {
        path: "/clients/management",
        name: "Clients management",
        component: ClientsTable,
        layout: "/admin",
      },
      {
        path: "/clients/budgets",
        name: "Budgets",
        component: ClientBudget,
        layout: "/admin",
      },
      {
        path: "/clients/pricing-grid",
        name: "Pricing grid",
        component: PricingGridView,
        layout: "/admin",
      },
      {
        path: "/clients/phone-numbers",
        name: "Phone numbers",
        component: ClientsPhoneNumbersTable,
        layout: "/admin",
      },
      {
        path: "/clients/google-ads",
        name: "Google ads",
        component: GoogleAds,
        layout: "/admin",
      },
      {
        path: "/clients/bing-ads",
        name: "Bing ads",
        component: BingAds,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Calls",
    icon: "fa fa-phone text-default",
    state: "formsCollapse",
    views: [
      {
        path: "/calls/recordings",
        name: "Recordings",
        component: RecordingsTagging,
        layout: "/admin",
      }
    ],
  },
  {
    collapse: true,
    name: "Geography",
    icon: "fa fa-map-marker text-default",
    state: "formsCollapse",
    views: [
      {
        path: "/geography/territories",
        name: "Territories",
        component: TerritoriesTable,
        layout: "/admin",
      }
    ],
  },
  {
    collapse: true,
    name: "Numbers",
    icon: "icon-twilio text-default",
    state: "formsCollapse",
    views: [
      {
        path: "/numbers/twilio-numbers",
        name: "Twilio Numbers",
        component: TwilioNumbersTable,
        layout: "/admin",
      }
    ],
  },
  {
    collapse: true,
    name: "Data Sync",
    icon: "fa fa-sync text-default",
    state: "formsCollapse",
    views: [
      {
        path: "/sync/ad-accounts",
        name: "Ad Accounts",
        component: ControlPanel,
        layout: "/admin",
      }
    ],
  }
];

export default routes;
