import React from "react";

import classnames from "classnames";

import { Collapse, Navbar, NavItem, Nav, Container } from "reactstrap";
import { RootState } from "../../redux/root-reducer";
import actions from "../../authentication/redux/authentication-actions";
import { connect, ConnectedProps } from "react-redux";
import UserOptionsDropdown from "./UserOptionsDropdown";

const mapState = (state: RootState) => ({
  email: state.authentication.user?.email,
});

const mapDispatch = {
  logout: actions.logout,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type INavigationBarProps = {
  sidenavOpen: boolean;
  toggleSidenav: () => void;
};

type Props = PropsFromRedux & INavigationBarProps;

function NavigationBar({
  sidenavOpen,
  toggleSidenav,
  email,
  logout,
}: Props): JSX.Element {
  return (
    <>
      <Navbar className="navbar-top navbar-expand border-bottom navbar-light bg-default">
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler sidenav-toggler-dark",
                    {
                      active: !sidenavOpen,
                    }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UserOptionsDropdown email={email} logout={logout} />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default connector(NavigationBar);
