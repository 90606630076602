import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../redux/root-reducer";
import {
  profileSymbol,
  profileName,
} from "../../authentication/redux/selectors";
import { Media } from "reactstrap";

const mapState = (state: RootState) => ({
  profileName: profileName(state.authentication),
  profileSymbol: profileSymbol(state.authentication),
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

function UserProfile({ profileName, profileSymbol }: Props) {
  return (
    <>
      <div
        className="avatar rounded-circle bg-danger sidenav-toggler"
        onClick={(e) => e.preventDefault()}
      >
        <span className="text-secondary font-weight-bold text-uppercase">
          {profileSymbol}
        </span>
      </div>
      <Media className="ml-2 d-none d-lg-block">
        <span className="text-secondary mb-0 text-sm font-weight-bold">{profileName}</span>
      </Media>
    </>
  );
}

export default connector(UserProfile);
