import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "redux/root-reducer";
import { Status, SyncOptions, SyncStatus, SyncType } from "sync/redux/sync-state";
import warehouseService from "sync/services/warehouse-service";

export enum SyncActionType {
	SYNC_FAILED = "SYNC_FAILED",
	CLEAR_ERROR = "CLEAR_ERROR",
	CHANGE_GOOGLE_ACCOUNTS_STATUS = "CHANGE_GOOGLE_ACCOUNTS_STATUS",
	CHANGE_GOOGLE_PERFORMANCE_STATUS = "CHANGE_GOOGLE_PERFORMANCE_STATUS",
	CHANGE_BING_ACCOUNTS_STATUS = "CHANGE_BING_ACCOUNTS_STATUS",
	CHANGE_BING_PERFORMANCE_STATUS = "CHANGE_BING_PERFORMANCE_STATUS"
}

export interface ClearErrorAction {
	type: SyncActionType.CLEAR_ERROR;
}

export interface FailedAction {
	type: SyncActionType.SYNC_FAILED;
	error: any;
}

export interface ChangeGoogleAccountsStatusAction {
	type: SyncActionType.CHANGE_GOOGLE_ACCOUNTS_STATUS;
	status: SyncStatus;
}

export interface ChangeGooglePerformanceStatusAction {
	type: SyncActionType.CHANGE_GOOGLE_PERFORMANCE_STATUS;
	status: SyncStatus;
}

export interface ChangeBingAccountsStatusAction {
	type: SyncActionType.CHANGE_BING_ACCOUNTS_STATUS;
	status: SyncStatus;
}

export interface ChangeBingPerformanceStatusAction {
	type: SyncActionType.CHANGE_BING_PERFORMANCE_STATUS;
	status: SyncStatus;
}

export type SyncAction = ClearErrorAction | FailedAction | ChangeGoogleAccountsStatusAction | ChangeGooglePerformanceStatusAction | ChangeBingAccountsStatusAction | ChangeBingPerformanceStatusAction;

function failed(error: any): FailedAction {
	return {
		type: SyncActionType.SYNC_FAILED,
		error
	};
}

function clearError(): ClearErrorAction {
	return {
		type: SyncActionType.CLEAR_ERROR
	};
}

export const changeGoogleAccountsStatus = (syncStatus: SyncStatus) => {
	return {
		type: SyncActionType.CHANGE_GOOGLE_ACCOUNTS_STATUS,
		status: syncStatus
	};
};

export const changeGooglePerformanceStatus = (syncStatus: SyncStatus) => {
	return {
		type: SyncActionType.CHANGE_GOOGLE_PERFORMANCE_STATUS,
		status: syncStatus
	};
};

export const changeBingAccountsStatus = (syncStatus: SyncStatus) => {
	return {
		type: SyncActionType.CHANGE_BING_ACCOUNTS_STATUS,
		status: syncStatus
	};
};

export const changeBingPerformanceStatus = (syncStatus: SyncStatus) => {
	return {
		type: SyncActionType.CHANGE_BING_PERFORMANCE_STATUS,
		status: syncStatus
	};
};

type SyncThunkAction = ThunkAction<void, RootState, unknown, Action<SyncActionType>>;

export const startSync = (type: SyncType, options: SyncOptions): SyncThunkAction => async (dispatch, getState) => {
	try {
		const accessToken = getState().authentication?.tokens?.accessToken;
		let workRequestStatus = null;
		let status: SyncStatus = { status: Status.NOT_INITIALIZED };
		if (accessToken) {
			switch (type) {
				case SyncType.GOOGLE_ADS_ACCOUNTS:
					workRequestStatus = await warehouseService.syncGoogleAdsAccounts(accessToken, options.withLabels || false);
					if (workRequestStatus.id) {
						status = { id: workRequestStatus.id, status: Status.IN_PROGRESS };
					}
					dispatch(changeGoogleAccountsStatus(status));
					break;
				case SyncType.GOOGLE_ADS_PERFORMANCE_REPORT:
					workRequestStatus = await warehouseService.syncGoogleAdsPerformanceReports(accessToken, options.startDate, options.endDate);
					if (workRequestStatus.id) {
						status = { id: workRequestStatus.id, status: Status.IN_PROGRESS };
					}
					dispatch(changeGooglePerformanceStatus(status));
					break;
				case SyncType.BING_ADS_ACCOUNTS:
					workRequestStatus = await warehouseService.syncBingAdsAccounts(accessToken);
					if (workRequestStatus.id) {
						status = { id: workRequestStatus.id, status: Status.IN_PROGRESS };
					}
					dispatch(changeBingAccountsStatus(status));
				case SyncType.BING_ADS_PERFORMANCE_REPORT:
					workRequestStatus = await warehouseService.syncBingAdsPerformanceReports(accessToken, options.startDate, options.endDate);
					if (workRequestStatus.id) {
						status = { id: workRequestStatus.id, status: Status.IN_PROGRESS };
					}
					dispatch(changeBingPerformanceStatus(status));
					break;
			}

			if (workRequestStatus.status === "CONFLICT") {
				dispatch(failed("Another Sync process is already in progress"));
			} else {
				dispatch(clearError());
			}
		}
	} catch (e) {
		dispatch(failed(e));
	}
};

export const refreshSyncStatus = (type: SyncType): SyncThunkAction => async (dispatch, getState) => {
	const currentStatus = getState().sync.syncStatus[type];
	if (currentStatus.id && currentStatus.status === Status.IN_PROGRESS) {
		try {
			const accessToken = getState().authentication?.tokens?.accessToken;
			if (accessToken) {
				let syncStatus = await warehouseService.getSyncStatus(accessToken, currentStatus.id);

				switch (type) {
					case SyncType.GOOGLE_ADS_ACCOUNTS:
						dispatch(changeGoogleAccountsStatus(syncStatus));
						break;
					case SyncType.GOOGLE_ADS_PERFORMANCE_REPORT:
						dispatch(changeGooglePerformanceStatus(syncStatus));
						break;
					case SyncType.BING_ADS_ACCOUNTS:
						dispatch(changeBingAccountsStatus(syncStatus));
						break;
					case SyncType.BING_ADS_PERFORMANCE_REPORT:
						dispatch(changeBingPerformanceStatus(syncStatus));
						break;
				}
			}
		} catch (e) {}
	}
};

export const actions = {
	startSync: startSync,
	refreshSyncStatus: refreshSyncStatus
};
