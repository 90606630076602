import React, { useEffect } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Form,
    Button,
} from "reactstrap"
import BearsightTextInput from "toolkit/components/BearsightTextInput";
import { useStateReducer } from "toolkit/state-reducer";
import SpinningButton from "toolkit/components/SpinningButton";
import ModalCloseButton from "toolkit/components/ModalCloseButton";
import { PricingGrid } from "./services/pricing-grid";

interface Props {
    loading: boolean;
    onBack: () => void;
    onCreate: (pricingGrid: PricingGrid) => void;
}

interface State {
    description?: string;
}

export default function CreatePricingGrid({
    loading,
    onBack,
    onCreate,
}: Props) {

    const [state, setState] = useStateReducer<State>({});
    const { description } = state;

    const handleCreatePricingGrid = () => {
        if (description) {
            onCreate({ id:"", description: description, details:[], dateCreated: "", dateUpdated: "" });
        }
    };

    useEffect(() => {
        if (!loading && description) {
            setState({ description: undefined });
            onBack();
        }
    }, [loading]);

    return (
        <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                    <ModalCloseButton onClose={onBack} />
                    <div className="text-muted text-center mt-2 mb-3">
                        <small>CREATE PRICING GRID</small>
                    </div>
                </CardHeader>
                <CardBody className="px-lg-5">
                    <Form role="form" onSubmit={e => e.preventDefault()}>
                        <BearsightTextInput
                            label="Description"
                            text={description}
                            validation={(value) => value === ""}
                            onChange={(value) => setState({ description: value })}
                        />

                        <Row className="justify-content-end">
                            <SpinningButton
                                block={false}
                                icon="fa fa-save"
                                color="default"
                                type="submit"
                                loading={loading}
                                text="CREATE"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleCreatePricingGrid();
                                }}
                            />
                            <Button
                                color="link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onBack();
                                }}
                            >
                                <span className="text-default">CANCEL</span>
                            </Button>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </div>
    );
}
