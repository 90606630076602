import React from "react";

import NavigationBar from "./components/NavigationBar";
import SideBar from "./components/SideBar";
import routes from "../routes/routes";
import publicRoutes from "../routes/publicRoutes";
import { View } from "../routes/routes";

import logo from "../assets/img/brand/argon-react.png";
import { Switch, Redirect } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "redux/root-reducer";
import PrivateRoute from "authentication/components/PrivateRoute";
import { useStateReducer } from "toolkit/state-reducer";
import ChangePasswordPage from "authentication/view/ChangePassword";
import ClientManagement from "clients/client/ClientManagement";

interface IHomePageState {
	sidenavOpen: boolean;
	sidenavPinned: boolean;
}

const mapState = (state: RootState) => ({
	admin: state.authentication.user?.admin || false
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type HomePageProps = {};

type Props = PropsFromRedux & HomePageProps;

function HomePage({ admin }: Props) {
	const [state, setState] = useStateReducer<IHomePageState>({
		sidenavOpen: true,
		sidenavPinned: true
	});

	const toggleSidenav = () => {
		const sidenavPinned: boolean = document.body.classList.contains("g-sidenav-pinned");
		if (sidenavPinned) {
			document.body.classList.remove("g-sidenav-pinned");
			document.body.classList.add("g-sidenav-hidden");
		} else {
			document.body.classList.add("g-sidenav-pinned");
			document.body.classList.remove("g-sidenav-hidden");
		}

		setState({
			sidenavOpen: !state.sidenavOpen,
			sidenavPinned: sidenavPinned
		});
	};

	const closeSidenav = () => {
		const sidenavPinned: boolean = document.body.classList.contains("g-sidenav-pinned");

		if (sidenavPinned) {
			document.body.classList.remove("g-sidenav-pinned");
			document.body.classList.add("g-sidenav-hidden");
		}

		setState({
			sidenavOpen: false,
			sidenavPinned: false
		});
	};

	const mainContent = React.useRef<any>();

	const getRoutes = () => {
		const views: View[] = (admin ? routes : publicRoutes).flatMap(route => route.views);
		return views.map((prop, key) => {
			return (
				<PrivateRoute key={key} path={prop.layout + prop.path}>
					{<prop.component />}
				</PrivateRoute>
			);
		});
	};

	return (
		<>
			<SideBar
				routes={admin ? routes : publicRoutes}
				logo={{
					innerLink: "/",
					imgSrc: logo,
					imgAlt: "..."
				}}
				sidenavOpen={state.sidenavOpen}
				toggleSidenav={toggleSidenav}
			/>

			<div className="main-content" ref={mainContent}>
				<NavigationBar sidenavOpen={state.sidenavOpen} toggleSidenav={toggleSidenav} />

				<Switch>
					<PrivateRoute path="/admin/clients/management/:clientId">
						<ClientManagement />
					</PrivateRoute>
					<PrivateRoute path="/admin/change-password">
						<ChangePasswordPage />
					</PrivateRoute>
					{getRoutes()}

					<Redirect from="*" to="/admin/dashboard" />
				</Switch>
			</div>
			{state.sidenavOpen ? <div className="backdrop d-xl-none" onClick={closeSidenav} /> : null}
		</>
	);
}

export default connector(HomePage);
