import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "redux/root-reducer";

import { Table, Row, Form, Modal, Spinner, Container, Card, CardHeader, CardBody } from "reactstrap";
import { actions as clientActions } from "../redux/client-actions";
import { actions as systemActions } from "../../redux/system-actions";
import { useStateReducer } from "toolkit/state-reducer";
import IconButton from "toolkit/components/IconButton";
import BearsightTextInput from "toolkit/components/BearsightTextInput";
import { Client } from "clients/budgets/services/client";
import { selectClients } from "clients/redux/client-selector";
import BearsightPagination from "toolkit/components/BearsightPagination";
import { useDebounce } from "use-debounce";
import FormHeader from "toolkit/components/FormHeader";
import BingAdsAttribution from "./BingAdsAttribution";
import { getActive } from "toolkit/clock";

const mapState = (state: RootState) => ({
  loading: state.clients.loading,
  clients: selectClients(state.clients),
  currentPage: state.clients.clientsPage,
  totalPages: state.clients.clientsTotalPages,
  pageSize: state.clients.clientsPageSize,
  availableBingAdsAccounts: state.clients.bingAdsAccounts,
});

const mapDispatch = {
  loadClients: clientActions.loadClients,
  loadAvailableBingAdsAccounts: clientActions.loadAvailableBingAdsAccounts,
  attributeBingAccount: clientActions.attributeBingAccount,
  updateBingAdsAccounts: clientActions.updateBingAdsAccounts,
  notify: systemActions.notify,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

interface State {
  filteringQuery: string;
  selectedClient?: Client;
  editOpened: boolean;
}

function BingAds({
  loading,
  clients,
  totalPages,
  currentPage,
  pageSize,
  availableBingAdsAccounts,
  loadClients,
  loadAvailableBingAdsAccounts,
  attributeBingAccount,
  updateBingAdsAccounts,
  notify,
}: Props) {
  const initialState: State = {
    filteringQuery: "",
    editOpened: false,
  };

  const [state, setState] = useStateReducer<State>(initialState);

  const { filteringQuery, selectedClient, editOpened } = state;

  useEffect(() => {
    loadClients(currentPage, pageSize);
  }, []);

  const [debouncedSearchTerm] = useDebounce(filteringQuery, 500);

  useEffect(() => {
    loadClients(currentPage, pageSize, debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (selectedClient) {
      const updateSelectedClient = clients.find(
        (client) => client.id === selectedClient.id
      );
      if (updateSelectedClient) {
        setState({
          selectedClient: updateSelectedClient
        });
      }
    }
  }, [JSON.stringify(clients)]);

  const toggleEdit = () => {
    setState({ editOpened: !editOpened });
  };

  const handleClose = () => {
    setState({ editOpened: false });
  };

  const handleEdit = (client: Client) => {
    setState({ editOpened: true, selectedClient: client });
  };

  const handlePageSizeChanged = (pageSize: number) => {
    loadClients(currentPage, pageSize);
  };

  const handlePageChanged = (page: number) => {
    loadClients(page, pageSize);
  };

  return (
    <>
      <FormHeader name="BING ADS" parentName="Clients" />
      <Container className="mt--6 d-flex justify-content-center" fluid>
        <Card className="col-lg-12">
          <CardHeader>
            <h3 className="mb-0">BING ADS</h3>
          </CardHeader>
          <CardBody>
            {loading && clients.length === 0 ?
              <Row className="justify-content-center">
                <Spinner />
              </Row>
              :
              <>
                <Form className="needs-validation mb-4" noValidate inline onSubmit={e => e.preventDefault()}>
                  <BearsightTextInput
                    label=""
                    icon="fas fa-search"
                    text={filteringQuery}
                    onChange={(value) => setState({ filteringQuery: value })}
                  />
                </Form>

                <Table className="align-items-center" responsive hover striped>
                  <thead className="thead-light">
                    <tr>
                      {["Id", "Name", "Territory", "Bing account name", "Activation date", "Deactivation date", ""].map(
                        (name, idx) => (
                          <th scope="col" key={`header_${idx}`}>{name}</th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {clients.map((client) => {
                      return (
                        <tr key={client.id}>
                          <td>{client.id}</td>
                          <th scope="row">
                            <span className="mb-0 text-sm">{client.name}</span>
                          </th>
                          <td>{getActive(client.territories)?.territory.name  || "N/A"}</td>
                          <td>
                            {client.activeBingAdsAccount?.BingAdsAccount.name ?
                              client.activeBingAdsAccount.BingAdsAccount.name
                              : "N/A"}
                          </td>
                          <td>
                            <span className="text-sm">
                              {client.activeBingAdsAccount?.activationDate
                                ? new Date(
                                  client.activeBingAdsAccount?.activationDate
                                ).toLocaleDateString()
                                : "N/A"}
                            </span>
                          </td>
                          <td>
                            <span className="text-sm">
                              {client.activeBingAdsAccount?.deactivationDate
                                ? new Date(
                                  client.activeBingAdsAccount?.deactivationDate
                                ).toLocaleDateString()
                                : "N/A"}
                            </span>
                          </td>
                          <td>
                            <IconButton
                              id={client.id}
                              color="default"
                              icon="fa fa-edit"
                              onClick={() => handleEdit(client)}
                              tooltip="Edit ads account"
                              loading={loading}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                <hr />
                <BearsightPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  pageSize={pageSize}
                  onPageSizeChanged={handlePageSizeChanged}
                  handleSearch={handlePageChanged}
                />
              </>
            }
          </CardBody>
        </Card>

        <Modal
              className="modal-dialog-centered"
              isOpen={editOpened}
              size="xl"
              toggle={toggleEdit}
            >
              <BingAdsAttribution
                loading={loading}
                client={selectedClient}
                onClose={handleClose}
                loadClientBingAdsAccounts={loadAvailableBingAdsAccounts}
                onAttributeBingAdsAccount={attributeBingAccount}
                onUpdateBingAdsAccount={updateBingAdsAccounts}
                availableBingAdsAccounts={availableBingAdsAccounts}
                from="BING_ADS"
                />
            </Modal>

      </Container>
    </>
  );
}

export default connector(BingAds);
