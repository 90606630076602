import React from "react";

interface Props {
    onClose?: () => void;
}

export default function ModalCloseButton({
    onClose
}: Props): JSX.Element {
    const handleClose = (e: React.MouseEvent) => {
        e.preventDefault();
        if (onClose) {
            onClose();
        }
    }

    return (
        <button
            onClick={handleClose}
            type="button"
            className="close"
            aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    );
}
