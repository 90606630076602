import { AxiosRequestConfig } from "axios";

export const defaultHeaders = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

export const withAuthorization = (
  token: string,
  params?: any
): AxiosRequestConfig => {
  return {
    params: params,
    headers: {
      ...defaultHeaders.headers,
      Authorization: `Bearer ${token}`,
    },
  };
};
