import { Moment } from "moment";
import React, { useEffect, useState } from "react";
import ReactDatetime from "react-datetime";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { Badge, Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, Row, Spinner, Table } from "reactstrap";

import { actions as clientActions } from "clients/redux/client-actions";
import { RootState } from "redux/root-reducer";
import BearsightTextInput from "toolkit/components/BearsightTextInput";
import { getActive, toEpoch } from "toolkit/clock";
import FormHeader from "toolkit/components/FormHeader";
import IconButton from "toolkit/components/IconButton";
import SpinningButton from "toolkit/components/SpinningButton";
import GoogleAdsAttribution from "clients/google-ads/GoogleAdsAttribution";
import { useStateReducer } from "toolkit/state-reducer";
import BingAdsAttribution from "clients/bing-ads/BingAdsAttribution";
import EditBudget from "clients/budgets/EditBudget";
import BearsightPagination from "toolkit/components/BearsightPagination";
import ClientNumberAllocation from "clients/phonenumbers/ClientNumberAllocation";
import { ClientBingAds, ClientBudget, ClientGoogleAds, ClientPhoneNumber, ClientPricingGrid, ClientTerritory, GoogleAdsAccount, PhoneNumber } from "clients/budgets/services/client";
import PricingGridAttribution from "clients/pricing-grid/PricingGridAttribution";
import TerritoriesAttribution from "clients/territories/TerritoriesAttribution";
import logo from "../../assets/img/brand/logo.png";
import ConfirmationPopup from "toolkit/components/ConfirmationPopup";

enum Status {
	All = "All",
	Active = "Active",
	Inactive = "Inactive"
}
enum ResourceType {
	PHONE_NUMBERS = "PHONE_NUMBERS",
	GOOGLE_ADS = "GOOGLE_ADS",
	BING_ADS = "BING_ADS",
	TWILIO_NUMBERS = "TWILIO_NUMBERS",
	PRICING_GRIDS = "PRICING_GRIDS",
	TERRITORIES = "TERRIRTORIES",
	BUDGETS = "BUDGETS"
}

const STATUSES = [Status.Active, Status.Inactive, Status.All];

const mapDispatch = {
	loadClient: clientActions.loadClient,
	loadAvailableGoogleAdsAccounts: clientActions.loadAvailableGoogleAdsAccounts,
	attributeGoogleAccount: clientActions.attributeGoogleAccount,
	updateGoogleAdsAccounts: clientActions.updateGoogleAdsAccounts,
	loadAvailableBingAdsAccounts: clientActions.loadAvailableBingAdsAccounts,
	attributeBingAccount: clientActions.attributeBingAccount,
	updateBingAdsAccounts: clientActions.updateBingAdsAccounts,
	loadClientPhoneNumbers: clientActions.loadClientPhoneNumbers,
	loadAvailablePhoneNumbers: clientActions.loadAvailablePhoneNumbers,
	updatePhoneNumber: clientActions.updatePhoneNumber,
	allocatePhoneNumber: clientActions.allocatePhoneNumber,
	updateBudget: clientActions.updateBudget,
	assignClientToPricingGrid: clientActions.assignClientToPricingGrid,
	updateClientTerritory: clientActions.updateClientTerritory,
	loadPricingGrids: clientActions.loadPricingGrids,
	loadTerritories: clientActions.loadTerritories,
	assignTerritoryToClient: clientActions.assignTerritoryToClient,
	deleteClientPhoneNumber: clientActions.deleteClientPhoneNumber,
	deleteClientGoogleAdsAccount: clientActions.deleteClientGoogleAdsAccount,
	deleteClientBingAdsAccount: clientActions.deleteClientBingAdsAccount,
	deleteClientPricingGrid: clientActions.deleteClientPricingGrid,
	deleteClientTerritory: clientActions.deleteClientTerritory,
	deleteClientBudget: clientActions.deleteClientBudget
};

const mapState = (state: RootState) => ({
	loading: state.clients.loading,
	client: state.clients.client,
	availableGoogleAdsAccounts: state.clients.googleAdsAccounts,
	availableBingAdsAccounts: state.clients.bingAdsAccounts,
	clientPhoneNumbers: state.clients.clientPhoneNumbers,
	availablePhoneNumbers: state.clients.availablePhoneNumbers,
	pricingGrids: state.clients.pricingGrid,
	territories: state.clients.territories
});

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

interface State {
	phoneNumbersCurrentPage: number;
	phoneNumbersTotalPages: number;
	phoneNumbersPageSize: number;
	editedPhoneNumbers: { [key: string]: { loading: boolean; data: ClientPhoneNumber } };

	phoneNumberFilterQuery?: string;
	phoneNumberFilterStatus: Status;

	googleAdsFilterQuery?: string;
	googleAdsFilterStatus: Status;
	editedGoogleAds: { [key: string]: { loading: boolean; data: ClientGoogleAds } };

	bingAdsFilterQuery?: string;
	bingAdsFilterStatus: Status;
	editedBingAds: { [key: string]: { loading: boolean; data: ClientBingAds } };

	twilioFilterQuery?: string;
	twilioFilterStatus: Status;

	pricingGridFilterQuery?: string;
	pricingGridFilterStatus: Status;
	editedPricingGrids: { [key: string]: { loading: boolean; data: ClientPricingGrid } };

	territoryFilterQuery?: string;
	territoryFilterStatus: Status;
	editedTerritories: { [key: string]: { loading: boolean; data: ClientTerritory } };

	budgetFilterQuery?: string;
	budgetFilterStatus: Status;
	editedBudgets: { [key: string]: { loading: boolean; data: ClientBudget } };

	allocateOpened: boolean;
	confirmationOpened: boolean;
	selectedResourceForDeletion?: { type: ResourceType; id: string };
	activeAllocation?: ResourceType;
}

interface RouterParamTypes {
	clientId: string;
}

function ClientManagement({
	loadClient,
	loadAvailableGoogleAdsAccounts,
	attributeGoogleAccount,
	updateGoogleAdsAccounts,
	loadAvailableBingAdsAccounts,
	attributeBingAccount,
	updateBingAdsAccounts,
	loadClientPhoneNumbers,
	updatePhoneNumber,
	allocatePhoneNumber,
	loadAvailablePhoneNumbers,
	updateBudget,
	assignClientToPricingGrid,
	updateClientTerritory,
	loadPricingGrids,
	loadTerritories,
	assignTerritoryToClient,
	deleteClientPhoneNumber,
	deleteClientGoogleAdsAccount,
	deleteClientBingAdsAccount,
	deleteClientPricingGrid,
	deleteClientTerritory,
	deleteClientBudget,
	client,
	loading,
	availableGoogleAdsAccounts,
	availableBingAdsAccounts,
	availablePhoneNumbers,
	clientPhoneNumbers,
	pricingGrids,
	territories
}: Props) {
	const initialState: State = {
		phoneNumbersCurrentPage: 0,
		phoneNumbersTotalPages: 0,
		phoneNumbersPageSize: 5,
		editedPhoneNumbers: {},
		budgetFilterStatus: Status.Active,
		editedBudgets: {},
		googleAdsFilterStatus: Status.Active,
		editedGoogleAds: {},
		twilioFilterStatus: Status.Active,
		bingAdsFilterStatus: Status.Active,
		editedBingAds: {},
		pricingGridFilterStatus: Status.Active,
		editedPricingGrids: {},
		territoryFilterStatus: Status.Active,
		editedTerritories: {},
		phoneNumberFilterStatus: Status.Active,
		allocateOpened: false,
		confirmationOpened: false
	};
	const { clientId } = useParams<RouterParamTypes>();
	const [state, setState] = useStateReducer<State>(initialState);
	const {
		phoneNumbersCurrentPage,
		phoneNumbersTotalPages,
		phoneNumbersPageSize,
		editedPhoneNumbers,
		activeAllocation,
		phoneNumberFilterQuery,
		phoneNumberFilterStatus,
		googleAdsFilterQuery,
		googleAdsFilterStatus,
		editedGoogleAds,
		bingAdsFilterQuery,
		bingAdsFilterStatus,
		editedBingAds,
		territoryFilterQuery,
		territoryFilterStatus,
		editedTerritories,
		pricingGridFilterQuery,
		pricingGridFilterStatus,
		editedPricingGrids,
		budgetFilterQuery,
		budgetFilterStatus,
		editedBudgets,
		allocateOpened,
		confirmationOpened,
		selectedResourceForDeletion
	} = state;

	useEffect(() => {
		loadClient(clientId);
	}, [clientId]);

	useEffect(() => {
		let newEditedPhoneNumbers = { ...editedPhoneNumbers };
		let newEditedGoogleAds = { ...editedGoogleAds };
		let newEditedBingAds = { ...editedBingAds };
		let newEditedBudgets = { ...editedBudgets };
		let newEditePricingGrids = { ...editedPricingGrids };
		let newEditeTerritories = { ...editedTerritories };

		cleanAllSubmitted(newEditedPhoneNumbers);
		cleanAllSubmitted(newEditedGoogleAds);
		cleanAllSubmitted(newEditedBingAds);
		cleanAllSubmitted(newEditedBudgets);
		cleanAllSubmitted(newEditePricingGrids);
		cleanAllSubmitted(newEditeTerritories);

		setState({
			editedGoogleAds: {
				...newEditedGoogleAds
			},
			editedBingAds: {
				...newEditedBingAds
			},
			editedBudgets: {
				...newEditedBudgets
			},
			editedPricingGrids: {
				...newEditePricingGrids
			},
			editedTerritories: {
				...newEditeTerritories
			},
			editedPhoneNumbers: {
				...newEditedPhoneNumbers
			}
		});
		loadClientPhoneNumbers(clientId, phoneNumberFilterStatus, phoneNumberFilterQuery || "", phoneNumbersCurrentPage, phoneNumbersPageSize);
	}, [client]);

	useEffect(() => {
		if (clientPhoneNumbers) {
			setState({
				phoneNumbersTotalPages: clientPhoneNumbers.totalPages
			});
		}
	}, [clientPhoneNumbers]);

	useEffect(() => {
		loadClientPhoneNumbers(clientId, phoneNumberFilterStatus, phoneNumberFilterQuery || "", phoneNumbersCurrentPage, phoneNumbersPageSize);
	}, [clientId, phoneNumberFilterStatus, phoneNumberFilterQuery, phoneNumbersCurrentPage, phoneNumbersPageSize]);

	const filterRecord = (attribute: string, field: string, status: Status, query?: string) => (record: any) => {
		if (record[attribute] != null) {
			let value = record[attribute][field]?.toString();
			let isQueryMatched = query == null || query == "" || value.toLowerCase().indexOf(query.toLowerCase()) > -1;
			if (status === Status.All) {
				return isQueryMatched;
			}
			let deactivationDate = typeof record.deactivationDate === "string" ? toEpoch(record.deactivationDate) : record.deactivationDate;
			if (status === Status.Active) {
				return isQueryMatched && (deactivationDate == null || deactivationDate >= new Date().getTime());
			}
			if (status === Status.Inactive) {
				return isQueryMatched && deactivationDate != null && deactivationDate < new Date().getTime();
			}
		}

		return false;
	};

	const cleanAllSubmitted = (records: any) => {
		for (let key in records) {
			if (records[key].loading) {
				delete records[key];
			}
		}
	};

	const handleActivationDateChange = (type: ResourceType, record: any, value: Moment | string) => {
		switch (type) {
			case ResourceType.PHONE_NUMBERS:
				let editedPhoneNumber = editedPhoneNumbers[record.associationId];
				if (editedPhoneNumber == null) {
					editedPhoneNumber = {
						loading: false,
						data: {
							...record
						}
					};
				}
				if (typeof value !== "string") {
					const date = parseInt(value.valueOf().toString());
					editedPhoneNumber.data.activationDate = date;
					setState({
						editedPhoneNumbers: {
							...editedPhoneNumbers,
							[record.associationId]: editedPhoneNumber
						}
					});
				}
				break;
			case ResourceType.GOOGLE_ADS:
				let editedGoogleAd = editedGoogleAds[record.associationId];
				if (editedGoogleAd == null) {
					editedGoogleAd = {
						loading: false,
						data: {
							...record
						}
					};
				}
				if (typeof value !== "string") {
					const date = parseInt(value.valueOf().toString());
					editedGoogleAd.data.activationDate = date;
					setState({
						editedGoogleAds: {
							...editedGoogleAds,
							[record.associationId]: editedGoogleAd
						}
					});
				}
				break;
			case ResourceType.BING_ADS:
				let editedBingAd = editedBingAds[record.associationId];
				if (editedBingAd == null) {
					editedBingAd = {
						loading: false,
						data: {
							...record
						}
					};
				}
				if (typeof value !== "string") {
					const date = parseInt(value.valueOf().toString());
					editedBingAd.data.activationDate = date;
					setState({
						editedBingAds: {
							...editedBingAds,
							[record.associationId]: editedBingAd
						}
					});
				}
				break;
			case ResourceType.BUDGETS:
				let editedBudget = editedBudgets[record.associationId];
				if (editedBudget == null) {
					editedBudget = {
						loading: false,
						data: {
							...record
						}
					};
				}
				if (typeof value !== "string") {
					const date = parseInt(value.valueOf().toString());
					editedBudget.data.activationDate = date;
					setState({
						editedBudgets: {
							...editedBudgets,
							[record.associationId]: editedBudget
						}
					});
				}
				break;
			case ResourceType.PRICING_GRIDS:
				let editedPricingGrid = editedPricingGrids[record.associationId];
				if (editedPricingGrid == null) {
					editedPricingGrid = {
						loading: false,
						data: {
							...record
						}
					};
				}
				if (typeof value !== "string") {
					const date = parseInt(value.valueOf().toString());
					editedPricingGrid.data.activationDate = date;
					setState({
						editedPricingGrids: {
							...editedPricingGrids,
							[record.associationId]: editedPricingGrid
						}
					});
				}
				break;
			case ResourceType.TERRITORIES:
				let editedTerritory = editedTerritories[record.associationId];
				if (editedTerritory == null) {
					editedTerritory = {
						loading: false,
						data: {
							...record
						}
					};
				}
				if (typeof value !== "string") {
					const date = parseInt(value.valueOf().toString());
					editedTerritory.data.activationDate = date;
					setState({
						editedTerritories: {
							...editedTerritories,
							[record.associationId]: editedTerritory
						}
					});
				}
				break;
		}
	};

	const handleDeactivationDateChange = (type: ResourceType, record: any, value: Moment | string) => {
		switch (type) {
			case ResourceType.PHONE_NUMBERS:
				let editedPhoneNumber = editedPhoneNumbers[record.associationId];
				if (editedPhoneNumber == null) {
					editedPhoneNumber = {
						loading: false,
						data: {
							...record
						}
					};
				}
				if (typeof value !== "string") {
					const date = parseInt(value.valueOf().toString());
					editedPhoneNumber.data.deactivationDate = date;
				} else {
					editedPhoneNumber.data.deactivationDate = 0;
				}
				setState({
					editedPhoneNumbers: {
						...editedPhoneNumbers,
						[record.associationId]: editedPhoneNumber
					}
				});

				break;
			case ResourceType.GOOGLE_ADS:
				let editedGoogleAd = editedGoogleAds[record.associationId];
				if (editedGoogleAd == null) {
					editedGoogleAd = {
						loading: false,
						data: {
							...record
						}
					};
				}

				if (typeof value !== "string") {
					const date = parseInt(value.valueOf().toString());
					editedGoogleAd.data.deactivationDate = date;
				} else {
					editedGoogleAd.data.deactivationDate = 0;
				}
				setState({
					editedGoogleAds: {
						...editedGoogleAds,
						[record.associationId]: editedGoogleAd
					}
				});

				break;
			case ResourceType.BING_ADS:
				let editedBingAd = editedBingAds[record.associationId];
				if (editedBingAd == null) {
					editedBingAd = {
						loading: false,
						data: {
							...record
						}
					};
				}

				if (typeof value !== "string") {
					const date = parseInt(value.valueOf().toString());
					editedBingAd.data.deactivationDate = date;
				} else {
					editedBingAd.data.deactivationDate = 0;
				}

				setState({
					editedBingAds: {
						...editedBingAds,
						[record.associationId]: editedBingAd
					}
				});
				break;
			case ResourceType.BUDGETS:
				let editedBudget = editedBudgets[record.associationId];
				if (editedBudget == null) {
					editedBudget = {
						loading: false,
						data: {
							...record
						}
					};
				}

				if (typeof value !== "string") {
					const date = parseInt(value.valueOf().toString());
					editedBudget.data.deactivationDate = date;
				} else {
					editedBudget.data.deactivationDate = 0;
				}

				setState({
					editedBudgets: {
						...editedBudgets,
						[record.associationId]: editedBudget
					}
				});
				break;
			case ResourceType.PRICING_GRIDS:
				let editedPricingGrid = editedPricingGrids[record.associationId];
				if (editedPricingGrid == null) {
					editedPricingGrid = {
						loading: false,
						data: {
							...record
						}
					};
				}

				if (typeof value !== "string") {
					const date = parseInt(value.valueOf().toString());
					editedPricingGrid.data.deactivationDate = date;
				} else {
					editedPricingGrid.data.deactivationDate = 0;
				}

				setState({
					editedPricingGrids: {
						...editedPricingGrids,
						[record.associationId]: editedPricingGrid
					}
				});
				break;
			case ResourceType.TERRITORIES:
				let editedTerritory = editedTerritories[record.associationId];
				if (editedTerritory == null) {
					editedTerritory = {
						loading: false,
						data: {
							...record
						}
					};
				}

				if (typeof value !== "string") {
					const date = parseInt(value.valueOf().toString());
					editedTerritory.data.deactivationDate = date;
				} else {
					editedTerritory.data.deactivationDate = 0;
				}

				setState({
					editedTerritories: {
						...editedTerritories,
						[record.associationId]: editedTerritory
					}
				});
				break;
		}
	};

	const handleAllocate = (type: ResourceType) => {
		setState({
			activeAllocation: type,
			allocateOpened: true
		});
	};

	const handleDelete = (type: ResourceType, id: string) => {
		//
		setState({
			selectedResourceForDeletion: {
				type,
				id
			},
			confirmationOpened: true
		});
	};

	const handleConfirmDeletion = () => {
		let id = selectedResourceForDeletion?.id;
		if (id != null) {
			switch (selectedResourceForDeletion?.type) {
				case ResourceType.PHONE_NUMBERS:
					//Delete Action
					deleteClientPhoneNumber(clientId, id);
					break;
				case ResourceType.GOOGLE_ADS:
					//Delete Action
					deleteClientGoogleAdsAccount(clientId, id);
					break;
				case ResourceType.BING_ADS:
					//Delete Action
					deleteClientBingAdsAccount(clientId, id);
					break;
				case ResourceType.BUDGETS:
					//Delete Action
					deleteClientBudget(clientId, id);
					break;
				case ResourceType.PRICING_GRIDS:
					deleteClientPricingGrid(clientId, id);
					//Delete Action
					break;
				case ResourceType.TERRITORIES:
					deleteClientTerritory(clientId, id);
					//Delete Action
					break;
				default:
					return;
			}
		}
		setState({ selectedResourceForDeletion: undefined, confirmationOpened: false });
	};

	const handleCancelDeletion = () => {
		setState({ selectedResourceForDeletion: undefined, confirmationOpened: false });
	};

	const handleEdit = (type: ResourceType, id: string) => {
		switch (type) {
			case ResourceType.PHONE_NUMBERS:
				let editedPhoneNumber = editedPhoneNumbers[id];
				editedPhoneNumber.loading = true;
				setState({
					editedPhoneNumbers: {
						...editedPhoneNumbers,
						[id]: editedPhoneNumber
					}
				});
				updatePhoneNumber(clientId, editedPhoneNumber.data.associationId, editedPhoneNumber.data.activationDate, editedPhoneNumber.data.deactivationDate);
				break;
			case ResourceType.GOOGLE_ADS:
				let editedGoogleAd = editedGoogleAds[id];
				editedGoogleAd.loading = true;
				setState({
					editedGoogleAds: {
						...editedGoogleAds,
						[id]: editedGoogleAd
					}
				});
				updateGoogleAdsAccounts(clientId, editedGoogleAd.data.GoogleAdsAccount.customerId, editedGoogleAd.data.activationDate, editedGoogleAd.data.deactivationDate);
				break;
			case ResourceType.BING_ADS:
				let editedBingAd = editedBingAds[id];
				editedBingAd.loading = true;
				setState({
					editedBingAds: {
						...editedBingAds,
						[id]: editedBingAd
					}
				});
				updateBingAdsAccounts(clientId, editedBingAd.data.BingAdsAccount.id, editedBingAd.data.activationDate, editedBingAd.data.deactivationDate);
				break;
			case ResourceType.BUDGETS:
				let editedBudget = editedBudgets[id];
				editedBudget.loading = true;
				setState({
					editedBudgets: {
						...editedBudgets,
						[id]: editedBudget
					}
				});
				updateBudget(clientId, editedBudget.data);
				break;
			case ResourceType.PRICING_GRIDS:
				let editedPricingGrid = editedPricingGrids[id];
				editedPricingGrid.loading = true;
				setState({
					editedPricingGrids: {
						...editedPricingGrids,
						[id]: editedPricingGrid
					}
				});
				assignClientToPricingGrid(clientId, editedPricingGrid.data.pricingGrid, editedPricingGrid.data.activationDate, editedPricingGrid.data.deactivationDate);
				break;
			case ResourceType.TERRITORIES:
				let editedTerritory = editedTerritories[id];
				editedTerritory.loading = true;
				setState({
					editedTerritories: {
						...editedTerritories,
						[id]: editedTerritory
					}
				});
				updateClientTerritory(editedTerritory.data.territory.id, {
					clientId: clientId,
					name: editedTerritory.data.territory.name,
					account: "",
					activationDate: editedTerritory.data.activationDate,
					deactivationDate: editedTerritory.data.deactivationDate || 0
				});
				break;
			default:
				return;
		}
	};

	const toggleAllocate = () => {
		setState({ allocateOpened: !allocateOpened });
	};

	const handleClose = () => {
		setState({ allocateOpened: false });
	};

	const handlePhoneNumberPageSizeChanged = (pageSize: number) => {
		setState({
			phoneNumbersCurrentPage: 0,
			phoneNumbersPageSize: pageSize
		});
	};

	const handlePhoneNumberPageChanged = (page: number) => {
		setState({
			phoneNumbersCurrentPage: page
		});
	};

	return (
		<>
			<FormHeader name="MANAGEMENT" parentName="Client" />
			<Container className="mt--6 d-flex justify-content-center" fluid>
				<Card className="col-lg-11">
					<CardHeader>
						<h3 className="mb-0">CLIENT</h3>
					</CardHeader>
					{loading && (
						<Row className="justify-content-center">
							<Spinner />
						</Row>
					)}
					{!loading && client && (
						<CardBody>
							<Row>
								<Col className="col-md-5">
									<Card>
										<CardBody>
											<Row>
												<Col className="col-auto">
													<div className="icon icon-shape text-white rounded-circle shadow">
														<img style={{ width: "30px", height: "30px" }} src={logo} alt="logo" />
													</div>
												</Col>
												<Col className="col">
													<CardTitle className="h4 font-weight-bold mb-0">{client?.name}</CardTitle>
													<span className="text-uppercase text-muted mb-0">{client?.id}</span>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Col>
								<Col className="col-md-3">
									<Card>
										<CardBody>
											<Row>
												<Col className="col-auto">
													<i className="fas fa-dollar-sign" />
												</Col>
												<Col className="col">
													<CardTitle className="h4 font-weight-bold mb-0">Budget</CardTitle>
													{getActive(client?.budgets) ? <Badge color="success">${getActive(client?.budgets).budget.budget}</Badge> : "N/A"}
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Col>
								<Col className="col-md-4">
									<Card>
										<CardBody>
											<Row>
												<Col className="col-auto">
													<i className="fas fa-hand-holding-usd" />
												</Col>
												<Col className="col">
													<CardTitle className="h4 font-weight-bold mb-0">Pricing Grid</CardTitle>
													<span className="text-muted mb-0">{getActive(client?.pricingGrids || []) ? getActive(client?.pricingGrids || [])?.pricingGrid.description : "N/A"}</span>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</CardBody>
					)}
				</Card>
			</Container>
			<Container className="d-flex justify-content-center" fluid>
				<Card className="col-lg-11">
					<CardBody>
						<Row>
							<Col className="col-md-3" >
								<h3 className="mb-0">Phone numbers</h3>
							</Col>
							<Col className="col-md-4">
								<FormGroup>
									<Input
										type="select"
										name="status"
										id="status"
										onChange={(e: any) => {
											const selectedIds = [...e.target.selectedOptions].map((option: any) => option.value);

											setState({
												phoneNumbersCurrentPage: 0,
												phoneNumberFilterStatus: selectedIds[0]
											});
										}}
									>
										{STATUSES.map(s => (
											<option key={s} defaultValue={phoneNumberFilterStatus} value={s}>
												{s}
											</option>
										))}
									</Input>
								</FormGroup>
							</Col>
							<Col className="col-md-4">
								<BearsightTextInput
									label=""
									icon="fas fa-search"
									text={phoneNumberFilterQuery}
									onChange={value =>
										setState({
											phoneNumberFilterQuery: value
										})
									}
								/>
							</Col>
							<Col className="col-md-1">
								<SpinningButton
									block={false}
									loading={false}
									isRound={true}
									icon="fab fa fa-plus"
									color="default"
									text=""
									onClick={e => {
										e.preventDefault();
										handleAllocate(ResourceType.PHONE_NUMBERS);
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Table className="d-flex flex-column" hover striped>
								<thead className="thead-light d-flex">
									<tr className="row" style={{flex: 1}}>
										<th className="col-md-3" scope="col">Name</th>
										<th className="col-md-4" scope="col">Activation Date</th>
										<th className="col-md-4" scope="col">Deactivation Date</th>
										<th className="col-md-1" scope="col">Actions</th>
									</tr>
								</thead>
								<tbody className="d-flex flex-column">
									{(clientPhoneNumbers?.phoneNumbers?.slice(0, phoneNumbersPageSize) || []).map(phoneNumber => {
										let actualPhoneNumber = editedPhoneNumbers[phoneNumber.associationId] ? editedPhoneNumbers[phoneNumber.associationId].data : phoneNumber;
										return (
											<tr  className="row" style={{flex: 1}} key={actualPhoneNumber.associationId}>
												<td className="col-md-3 overflow">
													<b>{actualPhoneNumber.phoneNumber.phoneNumber}</b>
												</td>
												<td className="col-md-4">
													<FormGroup className="mb-0">
														<InputGroup className="input-group-alternative">
															<InputGroupAddon addonType="prepend">
																<InputGroupText>
																	<i className="ni ni-calendar-grid-58" />
																</InputGroupText>
															</InputGroupAddon>
															<ReactDatetime
																defaultValue={new Date(actualPhoneNumber.activationDate)}
																inputProps={{
																	placeholder: "Activation date"
																}}
																timeFormat={false}
																onChange={(e: Moment | string) => {
																	handleActivationDateChange(ResourceType.PHONE_NUMBERS, actualPhoneNumber, e);
																}}
															/>
														</InputGroup>
													</FormGroup>
												</td>
												<td className="col-md-4">
													<FormGroup className="mb-0">
														<InputGroup className="input-group-alternative">
															<InputGroupAddon addonType="prepend">
																<InputGroupText>
																	<i className="ni ni-calendar-grid-58" />
																</InputGroupText>
															</InputGroupAddon>
															<ReactDatetime
																defaultValue={actualPhoneNumber.deactivationDate && actualPhoneNumber.deactivationDate !== 0 ? new Date(actualPhoneNumber.deactivationDate) : undefined}
																inputProps={{
																	placeholder: "Deactivation date"
																}}
																timeFormat={false}
																onChange={(e: Moment | string) => {
																	handleDeactivationDateChange(ResourceType.PHONE_NUMBERS, actualPhoneNumber, e);
																}}
															/>
														</InputGroup>
													</FormGroup>
												</td>
												<td className="col-md-1">
													<span className="mr-2">
														<IconButton
															disabled={editedPhoneNumbers[actualPhoneNumber.associationId] == null}
															loading={editedPhoneNumbers[actualPhoneNumber.associationId]?.loading}
															id={actualPhoneNumber.associationId}
															color="default"
															icon="fa fa-save"
															onClick={() => handleEdit(ResourceType.PHONE_NUMBERS, phoneNumber.associationId)}
															tooltip="Edit Phone Number"
														/>
													</span>
													<span className="mr-2">
														<IconButton
															id={actualPhoneNumber.associationId}
															loading={false}
															color="danger"
															icon="fa fa-trash"
															onClick={() => handleDelete(ResourceType.PHONE_NUMBERS, actualPhoneNumber.associationId)}
															tooltip="Delete"
														/>
													</span>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</Row>
						<hr />
						<BearsightPagination currentPage={phoneNumbersCurrentPage} totalPages={phoneNumbersTotalPages} pageSize={phoneNumbersPageSize} onPageSizeChanged={handlePhoneNumberPageSizeChanged} handleSearch={handlePhoneNumberPageChanged} />
					</CardBody>
				</Card>
			</Container>
			<Container className="d-flex justify-content-center" fluid>
				<Card className="col-lg-11">
					<CardBody>
						<Row>
							<Col className="col-md-3">
								<h3 className="mb-0">Google Ads</h3>
							</Col>
							<Col className="col-md-4">
								<FormGroup>
									<Input
										type="select"
										name="status"
										id="status"
										onChange={(e: any) => {
											const selectedIds = [...e.target.selectedOptions].map((option: any) => option.value);

											setState({
												googleAdsFilterStatus: selectedIds[0]
											});
										}}
									>
										{STATUSES.map(s => (
											<option defaultValue={googleAdsFilterStatus} value={s}>
												{s}
											</option>
										))}
									</Input>
								</FormGroup>
							</Col>
							<Col className="col-md-4">
								<BearsightTextInput
									label=""
									icon="fas fa-search"
									text={googleAdsFilterQuery}
									onChange={value =>
										setState({
											googleAdsFilterQuery: value
										})
									}
								/>
							</Col>
							<Col className="col-md-1">
								<SpinningButton
									block={false}
									loading={false}
									isRound={true}
									icon="fab fa fa-plus"
									color="default"
									text=""
									onClick={e => {
										e.preventDefault();
										handleAllocate(ResourceType.GOOGLE_ADS);
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Table className="d-flex flex-column" hover striped>
								
								<thead className="thead-light d-flex">
									<tr className="row" style={{flex: 1}}>
										<th className="col-md-3" scope="col">Account Name</th>
										<th className="col-md-4" scope="col">Activation Date</th>
										<th className="col-md-4" scope="col">Deactivation Date</th>
										<th className="col-md-1" scope="col">Actions</th>
									</tr>
								</thead>
								<tbody className="d-flex flex-column">
									{(client?.googleAdsAccounts || [])
										.filter(account => filterRecord("GoogleAdsAccount", "name", googleAdsFilterStatus, googleAdsFilterQuery)(account))
										.map(account => {
											let googleAdAccount = editedGoogleAds[account.associationId] ? editedGoogleAds[account.associationId].data : account;
											return (
												<tr className="row" style={{flex: 1}} key={googleAdAccount.associationId}>
													<td className="col-md-3 overflow">
														<b>{googleAdAccount.GoogleAdsAccount.name}</b>
													</td>
													<td  className="col-md-4">
														<FormGroup className="mb-0">
															<InputGroup className="input-group-alternative">
																<InputGroupAddon addonType="prepend">
																	<InputGroupText>
																		<i className="ni ni-calendar-grid-58" />
																	</InputGroupText>
																</InputGroupAddon>
																<ReactDatetime
																	defaultValue={new Date(googleAdAccount.activationDate)}
																	inputProps={{
																		placeholder: "Activation date"
																	}}
																	timeFormat={false}
																	onChange={(e: Moment | string) => {
																		handleActivationDateChange(ResourceType.GOOGLE_ADS, googleAdAccount, e);
																	}}
																/>
															</InputGroup>
														</FormGroup>
													</td>
													<td  className="col-md-4">
														<FormGroup className="mb-0">
															<InputGroup className="input-group-alternative">
																<InputGroupAddon addonType="prepend">
																	<InputGroupText>
																		<i className="ni ni-calendar-grid-58" />
																	</InputGroupText>
																</InputGroupAddon>
																<ReactDatetime
																	defaultValue={googleAdAccount.deactivationDate && googleAdAccount.deactivationDate !== 0 ? new Date(googleAdAccount.deactivationDate) : undefined}
																	inputProps={{
																		placeholder: "Deactivation date"
																	}}
																	timeFormat={false}
																	onChange={(e: Moment | string) => {
																		handleDeactivationDateChange(ResourceType.GOOGLE_ADS, googleAdAccount, e);
																	}}
																/>
															</InputGroup>
														</FormGroup>
													</td>
													<td   className="col-md-1">
														<span className="mr-2">
															<IconButton
																id={googleAdAccount.associationId}
																disabled={editedGoogleAds[googleAdAccount.associationId] == null}
																loading={editedGoogleAds[googleAdAccount.associationId]?.loading}
																color="default"
																icon="fa fa-save"
																onClick={() => handleEdit(ResourceType.GOOGLE_ADS, googleAdAccount.associationId)}
																tooltip="Edit Google Account"
															/>
														</span>
														<span className="mr-2">
															<IconButton
																id={googleAdAccount.associationId}
																loading={false}
																color="danger"
																icon="fa fa-trash"
																onClick={() => handleDelete(ResourceType.GOOGLE_ADS, googleAdAccount.associationId)}
																tooltip="Delete"
															/>
														</span>
													</td>
												</tr>
											);
										})}
								</tbody>
							</Table>
						</Row>
					</CardBody>
				</Card>
			</Container>
			<Container className="d-flex justify-content-center" fluid>
				<Card className="col-lg-11">
					<CardBody>
						<Row>
							<Col className="col-md-3">
								<h3 className="mb-0">Bing Ads</h3>
							</Col>
							<Col className="col-md-4">
								<FormGroup>
									<Input
										type="select"
										name="status"
										id="status"
										onChange={(e: any) => {
											const selectedIds = [...e.target.selectedOptions].map((option: any) => option.value);

											setState({
												bingAdsFilterStatus: selectedIds[0]
											});
										}}
									>
										{STATUSES.map(s => (
											<option defaultValue={bingAdsFilterStatus} value={s}>
												{s}
											</option>
										))}
									</Input>
								</FormGroup>
							</Col>
							<Col className="col-md-4">
								<BearsightTextInput
									label=""
									icon="fas fa-search"
									text={budgetFilterQuery}
									onChange={value =>
										setState({
											bingAdsFilterQuery: value
										})
									}
								/>
							</Col>
							<Col className="col-md-1">
								<SpinningButton
									block={false}
									loading={false}
									isRound={true}
									icon="fab fa fa-plus"
									color="default"
									text=""
									onClick={e => {
										e.preventDefault();
										handleAllocate(ResourceType.BING_ADS);
									}}
								/>
							</Col>
						</Row>
						<Row>
						<Table className="d-flex flex-column" hover striped>
								<thead className="thead-light d-flex">
									<tr className="row" style={{flex: 1}}>
										<th className="col-md-3" scope="col">Account Name</th>
										<th className="col-md-4" scope="col">Activation Date</th>
										<th className="col-md-4" scope="col">Deactivation Date</th>
										<th className="col-md-1" scope="col">Actions</th>
									</tr>
								</thead>
								<tbody className="d-flex flex-column">
									{(client?.bingAdsAccounts || [])
										.filter(account => filterRecord("BingAdsAccount", "name", bingAdsFilterStatus, bingAdsFilterQuery)(account))
										.map(account => {
											let bingAdAccount = editedBingAds[account.associationId] ? editedBingAds[account.associationId].data : account;
											return (
												<tr className="row" style={{flex: 1}}  key={bingAdAccount.associationId}>
													<td className="col-md-3 overflow">
														<b>{bingAdAccount.BingAdsAccount.name}</b>
													</td>
													<td className="col-md-4">
														<FormGroup className="mb-0">
															<InputGroup className="input-group-alternative">
																<InputGroupAddon addonType="prepend">
																	<InputGroupText>
																		<i className="ni ni-calendar-grid-58" />
																	</InputGroupText>
																</InputGroupAddon>
																<ReactDatetime
																	defaultValue={new Date(bingAdAccount.activationDate)}
																	inputProps={{
																		placeholder: "Activation date"
																	}}
																	timeFormat={false}
																	onChange={(e: Moment | string) => {
																		handleActivationDateChange(ResourceType.BING_ADS, bingAdAccount, e);
																	}}
																/>
															</InputGroup>
														</FormGroup>
													</td>
													<td className="col-md-4">
														<FormGroup className="mb-0">
															<InputGroup className="input-group-alternative">
																<InputGroupAddon addonType="prepend">
																	<InputGroupText>
																		<i className="ni ni-calendar-grid-58" />
																	</InputGroupText>
																</InputGroupAddon>
																<ReactDatetime
																	defaultValue={bingAdAccount.deactivationDate && bingAdAccount.deactivationDate !== 0 ? new Date(bingAdAccount.deactivationDate) : undefined}
																	inputProps={{
																		placeholder: "Deactivation date"
																	}}
																	timeFormat={false}
																	onChange={(e: Moment | string) => {
																		handleDeactivationDateChange(ResourceType.BING_ADS, bingAdAccount, e);
																	}}
																/>
															</InputGroup>
														</FormGroup>
													</td>
													<td className="col-md-1">
														<span className="mr-2">
															<IconButton
																loading={editedBingAds[bingAdAccount.associationId]?.loading}
																disabled={editedBingAds[bingAdAccount.associationId] == null}
																id={bingAdAccount.associationId}
																color="default"
																icon="fa fa-save"
																onClick={() => handleEdit(ResourceType.BING_ADS, bingAdAccount.associationId)}
																tooltip="Edit Bing Account"
															/>
														</span>
														<span className="mr-2">
															<IconButton
																id={bingAdAccount.associationId}
																loading={false}
																color="danger"
																icon="fa fa-trash"
																onClick={() => handleDelete(ResourceType.BING_ADS, bingAdAccount.associationId)}
																tooltip="Delete"
															/>
														</span>
													</td>
												</tr>
											);
										})}
								</tbody>
							</Table>
						</Row>
					</CardBody>
				</Card>
			</Container>
			<Container className="d-flex justify-content-center" fluid>
				<Card className="col-lg-11">
					<CardBody>
						<Row>
							<Col className="col-md-3">
								<h3 className="mb-0">Pricing Grid</h3>
							</Col>
							<Col className="col-md-4">
								<FormGroup>
									<Input
										type="select"
										name="status"
										id="status"
										onChange={(e: any) => {
											const selectedIds = [...e.target.selectedOptions].map((option: any) => option.value);

											setState({
												pricingGridFilterStatus: selectedIds[0]
											});
										}}
									>
										{STATUSES.map(s => (
											<option defaultValue={pricingGridFilterStatus} value={s}>
												{s}
											</option>
										))}
									</Input>
								</FormGroup>
							</Col>
							<Col className="col-md-4">
								<BearsightTextInput
									label=""
									icon="fas fa-search"
									text={pricingGridFilterQuery}
									onChange={value =>
										setState({
											pricingGridFilterQuery: value
										})
									}
								/>
							</Col>
							<Col className="col-md-1">
								<SpinningButton
									block={false}
									loading={false}
									isRound={true}
									icon="fab fa fa-plus"
									color="default"
									text=""
									onClick={e => {
										e.preventDefault();

										handleAllocate(ResourceType.PRICING_GRIDS);
									}}
								/>
							</Col>
						</Row>
						<Row>
						<Table className="d-flex flex-column" hover striped>
								<thead className="thead-light d-flex">
									<tr className="row" style={{flex: 1}}>
										<th className="col-md-3" scope="col">Name</th>
										<th className="col-md-4" scope="col">Activation Date</th>
										<th className="col-md-4" scope="col">Deactivation Date</th>
										<th className="col-md-1" scope="col">Actions</th>
									</tr>
								</thead>
								<tbody className="d-flex flex-column">
									{(client?.pricingGrids || [])
										.filter(pricingGrid => filterRecord("pricingGrid", "description", pricingGridFilterStatus, pricingGridFilterQuery)(pricingGrid))
										.map(pricingGrid => {
											let actualPricingGrid = editedPricingGrids[pricingGrid.associationId] ? editedPricingGrids[pricingGrid.associationId].data : pricingGrid;
											return (
												<tr className="row" style={{flex: 1}} key={actualPricingGrid.associationId}>
													<td className="col-md-3 overflow">
														<b>{actualPricingGrid.pricingGrid.description}</b>
													</td>
													<td className="col-md-4">
														<FormGroup className="mb-0">
															<InputGroup className="input-group-alternative">
																<InputGroupAddon addonType="prepend">
																	<InputGroupText>
																		<i className="ni ni-calendar-grid-58" />
																	</InputGroupText>
																</InputGroupAddon>
																<ReactDatetime
																	defaultValue={actualPricingGrid.activationDate ? new Date(actualPricingGrid.activationDate) : undefined}
																	inputProps={{
																		placeholder: "Activation date"
																	}}
																	timeFormat={false}
																	onChange={(e: Moment | string) => {
																		handleActivationDateChange(ResourceType.PRICING_GRIDS, actualPricingGrid, e);
																	}}
																/>
															</InputGroup>
														</FormGroup>
													</td>
													<td className="col-md-4">
														<FormGroup className="mb-0">
															<InputGroup className="input-group-alternative">
																<InputGroupAddon addonType="prepend">
																	<InputGroupText>
																		<i className="ni ni-calendar-grid-58" />
																	</InputGroupText>
																</InputGroupAddon>
																<ReactDatetime
																	defaultValue={actualPricingGrid.deactivationDate && actualPricingGrid.deactivationDate !== 0 ? new Date(actualPricingGrid.deactivationDate) : undefined}
																	inputProps={{
																		placeholder: "Deactivation date"
																	}}
																	timeFormat={false}
																	onChange={(e: Moment | string) => {
																		handleDeactivationDateChange(ResourceType.PRICING_GRIDS, actualPricingGrid, e);
																	}}
																/>
															</InputGroup>
														</FormGroup>
													</td>
													<td className="col-md-1">
														<span className="mr-2">
															<IconButton
																loading={editedPricingGrids[actualPricingGrid.associationId]?.loading}
																disabled={editedPricingGrids[actualPricingGrid.associationId] == null}
																id={actualPricingGrid.associationId}
																color="default"
																icon="fa fa-save"
																onClick={() => handleEdit(ResourceType.PRICING_GRIDS, actualPricingGrid.associationId)}
																tooltip="Edit Pricing Grid"
															/>
														</span>
														<span className="mr-2">
															<IconButton
																id={actualPricingGrid.associationId}
																loading={false}
																color="danger"
																icon="fa fa-trash"
																onClick={() => handleDelete(ResourceType.PRICING_GRIDS, actualPricingGrid.associationId)}
																tooltip="Delete"
															/>
														</span>
													</td>
												</tr>
											);
										})}
								</tbody>
							</Table>
						</Row>
					</CardBody>
				</Card>
			</Container>
			<Container className="d-flex justify-content-center" fluid>
				<Card className="col-lg-11">
					<CardBody>
						<Row>
							<Col className="col-md-3">
								<h3 className="mb-0">Territory</h3>
							</Col>
							<Col className="col-md-4">
								<FormGroup>
									<Input
										type="select"
										name="status"
										id="status"
										onChange={(e: any) => {
											const selectedIds = [...e.target.selectedOptions].map((option: any) => option.value);

											setState({
												territoryFilterStatus: selectedIds[0]
											});
										}}
									>
										{STATUSES.map(s => (
											<option defaultValue={territoryFilterStatus} value={s}>
												{s}
											</option>
										))}
									</Input>
								</FormGroup>
							</Col>
							<Col className="col-md-4">
								<BearsightTextInput
									label=""
									icon="fas fa-search"
									text={territoryFilterQuery}
									onChange={value =>
										setState({
											territoryFilterQuery: value
										})
									}
								/>
							</Col>
							<Col className="col-md-1">
								<SpinningButton
									block={false}
									loading={false}
									isRound={true}
									icon="fab fa fa-plus"
									color="default"
									text=""
									onClick={e => {
										e.preventDefault();
										handleAllocate(ResourceType.TERRITORIES);
									}}
								/>
							</Col>
						</Row>
						<Row>
						<Table className="d-flex flex-column" hover striped>
								<thead className="thead-light d-flex">
									<tr className="row" style={{flex: 1}}>
										<th className="col-md-3" scope="col">Name</th>
										<th className="col-md-4" scope="col">Activation Date</th>
										<th className="col-md-4" scope="col">Deactivation Date</th>
										<th className="col-md-1" scope="col">Actions</th>
									</tr>
								</thead>
								<tbody className="d-flex flex-column">
									{(client?.territories || [])
										.filter(territory => filterRecord("territory", "name", territoryFilterStatus, territoryFilterQuery)(territory))
										.map(territory => {
											let actualTerritory = editedTerritories[territory.associationId] ? editedTerritories[territory.associationId].data : territory;
											return (
												<tr className="row" style={{flex: 1}} key={actualTerritory.associationId}>
													<td className="col-md-3 overflow">
														<b>{actualTerritory.territory.name}</b>
													</td>
													<td className="col-md-4">
														<FormGroup className="mb-0">
															<InputGroup className="input-group-alternative">
																<InputGroupAddon addonType="prepend">
																	<InputGroupText>
																		<i className="ni ni-calendar-grid-58" />
																	</InputGroupText>
																</InputGroupAddon>
																<ReactDatetime
																	defaultValue={actualTerritory.activationDate ? new Date(actualTerritory.activationDate) : undefined}
																	inputProps={{
																		placeholder: "Activation date"
																	}}
																	timeFormat={false}
																	onChange={(e: Moment | string) => {
																		handleActivationDateChange(ResourceType.TERRITORIES, actualTerritory, e);
																	}}
																/>
															</InputGroup>
														</FormGroup>
													</td>
													<td className="col-md-4">
														<FormGroup className="mb-0">
															<InputGroup className="input-group-alternative">
																<InputGroupAddon addonType="prepend">
																	<InputGroupText>
																		<i className="ni ni-calendar-grid-58" />
																	</InputGroupText>
																</InputGroupAddon>
																<ReactDatetime
																	defaultValue={actualTerritory.deactivationDate && actualTerritory.deactivationDate !== 0 ? new Date(actualTerritory.deactivationDate) : undefined}
																	inputProps={{
																		placeholder: "Deactivation date"
																	}}
																	timeFormat={false}
																	onChange={(e: Moment | string) => {
																		handleDeactivationDateChange(ResourceType.TERRITORIES, actualTerritory, e);
																	}}
																/>
															</InputGroup>
														</FormGroup>
													</td>
													<td className="col-md-1">
														<span className="mr-2">
															<IconButton
																loading={editedTerritories[actualTerritory.associationId]?.loading}
																disabled={editedTerritories[actualTerritory.associationId] == null}
																id={territory.associationId}
																color="default"
																icon="fa fa-save"
																onClick={() => handleEdit(ResourceType.TERRITORIES, territory.associationId)}
																tooltip="Edit Territory"
															/>
														</span>
														<span className="mr-2">
															<IconButton
																id={actualTerritory.associationId}
																loading={false}
																color="danger"
																icon="fa fa-trash"
																onClick={() => handleDelete(ResourceType.TERRITORIES, actualTerritory.associationId)}
																tooltip="Delete"
															/>
														</span>
													</td>
												</tr>
											);
										})}
								</tbody>
							</Table>
						</Row>
					</CardBody>
				</Card>
			</Container>
			<Container className="d-flex justify-content-center" fluid>
				<Card className="col-lg-11">
					<CardBody>
						<Row>
							<Col className="col-md-3">
								<h3 className="mb-0">Budget</h3>
							</Col>
							<Col className="col-md-4">
								<FormGroup>
									<Input
										type="select"
										name="status"
										id="status"
										onChange={(e: any) => {
											const selectedIds = [...e.target.selectedOptions].map((option: any) => option.value);

											setState({
												budgetFilterStatus: selectedIds[0]
											});
										}}
									>
										{STATUSES.map(s => (
											<option defaultValue={budgetFilterStatus} value={s}>
												{s}
											</option>
										))}
									</Input>
								</FormGroup>
							</Col>
							<Col className="col-md-4">
								<BearsightTextInput
									label=""
									icon="fas fa-search"
									text={budgetFilterQuery}
									onChange={value =>
										setState({
											budgetFilterQuery: value
										})
									}
								/>
							</Col>
							<Col className="col-md-1">
								<SpinningButton
									block={false}
									loading={false}
									isRound={true}
									icon="fab fa fa-plus"
									color="default"
									text=""
									onClick={e => {
										e.preventDefault();
										handleAllocate(ResourceType.BUDGETS);
									}}
								/>
							</Col>
						</Row>
						<Row>
						<Table className="d-flex flex-column" hover striped>
								<thead className="thead-light d-flex">
									<tr className="row" style={{flex: 1}}>
										<th className="col-md-3" scope="col">Budget</th>
										<th className="col-md-4" scope="col">Activation Date</th>
										<th className="col-md-4" scope="col">Deactivation Date</th>
										<th className="col-md-1" scope="col">Actions</th>
									</tr>
								</thead>
								<tbody className="d-flex flex-column">
									{client?.budgets
										.filter(budget => filterRecord("budget", "budget", budgetFilterStatus, budgetFilterQuery)(budget))
										.map(budget => {
											let actualBudget = editedBudgets[budget.associationId] ? editedBudgets[budget.associationId].data : budget;
											return (
												<tr  className="row" style={{flex: 1}} key={actualBudget.associationId}>
													<td className="col-md-3 overflow">
														<b>${actualBudget.budget.budget}</b>
													</td>
													<td className="col-md-4">
														<FormGroup className="mb-0">
															<InputGroup className="input-group-alternative">
																<InputGroupAddon addonType="prepend">
																	<InputGroupText>
																		<i className="ni ni-calendar-grid-58" />
																	</InputGroupText>
																</InputGroupAddon>
																<ReactDatetime
																	defaultValue={new Date(actualBudget.activationDate)}
																	inputProps={{
																		placeholder: "Activation date"
																	}}
																	timeFormat={false}
																	onChange={(e: Moment | string) => {
																		handleActivationDateChange(ResourceType.BUDGETS, actualBudget, e);
																	}}
																/>
															</InputGroup>
														</FormGroup>
													</td>
													<td className="col-md-4">
														<FormGroup className="mb-0">
															<InputGroup className="input-group-alternative">
																<InputGroupAddon addonType="prepend">
																	<InputGroupText>
																		<i className="ni ni-calendar-grid-58" />
																	</InputGroupText>
																</InputGroupAddon>
																<ReactDatetime
																	defaultValue={actualBudget.deactivationDate && actualBudget.deactivationDate !== 0 ? new Date(actualBudget.deactivationDate) : undefined}
																	inputProps={{
																		placeholder: "Deactivation date"
																	}}
																	timeFormat={false}
																	onChange={(e: Moment | string) => {
																		handleDeactivationDateChange(ResourceType.BUDGETS, actualBudget, e);
																	}}
																/>
															</InputGroup>
														</FormGroup>
													</td>
													<td className="col-md-1">
														<span className="mr-2">
															<IconButton
																loading={editedBudgets[actualBudget.associationId]?.loading}
																disabled={editedBudgets[actualBudget.associationId] == null}
																id={actualBudget.associationId}
																color="default"
																icon="fa fa-save"
																onClick={() => handleEdit(ResourceType.BUDGETS, actualBudget.associationId)}
																tooltip="Edit Budget"
															/>
														</span>
														<span className="mr-2">
															<IconButton
																id={actualBudget.associationId}
																loading={false}
																color="danger"
																icon="fa fa-trash"
																onClick={() => handleDelete(ResourceType.BUDGETS, actualBudget.associationId)}
																tooltip="Delete"
															/>
														</span>
													</td>
												</tr>
											);
										})}
								</tbody>
							</Table>
						</Row>
					</CardBody>
				</Card>
			</Container>
			<Modal className="modal-dialog-centered" isOpen={allocateOpened} size="xl" toggle={toggleAllocate}>
				{activeAllocation === ResourceType.PHONE_NUMBERS && (
					<ClientNumberAllocation
						onAllocatePhoneNumber={allocatePhoneNumber}
						loading={loading}
						client={client}
						loadClientPhoneNumbers={loadClientPhoneNumbers}
						clientPhoneNumbers={clientPhoneNumbers}
						availablePhoneNumbers={availablePhoneNumbers}
						loadAvailablePhoneNumbers={loadAvailablePhoneNumbers}
						onClose={handleClose}
						onUpdatePhoneNumber={updatePhoneNumber}
						from="CLIENT_MANAGEMENT"
					/>
				)}
				{activeAllocation === ResourceType.GOOGLE_ADS && (
					<GoogleAdsAttribution
						loading={loading}
						client={client}
						onClose={handleClose}
						loadClientGoogleAdsAccounts={loadAvailableGoogleAdsAccounts}
						onAttributeGoogleAdsAccount={attributeGoogleAccount}
						onUpdateGoogleAdsAccount={updateGoogleAdsAccounts}
						availableGoogleAdsAccounts={availableGoogleAdsAccounts}
						from="CLIENT_MANAGEMENT"
					/>
				)}
				{activeAllocation === ResourceType.BING_ADS && (
					<BingAdsAttribution
						loading={loading}
						client={client}
						onClose={handleClose}
						loadClientBingAdsAccounts={loadAvailableBingAdsAccounts}
						onAttributeBingAdsAccount={attributeBingAccount}
						onUpdateBingAdsAccount={updateBingAdsAccounts}
						availableBingAdsAccounts={availableBingAdsAccounts}
						from="CLIENT_MANAGEMENT"
					/>
				)}
				{activeAllocation === ResourceType.PRICING_GRIDS && (
					<PricingGridAttribution loading={loading} client={client} onClose={handleClose} loadPricingGrids={loadPricingGrids} onAttributePricingGrid={assignClientToPricingGrid} pricingGrids={pricingGrids} />
				)}
				{activeAllocation === ResourceType.TERRITORIES && (
					<TerritoriesAttribution loading={loading} client={client} onClose={handleClose} loadTerritories={loadTerritories} onAttributeTerritory={assignTerritoryToClient} territories={territories?.data} />
				)}
				{activeAllocation === ResourceType.BUDGETS && <EditBudget onClose={handleClose} client={client} from="CLIENT_MANAGEMENT" />}
			</Modal>
			<ConfirmationPopup title={"Delete record"} message={"Are you sure you want to delete the record ?"} onConfirm={handleConfirmDeletion} onCancel={handleCancelDeletion} opened={confirmationOpened} />
		</>
	);
}

export default connector(ClientManagement);
