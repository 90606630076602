import da from "assets/vendor/fullcalendar/dist/locale/da";
import pl from "assets/vendor/fullcalendar/dist/locale/pl";
import axios, { AxiosInstance } from "axios";
import { Plan } from "clients/budgets/services/client";
import { withAuthorization } from "toolkit/common-headers";
import { Page } from "toolkit/pagination";

class PlansService {
    private http: AxiosInstance;
    constructor(baseURL: string) {
        this.http = axios.create({
            baseURL
        });
    }

    async plans(
        accessToken: string,
        page: number,
        pageSize: number,
        query?: string
    ): Promise<Page<Plan>> {
        const result = await this.http.get(
            "",
            withAuthorization(accessToken,  {
                page,
                size: pageSize,
                q: query !== undefined && query.length > 0 ? query : undefined
            })
        );

        const data = result.data;
        if(data === undefined){
            return Promise.resolve({
                page:0,
                totalPages:0,
                hasMorePages: false,
                data: []
            });
        }

        return {
            page: data.page,
            totalPages: data.totalPages,
            hasMorePages: data.hasMorePages,
            pageSize,
            data: data.plans.map((plan: any) => {
                return {
                    id: plan.id,
                    name: plan.name
                };
            })
        };
    };
}

const plansService = new PlansService(
    process.env.REACT_APP_PLANS_API || ""
);
export default plansService;