import React, { FunctionComponent } from "react";
import { RootState } from "./redux/root-reducer";
import { connect, ConnectedProps } from "react-redux";
import tokenStorage from "authentication/services/tokens-storage";
import actions from "authentication/redux/authentication-actions";

const mapState = (state: RootState) => ({});

const mapDispatch = {
  initialize: actions.initialize,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const RootComponent: FunctionComponent<Props> = ({ children, initialize }) => {
  const tokens = tokenStorage.find();

  if (tokens) {
    initialize(tokens);
  }

  return <>{children}</>;
};

export default connector(RootComponent);
