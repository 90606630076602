import RecordingsTagging from "calls/recordings/RecordingsTagging";

export interface View {
  path: string;
  name: string;
  component: any;
  layout: string;
}

export interface Route {
  collapse: boolean;
  name: string;
  miniName?: string;
  icon: string;
  state: string;
  views: View[];
}

const publicRoutes: Route[] = [
  {
    collapse: true,
    name: "Calls",
    icon: "fa fa-phone text-default",
    state: "formsCollapse",
    views: [
      {
        path: "/calls/recordings",
        name: "Recordings",
        component: RecordingsTagging,
        layout: "/admin",
      }
    ],
  },
];

export default publicRoutes;
