import tokenStorage from "./tokens-storage";
import { Tokens } from "authentication/redux/tokens";

const EXPIRATION_THRESHOLD = 1;

class TokensService {
  isAuthenticated(): boolean {
    const token = tokenStorage.find();

    return !!token && this.isValid(token);
  }

  private isValid(token: Tokens): boolean {
    const now = new Date().getTime();
    const tokenExpiryDate = new Date(token.expiresAt).getTime();
    const expiredIn = Math.floor((tokenExpiryDate - now) / 1000 / 60);
    return expiredIn > EXPIRATION_THRESHOLD;
  }
}

const tokensService = new TokensService();
export default tokensService;
