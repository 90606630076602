import axios, { AxiosInstance } from "axios";
import { withAuthorization } from "toolkit/common-headers";
import { formatDate, toEpoch } from "toolkit/clock";
import { ClientPhoneNumber, ClientPhoneNumbers, PhoneNumber } from "clients/budgets/services/client";

class PhoneNumbersService {
	private http: AxiosInstance;
	constructor(baseUrl: string) {
		this.http = axios.create({
			baseURL: baseUrl
		});
	}

	async clientNumbers(accessToken: string, clientId: string, status: string,query: string, page: number, size: number): Promise<ClientPhoneNumbers> {
		const result = await this.http.get(
			`/${clientId}/allocated-phone-numbers`,
			withAuthorization(accessToken, {
				status,
				clientId,
				phoneNumber: query,
				page,
				size,
			})
		);

		const data = result.data;

		if (data === undefined) {
			return Promise.resolve({
				page: 0,
				totalPages: 0,
				phoneNumbers: []
			});
		}

		return {
			page: data.page,
			totalPages: data.totalPages,
			phoneNumbers: data.phoneNumbers.map(this.toClientPhoneNumber)
		};
	}

	async availableNumbers(accessToken: string, activationDate: number): Promise<PhoneNumber[]> {
		const result = await this.http.get(
			`/phone-numbers`,
			withAuthorization(accessToken, {
				activationDate: formatDate(activationDate)
			})
		);

		const data = result.data;

		if (data === undefined) {
			return Promise.resolve([]);
		}

		return data.map(this.toPhoneNumber);
	}

	async allocate(accessToken: string, clientId: string, phoneNumberIds: string[], activationDate: number, deactivationDate?: number): Promise<ClientPhoneNumbers> {
		for (var i = 0; i < phoneNumberIds.length; i++) {
			const phoneNumberId = phoneNumberIds[i];
			await this.http.post(
				"/phone-numbers",
				{
					phoneNumberId,
					clientId,
					activationDate: formatDate(activationDate),
					deactivationDate: formatDate(deactivationDate)
				},
				withAuthorization(accessToken)
			);
		}

		return this.clientNumbers(accessToken, clientId, "All", "", 0, 10000);
	}

	async update(accessToken: string, clientId: string, phoneNumberId: string, activationDate: number, deactivationDate?: number): Promise<ClientPhoneNumbers> {
		await this.http.put(
			`/${clientId}/phone-numbers/${phoneNumberId}`,
			{
				activationDate: formatDate(activationDate),
				deactivationDate: deactivationDate ? formatDate(deactivationDate) : undefined
			},
			withAuthorization(accessToken)
		);

		return this.clientNumbers(accessToken, clientId, "All", "",  0, 10000);
	}

	private toPhoneNumber = (p: any): PhoneNumber => {
		return {
			phoneNumber: p.phoneNumber,
			id: p.id,
			formattedPhoneNumber: p.formattedPhoneNumber,
			type: p.type
		};
	};

	private toClientPhoneNumber = (p: any): ClientPhoneNumber => {
		return {
			phoneNumber: p.phoneNumber,
			activationDate: toEpoch(p.activationDate) || 0,
			deactivationDate: toEpoch(p.deactivationDate),
			associationId: p.associationId
		};
	};
}

const phoneNumbersService = new PhoneNumbersService(process.env.REACT_APP_CLIENTS_API || "");
export default phoneNumbersService;
