import axios, { AxiosInstance } from "axios";
import { withAuthorization } from "toolkit/common-headers";
import { PricingGrid, PricingGridDetail } from "./pricing-grid";
import { PricingGrid as PricingGridSummary } from "clients/budgets/services/client";

class PricingGridService {
	private http: AxiosInstance;
	constructor(baseUrl: string) {
		this.http = axios.create({
			baseURL: baseUrl
		});
	}

	async grids(accessToken: string): Promise<PricingGridSummary[]> {
		const result = await this.http.get("", withAuthorization(accessToken));

		const data = result.data;

		if (data === undefined) {
			return Promise.resolve([]);
		}

		const pricingGrids: PricingGridSummary[] = [];
		for (var i = 0; i < data.length; i++) {
			const pricingGrid = data[i];
			pricingGrids.push({
				id: pricingGrid.id,
				dateCreated: pricingGrid.dateCreated,
				dateUpdated: pricingGrid.dateUpdated,
				description: pricingGrid.description,
				details: await this.details(accessToken, pricingGrid.id)
			});
		}

		return pricingGrids;
	}

	private async details(accessToken: string, gridId: string): Promise<PricingGridDetail[]> {
		const result = await this.http.get(`/${gridId}/details`, withAuthorization(accessToken));

		const data = result.data;

		if (data === undefined) {
			return Promise.resolve([]);
		}

		return data.map((details: any) => {
			return {
				id: details.id,
				rangeFrom: details.rangeFrom,
				rangeTo: details.rangeTo,
				fee: details.fee
			};
		});
	}

	async create(accessToken: string, pricingGrid: PricingGrid): Promise<void> {
		await this.http.post(
			"",
			{
				description: pricingGrid.description
			},
			withAuthorization(accessToken)
		);

		return Promise.resolve();
	}

	async updateDetail(accessToken: string, gridId: string, detail: PricingGridDetail): Promise<void> {
		await this.http.put(
			`/${gridId}/details/${detail.id}`,
			{
				rangeFrom: detail.rangeFrom,
				rangeTo: detail.rangeTo,
				fee: detail.fee
			},
			withAuthorization(accessToken)
		);

		return Promise.resolve();
	}

	async addDetail(accessToken: string, gridId: string, detail: PricingGridDetail): Promise<void> {
		await this.http.put(
			`/${gridId}/details`,
			{
				rangeFrom: detail.rangeFrom,
				rangeTo: detail.rangeTo,
				fee: detail.fee
			},
			withAuthorization(accessToken)
		);

		return Promise.resolve();
	}

	async deleteDetail(accessToken: string, gridId: string, detailId: string): Promise<void> {
		await this.http.delete(`/${gridId}/details/${detailId}`, withAuthorization(accessToken));

		return Promise.resolve();
	}
}

const pricingGridService = new PricingGridService(process.env.REACT_APP_PRICING_GRID_API || "");
export default pricingGridService;
