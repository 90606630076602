import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "redux/root-reducer";
import FormHeader from "toolkit/components/FormHeader";

import { Card, CardHeader, CardBody, Container, Row, Spinner, Form, Table, Modal, FormGroup, Input } from "reactstrap";
import { actions as systemActions } from "../../redux/system-actions";
import { actions as clientActions } from "clients/redux/client-actions";
import BearsightTextInput from "toolkit/components/BearsightTextInput";
import IconButton from "toolkit/components/IconButton";
import BearsightPagination from "toolkit/components/BearsightPagination";
import { useStateReducer } from "toolkit/state-reducer";
import { useDebounce } from "use-debounce/lib";
import { Client } from "clients/budgets/services/client";
import EditClient from "./EditClient";
import CreateClient from "./CreateClient";
import { Link } from "react-router-dom";
import { getActive } from "toolkit/clock";

const mapState = (state: RootState) => ({
	clients: state.clients.clients,
	territories: state.clients.territories,
	plans: state.clients.plans,
	page: state.clients.clientsPage,
	pageSize: state.clients.clientsPageSize,
	totalPages: state.clients.clientsTotalPages,
	loading: state.clients.loading
});

const mapDispatch = {
	createClient: clientActions.createClient,
	updateClient: clientActions.updateClient,
	loadClients: clientActions.loadClients,
	loadTerritories: clientActions.loadTerritories,
	loadPlans: clientActions.loadPlans,
	notify: systemActions.notify
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

interface State {
	filteringQuery: string;
	filteringStatus: Status;
	editOpened: boolean;
	createOpened: boolean;
	selectedClient?: Client;
}

enum Status {
	All = "All",
	Active = "Active",
	Inactive = "Inactive",
	Blacklisted = "Blacklisted"
}

const STATUSES = [Status.All, Status.Active, Status.Inactive, Status.Blacklisted];

function ClientsTable({ loading, clients, territories, plans, updateClient, createClient, page, pageSize, totalPages, loadClients, loadTerritories, loadPlans, notify }: Props) {
	const initialState: State = {
		filteringQuery: "",
		filteringStatus: Status.All,
		editOpened: false,
		createOpened: false
	};

	const [state, setState] = useStateReducer<State>(initialState);

	const { filteringQuery, filteringStatus, selectedClient, editOpened, createOpened } = state;

	useEffect(() => {
		loadClients(0, 5, undefined, undefined, filteringStatus);
		loadTerritories(0, 10000000);
		loadPlans(0, 10000000);
	}, []);

	useEffect(() => {
		if (clients) {
			setState({
				selectedClient: selectedClient ? clients.find(c => c.id === selectedClient.id) : undefined
			});
		}
	}, [JSON.stringify(clients)]);

	const toggleEdit = () => {
		setState({ editOpened: !editOpened });
	};

	const toggleCreate = () => {
		setState({ createOpened: !createOpened });
	};

	const closeEdit = () => {
		setState({ editOpened: !editOpened, selectedClient: undefined });
	};

	const closeCreate = () => {
		setState({ createOpened: false, selectedClient: undefined });
	};

	const handlePageSizeChanged = (pageSize: number) => {
		loadClients(page, pageSize, filteringQuery, undefined, filteringStatus);
	};

	const handlePageChanged = (page: number) => {
		loadClients(page, pageSize, filteringQuery, undefined, filteringStatus);
	};

	const handleEdit = (client: Client) => {
		if (client.id.length === 0) {
			createClient(client);
		} else {
			setState({ editOpened: true, selectedClient: client });
		}
	};

	const [debouncedSearchTerm] = useDebounce(filteringQuery, 500);

	useEffect(() => {
		loadClients(page, pageSize, debouncedSearchTerm, undefined, filteringStatus);
	}, [debouncedSearchTerm, filteringStatus]);

	return (
		<>
			<FormHeader name="MANAGEMENT" parentName="Clients" />
			<Container className="mt--6 d-flex justify-content-center" fluid>
				<Card className="col-lg-8">
					<CardHeader>
						<h3 className="mb-0">CLIENTS</h3>
					</CardHeader>
					<CardBody>
						<Form className="needs-validation mb-4" noValidate inline onSubmit={e => e.preventDefault()}>
							<BearsightTextInput label="" icon="fas fa-search" text={filteringQuery} onChange={value => setState({ filteringQuery: value })} />
							<FormGroup className="ml-4">
								<label className="form-control-label mr-4">Status: </label>
								<Input
									type="select"
									name="status"
									id="status"
									onChange={(e: any) => {
										const selectedIds = [...e.target.selectedOptions].map((option: any) => option.value);

										setState({
											filteringStatus: selectedIds[0]
										});
									}}
								>
									{STATUSES.map(s => (
										<option defaultValue={filteringStatus} value={s}>
											{s}
										</option>
									))}
								</Input>
							</FormGroup>
						</Form>
						{loading && (
							<Row className="justify-content-center">
								<Spinner />
							</Row>
						)}
						{!loading && clients.length > 0 && (
							<>
								<div className="d-flex flex-row-reverse">
									<span className="mr-4">
										<IconButton
											icon="fa fa-plus"
											onClick={() => {
												toggleCreate();
											}}
											tooltip="Add new client"
											loading={loading}
										/>
									</span>
								</div>
								<Table className="align-items-center" responsive hover striped>
									<thead className="thead-light">
										<tr>
											{["Id", "Name", "Territory", "Plan", ""].map(name => (
												<th scope="col">{name}</th>
											))}
										</tr>
									</thead>
									<tbody>
										{clients.map(client => {
											return (
												<tr key={client.id}>
													<td>
														<Link to={`/admin/clients/management/${client.id}`}>{client.id}</Link>
													</td>

													<th scope="row">{client.name}</th>

													<td>{getActive(client.territories)?.territory.name || "N/A"}</td>

													<td>{(client.plan && client.plan.name) || "N/A"}</td>

													<td className="text-right">
														<IconButton
															tableAction={true}
															id={client.id}
															color="default"
															icon={client.id.length === 0 ? "fa fa-save" : "fa fa-edit"}
															onClick={() => handleEdit(client)}
															tooltip={client.id.length === 0 ? "Save" : "Edit"}
															loading={loading}
														/>
													</td>
												</tr>
											);
										})}
									</tbody>
								</Table>
							</>
						)}

						<hr />
						<BearsightPagination currentPage={page} totalPages={totalPages} pageSize={pageSize} onPageSizeChanged={handlePageSizeChanged} handleSearch={handlePageChanged} />
					</CardBody>
				</Card>

				<Modal className="modal-dialog-centered" isOpen={editOpened} size="xl" toggle={toggleEdit}>
					{selectedClient && <EditClient loading={loading} onBack={closeEdit} onUpdate={updateClient} client={selectedClient} availablePlans={plans?.data || []} />}
				</Modal>

				<Modal className="modal-dialog-centered" isOpen={createOpened} size="xl" toggle={toggleCreate}>
					<CreateClient loading={loading} availablePlans={plans?.data || []} onBack={closeCreate} onCreate={createClient} />
				</Modal>
			</Container>
		</>
	);
}

export default connector(ClientsTable);
