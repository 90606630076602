import React, { useEffect } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Form,
    Button
} from "reactstrap";
import BearsightTextInput from "toolkit/components/BearsightTextInput";
import { useStateReducer } from "toolkit/state-reducer";
import SpinningButton from "toolkit/components/SpinningButton";
import ModalCloseButton from "toolkit/components/ModalCloseButton";
import { User } from "users/redux/user";
import BearsightPasswordInput from "toolkit/components/BearsightPasswordInput";
import { notEmptyValidation, matchValidation } from "toolkit/validations";
import { ChangePasswordCredentials } from "authentication/services/credentials";

interface Props {
    loading: boolean;
    user: User;
    onBack: () => void;
    onResetPassword: (changePasswordCredentials: Partial<ChangePasswordCredentials>) => void;
}

interface State {
    newPassword?: string;
    confirmNewPassword?: string;
}

export default function ResetPassword({
    loading,
    user,
    onBack,
    onResetPassword,
}: Props) {

    const [state, setState] = useStateReducer<State>({});
    const { newPassword, confirmNewPassword } = state;
    const formIsValid: boolean =
    !notEmptyValidation(newPassword) &&
    !notEmptyValidation(confirmNewPassword) &&
    !matchValidation(newPassword)(confirmNewPassword);

    const handleResetPassword = () => {
        if (formIsValid) {
            onResetPassword({ username: user.username, newPassword: newPassword });
        }
    };

    useEffect(() => {
        if (!loading && newPassword && confirmNewPassword) {
            setState({ newPassword: undefined, confirmNewPassword: undefined });
            onBack();
        }
    }, [loading]);

    return (
        <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                    <ModalCloseButton onClose={onBack} />
                    <div className="text-muted text-center mt-2 mb-3">
                        <small>RESET USER'S PASSWORD</small>
                    </div>
                </CardHeader>
                <CardBody className="px-lg-5">
                    <Form role="form">

                        <BearsightTextInput
                            label="Username"
                            icon="fas fa-user"
                            text={user.username}
                            disabled={true}
                        />

                        <BearsightPasswordInput
                            disabled={loading}
                            label="New password"
                            onChange={(value) => setState({ newPassword: value })}
                            validation={notEmptyValidation}
                        />
                        <BearsightPasswordInput
                            disabled={loading}
                            label="Confirm new password"
                            onChange={(value) => setState({ confirmNewPassword: value })}
                            validation={matchValidation(state.newPassword)}
                            error="Passwords don't match"
                        />

                        <Row className="justify-content-end">
                            <SpinningButton
                                block={false}
                                icon="ni ni-lock-circle-open"
                                color="default"
                                type="submit"
                                loading={loading}
                                text="RESET"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleResetPassword();
                                }}
                            />
                            <Button
                                color="link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onBack();
                                }}
                            >
                                <span className="text-default">CANCEL</span>
                            </Button>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </div>
    );
}
