import React, { useState } from "react";
import { Tooltip } from "reactstrap";

interface IconButtonProps {
  onClick: () => void;
  icon: string;
  loading?: boolean;
  tooltip: string;
  id?: string;
  color?: string;
  disabled?: boolean;
  tableAction?: boolean;
  style?: Object;
}

export default function IconButton({
  id,
  loading,
  icon,
  tooltip,
  color,
  disabled,
  tableAction,
  style,
  onClick,
}: IconButtonProps): JSX.Element {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [targetId] = useState("id" + id + tooltip.split(" ").join("") + "-btn");
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const isDisabled: boolean = !!loading || !!disabled;
  const disabledClassName = isDisabled ? "bs-disabled" : "";
  return (
    <>
      <a
        className={tableAction ? "table-action" : ""}
        id={targetId}
        data-placement="top"
        href="#bearsight"
        onClick={(e) => {
          e.preventDefault();
          if (!isDisabled) {
            onClick();
          }
        }}
      >
        {loading ? (
          <span
            className={
              "spinner-border spinner-border-sm text-" + (color || "default")
            }
            role="status"
            aria-hidden="true"
          ></span>
        ) : (
          <span className="text-sm">
            <i
              className={
                icon +
                " fa-lg text-" +
                (color || "default") +
                " " +
                disabledClassName
              }
              style={style || {}}
            />
          </span>
        )}
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target={targetId}
          toggle={toggle}
        >
          {tooltip}
        </Tooltip>
      </a>
    </>
  );
}
