import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "redux/root-reducer";
import FormHeader from "toolkit/components/FormHeader";
import IconButton from "toolkit/components/IconButton";

import { Card, CardHeader, CardBody, Container, Modal } from "reactstrap";
import { actions as systemActions } from "../../redux/system-actions";
import { actions as clientActions } from "clients/redux/client-actions";
import PricingGridTable from "./PricingGridTable";
import { PricingGrid, PricingGridDetail } from "./services/pricing-grid";
import { useStateReducer } from "toolkit/state-reducer";
import PricingGridDetailsTable from "./PricingGridDetailsTable";
import PricingGridUserAssignement from "./PricingGridUserAssignement";
import { selectPricingGrids } from "clients/redux/client-selector";
import { ActiveModel } from "./pricing-grid-active-model";
import CreatePricingGrid from "./CreatePricingGrid";
import { PricingGrid as PricingGridSummary } from "clients/budgets/services/client";


const mapState = (state: RootState) => ({
  loading: state.clients.loading,
  pricingGrid: selectPricingGrids(state.clients),
  selectedPricingGridClients: state.clients.selectedPricingGridClients,
});

const mapDispatch = {
  clearClientsForPricingGrid: clientActions.clearClientsForPricingGrid,
  loadClientsForPricingGrid: clientActions.loadClientsForPricingGrid,
  addPricingGridDetails: clientActions.addPricingGridDetail,
  updatePricingGridDetails: clientActions.updatePricingGridDetails,
  deletePricingGridDetails: clientActions.deletePricingGridDetails,
  loadPricingGrids: clientActions.loadPricingGrids,
  assignClientsToPricingGrid: clientActions.assignClientsToPricingGrid,
  createPricingGrid: clientActions.createPricingGrid,
  notify: systemActions.notify,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

interface State {
  modalOpened: boolean;
  activePricingGrid?: any;
  activeModal?: ActiveModel;
  createOpened: boolean;
}

function PricingGridView({
  loading,
  pricingGrid,
  loadClientsForPricingGrid,
  loadPricingGrids,
  assignClientsToPricingGrid,
  addPricingGridDetails,
  updatePricingGridDetails,
  deletePricingGridDetails,
  selectedPricingGridClients,
  clearClientsForPricingGrid,
  createPricingGrid,
  notify,
}: Props) {
  const [state, setState] = useStateReducer<State>({
    modalOpened: false,
    createOpened: false
  });

  const { modalOpened, createOpened, activePricingGrid, activeModal } = state;

  useEffect(() => {
    loadPricingGrids();
  }, []);

  useEffect(() => {
    if (activePricingGrid) {
      const updatedActivePricingGrid = pricingGrid.find(pg => pg.id === activePricingGrid.id);
      setState({ activePricingGrid: updatedActivePricingGrid });
    }
  }, [pricingGrid]);

  const handlePricingGridSelected = (pricingGrid: PricingGridSummary) => {
    setState({
      activePricingGrid: pricingGrid,
      modalOpened: true,
      activeModal: ActiveModel.DETAILS_EDIT,
    });
  };

  const handleClientAssignementSelected = (pricingGrid: PricingGridSummary) => {
    setState({
      activePricingGrid: pricingGrid,
      activeModal: ActiveModel.CLIENT_ASSIGNEMENT,
      modalOpened: true,
    });
    loadClientsForPricingGrid(pricingGrid.id);
  };

  const handleModalClosed = () => {
    setState({
      activePricingGrid: undefined,
      modalOpened: false,
      activeModal: undefined,
    });
    clearClientsForPricingGrid();
  };

  const toggleCreate = () => {
    setState({ createOpened: !createOpened });
  };

  const closeCreate = () => {
    setState({ createOpened: false });
  };


  const handleUpdatePricingGridDetail = (detail: PricingGridDetail) => {
    if (activePricingGrid) {
      updatePricingGridDetails(activePricingGrid, { ...detail });
    }
  };

  const handleDeletePricingGridDetail = (id: string) => {
    if (activePricingGrid) {
      deletePricingGridDetails(activePricingGrid, id);
    }
  }

  const handleAddPricingGridDetail = (detail: PricingGridDetail) => {
    if (activePricingGrid) {
      addPricingGridDetails(activePricingGrid, { ...detail });
    }
  }

  const toggleModalOpened = () => {
    setState({
      modalOpened: !modalOpened,
    });
  };

  useEffect(() => {
    if (!modalOpened) {
      handleModalClosed();
    }
  }, [modalOpened]);

  const handleAssignClientToPricingGrid = (
    clientIds: string[],
    pricingGrid: PricingGrid,
    activationDate: number,
    deactivationDate?: number
  ) => {
    assignClientsToPricingGrid(
      clientIds,
      pricingGrid,
      activationDate,
      deactivationDate
    );
  };

  return (
    <>
      <FormHeader name="PRICING GRID" parentName="Clients" />
      <Container className="mt--6 d-flex justify-content-center" fluid>
        <Card className="col-lg-12">
          <CardHeader>
            <h3 className="mb-0">PRICING GRID</h3>
            <div className="d-flex flex-row-reverse">
              <span className="mr-4">
              <IconButton
                  icon="fa fa-plus"
                  onClick={() => {
                    toggleCreate();
                  }}
                  tooltip="Add New Pricing Grid"
                  loading={loading}
              />
              </span>
            </div>
          </CardHeader>
          <CardBody>
            <PricingGridTable
              loading={loading}
              pricingGrid={pricingGrid}
              onSelectPricingGrid={handlePricingGridSelected}
              onAssignClients={handleClientAssignementSelected}
            />
          </CardBody>
        </Card>

        <Modal
          className="modal-dialog-centered"
          isOpen={modalOpened}
          size="xl"
          toggle={toggleModalOpened}
        >
          {activePricingGrid && activeModal === ActiveModel.DETAILS_EDIT && (
            <PricingGridDetailsTable
              loading={loading}
              pricingGridDetails={activePricingGrid.details}
              onBack={handleModalClosed}
              onUpdateDetails={handleUpdatePricingGridDetail}
              onDeleteDetails={handleDeletePricingGridDetail}
              onAddDetails={handleAddPricingGridDetail}
            />
          )}
          {activePricingGrid &&
            activeModal === ActiveModel.CLIENT_ASSIGNEMENT && (
              <PricingGridUserAssignement
                loading={loading}
                pricingGrid={activePricingGrid}
                assignedClients={selectedPricingGridClients?.assigned}
                unAssignedClients={selectedPricingGridClients?.unassigned}
                onBack={handleModalClosed}
                assignClientToPricingGrid={handleAssignClientToPricingGrid}
              />
            )}
        </Modal>
        <Modal
          className="modal-dialog-centered"
          isOpen={createOpened}
          size="xl"
          toggle={toggleCreate}
        >
          <CreatePricingGrid
            loading={loading}
            onBack={closeCreate}
            onCreate={createPricingGrid}
          />

        </Modal>
      </Container>
    </>
  );
}

export default connector(PricingGridView);
