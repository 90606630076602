import axios, { AxiosInstance } from "axios";
import { Tokens } from "../redux/tokens";
import { defaultHeaders } from "../../toolkit/common-headers";
import { Credentials, ChangePasswordCredentials } from "./credentials";

class AuthenticationService {
  private http: AxiosInstance;

  constructor(baseUrl: string) {
    this.http = axios.create({
      baseURL: baseUrl,
    });
  }

  async authenticate(credentials: Credentials): Promise<Tokens> {
    const result = await this.http.post(
      "/users/action/authenticate",
      credentials,
      defaultHeaders
    );

    const data = result.data;
    return {
      accessToken: data.token,
      expiresAt: data.expiresAt,
      expiresIn: data.expiresIn,
    };
  }

  async changePassword(changePasswordCredentails: ChangePasswordCredentials): Promise<void> {
    return new Promise((resolve, reject)=> {
      this.http.put(
        "/users/action/change-password",
        changePasswordCredentails,
        defaultHeaders
      ).then(()=>{
        resolve();
      }).catch((error) => {
        if(error.response && error.response.data){
          let { message } = error.response.data;
          reject(message);
        }else{
          reject(error);
        }
      });
    }); 
  }
  
}

const authenticationService = new AuthenticationService(
  process.env.REACT_APP_AUTHENTICATION_API || ""
);

export default authenticationService;
