import authenticationReducer from "../authentication/redux/authentication-reducer";
import { combineReducers } from "redux";
import userFormReducer from "users/redux/user-form-reducer";
import { systemReducer } from "./system-reducer";
import recordingReducer from "calls/redux/recordings-reducer";
import clientReducer from "clients/redux/client-reducer";
import usersReducer from "users/redux/users-reducer";
import numbersReducer from "numbers/redux/numbers-reducer";
import { syncReducer } from "sync/redux/sync-reducer";
const rootReducer = combineReducers({
  authentication: authenticationReducer,
  userForm: userFormReducer,
  recordings: recordingReducer,
  clients: clientReducer,
  users: usersReducer,
  system: systemReducer,
  numbers: numbersReducer,
  sync: syncReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
