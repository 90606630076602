import axios, { AxiosInstance } from "axios";
import { withAuthorization } from "toolkit/common-headers";
import { Page } from "toolkit/pagination";
import { TwilioNumber } from "./twilio-number";

class NumbersService{

    private http: AxiosInstance;
    constructor(baseUrl: string){
        this.http = axios.create({
            baseURL: baseUrl,
        });
    }

    async twilioNumbers(
        accessToken: string,
        page: number,
        pageSize: number,
        phoneType?: string,
        query?: string,
        deleted?: boolean,
    ): Promise<Page<TwilioNumber>> {

        const result = await this.http.get(
            "/incomingNumbers",
            withAuthorization(accessToken, {
              page: page,
              size: pageSize,
              phoneType: phoneType !== undefined && phoneType.length > 0 ? phoneType : undefined,
              q: query !== undefined && query.length > 0 ? query : undefined,
              deleted: deleted !== undefined ? deleted : undefined,
            })
        );

        const data = result.data;

        if (data === undefined) {
            return Promise.resolve({
                page: 0,
                totalPages: 0,
                hasMorePages: false,
                data: [],
            });
        }

        return {
            page: data.page,
            totalPages: data.totalPages,
            hasMorePages: data.hasMorePages,
            data: data.items.map((twilioNumber:any) => {
                return {
                    id:twilioNumber.id,
                    phoneNumber: twilioNumber.phoneNumber,
                    friendlyName: twilioNumber.friendlyName,
                    phoneType: twilioNumber.phoneType,
                    isDeleted: twilioNumber.isDeleted
                }
            })
        }

    }

    async updateTwilioNumber(
        accessToken: string,
        numberId: string,
        numberType: string,
        deleted: boolean
    ): Promise<void> {
        
        await this.http.put(
            `/incomingNumbers/${numberId}`,
            {
                numberType,
                deleted
            },
            withAuthorization(accessToken)
        );
        return Promise.resolve();
    }

}


const numbersService = new NumbersService(
    process.env.REACT_APP_TWILIO_NUMBERS_API || ""
)

export default numbersService;