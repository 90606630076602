import React, { useEffect } from "react";

import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Form,
    Button,
    FormGroup,
    Input,
} from "reactstrap";
import BearsightTextInput from "toolkit/components/BearsightTextInput";
import { useStateReducer } from "toolkit/state-reducer";
import SpinningButton from "toolkit/components/SpinningButton";
import { Client, Plan, UpdateClientPayload } from "clients/budgets/services/client";
import ModalCloseButton from "toolkit/components/ModalCloseButton";
import BearsightMultiselect from "toolkit/components/BearsightMultiselect";

interface Props {
    loading: boolean;
    client: Client;
    availablePlans: Array<Plan>;
    onBack: () => void;
    onUpdate: (client: UpdateClientPayload) => void;
}

interface State {
    name?: string;
    status?: Status;
    selectedPlan?: Plan;
}

enum Status {
    Active = "Active",
    Inactive = "Inactive",
    Blacklisted = "Blacklisted",
}


const STATUSES = [
    Status.Active,
    Status.Inactive,
    Status.Blacklisted
];

const DEFAULT_STATUS = Status.Active;

export default function EditClient({
    loading,
    client,
    availablePlans,
    onBack,
    onUpdate,
}: Props) {

    const [state, setState] = useStateReducer<State>({});
    const { name, status, selectedPlan } = state;

    useEffect(() => {
        setState(
            { name: client.name, status: DEFAULT_STATUS }
        );
    }, [client.id]);

    const handleEditClient = () => {
        if (name && status) {
            onUpdate({
                id: client.id,
                name,
                status: status,
                planId:selectedPlan ? selectedPlan.id : undefined,
            });
        }
    };

    return (
        <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                    <ModalCloseButton onClose={onBack} />
                    <div className="text-muted text-center mt-2 mb-3">
                        <small>EDIT CLIENT</small>
                    </div>
                </CardHeader>
                <CardBody className="px-lg-5">
                    <Form role="form">

                        <BearsightTextInput
                            label="Name"
                            icon="fas fa-user"
                            text={name}
                            onChange={(value) => setState({ name: value })}
                        />

                        <FormGroup>
                            <label className="form-control-label">Status</label>
                            <Input
                                type="select"
                                name="status"
                                id="status"
                                onChange={(e: any) => {
                                    const selectedIds = [...e.target.selectedOptions].map(
                                        (option: any) => option.value
                                    );

                                    setState({
                                        status: selectedIds[0],
                                    });
                                }}

                            >
                                {STATUSES.map(s => <option defaultValue={status} value={s}>{s}</option>)}
                            </Input>
                        </FormGroup>

                        <BearsightMultiselect<Plan>
                            isMulti={false}
                            disabled={loading}
                            loading={loading}
                            options={availablePlans}
                            selected={selectedPlan ? [selectedPlan] : undefined}
                            label="Plan"
                            onChange={(value: Plan[]) =>
                                setState({ selectedPlan: value[0] })
                            }
                            displayValue="name"
                        />

                        <Row className="justify-content-end">
                            <SpinningButton
                                block={false}
                                icon="fa fa-save"
                                color="default"
                                type="submit"
                                loading={loading}
                                text="Edit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleEditClient();
                                }}
                            />
                            <Button
                                color="link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onBack();
                                }}
                            >
                                <span className="text-default">CANCEL</span>
                            </Button>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </div>
    );
}
