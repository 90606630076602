import React, { useRef, useEffect } from "react";
import NotificationAlert from "react-notification-alert";
import { RootState } from "redux/root-reducer";
import { connect, ConnectedProps } from "react-redux";
import { actions as systemActions } from "../../redux/system-actions";

const mapState = (state: RootState) => ({
  title: state.system.notification?.title,
  message: state.system.notification?.message,
  type: state.system.notification?.type,
});

const mapDispatch = {
  clearNotification: systemActions.clearNotification,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

function Notifications({
  title,
  message,
  type,
  clearNotification,
}: Props): JSX.Element {
  const notificationRef = useRef<any>(null);

  const notify = () => {
    const options: any = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type ? type : "default",
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };

    notificationRef.current?.notificationAlert(options);
    clearNotification();
  };

  useEffect(() => {
    if (title && message) {
      notify();
    }
  }, [title, message]);

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationRef} />
      </div>
    </>
  );
}

export default connector(Notifications);
