import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "redux/root-reducer";
import FormHeader from "toolkit/components/FormHeader";

import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";
import SpinningButton from "../../toolkit/components/SpinningButton";
import BearsightPasswordInput from "toolkit/components/BearsightPasswordInput";
import BearsightTextInput from "toolkit/components/BearsightTextInput";
import { actions } from "users/redux/user-form-actions";
import { actions as systemActions } from "../../redux/system-actions";
import {
  notEmptyValidation,
  matchValidation,
  emailValidation,
} from "toolkit/validations";
import ConfirmationPopup from "toolkit/components/ConfirmationPopup";
import { useStateReducer } from "toolkit/state-reducer";

const mapState = (state: RootState) => ({
  loading: state.userForm.loading,
  succeed: state.userForm.succeed,
  error: state.userForm.error,
});

const mapDispatch = {
  createUser: actions.createUser,
  clearSuccess: actions.clearSuccess,
  clearError: actions.clearError,
  notify: systemActions.notify,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

interface UserRegistrationState {
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  submitted: boolean;
  confirmationOpened: boolean;
}

function UserRegistration({
  loading,
  succeed,
  error,
  createUser,
  clearSuccess,
  clearError,
  notify,
}: Props) {
  const initialState: UserRegistrationState = {
    submitted: false,
    confirmationOpened: false,
  };

  const [state, setState] = useStateReducer<UserRegistrationState>(
    initialState
  );

  const {
    firstName,
    lastName,
    username,
    email,
    password,
    confirmPassword,
    submitted,
    confirmationOpened,
  } = state;

  const formIsValid: boolean =
    !notEmptyValidation(firstName) &&
    !notEmptyValidation(lastName) &&
    !notEmptyValidation(password) &&
    !notEmptyValidation(username) &&
    !matchValidation(password)(confirmPassword) &&
    !emailValidation(email);

  const openConfirmation = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setState({ confirmationOpened: true });
  };

  const handleCancel = () => {
    setState({ confirmationOpened: false });
  };

  const handleConfirm = () => {
    handleCreate();
  };

  const handleCreate = () => {
    setState({ submitted: true, confirmationOpened: false });

    if (
      firstName &&
      lastName &&
      password &&
      confirmPassword &&
      username &&
      email &&
      formIsValid
    ) {
      createUser({
        firstName,
        lastName,
        username,
        email,
        password,
      });
    }
  };

  useEffect(() => {
    if (succeed) {
      clearSuccess();
      notify("Registration", "User successfully registed.");
    }
  }, [succeed]);

  useEffect(() => {
    if (error) {
      clearError();
      notify(
        "Registration",
        typeof error === "string" ? error : "Failed to register user.",
        "danger"
      );
    }
  }, [error]);

  return (
    <>
      <FormHeader name="Add new user" parentName="Portal users" />
      <Container className="mt--6 d-flex justify-content-center" fluid>
        <Card className="col-lg-6">
          <CardHeader>
            <h3 className="mb-0">USER REGISTRATION</h3>
          </CardHeader>
          <CardBody>
            <Form
              onSubmit={openConfirmation}
              className="needs-validation"
              noValidate
            >
              <Row>
                <Col md="6">
                  <BearsightTextInput
                    disabled={loading}
                    label="First name"
                    icon="fas fa-user"
                    onChange={(value) => setState({ firstName: value })}
                    validation={notEmptyValidation}
                    submitted={submitted}
                  />
                </Col>
                <Col md="6">
                  <BearsightTextInput
                    disabled={loading}
                    label="Last name"
                    icon="fas fa-user"
                    onChange={(value) => setState({ lastName: value })}
                    validation={notEmptyValidation}
                    submitted={submitted}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BearsightTextInput
                    disabled={loading}
                    label="Username"
                    icon="fa fa-address-book"
                    onChange={(value) => setState({ username: value })}
                    validation={notEmptyValidation}
                    submitted={submitted}
                    error="Invalid username"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BearsightTextInput
                    disabled={loading}
                    label="Email"
                    icon="fas fa-envelope"
                    onChange={(value) => setState({ email: value })}
                    validation={emailValidation}
                    submitted={submitted}
                    error="Invalid email"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BearsightPasswordInput
                    disabled={loading}
                    onChange={(value) => setState({ password: value })}
                    validation={notEmptyValidation}
                    submitted={submitted}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BearsightPasswordInput
                    disabled={loading}
                    label="Confirm password"
                    onChange={(value) => setState({ confirmPassword: value })}
                    validation={matchValidation(state.password)}
                    error="Passwords dont match"
                    submitted={submitted}
                  />
                </Col>
              </Row>
              <Row className="mt-6">
                <Col>
                  <SpinningButton
                    icon="fa fa-plus"
                    disabled={!formIsValid}
                    color="default"
                    loading={loading}
                    text="REGISTER"
                    type="submit"
                  />
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>

        <ConfirmationPopup
          title="Registration confirmation"
          message="You are about to create a new user. You want to continue ?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          opened={confirmationOpened}
        />
      </Container>
    </>
  );
}

export default connector(UserRegistration);
