import React, { useEffect } from "react";

import { Row, Form, Card, CardBody, Spinner, Button, Table, FormGroup, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { useStateReducer } from "toolkit/state-reducer";
import { Client, ClientGoogleAds, GoogleAdsAccount } from "clients/budgets/services/client";
import BearsightCardHeader from "toolkit/components/BearsightCardHeader";
import DateRange from "calls/recordings/DateRange";
import SpinningButton from "toolkit/components/SpinningButton";
import { GoogleAdsAttributionMode } from "./google-ads-attribution-mode";
import ReactDatetime from "react-datetime";
import { Moment } from "moment";
import IconButton from "toolkit/components/IconButton";
import { epochNow } from "toolkit/clock";
import BearsightMultiselect from "toolkit/components/BearsightMultiselect";

type Props = {
    from?: "CLIENT_MANAGEMENT" | "GOOGLE_ADS"
    client?: Client;
    loading: boolean;
    availableGoogleAdsAccounts?: GoogleAdsAccount[];
    onClose: () => void;
    onAttributeGoogleAdsAccount: (clientId: string, googleAdsAccountId: string, activationDate: number, deactivationDate?: number) => void;
    onUpdateGoogleAdsAccount: (clientId: string, googleAdsAccountId: string, activationDate: number, deactivationDate?: number) => void;
    loadClientGoogleAdsAccounts: (activationDate: number) => void;
};

interface State {
    activationDate?: number;
    deactivationDate?: number;
    selectedGoogleAdsAccount?: GoogleAdsAccount;
    attributedGoogleAdsAccounts?: ClientGoogleAds[];
    mode: GoogleAdsAttributionMode;
}

export default function GoogleAdsAttribution({
    from,
    client,
    loading,
    availableGoogleAdsAccounts,
    onAttributeGoogleAdsAccount,
    onUpdateGoogleAdsAccount,
    loadClientGoogleAdsAccounts,
    onClose,
}: Props
) {
    console.log(from)
    const initialState: State = {
        activationDate: epochNow(),
        mode: from === "CLIENT_MANAGEMENT" ? GoogleAdsAttributionMode.ATTRIBUTION : GoogleAdsAttributionMode.ATTRIBUTED,
    };

    const [state, setState] = useStateReducer<State>(initialState);

    const { mode, attributedGoogleAdsAccounts, selectedGoogleAdsAccount, activationDate, deactivationDate } = state;

    useEffect(() => {
        if (activationDate !== undefined) {
            loadClientGoogleAdsAccounts(activationDate);
        }
    }, [activationDate]);

    const handleAttribute = () => {
        if (selectedGoogleAdsAccount && client && activationDate) {
            onAttributeGoogleAdsAccount(client.id, selectedGoogleAdsAccount.customerId, activationDate, deactivationDate);
        }
    };

    const handleClose = () => {
        setState({
            activationDate: undefined,
            deactivationDate: undefined,
        });
        onClose();
    };

    const handleCancel = () => {
        if(from === "CLIENT_MANAGEMENT"){
            handleClose();
        }else {
            setState({
                mode: GoogleAdsAttributionMode.ATTRIBUTED,
            });
        } 
    };

    const goToAttributionMode = () => {
        setState({
            mode: GoogleAdsAttributionMode.ATTRIBUTION,
        });
    };

    const handleEditGoogleAccount = (googleAdsAccount: ClientGoogleAds) => {
        if (client) {
            onUpdateGoogleAdsAccount(client.id, googleAdsAccount.GoogleAdsAccount.customerId, googleAdsAccount.activationDate, googleAdsAccount.deactivationDate);
        }
    };

    useEffect(() => {
        if (client && client.googleAdsAccounts) {
            setState({
                attributedGoogleAdsAccounts: [
                    ...client.googleAdsAccounts
                        .map(p => {
                            return { ...p };
                        })
                ],
                mode: from === "CLIENT_MANAGEMENT" ? GoogleAdsAttributionMode.ATTRIBUTION : GoogleAdsAttributionMode.ATTRIBUTED
            });
        }
    }, [client && client.googleAdsAccounts ? JSON.stringify(client.googleAdsAccounts) : undefined]);

    if (loading && !availableGoogleAdsAccounts) {
        return <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
                <BearsightCardHeader
                    onClose={handleClose}
                    title="CLIENT"
                    subTitle={`${client?.name} (${client?.id})`}
                />
                <CardBody className="px-lg-5">
                    <div className="text-center text-muted mb-4">
                        <small>GOOGLE ADS ACCOUNTS</small>
                    </div>
                    <Row className="justify-content-center">
                        <Spinner />
                    </Row>
                </CardBody>
            </Card>
        </div>
    }

    if (mode === GoogleAdsAttributionMode.ATTRIBUTED) {
        return (
            <>
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <BearsightCardHeader
                            onClose={handleClose}
                            title="CLIENT"
                            subTitle={`${client?.name} (${client?.id})`}
                        />
                        <CardBody className="px-lg-5">
                            <div className="text-center text-muted mb-4">
                                <small>GOOGLE ADS ACCOUNTS</small>
                            </div>

                            <Table size="sm" className="align-items-center" hover striped>
                                <thead className="thead-light">
                                    <tr>
                                        {["Id", "Google ads account Name", "Activation date", "Deactivation date", ""].map(
                                            (name) => (
                                                <th key={name} scope="col">
                                                    {name}
                                                </th>
                                            )
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {attributedGoogleAdsAccounts && attributedGoogleAdsAccounts
                                        .map((googleAdsAccount: ClientGoogleAds) => {
                                            return (
                                                <tr key={googleAdsAccount.associationId}>
                                                    <td>{googleAdsAccount.GoogleAdsAccount.customerId}</td>
                                                    <th scope="row">
                                                        {googleAdsAccount.GoogleAdsAccount.name}
                                                    </th>

                                                    <td>
                                                        <FormGroup style={{ marginBottom: 0.0 }}>
                                                            <InputGroup className="input-group-alternative">
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>
                                                                        <i className="ni ni-calendar-grid-58" />
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <ReactDatetime
                                                                    defaultValue={new Date(googleAdsAccount.activationDate)}
                                                                    inputProps={{
                                                                        placeholder: "Activation date",
                                                                    }}
                                                                    timeFormat={false}
                                                                    onChange={(e: Moment | string) => {
                                                                        if (typeof e !== "string") {
                                                                            const date = parseInt(
                                                                                e.valueOf().toString()
                                                                            );
                                                                            googleAdsAccount.activationDate = date;
                                                                        }
                                                                    }}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </td>

                                                    <td>
                                                        <FormGroup style={{ marginBottom: 0.0 }}>
                                                            <InputGroup className="input-group-alternative">
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>
                                                                        <i className="ni ni-calendar-grid-58" />
                                                                    </InputGroupText>
                                                                </InputGroupAddon>

                                                                <ReactDatetime
                                                                    defaultValue={
                                                                        googleAdsAccount.deactivationDate &&
                                                                            googleAdsAccount.deactivationDate !== 0
                                                                            ? new Date(googleAdsAccount.deactivationDate)
                                                                            : undefined
                                                                    }
                                                                    inputProps={{
                                                                        placeholder: "Deactivation date",
                                                                    }}
                                                                    timeFormat={false}
                                                                    onChange={(e: Moment | string) => {
                                                                        if (typeof e !== "string") {
                                                                            const date = parseInt(
                                                                                e.valueOf().toString()
                                                                            );
                                                                            googleAdsAccount.deactivationDate = date;
                                                                        } else {
                                                                            googleAdsAccount.deactivationDate = undefined;  
                                                                        }
                                                                    }}
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </td>

                                                    <td>
                                                        <IconButton
                                                            id={googleAdsAccount.associationId}
                                                            color="default"
                                                            icon="fa fa-save"
                                                            onClick={() => handleEditGoogleAccount(googleAdsAccount)}
                                                            tooltip="Edit google ads account"
                                                            loading={loading}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <Row className="justify-content-end">
                                <SpinningButton
                                    block={false}
                                    icon="fa fa-plus"
                                    color="default"
                                    loading={loading}
                                    text="Allocate new google ads account"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        goToAttributionMode();
                                    }}
                                />
                                <Button
                                    color="link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onClose();
                                    }}
                                >
                                    <span className="text-default">CANCEL</span>
                                </Button>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </>
        );
    }

    let newAvailableGoogleAdsAccounts: GoogleAdsAccount[] = [];
    if (availableGoogleAdsAccounts) {
        newAvailableGoogleAdsAccounts = availableGoogleAdsAccounts?.map(aGAA => {
            const newAGAA: GoogleAdsAccount = { ...aGAA };
            newAGAA.name = `${newAGAA.name} (${newAGAA.customerId})`;
            return newAGAA;
        })
    }

    return (
        <>
            <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                    <BearsightCardHeader
                        onClose={handleClose}
                        title="CLIENT"
                        subTitle={`${client?.name} (${client?.id})`}
                    />
                    <CardBody className="px-lg-5">
                        <div className="text-center text-muted mb-4">
                            <small>GOOGLE ADS ACCOUNTS</small>
                        </div>
                        <Form role="form">

                            <>
                                <DateRange
                                    defaultStartDate={epochNow()}
                                    startLabel="Activation date"
                                    endLabel="Deactivation date"
                                    onStartDateChange={(date) => setState({ activationDate: date })}
                                    onEndDateChange={(date) => setState({ deactivationDate: date })}
                                />

                                {availableGoogleAdsAccounts &&
                                    <BearsightMultiselect<GoogleAdsAccount>
                                        isMulti={false}
                                        disabled={loading}
                                        loading={loading}
                                        options={newAvailableGoogleAdsAccounts}
                                        selected={selectedGoogleAdsAccount ? [selectedGoogleAdsAccount] : undefined}
                                        label="Google ads account"
                                        onChange={(value: GoogleAdsAccount[]) => {
                                            setState({ selectedGoogleAdsAccount: value[0] });
                                        }}
                                        displayValue="name"
                                    />
                                }

                                <Row className="justify-content-end">
                                    <SpinningButton
                                        block={false}
                                        icon="fa fa-plus"
                                        color="default"
                                        type="submit"
                                        loading={loading}
                                        text="Add"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleAttribute();
                                        }}
                                    />
                                    <Button
                                        color="link"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleCancel();
                                        }}
                                    >
                                        <span className="text-default">CANCEL</span>
                                    </Button>
                                </Row>
                            </>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </>
    );
}
